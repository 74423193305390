import { Box as MuiBox } from '@mui/material';

import { DrawerComponent } from '../../../../library/organisms';
import { ButtonAtom } from '../../../../library/atoms';
import { useAuth0 } from '@auth0/auth0-react';
import { commonStrings } from '../../../../../assets/strings/sv';
import { Header } from './styles';

export const HeaderComponent = () => {
  const { loginWithRedirect } = useAuth0();

  const handleLoginUser = () => {
    // User will always be redirected to dashboard after logging in
    loginWithRedirect({
      appState: { returnTo: '/dashboard' },
    });
  };

  const handleCreateAccount = () => {
    loginWithRedirect({ authorizationParams: { screen_hint: 'signup' } });
  };

  return (
    <Header>
      <MuiBox display="flex" alignItems="center">
        <DrawerComponent isDrawerPermanent={false} />
      </MuiBox>
      <MuiBox display="flex" alignItems="center" gap={2}>
        <ButtonAtom
          text={commonStrings.register}
          variant="gradient"
          onClick={handleCreateAccount}
        />
        <ButtonAtom text={commonStrings.login} onClick={handleLoginUser} />
      </MuiBox>
    </Header>
  );
};
