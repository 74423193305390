import {
  BookOutlined,
  FolderOutlined,
  GamesOutlined,
  HouseOutlined,
  LocationOnOutlined,
  TimerOutlined,
} from '@mui/icons-material';
import {
  Grid as MuiGrid,
  Typography as MuiTypography,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import { homePageStrings } from '../../../../../assets/strings/sv';
import { Bulletpoints } from './styles';

export const BulletpointsComponent = () => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  const { bulletpoints } = homePageStrings;

  const stringToIcon = (icon: string) => {
    switch (icon) {
      case 'time': {
        return <TimerOutlined />;
      }
      case 'game': {
        return <GamesOutlined />;
      }
      case 'learn': {
        return <BookOutlined />;
      }
      case 'organize': {
        return <FolderOutlined />;
      }
      case 'availability': {
        return <LocationOnOutlined />;
      }
      case 'community': {
        return <HouseOutlined />;
      }
      default: {
        return <BookOutlined />;
      }
    }
  };

  return (
    <Bulletpoints>
      {isDesktop && (
        <MuiTypography variant="h3">{bulletpoints.title}</MuiTypography>
      )}
      <MuiGrid container spacing={7}>
        {bulletpoints.sections.map(({ title, text, icon }, index) => (
          <MuiGrid item xs={12} sm={6} md={4} key={index}>
            {stringToIcon(icon)}
            <MuiTypography variant="h6">{title}</MuiTypography>
            <MuiTypography variant="body1">{text}</MuiTypography>
          </MuiGrid>
        ))}
      </MuiGrid>
    </Bulletpoints>
  );
};
