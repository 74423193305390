import { useState } from 'react';
import { differenceInDays } from 'date-fns';

import { RatingContext } from './context';
import { RatingPopup } from './state';
import { useUsageContext } from '../../hooks';
import { GARatingCategory } from '../usage';
import { getTodayUnix } from '../../components/helpers';

interface IRatingProvider {
  children: React.ReactNode;
}

enum LocalStorage {
  LAST_RATING_DATE = 'lastRatingDate',
}

export const RatingProvider = ({ children }: IRatingProvider) => {
  const { collectRatingStats, collectRatingAppearanceStats } =
    useUsageContext();

  const [popup, setPopup] = useState<RatingPopup | null>(null);
  const [isOpen, setIsOpen] = useState(false);

  const handleChangeRating = (rating: number | null) => {
    if (!popup || !rating) return;
    setPopup({ ...popup, rating });

    saveLastRating(popup.category);
    collectRatingStats(rating, popup.category);
  };

  const handleOpenPopup = (
    title: string,
    category: GARatingCategory,
    delaySeconds: number = 0
  ) => {
    if (!isRatingDue(category)) return;

    setTimeout(() => {
      setPopup({ title, category, rating: null });
      setIsOpen(true);
    }, delaySeconds * 1000);
    collectRatingAppearanceStats();
  };

  const isRatingDue = (category: GARatingCategory) => {
    const data = localStorage.getItem(
      `${LocalStorage.LAST_RATING_DATE}_${category}`
    );
    if (data) {
      const lastRating: number = JSON.parse(data);
      const daysSinceLastRating = differenceInDays(getTodayUnix(), lastRating);
      if (daysSinceLastRating > 7) {
        // Over a week since the user last rated this category
        return true;
      }
      return false;
    }
    if (!data) {
      return true;
    }
  };

  const saveLastRating = (category: GARatingCategory) => {
    localStorage.setItem(
      `${LocalStorage.LAST_RATING_DATE}_${category}`,
      JSON.stringify(getTodayUnix())
    );
  };

  const handleClosePopup = () => {
    popup && saveLastRating(popup.category);
    setIsOpen(false);
  };

  const config = {
    popup,
    isOpen,
    handleChangeRating,
    handleOpenPopup,
    handleClosePopup,
  };

  return (
    <RatingContext.Provider value={config}>{children}</RatingContext.Provider>
  );
};
