import { Grid as MuiGrid } from '@mui/material';
import { ModuleSkeletonComponent } from './components';

interface IModuleGridMolecule {
  modules: JSX.Element[];
  isLoading?: boolean;
}

export const ModuleGridMolecule = ({
  modules,
  isLoading,
}: IModuleGridMolecule) => {
  return (
    <MuiGrid container spacing={2}>
      {isLoading ? (
        Array.from({ length: 10 }, (_, index) => (
          <MuiGrid item xs={6} sm={4} md={4} lg={3} xl={2} key={index}>
            <ModuleSkeletonComponent />
          </MuiGrid>
        ))
      ) : (
        <>
          {modules.map((module, index) => (
            <MuiGrid item key={index} xs={6} sm={4} md={4} lg={3} xl={2}>
              {module}
            </MuiGrid>
          ))}
        </>
      )}
    </MuiGrid>
  );
};
