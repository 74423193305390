import { useMediaQuery, useTheme } from '@mui/material';
import React from 'react';

import commonStrings from '../../../../../../assets/strings/sv/common.json';
import { QuizState } from '../../../../../utils';
import { ButtonAtom } from '../../../../atoms';
import { UserAnswer } from '../../types';
import { FullscreenModalMolecule } from '../../../../molecules';

interface IQuizModalComponent {
  handleCloseModal: () => void;
  isQuizModalOpen: boolean;
  children?: React.ReactNode;
  title?: string | null;
  handleRetryWrongAnswers: () => void;
  handleRestartQuiz: () => void;
  quizState: QuizState;
  incorrectAnswers: UserAnswer[];
}
export const QuizModalComponent = ({
  handleCloseModal,
  isQuizModalOpen,
  children,
  title,
  incorrectAnswers,
  quizState,
  handleRetryWrongAnswers,
  handleRestartQuiz,
}: IQuizModalComponent) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));
  const { quizSection } = commonStrings.studyMaterialSection;

  return (
    <FullscreenModalMolecule
      isOpen={isQuizModalOpen}
      handleClose={handleCloseModal}
      title={title || ''}
      headerElement={
        quizState === QuizState.CLOSE && isDesktop ? (
          <>
            <ButtonAtom
              variant="gradient"
              text={quizSection.quizIsDone.startOver}
              onClick={() => handleRestartQuiz()}
            />
            {incorrectAnswers.length ? (
              <ButtonAtom
                variant="border"
                text={quizSection.quizIsDone.startOverIncorrectAnswers}
                sx={{ flex: 'none' }}
                onClick={handleRetryWrongAnswers}
              />
            ) : (
              <></>
            )}
          </>
        ) : undefined
      }
      children={children}
    />
  );
};
