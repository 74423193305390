import { useMediaQuery, useTheme, Box as MuiBox } from '@mui/material';
import {
  SettingsOutlined,
  ShareOutlined,
  TuneOutlined,
} from '@mui/icons-material';

import { HeaderAction } from '../../types';
import { IconButtonAtom } from '../../../../../../library/atoms';
import { useState } from 'react';
import {
  PopperMenuItem,
  PopperMenuMolecule,
} from '../../../../../../library/molecules';
import { useStudyMaterialContext } from '../../../../../../../hooks';
import { GetStudyMaterialQuery } from '../../../../../../../generated';
import {
  BookmarkComponent,
  PublishComponent,
  SaveComponent,
  ShareComponent,
} from './components';
import { EditComponent } from './components/edit';

interface IActionsComponent {
  actions: HeaderAction[];
  studyMaterial: GetStudyMaterialQuery['getStudyMaterial'];
}

export const ActionsComponent = ({
  actions,
  studyMaterial,
}: IActionsComponent) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const { bookmarkStudyMaterial, publishStudyMaterial, saveStudyMaterial } =
    useStudyMaterialContext();

  const [isShareModalOpen, setIsShareStudyMaterialModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const handleOpenShareModal = () => setIsShareStudyMaterialModalOpen(true);
  const handleCloseShareModal = () => setIsShareStudyMaterialModalOpen(false);

  const handleOpenEditModal = () => setIsEditModalOpen(true);
  const handleCloseEditModal = () => setIsEditModalOpen(false);

  // eslint-disable-next-line array-callback-return
  const menuItems: PopperMenuItem[] = actions?.map((action) => {
    switch (action) {
      case 'edit': {
        return {
          text: 'Redigera lektion',
          onClick: handleOpenEditModal,
        };
      }
      case 'bookmark': {
        return {
          text: 'Bokmarkera lektion',
          onClick: () => bookmarkStudyMaterial(studyMaterial.id),
        };
      }
      case 'publish': {
        return {
          text: studyMaterial.flags?.isPublished
            ? 'Avpublicera lektion'
            : 'Publicera lektion',
          onClick: () => publishStudyMaterial(studyMaterial.id),
        };
      }
      case 'share': {
        return {
          text: 'Dela lektion',
          onClick: handleOpenShareModal,
        };
      }
      case 'save': {
        return {
          text: 'Spara lektion',
          onClick: () => saveStudyMaterial(studyMaterial.id),
        };
      }
    }
  });

  const renderAction = (
    action: HeaderAction,
    studyMaterial: GetStudyMaterialQuery['getStudyMaterial']
  ) => {
    switch (action) {
      case 'edit':
        return (
          <IconButtonAtom
            icon={<TuneOutlined />}
            onClick={handleOpenEditModal}
          />
        );
      case 'share':
        return (
          <IconButtonAtom
            icon={<ShareOutlined />}
            onClick={handleOpenShareModal}
          />
        );
      case 'bookmark':
        return <BookmarkComponent studyMaterial={studyMaterial} />;
      case 'publish':
        return <PublishComponent studyMaterial={studyMaterial} />;
      case 'save':
        return <SaveComponent studyMaterial={studyMaterial} />;
    }
  };

  const Modals = () => {
    return (
      <>
        <ShareComponent
          studyMaterial={studyMaterial}
          isModalOpen={isShareModalOpen}
          handleCloseModal={handleCloseShareModal}
        />
        <EditComponent
          studyMaterial={studyMaterial}
          isModalOpen={isEditModalOpen}
          handleCloseModal={handleCloseEditModal}
        />
      </>
    );
  };

  return (
    <>
      <Modals />
      {isMobile ? (
        <PopperMenuMolecule
          icon={<SettingsOutlined />}
          shape="round"
          menuItems={menuItems}
          data={studyMaterial}
        />
      ) : (
        <MuiBox display="flex" alignItems="center" gap={2}>
          {actions.map((action, index) => (
            <MuiBox key={index}>{renderAction(action, studyMaterial)}</MuiBox>
          ))}
        </MuiBox>
      )}
    </>
  );
};
