import React, { useEffect, useMemo, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import {
  ExploreOutlined,
  HomeOutlined,
  LogoutOutlined,
  Menu,
  OpenInNew,
  SchoolOutlined,
  SettingsOutlined,
  ShareOutlined,
  TextFieldsOutlined,
} from '@mui/icons-material';
import { Divider, Box as MuiBox, useMediaQuery, useTheme } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';

import logo from '../../../../assets/images/logo.png';
import { commonStrings } from '../../../../assets/strings/sv';
import { AvatarAtom, ButtonAtom, IconButtonAtom } from '../../atoms';
import { DrawerUrl, drawerWidthLarge, drawerWidthSmall } from '../../../utils';
import { MenuItemComponent, ShareComponent } from './components';
import { Drawer, InnerDrawer, MenuContainer } from './styles';

interface IDrawerComponent {
  isDrawerPermanent?: boolean;
}

export const DrawerComponent = ({
  isDrawerPermanent = true,
}: IDrawerComponent) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const isTablet = useMediaQuery(theme.breakpoints.only('md'));

  const { isAuthenticated, user, logout: handleLogout } = useAuth0();

  const [mobileDrawerIsOpen, setMobileDrawerIsOpen] = useState(false);
  const [isShareQurieModalOpen, setIsShareQurieModalOpen] = useState(false);
  const [currentUrlPath, setCurrentUrlPath] = useState<DrawerUrl>();

  const { dashboardLink, logout } = commonStrings;
  const { drawer } = commonStrings;

  const _isDrawerPermanent = useMemo(
    () => isDrawerPermanent && !isMobile,
    [isDrawerPermanent, isMobile]
  );

  useEffect(() => {
    const path = location.pathname.replace('/', '');

    if (path !== currentUrlPath) {
      setCurrentUrlPath(path ? (path as DrawerUrl) : undefined);
    }
  }, [location.pathname, currentUrlPath]);

  const handleLogoutUser = () => {
    if (user) {
      handleLogout({ logoutParams: { returnTo: window.location.origin } });
    }
  };

  const handleSelectMenuItem = (url?: DrawerUrl) => {
    setMobileDrawerIsOpen(!mobileDrawerIsOpen);
    navigate(`/${url ? url : ''}`);
  };

  const handleDrawerToggle = () => {
    setMobileDrawerIsOpen(!mobileDrawerIsOpen);
  };

  const handleCloseShareQurieModal = () => {
    setIsShareQurieModalOpen(false);
  };

  const handleOpenShareQurieModal = () => {
    setIsShareQurieModalOpen(true);
  };

  const authenticatedMenuItems = [
    <MenuItemComponent
      text={drawer.menuItems.authenticated.dashboard}
      onClick={() => handleSelectMenuItem(DrawerUrl.DASHBOARD)}
      icon={<SchoolOutlined />}
      isSelected={!currentUrlPath || currentUrlPath === DrawerUrl.DASHBOARD}
    />,
    <MenuItemComponent
      text={drawer.menuItems.authenticated.explore}
      onClick={() => handleSelectMenuItem(DrawerUrl.EXPLORE)}
      icon={<ExploreOutlined />}
      isSelected={currentUrlPath === DrawerUrl.EXPLORE}
    />,
    <MenuItemComponent
      text={drawer.menuItems.authenticated.write}
      onClick={() => {}}
      disabled
      icon={<TextFieldsOutlined />}
    />,
    <MenuItemComponent
      text={drawer.menuItems.authenticated.settings}
      onClick={() => {}}
      disabled
      icon={<SettingsOutlined />}
    />,
  ];

  const unauthenticatedMenuItems = [
    <MenuItemComponent
      text={drawer.menuItems.unathenticated.dashboard}
      onClick={() => handleSelectMenuItem(undefined)}
      icon={<HomeOutlined />}
      isSelected={!currentUrlPath}
    />,
    <MenuItemComponent
      text={drawer.menuItems.unathenticated.explore}
      onClick={() => handleSelectMenuItem(DrawerUrl.EXPLORE)}
      icon={<ExploreOutlined />}
      isSelected={currentUrlPath === DrawerUrl.EXPLORE}
    />,
  ];

  const menuItems = isAuthenticated
    ? authenticatedMenuItems
    : unauthenticatedMenuItems;

  const innerDrawerContainer = (
    <InnerDrawer width={isTablet ? drawerWidthSmall : drawerWidthLarge}>
      <MuiBox alignSelf="center">
        <AvatarAtom alt="avatar" src={user?.picture || logo} />
      </MuiBox>
      <MenuContainer>
        {menuItems.map((menuItem, index) => (
          <MuiBox key={index}>{menuItem}</MuiBox>
        ))}
        <Divider />
        <MenuItemComponent
          text={drawer.menuItems.common.qurie}
          onClick={() => window.open(dashboardLink, '_blank')}
          icon={<OpenInNew />}
          isSelected={false}
        />
        <MenuItemComponent
          text={drawer.menuItems.common.share}
          onClick={handleOpenShareQurieModal}
          icon={<ShareOutlined />}
          isSelected={false}
        />
      </MenuContainer>
      {isAuthenticated &&
        (isTablet ? (
          <MenuItemComponent
            icon={<LogoutOutlined />}
            sx={{ minWidth: '100%' }}
            onClick={handleLogoutUser}
          />
        ) : (
          <ButtonAtom
            variant="border"
            text={logout}
            sx={{ minWidth: '100%' }}
            onClick={handleLogoutUser}
          />
        ))}
    </InnerDrawer>
  );

  return (
    <>
      {!_isDrawerPermanent && (
        <MuiBox>
          <IconButtonAtom
            onClick={handleDrawerToggle}
            icon={<Menu />}
            shape="round"
          />
        </MuiBox>
      )}

      <ShareComponent
        isOpen={isShareQurieModalOpen}
        handleClose={handleCloseShareQurieModal}
      />

      <Drawer
        anchor="left"
        isDrawerPermanent={_isDrawerPermanent}
        variant={_isDrawerPermanent ? 'permanent' : 'temporary'}
        open={_isDrawerPermanent ? true : mobileDrawerIsOpen}
        onClose={handleDrawerToggle}
      >
        {innerDrawerContainer}
      </Drawer>
    </>
  );
};
