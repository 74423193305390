import { Box as MuiBox, alpha, styled } from '@mui/material';

export const Root = styled(MuiBox)(({ theme }) => ({
  flex: 1,
}));

export const ExamContainer = styled(MuiBox)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  flex: 1,
  rowGap: 38,
}));

export const MarkContainer = styled(MuiBox)(({ theme }) => ({
  height: 90,
  width: 90,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: 4,
  backgroundColor: alpha(theme.palette.common.white || '#ffff', 0.025),
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
}));

export const BetaContainer = styled(MuiBox)(({ theme }) => ({
  padding: '4px 16px',
  backgroundColor: alpha(theme.palette.common.white || '#ffff', 0.025),
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: 100,
}));
