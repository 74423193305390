import React from 'react';
import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  HttpLink,
  from,
  ApolloLink,
  Observable,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { removeTypenameFromVariables } from '@apollo/client/link/remove-typename';
import { useAuth0 } from '@auth0/auth0-react';

export const CustomApolloProvider = ({
  cache,
  children,
}: {
  cache: InMemoryCache;
  children: React.ReactNode;
}) => {
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();

  const MAX_RETRIES = 3;
  const RETRY_DELAY_MS = 250;

  const httpLink = new HttpLink({
    uri: `${process.env.REACT_APP_CLIENT_URI}`,
  });

  const authLink = setContext(async (_, { headers }) => {
    if (isAuthenticated) {
      const token = await getAccessTokenSilently();
      return {
        headers: {
          ...headers,
          authorization: token ? `Bearer ${token}` : '',
        },
      };
    }
  });

  // __typename needs to be removed to comply with our mutation schemas
  const removeTypenameLink = removeTypenameFromVariables();

  const errorLink = new ApolloLink((operation, forward) => {
    return new Observable((observer) => {
      let retries = 0;

      const subscribeWithRetry = () => {
        forward(operation).subscribe({
          next: (response) => {
            if (response.errors) {
              response.errors.forEach((error) => {
                if (
                  retries < MAX_RETRIES &&
                  error.extensions.code !== 'UNAUTHORIZED'
                ) {
                  console.error(error.message, { ...error, retries });
                  retries++;
                  setTimeout(subscribeWithRetry, RETRY_DELAY_MS);
                } else {
                  observer.next(response);
                  observer.complete();
                }
              });
            } else {
              observer.next(response);
              observer.complete();
            }
          },
          error: (networkError) => {
            if (retries < MAX_RETRIES) {
              retries++;
              console.error('Network error. Retrying...', {
                retries,
                error: networkError,
              });
              setTimeout(subscribeWithRetry, RETRY_DELAY_MS);
            } else {
              observer.error(networkError);
            }
          },
        });
      };

      subscribeWithRetry();
    });
  });

  const client = new ApolloClient({
    link: from([authLink, errorLink, removeTypenameLink, httpLink]),
    cache,
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};
