import { Typography as MuiTypography, Skeleton } from '@mui/material';
import { AddOutlined } from '@mui/icons-material';

import { AnalysisQuestionFieldError } from '../../../../../utils';
import {
  AnalysisQuestion as AnalysisQuestionType,
  FailureReason,
  Language,
  Status,
} from '../../../../../../generated';
import { AnalysisQuestionComponent } from './components';
import { UnsavedAnalysisQuestion } from '../../types';
import { ButtonAtom } from '../../../../atoms';
import {
  AnalysisQuestionSection,
  AnalysisQuestionsContainer,
  ErrorContainer,
  SectionHeader,
} from './styles';
import { commonStrings } from '../../../../../../assets/strings/sv';

interface IAnalysisQuestionSectionComponent {
  analysisQuestions: (AnalysisQuestionType | UnsavedAnalysisQuestion)[];
  language?: Language;
  isEditModeActive: boolean;
  fieldErrors: AnalysisQuestionFieldError[];
  status: Status;
  failureReason?: FailureReason | null;
  handleChangeAnalysisQuestion: (
    question: AnalysisQuestionType | UnsavedAnalysisQuestion
  ) => void;
  handleDeleteAnalysisQuestion: (
    question: AnalysisQuestionType | UnsavedAnalysisQuestion
  ) => void;
  handleAddAnalysisQuestion: () => void;
  handleCreateAnalysisQuestions: () => void;
}

export const AnalysisQuestionSectionComponent = ({
  analysisQuestions,
  language,
  isEditModeActive,
  fieldErrors,
  status,
  failureReason,
  handleChangeAnalysisQuestion,
  handleDeleteAnalysisQuestion,
  handleAddAnalysisQuestion,
  handleCreateAnalysisQuestions,
}: IAnalysisQuestionSectionComponent) => {
  const { statusMessages, studyMaterialSection } = commonStrings;
  const { questionsSection } = studyMaterialSection;

  if (status === Status.Pending) {
    const skeletonArray = Array.from(Array(4).keys());
    return (
      <AnalysisQuestionSection>
        <SectionHeader>
          <MuiTypography variant="h6">
            {questionsSection.analysisQuestions}
          </MuiTypography>
        </SectionHeader>
        <AnalysisQuestionsContainer>
          {skeletonArray.map(() => (
            <Skeleton variant="rounded" width="100%" height="72px" />
          ))}
        </AnalysisQuestionsContainer>
      </AnalysisQuestionSection>
    );
  }

  if (status === Status.Failed) {
    return (
      <AnalysisQuestionSection>
        <SectionHeader>
          <MuiTypography variant="h6">
            {questionsSection.analysisQuestions}
          </MuiTypography>
        </SectionHeader>
        <ErrorContainer>
          <MuiTypography>
            {
              statusMessages.analysisQuestions.create.error.default[
                failureReason === FailureReason.ContentViolation
                  ? 'contentViolation'
                  : 'default'
              ]
            }
          </MuiTypography>
          <ButtonAtom
            text="Försök igen"
            onClick={handleCreateAnalysisQuestions}
          />
        </ErrorContainer>
      </AnalysisQuestionSection>
    );
  }

  return (
    <AnalysisQuestionSection>
      <SectionHeader>
        <MuiTypography variant="h6">
          {questionsSection.analysisQuestions}
        </MuiTypography>
        {isEditModeActive && (
          <ButtonAtom
            variant="primary"
            text="Ny fråga"
            onClick={handleAddAnalysisQuestion}
            endIcon={<AddOutlined />}
          />
        )}
      </SectionHeader>
      <AnalysisQuestionsContainer>
        {analysisQuestions.length ? (
          <>
            {analysisQuestions.map((analysisQuestion, index) => (
              <AnalysisQuestionComponent
                key={index}
                analysisQuestion={analysisQuestion}
                language={language}
                isEditModeActive={isEditModeActive}
                fieldError={fieldErrors?.[index]}
                handleChangeAnalysisQuestion={handleChangeAnalysisQuestion}
                handleDeleteAnalysisQuestion={handleDeleteAnalysisQuestion}
              />
            ))}
          </>
        ) : (
          <MuiTypography variant="body1">
            {questionsSection.analysisQuestionsPlaceholder}
          </MuiTypography>
        )}
      </AnalysisQuestionsContainer>
    </AnalysisQuestionSection>
  );
};
