import React from 'react';

import { Avatar } from './styles';

interface IAvatarAtom {
  alt: string;
  src: string;
}

export const AvatarAtom = ({ alt, src }: IAvatarAtom) => {
  return <Avatar alt={alt} src={src} />;
};
