import { Typography, styled } from '@mui/material';

export const Highlight = styled('span')(({ theme }) => ({
  backgroundColor: theme.palette.highlight.regular,
}));

export const Text = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  display: 'inline-block',
  whiteSpace: 'pre-line',
}));
