export enum ButtonText {
  TRUE = 'sant',
  FALSE = 'falskt',
}

export enum ButtonType {
  UNDEFINED = 'UNDEFINED',
  CORRECT_SELECTED = 'CORRECT_SELECTED',
  INCORRECT_SELECTED = 'INCORRECT_SELECTED',
  CORRECT_NOT_SELECTED = 'CORRECT_NOT_SELECTED',
}

export type ButtonMode = {
  [key: number]: ButtonType;
};
