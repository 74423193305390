import { RouteObject, useRoutes } from 'react-router-dom';

import {
  DashboardPage,
  ExplorePage,
  GenerateStudyMaterialPage,
  HomePage,
  NotFoundPage,
  Page,
  ProtectedPage,
  StudyMaterialPage,
} from '../components';

export const Routes = () => {
  const routes: RouteObject[] = [
    {
      element: <Page />,
      children: [
        {
          path: '/',
          children: [
            {
              index: true,
              element: <HomePage />,
            },
            {
              path: '/shared/material/:studyMaterialId/:menuItem',
              element: (
                <StudyMaterialPage
                  actions={['save', 'share']}
                  countViews
                  collectSharedPageVisitStats
                />
              ),
            },
            {
              path: '/result/:studyMaterialId/:menuItem',
              element: <StudyMaterialPage />,
            },
            {
              path: 'example/:studyMaterialId/:menuItem',
              element: <StudyMaterialPage actions={['share']} />,
            },
            {
              path: '/generate',
              element: <GenerateStudyMaterialPage />,
            },
          ],
        },
        {
          path: '/explore',
          children: [
            {
              index: true,
              element: <ExplorePage />,
            },
            {
              path: 'example/:studyMaterialId/:menuItem',
              element: <StudyMaterialPage actions={['share']} />,
            },
            {
              path: 'published/material/:studyMaterialId/:menuItem',
              element: (
                <StudyMaterialPage
                  actions={['bookmark', 'share']}
                  countViews
                  collectSharedPageVisitStats
                />
              ),
            },
          ],
        },
      ],
    },
    {
      element: <ProtectedPage />,
      children: [
        {
          path: '/dashboard',
          element: <DashboardPage />,
        },
        {
          path: '/material/:studyMaterialId/:menuItem',
          element: (
            <StudyMaterialPage
              isProtected
              isEditModeAvailable
              actions={['edit', 'publish', 'share']}
            />
          ),
        },
      ],
    },
    {
      path: '*',
      element: <NotFoundPage />,
    },
  ];

  return useRoutes(routes);
};
