import { Box as MuiBox, styled } from '@mui/material';

export const RootContainer = styled(MuiBox, {
  shouldForwardProp: (prop) => prop !== 'isHeaderVisible',
})<{ isHeaderVisible: boolean }>(({ theme, isHeaderVisible }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: isHeaderVisible ? 20 : 0,
  columnGap: theme.spacing(2),
  [theme.breakpoints.up('lg')]: {
    padding: isHeaderVisible ? '25px 40px' : 0,
  },
}));
