import { gql } from '@apollo/client';

export const LIST_BOOKMARKS = gql`
  query listBookmarks($options: ListBookmarksOptionsInput) {
    listBookmarks(options: $options) {
      id
      userId
      studyMaterial {
        id
        user {
          id
          picture
        }
        title
        views
        bookmark {
          id
        }
      }
    }
  }
`;
