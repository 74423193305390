import { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Close } from '@mui/icons-material';
import { Box as MuiBox, useTheme } from '@mui/material';

import { generateStudyMaterialPageStrings as pageStrings } from '../../../../../../../assets/strings/sv';
import { ButtonAtom, ScrollBoxAtom } from '../../../../../../library/atoms';
import { StudyMaterialDraft } from '../../../../types';

interface IDraftStudyMaterialComponent {
  draftStudyMaterials: StudyMaterialDraft[];
  setDraftStudyMaterials: React.Dispatch<
    React.SetStateAction<StudyMaterialDraft[]>
  >;
  selectedDraft: StudyMaterialDraft;
  setSelectedDraft: React.Dispatch<React.SetStateAction<StudyMaterialDraft>>;
  fieldErrors: Record<string, string>;
}

export const DraftStudyMaterialComponent = ({
  draftStudyMaterials,
  setDraftStudyMaterials,
  selectedDraft,
  setSelectedDraft,
  fieldErrors,
}: IDraftStudyMaterialComponent) => {
  const theme = useTheme();
  const { isAuthenticated } = useAuth0();

  const { preMaterialTitle } = pageStrings;

  const [deletedDraftId, setDeletedDraftId] = useState<string | null>(null);

  const handleSwitchBetweenPremadeMaterial = (id: string) => {
    const foundMaterial = draftStudyMaterials.find(
      (material) => material.id === id
    );

    if (foundMaterial) {
      setSelectedDraft(foundMaterial);
    }
  };

  const handleDeletePresavedMaterial = (id: string, event: any) => {
    event.stopPropagation();
    if (draftStudyMaterials.length === 1) return;

    setDeletedDraftId(id);

    const foundMaterialIndex = draftStudyMaterials.findIndex(
      (material) => material.id === id
    );

    if (foundMaterialIndex === -1) {
      return;
    }

    let newActiveMaterial: StudyMaterialDraft | undefined = undefined;

    if (
      foundMaterialIndex === draftStudyMaterials.length - 1 &&
      foundMaterialIndex > 0
    ) {
      newActiveMaterial = draftStudyMaterials[foundMaterialIndex - 1];
    } else {
      newActiveMaterial = draftStudyMaterials[foundMaterialIndex + 1];
    }

    setSelectedDraft(newActiveMaterial);

    const updateTestData = draftStudyMaterials.filter(
      (material) => material.id !== id
    );

    setDraftStudyMaterials(updateTestData);
    setDeletedDraftId(null);
  };

  useEffect(() => {
    const firstErrorIndex = draftStudyMaterials.findIndex((material) =>
      Boolean(fieldErrors[material.id])
    );

    if (firstErrorIndex === -1) {
      return;
    }

    const firstErrorId = draftStudyMaterials[firstErrorIndex].id;
    const firstErrorElement = document.getElementById(firstErrorId);

    if (firstErrorElement) {
      firstErrorElement.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'start',
      });
    }
  }, [draftStudyMaterials, fieldErrors]);

  return (
    <ScrollBoxAtom gradientColor={theme.palette.background[900]}>
      <>
        {isAuthenticated && (
          <MuiBox display="flex" columnGap={1.5} flex={1}>
            {draftStudyMaterials.length > 1 && (
              <>
                <MuiBox display="flex" columnGap={1.5}>
                  {draftStudyMaterials.map((material, index) => {
                    const hasError = Boolean(fieldErrors[material.id]);
                    const currentSelectedDraft =
                      selectedDraft.id === material.id;
                    const isDeletedDraft = deletedDraftId === material.id;
                    return (
                      <ButtonAtom
                        id={material.id}
                        key={material.id}
                        text={`${preMaterialTitle} ${index + 1}`}
                        variant={currentSelectedDraft ? 'primary' : 'border'}
                        sx={{
                          borderColor: hasError
                            ? theme.palette.error[200]
                            : undefined,
                          whiteSpace: 'nowrap',
                        }}
                        endIcon={
                          <Close
                            onClick={(event) =>
                              handleDeletePresavedMaterial(material.id, event)
                            }
                          />
                        }
                        onClick={() =>
                          handleSwitchBetweenPremadeMaterial(material.id)
                        }
                        disabled={isDeletedDraft && true}
                      />
                    );
                  })}
                </MuiBox>
              </>
            )}
          </MuiBox>
        )}
      </>
    </ScrollBoxAtom>
  );
};
