import { darken, lighten, useTheme } from '@mui/material';
import { LoadingButtonProps } from '@mui/lab';

import { Button } from './styles';

type ButtonAtomType = Omit<LoadingButtonProps, 'variant'> & {
  text: string;
  variant?:
    | 'primary'
    | 'border'
    | 'gradient'
    | 'inactive'
    | 'error'
    | 'errorBorder';
};

export const ButtonAtom = (props: ButtonAtomType) => {
  const { variant, text, sx, ...restProps } = props;
  const theme = useTheme();

  let styling;

  switch (variant) {
    case 'primary':
      styling = {
        color: theme.palette.common.black,
        backgroundColor: theme.palette.common.white,
        '&:hover': {
          backgroundColor: darken(theme.palette.common.white, 0.1),
        },
        ...sx,
      };
      break;
    case 'border':
      styling = {
        color: theme.palette.text.primary,
        backgroundColor: 'none',
        border: `1px solid ${theme.palette.divider}`,
        '&:hover': {
          backgroundColor: 'rgba(255,255,255,0.05)',
        },
        ...sx,
      };
      break;
    case 'inactive':
      styling = {
        color: theme.palette.text.secondary,
        backgroundColor: 'none',
        '&:hover': {
          color: theme.palette.text.primary,
          backgroundColor: 'rgba(255,255,255,0.05)',
        },
        ...sx,
      };
      break;
    case 'gradient':
      styling = {
        color: restProps.disabled
          ? `${theme.palette.text.disabled} !important`
          : theme.palette.common.black,
        background: theme.palette.gradient,
        backgroundColor: theme.palette.common.black,
        transition: 'filter 0.200ms ease, -webkit-filter 200ms ease',
        '&:hover': {
          filter: 'brightness(1.1)', // Increase brightness on hover
        },
        ...sx,
      };
      break;
    case 'error':
      styling = {
        color: theme.palette.common.black,
        backgroundColor: theme.palette.error.light,
        '&:hover': {
          backgroundColor: darken(theme.palette.error.light, 0.1),
        },
        ...sx,
      };
      break;
    case 'errorBorder':
      styling = {
        color: theme.palette.text.primary,
        backgroundColor: 'none',
        border: `1px solid ${theme.palette.error.light}`,
        '&:hover': {
          backgroundColor: 'rgba(255,255,255,0.05)',
        },
        ...sx,
      };
      break;
    default:
      styling = {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.background[700],
        '&:hover': {
          backgroundColor: lighten(theme.palette.background[700] || '', 0.05),
        },
        ...sx,
      };
      break;
  }

  return (
    <Button sx={styling} {...restProps}>
      {text}
    </Button>
  );
};
