import ReactGA from 'react-ga4';
import { GACategory } from './helpers';

export enum GAGenerateAction {
  SUMMARY_SATISFIED = 'SUMMARY_SATISFIED',
  SUMMARY_DISLIKE_GENERATE_LONGER = 'SUMMARY_GENERATE_LONGER',
  SUMMARY_DISLIKE_GENERATE_NEW = 'SUMMARY_GENERATE_NEW',
  QUESTIONS_SATISFIED = 'QUESTIONS_SATISFIED',
  QUESTIONS_DISLIKE_GENERATE_NEW = 'QUESTIONS_DISLIKE/GENERATE_NEW',
}

export const collectResultSatisfaction = (action: GAGenerateAction) => {
  ReactGA.event({
    category: GACategory.OKR,
    action: action,
    value: 1,
  });
};
