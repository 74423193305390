import React from 'react';

import { IAnalysisQuestionState, initialAnalysisQuestionState } from './state';
import {
  CreateAnalysisQuestionsInput,
  AnalysisQuestion,
  SaveAnalysisQuestionsInput,
} from '../../generated';

export interface IAnalysisQuestionContext extends IAnalysisQuestionState {
  createAnalysisQuestions: (input: CreateAnalysisQuestionsInput) => void;
  updateAnalysisQuestions: (questions: AnalysisQuestion[]) => void;
  deleteAnalysisQuestions: (AnalysisQuestions: AnalysisQuestion[]) => void;
  saveAnalysisQuestions: (input: SaveAnalysisQuestionsInput) => void;
}

const stub = (): never => {
  throw new Error('You forgot to wrap your component.');
};

const initialAnalysisQuestionContext: IAnalysisQuestionContext = {
  ...initialAnalysisQuestionState,
  createAnalysisQuestions: stub,
  updateAnalysisQuestions: stub,
  deleteAnalysisQuestions: stub,
  saveAnalysisQuestions: stub,
};

export const AnalysisQuestionContext = React.createContext(
  initialAnalysisQuestionContext
);
