import { Box as MuiBox, Grid as MuiGrid, styled } from '@mui/material';

export const TextContainer = styled(MuiBox)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  marginBottom: 50,
  width: '100%',
}));
export const ImageContainer = styled(MuiBox, {
  shouldForwardProp: (prop) => prop !== 'isIndexEven',
})<{ isIndexEven: boolean }>(({ theme, isIndexEven }) => ({
  display: 'flex',
  justifyContent: 'center',
  [theme.breakpoints.up('lg')]: {
    justifyContent: isIndexEven ? 'flex-end' : 'flex-start',
  },
}));

export const Container = styled(MuiGrid)(({ theme }) => ({
  width: '70vw',
  [theme.breakpoints.up('lg')]: {
    width: 1000,
  },
}));
