import { Box as MuiBox, styled } from '@mui/material';

export const GradientBackground = styled(MuiBox, {
  shouldForwardProp: (prop) => prop !== 'backgroundImage',
})<{ backgroundImage: string }>(({ theme, backgroundImage }) => ({
  position: 'absolute',
  right: 0,
  left: 0,
  top: 0,
  bottom: 0,
  backgroundImage: `url(${backgroundImage})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  zIndex: -999,
}));

export const Root = styled(MuiBox)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  overflowY: 'auto',
}));
