import { Box as MuiBox, keyframes, styled } from '@mui/material';

export const StatusContainer = styled(MuiBox)(({ theme }) => ({
  position: 'absolute',
  margin: 'auto',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
  alignItems: 'center',
  width: 'fit-content',
  height: 'fit-content',
  minWidth: '250px',
  maxWidth: '80vw',
  padding: '40px',
  backgroundColor: theme.palette.background[800],
  borderRadius: `${theme.shape.borderRadius}px`,
  textAlign: 'center',
  zIndex: 2,
}));

export const StyledBox = styled(MuiBox)(({ theme }) => ({
  display: 'flex',
}));

export const DotOne = styled('span')(({ theme }) => ({
  animation: `${visibility} 2s linear infinite`,
}));

export const DotTwo = styled('span')(({ theme }) => ({
  animation: `${visibility} 2s linear infinite 300ms`,
}));

export const DotThree = styled('span')(({ theme }) => ({
  animation: `${visibility} 2s linear infinite 600ms`,
}));

const visibility = keyframes`
0% {
    opacity: .2;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: .2;
  }
`;
