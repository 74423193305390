import React from 'react';

import logo from '../../../../assets/images/logo.svg';
import { BetaBadge } from './components';
import { Container, BetaBadgeContainer } from './styles';

interface IQurieLogoAtom {
  width?: number | string;
  height?: number | string;
  displayBetaBadge?: boolean;
}

export const QurieLogoAtom = ({
  width,
  height,
  displayBetaBadge,
}: IQurieLogoAtom) => {
  if (displayBetaBadge)
    return (
      <Container>
        <img
          width={width ? width : 'auto'}
          height={height ? height : 'auto'}
          src={logo}
          alt="qurie-logo"
        />
        <BetaBadgeContainer>
          <BetaBadge height={30} />
        </BetaBadgeContainer>
      </Container>
    );
  return (
    <img
      width={width ? width : 'auto'}
      height={height ? height : 'auto'}
      src={logo}
      alt="qurie-logo"
    />
  );
};
