import React from 'react';

import { ISummaryState, initialSummaryState } from './state';
import { CreateSummaryInput, UpdateSummaryInput } from '../../generated';

export interface ISummaryContext extends ISummaryState {
  createSummary: (input: CreateSummaryInput) => void;
  updateSummary: (id: string, update: UpdateSummaryInput) => void;
}

const stub = (): never => {
  throw new Error('You forgot to wrap your component.');
};

const initialSummaryContext: ISummaryContext = {
  ...initialSummaryState,
  createSummary: stub,
  updateSummary: stub,
};

export const SummaryContext = React.createContext(initialSummaryContext);
