import { Drawer as MuiDrawer, Box as MuiBox, styled } from '@mui/material';

export const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'isDrawerPermanent',
})<{ isDrawerPermanent: boolean }>(({ theme, isDrawerPermanent }) => ({
  '& .MuiDrawer-paper': {
    backgroundColor: theme.palette.background[900],
    border: 'none',
    backgroundImage: 'none',
  },
}));

export const InnerDrawer = styled(MuiBox)(({ theme }) => ({
  height: '100%',
  padding: '24px 20px',
  display: 'flex',
  flexDirection: 'column',
}));

export const MenuContainer = styled(MuiBox)(({ theme }) => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'left',
  justifyContent: 'center',
  rowGap: 8,
}));
