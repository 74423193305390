import { useState } from 'react';
import { Divider, Box as MuiBox, useMediaQuery, useTheme } from '@mui/material';
import {
  VisibilityOutlined,
  VisibilityOffOutlined,
  DeleteOutline,
} from '@mui/icons-material';

import { commonStrings } from '../../../../../../../../assets/strings/sv';
import {
  Language,
  Question as QuestionType,
} from '../../../../../../../../generated';
import { ContentType } from '../../../../../../../../utils';
import {
  HighlightedTextAtom,
  IconButtonAtom,
  TextInputAtom,
} from '../../../../../../atoms';
import { AudioPlayerMolecule } from '../../../../../../molecules';
import { Question, QuestionSection } from './styles';
import { UnsavedQuestion } from '../../../../types';
import { QuestionFieldError } from '../../../../../../../utils';

interface IQuestionComponent {
  question: QuestionType | UnsavedQuestion;
  language?: Language;
  isEditModeActive: boolean;
  fieldError?: QuestionFieldError;
  handleChangeQuestion: (question: QuestionType | UnsavedQuestion) => void;
  handleDeleteQuestion: (question: QuestionType | UnsavedQuestion) => void;
}

export const QuestionComponent = ({
  question,
  language,
  isEditModeActive,
  fieldError,
  handleChangeQuestion,
  handleDeleteQuestion,
}: IQuestionComponent) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const { tooltips } = commonStrings;

  const [isAnswerVisible, setIsAnswerVisible] = useState(false);
  const [highlightIndexes, setHighlightIndexes] = useState({
    question: {
      end: 0,
      start: 0,
    },
    answer: {
      end: 0,
      start: 0,
    },
  });

  const toggleAnswerVisibility = () => setIsAnswerVisible(!isAnswerVisible);

  if (isEditModeActive) {
    return (
      <Question>
        <QuestionSection>
          <TextInputAtom
            variant={isEditModeActive ? 'filled' : 'outlined'}
            value={question.question}
            fullWidth
            multiline
            errorMessage={fieldError?.question}
            onChange={(value) =>
              handleChangeQuestion({ ...question, question: value })
            }
          />
        </QuestionSection>
        <Divider flexItem orientation={isMobile ? 'horizontal' : 'vertical'} />
        <QuestionSection>
          <TextInputAtom
            variant={isEditModeActive ? 'filled' : 'outlined'}
            value={question.answer}
            errorMessage={fieldError?.answer}
            fullWidth
            multiline
            onChange={(value) =>
              handleChangeQuestion({ ...question, answer: value })
            }
          />
          <IconButtonAtom
            icon={<DeleteOutline />}
            onClick={() => handleDeleteQuestion(question)}
          />
        </QuestionSection>
      </Question>
    );
  }

  return (
    <Question>
      <QuestionSection>
        <HighlightedTextAtom
          text={question.question}
          startHighlightIndex={highlightIndexes.question.start}
          endHighlightIndex={highlightIndexes.question.end}
        />
        <AudioPlayerMolecule
          statsContentType={ContentType.QUESTION}
          key={question.question}
          text={question.question}
          language={language?.code || commonStrings.defaultLanguage}
          setCaptionedCharacterIndexes={(startIndex, endIndex) =>
            setHighlightIndexes((prevState) => ({
              ...prevState,
              question: { start: startIndex, end: endIndex },
            }))
          }
        />
      </QuestionSection>
      <Divider flexItem orientation={isMobile ? 'horizontal' : 'vertical'} />
      <QuestionSection>
        <MuiBox
          sx={{
            '& p': {
              filter: !isAnswerVisible ? 'blur(4px)' : 'none',
            },
          }}
        >
          <HighlightedTextAtom
            text={question.answer}
            startHighlightIndex={highlightIndexes.answer.start}
            endHighlightIndex={highlightIndexes.answer.end}
          />
        </MuiBox>
        <MuiBox display="flex" alignItems="center" gap={1}>
          <AudioPlayerMolecule
            statsContentType={ContentType.QUESTION}
            key={question.answer}
            text={question.answer}
            language={language?.code || commonStrings.defaultLanguage}
            setCaptionedCharacterIndexes={(startIndex, endIndex) =>
              setHighlightIndexes((prevState) => ({
                ...prevState,
                answer: { start: startIndex, end: endIndex },
              }))
            }
          />
          <IconButtonAtom
            onClick={toggleAnswerVisibility}
            icon={
              isAnswerVisible ? (
                <VisibilityOutlined />
              ) : (
                <VisibilityOffOutlined />
              )
            }
            tooltipTitle={isAnswerVisible ? tooltips.hide : tooltips.show}
          />
        </MuiBox>
      </QuestionSection>
    </Question>
  );
};
