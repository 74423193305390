import React from 'react';
import { Link, Text } from './styles';

interface ILinkAtom {
  text: string;
  onClick: () => void;
}

export const LinkAtom = ({ text, onClick }: ILinkAtom) => {
  return (
    <Link onClick={onClick}>
      <Text>{text}</Text>
    </Link>
  );
};
