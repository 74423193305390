import React, { Dispatch, SetStateAction, useState } from 'react';

import { ITextToSpeechContext, TextToSpeechContext } from './context';
import { TextToSpeechAudioPlayer } from '../../classes';
import { PlayState } from './types';

interface ITextToSpeechProvider {
  children: React.ReactNode;
}

const TextToSpeechProvider = ({ children }: ITextToSpeechProvider) => {
  const [activeAudioPlayer, setActiveAudioPlayer] =
    useState<TextToSpeechAudioPlayer>();

  const sentenceBoundaryCharacters = ['.', '?', '!'];
  const sentenceBoundaryRegex = /[^.!?]+([.!?]+|$)/g;

  const play = (
    text: string,
    audioPlayer: TextToSpeechAudioPlayer,
    playState: PlayState,
    setPlayState: Dispatch<SetStateAction<PlayState>>
  ) => {
    const sentences = text.match(sentenceBoundaryRegex); // Splits text at ?!. characters
    if (!sentences) return;

    if (playState === PlayState.NOT_INITIATED) {
      audioPlayer.play(sentences);
    } else {
      audioPlayer.resume();
    }
    setPlayState(PlayState.PLAYING);
    setActiveAudioPlayer(audioPlayer);
  };

  const pause = (
    audioPlayer: TextToSpeechAudioPlayer,
    setPlayState: Dispatch<SetStateAction<PlayState>>
  ) => {
    setPlayState(PlayState.PAUSED);
    audioPlayer.pause();
  };

  const reset = (
    audioPlayer: TextToSpeechAudioPlayer,
    setAudioPlayer: Dispatch<
      SetStateAction<TextToSpeechAudioPlayer | undefined>
    >,
    setPlayState: Dispatch<SetStateAction<PlayState>>
  ) => {
    audioPlayer.pause();
    setAudioPlayer(undefined);
    setPlayState(PlayState.NOT_INITIATED);
  };

  const config: ITextToSpeechContext = {
    play,
    pause,
    reset,
    activeAudioPlayer,
    sentenceBoundaryCharacters,
    sentenceBoundaryRegex,
  };

  return (
    <TextToSpeechContext.Provider value={config}>
      {children}
    </TextToSpeechContext.Provider>
  );
};

export { TextToSpeechProvider };
