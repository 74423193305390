import React from 'react';

import { LinearProgress, ModuleContainer, ModuleContent } from './styles';

interface IModuleContainerAtom {
  children: React.ReactNode;
  progress?: number;
  progressColor?: 'primary' | 'secondary';
  onClick?: () => void;
}
export const ModuleContainerAtom = ({
  children,
  progress,
  progressColor,
  onClick,
}: IModuleContainerAtom) => {
  return (
    <ModuleContainer onClick={onClick}>
      <ModuleContent elevation={0}>{children}</ModuleContent>
      {progress && (
        <LinearProgress
          color={progressColor}
          variant="determinate"
          value={progress}
        />
      )}
    </ModuleContainer>
  );
};
