import React from 'react';

import betaSvg from '../images/betaBadge.svg';

interface IBetaBadge {
  height?: number | string;
}
export const BetaBadge = ({ height }: IBetaBadge) => {
  return (
    <img height={height ? height : 'auto'} src={betaSvg} alt="beta-badge" />
  );
};
