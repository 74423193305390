import {
  Box as MuiBox,
  Rating as MuiRating,
  Typography as MuiTypography,
} from '@mui/material';

import { PopupAtom } from '../../../../library/atoms';
import { useRatingContext } from '../../../../../hooks';

export const RatingPopupComponent = () => {
  const { popup, isOpen, handleChangeRating, handleClosePopup } =
    useRatingContext();

  const handleRating = (rating: number | null) => {
    handleChangeRating(rating);
    handleClosePopup();
  };

  return (
    <PopupAtom isOpen={isOpen} handleClose={handleClosePopup}>
      <MuiBox
        display="flex"
        flexDirection="column"
        alignItems="center"
        rowGap={1}
        maxWidth={320}
      >
        <MuiTypography textAlign="center" alignSelf="flex-end">
          {popup?.title}
        </MuiTypography>
        <MuiRating
          value={popup?.rating}
          onChange={(e, value) => handleRating(value)}
        />
      </MuiBox>
    </PopupAtom>
  );
};
