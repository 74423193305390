import AddIcon from '@mui/icons-material/Add';

import { generateStudyMaterialPageStrings as pageStrings } from '../../../../../../../../../assets/strings/sv';
import {
  ButtonAtom,
  IconButtonAtom,
} from '../../../../../../../../library/atoms';
import { StudyMaterialDraft } from '../../../../../../types';

interface IAddMaterialButton {
  handleAddStudyMaterial: () => void;
  draftStudyMaterials: StudyMaterialDraft[];
}
export const AddMaterialButton = ({
  handleAddStudyMaterial,
  draftStudyMaterials,
}: IAddMaterialButton) => {
  const { addPreMaterial } = pageStrings;

  return (
    <>
      {draftStudyMaterials.length < 6 && (
        <>
          {draftStudyMaterials.length < 2 ? (
            <ButtonAtom
              variant="border"
              text={addPreMaterial}
              endIcon={<AddIcon />}
              onClick={handleAddStudyMaterial}
            />
          ) : (
            <IconButtonAtom
              icon={<AddIcon />}
              onClick={handleAddStudyMaterial}
            />
          )}
        </>
      )}
    </>
  );
};
