import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { CircularProgress, Box as MuiBox } from '@mui/material';

import { PageNavigationOrganism } from '../../library/organisms';
import {
  FeaturesComponent,
  HeroComponent,
  BulletpointsComponent,
  AudienceComponent,
  GalleryComponent,
  HeaderComponent,
} from './components';
import { ButtonAtom } from '../../library/atoms';
import { Content, Home, InnerContent } from './styles';
import { homePageStrings } from '../../../assets/strings/sv';

export const HomePage = () => {
  const { isAuthenticated, isLoading } = useAuth0();
  const navigate = useNavigate();

  const navigateToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    // Authenticated users will be redirected to dashboard automatically
    if (isAuthenticated) {
      navigate('/dashboard');
    }
  }, [isAuthenticated, navigate]);

  if (isLoading || isAuthenticated) {
    return (
      <Home>
        <PageNavigationOrganism isDrawerPermanent={false} />
        <Content flex={1}>
          <MuiBox
            flex={1}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <CircularProgress />
          </MuiBox>
        </Content>
      </Home>
    );
  }

  return (
    <Home>
      <HeaderComponent />
      <Content>
        <InnerContent>
          <HeroComponent />
          <AudienceComponent />
        </InnerContent>
        <BulletpointsComponent />
        <InnerContent>
          <FeaturesComponent />
          <GalleryComponent />
          <MuiBox display="flex" justifyContent="center">
            <ButtonAtom
              variant="border"
              text={homePageStrings.anchorLinkTop}
              onClick={navigateToTop}
            />
          </MuiBox>
        </InnerContent>
      </Content>
    </Home>
  );
};
