import { useState } from 'react';
import {
  Typography as MuiTypography,
  Box as MuiBox,
  CircularProgress,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

import prompt from './images/prompt.svg';
import { ButtonAtom, TextInputAtom } from '../../../../../../library/atoms';
import { TextFieldOptionsComponent, TextOption } from './components';
import { useStudyMaterialContext } from '../../../../../../../hooks';
import { DemoSection } from './styles';
import {
  commonStrings,
  homePageStrings,
} from '../../../../../../../assets/strings/sv';

export const DemoSectionComponent = () => {
  const { createStudyMaterial } = useStudyMaterialContext();
  const navigate = useNavigate();

  const theme = useTheme();
  const isLargeDesktop = useMediaQuery(theme.breakpoints.up('lg'));

  const { demo } = homePageStrings.hero;

  const [selectedOption, setSelectedOption] = useState<TextOption | undefined>(
    demo.textOptions[0]
  );
  const [input, setInput] = useState(selectedOption?.text || '');
  const [isCreateStudyMaterialLoading, setIsCreateStudyMaterialLoading] =
    useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleCreateStudyMaterial = async () => {
    if (isCreateStudyMaterialLoading) return;

    if (input.length <= 500 || input.length > 5000) {
      return setErrorMessage(demo.errorMessage);
    }

    setIsCreateStudyMaterialLoading(true);

    const studyMaterial = await createStudyMaterial({
      title: selectedOption?.title || '',
      text: input,
    });

    studyMaterial && navigate(`/result/${studyMaterial.id}/summary`);
    setIsCreateStudyMaterialLoading(false);
  };

  const handleChangeOption = (option: TextOption) => {
    setSelectedOption(option);
    setInput(option.text);
  };

  const handleChangeInput = (input: string) => {
    setInput(input);
    setErrorMessage('');
    setSelectedOption(undefined);
  };

  return (
    <DemoSection>
      <TextFieldOptionsComponent
        selectedOption={selectedOption}
        options={demo.textOptions}
        changeOption={handleChangeOption}
      />
      <TextInputAtom
        variant="outlined"
        multiline
        errorMessage={errorMessage}
        value={input}
        onChange={handleChangeInput}
      />
      <MuiTypography variant="caption">{demo.description}</MuiTypography>
      <MuiBox
        position="relative"
        display="flex"
        alignItems="center"
        columnGap={2}
      >
        <img
          style={{
            position: 'absolute',
            left: isLargeDesktop ? -400 : -330,
            width: isLargeDesktop ? 400 : 330,
            bottom: 20,
          }}
          src={prompt}
          alt="create-prompt"
        />
        <ButtonAtom
          variant="gradient"
          text={commonStrings.createStudyMaterial}
          onClick={handleCreateStudyMaterial}
        />
        {isCreateStudyMaterialLoading && <CircularProgress size={16} />}
      </MuiBox>
    </DemoSection>
  );
};
