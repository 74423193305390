import {
  CreateExamEvaluationInput,
  CreateExamInput,
  ExamQuestion,
  SaveExamQuestionsInput,
  Status,
  useCreateExamEvaluationMutation,
  useCreateExamMutation,
  useDeleteExamQuestionMutation,
  useSaveExamQuestionsMutation,
  useUpdateExamQuestionMutation,
} from '../../generated';
import { GET_STUDY_MATERIAL } from '../../gql/queries';
import { useStudyMaterialStatusContext, useUsageContext } from '../../hooks';
import { addItemToCache, deleteItemFromCache } from '../helpers';
import { ExamContext } from './context';

interface IExamProvider {
  children: React.ReactNode;
}

export const ExamProvider = ({ children }: IExamProvider) => {
  const { collectExamEvaluationStats } = useUsageContext();
  const { updateStudyMaterialStatus } = useStudyMaterialStatusContext();

  const [_createExam] = useCreateExamMutation();
  const [_createExamEvaluation] = useCreateExamEvaluationMutation();
  const [_updateExamQuestion] = useUpdateExamQuestionMutation();
  const [_saveExamQuestions] = useSaveExamQuestionsMutation();
  const [_deleteExamQuestion] = useDeleteExamQuestionMutation();

  const createExam = async (input: CreateExamInput) => {
    updateStudyMaterialStatus({ exam: Status.Pending });
    await _createExam({
      variables: {
        input,
      },
      onError: (error) => {
        console.error('Could not create exam', { error });
      },
    });
  };

  const createExamEvaluation = async (input: CreateExamEvaluationInput) => {
    await _createExamEvaluation({
      variables: {
        input,
      },
      onError: (error) => {
        console.error('Could not create exam evaluation', { error });
      },
    });
    collectExamEvaluationStats();
  };

  const updateExamQuestions = async (examQuestions: ExamQuestion[]) => {
    examQuestions.map(
      async ({ id, examId, studyMaterialId, ...update }) =>
        await _updateExamQuestion({
          variables: {
            id,
            update,
          },
          onError: (error) => {
            console.error('Could not update exam question', { error });
          },
        })
    );
  };

  const saveExamQuestions = async (input: SaveExamQuestionsInput) => {
    await _saveExamQuestions({
      variables: {
        input,
      },
      update: (cache, { data }) => {
        data?.saveExamQuestions.map((question) =>
          addItemToCache(cache, question, {
            query: GET_STUDY_MATERIAL,
            variables: {
              getStudyMaterialId: input.studyMaterialId,
            },
            queryName: 'getStudyMaterial',
            nestedQuery: {
              queryName: 'exam',
              nestedQuery: {
                queryName: 'questions',
              },
            },
          })
        );
      },
      onError: (error) => {
        console.error('Could not save exam questions', { error });
      },
    });
  };

  const deleteExamQuestions = (examQuestions: ExamQuestion[]) => {
    examQuestions.map(
      async ({ id }) =>
        await _deleteExamQuestion({
          variables: {
            id,
          },
          update: (cache, _, { variables }) => {
            if (variables?.id) {
              deleteItemFromCache(cache, variables.id, 'ExamQuestion');
            }
          },
          onError: (error) => {
            console.error('Could not delete exam question', { error });
          },
        })
    );
  };

  const config = {
    createExam,
    createExamEvaluation,
    updateExamQuestions,
    saveExamQuestions,
    deleteExamQuestions,
  };

  return <ExamContext.Provider value={config}>{children}</ExamContext.Provider>;
};
