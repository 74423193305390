import React from 'react';
import { SxProps, Theme } from '@mui/material';

import { GradientBorder } from './styles';

interface IGradientBorderAtom {
  isGradient?: boolean;
  children: React.ReactNode;
  sx?: SxProps<Theme>;
}

export const GradientBorderAtom = ({
  isGradient = true,
  children,
  sx,
}: IGradientBorderAtom) => {
  return (
    <GradientBorder isGradient={isGradient} sx={sx}>
      {children}
    </GradientBorder>
  );
};
