import { Grid as MuiGrid, Skeleton as MuiSkeleton } from '@mui/material';

interface IFlashcardsSkeletonComponent {
  skeletonCount: number;
}

export const FlashcardsSkeletonComponent = ({
  skeletonCount,
}: IFlashcardsSkeletonComponent) => {
  const renderSkeletons = () => {
    return Array.from({ length: skeletonCount }, (_, index) => (
      <MuiGrid item key={index} xs={12} sm={6} md={4} lg={3} xl={3}>
        <MuiSkeleton
          style={{ borderRadius: 24 }}
          animation="wave"
          variant="rounded"
          height={300}
          width={'auto'}
        />
      </MuiGrid>
    ));
  };
  return (
    <MuiGrid container direction="row" spacing={2}>
      {renderSkeletons()}
    </MuiGrid>
  );
};
