import { Box as MuiBox, styled, Grid as MuiGrid } from '@mui/material';

import { drawerWidthLarge, drawerWidthSmall } from '../../utils';

export const Dashboard = styled(MuiBox)(({ theme }) => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
}));

export const ContentContainer = styled(MuiGrid)(({ theme }) => ({
  height: '100%',
  width: `calc(100% - ${drawerWidthLarge}px)`,
  marginLeft: 'auto',
  backgroundColor: theme.palette.background[800],
  padding: '24px 32px',
  gap: 40,
  [theme.breakpoints.down('lg')]: {
    width: `calc(100% - ${drawerWidthSmall}px)`,
  },
  [theme.breakpoints.down('md')]: {
    width: '100%',
    padding: '24px 20px',
  },
}));
