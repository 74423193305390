import { useEffect, useMemo } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import {
  Bookmark,
  CreateStudyMaterialInput,
  Status,
  UpdateStudyMaterialInput,
  useCreateBookmarkMutation,
  useCreateStudyMaterialMutation,
  useDeleteBookmarkMutation,
  useGetStudyMaterialLazyQuery,
  useListBookmarksLazyQuery,
  useListStudyMaterialLazyQuery,
  useSaveStudyMaterialMutation,
  useUpdateStudyMaterialMutation,
  useListExampleStudyMaterialQuery,
  useListPublishedStudyMaterialQuery,
  useDeleteStudyMaterialMutation,
  usePublishStudyMaterialMutation,
} from '../../generated';
import { IStudyMaterialContext, StudyMaterialContext } from './context';
import {
  useAnalysisQuestionContext,
  useFlashcardContext,
  useStudyMaterialStatusContext,
  useSubjectContext,
} from '../../hooks';
import {
  LIST_BOOKMARKS,
  LIST_STUDY_MATERIAL,
  LIST_PUBLISHED_STUDY_MATERIAL,
} from '../../gql/queries';
import { addItemToCache, deleteItemFromCache } from '../helpers';
interface IStudyMaterialProvider {
  children: React.ReactNode;
  id?: string;
}

const StudyMaterialProvider = ({ children, id }: IStudyMaterialProvider) => {
  const { isAuthenticated, user } = useAuth0();
  const { createAnalysisQuestions } = useAnalysisQuestionContext();
  const { createFlashcards } = useFlashcardContext();
  const { updateStudyMaterialStatus, status } = useStudyMaterialStatusContext();
  const { selectedSubject } = useSubjectContext();

  const [_deleteBookmark] = useDeleteBookmarkMutation();
  const [_createBookmark] = useCreateBookmarkMutation();
  const [_saveStudyMaterial] = useSaveStudyMaterialMutation();
  const [_publishStudyMaterial] = usePublishStudyMaterialMutation();
  const [_updateStudyMaterial] = useUpdateStudyMaterialMutation();
  const [_createStudyMaterial] = useCreateStudyMaterialMutation();
  const [_deleteStudyMaterial] = useDeleteStudyMaterialMutation();

  const [_getStudyMaterial, { data, loading, error }] =
    useGetStudyMaterialLazyQuery({
      fetchPolicy: 'network-only',
      pollInterval: 3000,
      onCompleted: ({ getStudyMaterial }) => {
        const { id, text, language } = getStudyMaterial;

        if (getStudyMaterial.status.analysisQuestions === Status.Idle) {
          createAnalysisQuestions({ studyMaterialId: id, language, text });
          updateStudyMaterialStatus({ analysisQuestions: Status.Pending });
        }
        if (getStudyMaterial.status.flashcards === Status.Idle) {
          createFlashcards({ studyMaterialId: id, language, text });
          updateStudyMaterialStatus({ flashcards: Status.Pending });
        }
      },
      onError: (error) => {
        console.error('Could not get study material', { error });
      },
    });

  const [
    _listStudyMaterials,
    {
      data: { listStudyMaterial } = { listStudyMaterial: [] },
      loading: studyMaterialsLoading,
    },
  ] = useListStudyMaterialLazyQuery({
    fetchPolicy: 'cache-and-network',
    onError: (error) =>
      console.error('Could not list study material', { error }),
  });

  const [
    _listBookmarkedStudyMaterials,
    {
      data: { listBookmarks } = {
        listBookmarks: [],
      },
      loading: bookmarkedStudyMaterialsLoading,
    },
  ] = useListBookmarksLazyQuery({
    fetchPolicy: 'cache-and-network',
    onError: (error) =>
      console.error('Could not list bookmarked study material', { error }),
  });

  const {
    data: { listStudyMaterial: listExampleStudyMaterial } = {
      listStudyMaterial: [],
    },
    loading: exampleStudyMaterialsLoading,
  } = useListExampleStudyMaterialQuery({
    variables: {
      subjectId: selectedSubject?.id,
    },
    fetchPolicy: 'cache-and-network',
    onError: (error) =>
      console.error('Could not list example study material', { error }),
  });

  const {
    data: { listStudyMaterial: listPublishedStudyMaterial } = {
      listStudyMaterial: [],
    },
    loading: publishedStudyMaterialsLoading,
  } = useListPublishedStudyMaterialQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      subjectId: selectedSubject?.id,
    },
    onError: (error) => {
      return console.error('Could not list published study material', {
        error,
      });
    },
  });

  useEffect(() => {
    if (isAuthenticated && user) {
      _listStudyMaterials({
        variables: {
          filter: {
            userId: user.sub,
            flags: {
              deleted: null,
            },
          },
        },
      });
      _listBookmarkedStudyMaterials();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  useEffect(() => {
    if (id) {
      _getStudyMaterial({
        variables: {
          id,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    updateStudyMaterialStatus({ ...data?.getStudyMaterial.status });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const _studyMaterial = useMemo(() => {
    if (!data || id !== data.getStudyMaterial.id) return undefined;
    return {
      ...data.getStudyMaterial,
      status: { ...data.getStudyMaterial.status, ...status },
    };
  }, [id, data, status]);

  const bookmarkStudyMaterial = async (studyMaterialId: string) => {
    const bookmark = listBookmarks.find(
      (bookmark) => bookmark.studyMaterial?.id === studyMaterialId
    );

    if (bookmark) {
      await _deleteBookmark({
        variables: {
          id: bookmark.id,
        },
        update: (cache, _, { variables }) => {
          if (variables?.id) {
            const __typename: Bookmark['__typename'] = 'Bookmark';
            deleteItemFromCache(cache, variables.id, __typename);
          }
        },
        onError: (error) =>
          console.error('Could not unbookmark study material', { error }),
      });
    } else {
      await _createBookmark({
        variables: {
          studyMaterialId,
        },
        update: (cache, { data }) => {
          addItemToCache(cache, data?.createBookmark, {
            query: LIST_BOOKMARKS,
            queryName: 'listBookmarks',
          });
        },
        onError: (error) =>
          console.error('Could not bookmark study material', { error }),
      });
    }
  };

  const deleteStudyMaterial = async (id: string) => {
    await _deleteStudyMaterial({
      variables: {
        id,
      },
      onError: (error) =>
        console.error('Could not delete study material', { error }),
    });
  };

  const updateStudyMaterial = async (
    id: string,
    update: UpdateStudyMaterialInput
  ) => {
    await _updateStudyMaterial({
      variables: {
        id,
        update,
      },
      onError: (error) => {
        console.error('Could not update study material', { error });
      },
    });
  };

  const publishStudyMaterial = async (id: string) => {
    const studyMaterial = listStudyMaterial.find(
      (studyMaterial) => studyMaterial.id === id
    );
    if (!studyMaterial) return;
    await _publishStudyMaterial({
      variables: {
        id,
        isPublished: !!!studyMaterial.flags?.isPublished,
      },
      refetchQueries: [{ query: LIST_PUBLISHED_STUDY_MATERIAL }],
      onError: (error) => {
        console.error('Could not publish study material', { error });
      },
    });
  };

  const saveStudyMaterial = async (id: string) => {
    const { data } = await _saveStudyMaterial({
      variables: {
        id,
      },
      update: (cache, { data }) => {
        if (data?.saveStudyMaterial) {
          addItemToCache(cache, data.saveStudyMaterial, {
            query: LIST_STUDY_MATERIAL,
            queryName: 'listStudyMaterial',
          });
        }
      },
      onError: (error) => {
        console.error('Could not save study material', { error });
      },
    });
    return data?.saveStudyMaterial;
  };

  const createStudyMaterial = async (input: CreateStudyMaterialInput) => {
    const { data } = await _createStudyMaterial({
      variables: {
        input,
      },
      update: (cache, { data }) => {
        if (data?.createStudyMaterial) {
          addItemToCache(cache, data.createStudyMaterial, {
            query: LIST_STUDY_MATERIAL,
            queryName: 'listStudyMaterial',
          });
        }
      },
      onError: (error) => {
        console.error('Could not create study material', { error });
      },
    });
    return data?.createStudyMaterial;
  };

  const config: IStudyMaterialContext = {
    studyMaterial: _studyMaterial,
    loading,
    error: !!error,
    studyMaterials: listStudyMaterial,
    studyMaterialsLoading,
    bookmarkedStudyMaterials: listBookmarks,
    bookmarkedStudyMaterialsLoading,
    exampleStudyMaterials: listExampleStudyMaterial,
    exampleStudyMaterialsLoading,
    publishedStudyMaterials: listPublishedStudyMaterial,
    publishedStudyMaterialsLoading,
    bookmarkStudyMaterial,
    deleteStudyMaterial,
    createStudyMaterial,
    updateStudyMaterial,
    publishStudyMaterial,
    saveStudyMaterial,
  };

  return (
    <StudyMaterialContext.Provider value={config}>
      {children}
    </StudyMaterialContext.Provider>
  );
};

export { StudyMaterialProvider };
