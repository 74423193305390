import React from 'react';
import { TextField, TextFieldProps } from '@mui/material';

import {
  FilledTextField,
  OutlinedTextField,
  StandardTextField,
  TransparentTextField,
} from './styles';

type TextInputAtomType = Omit<
  TextFieldProps,
  'onChange' | 'variant' | 'error' | 'helperText'
> & {
  variant?: 'transparent' | 'gradient' | 'outlined' | 'filled';
  onChange: (text: string) => void;
  errorMessage?: string;
};

export const TextInputAtom = (props: TextInputAtomType) => {
  const { onChange, variant, errorMessage, ...restProps } = props;
  if (variant === 'transparent')
    return (
      <TransparentTextField
        variant="standard"
        error={Boolean(errorMessage)}
        helperText={errorMessage}
        onChange={(e) => props.onChange(e.target.value)}
        {...restProps}
      />
    );

  if (variant === 'gradient')
    return (
      <TextField
        variant="outlined"
        error={Boolean(errorMessage)}
        helperText={errorMessage}
        onChange={(e) => props.onChange(e.target.value)}
        {...restProps}
      />
    );
  if (variant === 'outlined')
    return (
      <OutlinedTextField
        variant="outlined"
        error={Boolean(errorMessage)}
        helperText={errorMessage}
        onChange={(e) => props.onChange(e.target.value)}
        sx={{
          ...restProps.sx,
          ...(restProps.multiline
            ? {
                flex: 1,
                minHeight: 0,
                '& .MuiInputBase-root': {
                  alignItems: 'flex-start',
                  overflow: 'auto',
                  height: '100%',
                },
              }
            : {}),
        }}
        {...restProps}
      />
    );
  if (variant === 'filled')
    return (
      <FilledTextField
        variant="outlined"
        error={Boolean(errorMessage)}
        helperText={errorMessage}
        onChange={(e) => props.onChange(e.target.value)}
        sx={{
          ...restProps.sx,
          ...(restProps.multiline
            ? {
                flex: 1,
                minHeight: 0,
                '& .MuiInputBase-root': {
                  alignItems: 'flex-start',
                  overflow: 'auto',
                  height: '100%',
                },
              }
            : {}),
        }}
        {...restProps}
      />
    );
  return (
    <StandardTextField
      variant="filled"
      error={Boolean(errorMessage)}
      helperText={errorMessage}
      onChange={(e) => props.onChange(e.target.value)}
      {...restProps}
    />
  );
};
