import { Box as MuiBox, styled } from '@mui/material';

export const MenuItem = styled(MuiBox, {
  shouldForwardProp: (prop) => prop !== 'isSelected',
})<{
  disabled?: boolean;
  isSelected?: boolean;
}>(({ theme, disabled, isSelected }) => ({
  display: 'flex',
  alignItems: 'center',
  cursor: disabled ? 'default' : 'pointer',
  padding: '10px 10px 10px 10px',
  borderRadius: theme.shape.borderRadius,
  transition: 'background-color 200ms ease',
  backgroundColor: isSelected ? theme.palette.background[700] : 'transparent',
  '&:hover': {
    backgroundColor: disabled ? 'transparent' : theme.palette.background[700],
  },
}));
