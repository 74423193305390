import { Box as MuiBox, styled } from '@mui/material';

export const Question = styled(MuiBox)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: 32,
  padding: 16,
  backgroundColor: theme.palette.background[800],
  borderRadius: theme.shape.borderRadius,
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },
}));

export const QuestionSection = styled(MuiBox)(({ theme }) => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: 32,
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}));
