import { gql } from '@apollo/client';

export const GET_STUDY_MATERIAL = gql`
  query getStudyMaterial($id: ID!) {
    getStudyMaterial(id: $id) {
      id
      title
      text
      status {
        questions
        flashcards
        summary
        analysisQuestions
        quiz {
          statements
          multipleChoices
        }
        exam
      }
      bookmark {
        id
      }
      failureReason
      language {
        name
        code
      }
      summary {
        id
        summary
        studyMaterialId
      }
      analysisQuestions {
        id
        question
        studyMaterialId
      }
      quiz {
        statements {
          id
          studyMaterialId
          type
          quiz {
            ... on Statements {
              statement
              isTrue
            }
            ... on MultipleChoices {
              question
              choices {
                option
                isTrue
              }
            }
          }
        }
        multipleChoices {
          id
          studyMaterialId
          type
          quiz {
            ... on Statements {
              statement
              isTrue
            }
            ... on MultipleChoices {
              question
              choices {
                option
                isTrue
              }
            }
          }
        }
      }
      subjectId
      subject {
        id
        subject
      }
      questions {
        id
        question
        answer
        studyMaterialId
      }
      flashcards {
        id
        question
        answer
        studyMaterialId
      }
      published
      flags {
        deleted
        isPublished
      }
      folderId
      userId
      created
      updated
      views
      exam {
        id
        studyMaterialId
        evaluations(options: { order: DESCENDING }) {
          id
          feedback
          status
          questions(options: { order: DESCENDING }) {
            id
            userId
            examEvaluationId
            question
            answer
            feedback
            userAnswer
            maxScore
            score
          }
        }
        questions {
          id
          examId
          studyMaterialId
          question
          answer
          maxScore
        }
      }
    }
  }
`;
