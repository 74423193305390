import React, { Dispatch, SetStateAction } from 'react';

import { PlayState } from './types';

import { initialTextToSpeechState, ITextToSpeechState } from './state';
import { TextToSpeechAudioPlayer } from '../../classes';

export interface ITextToSpeechContext extends ITextToSpeechState {
  play: (
    text: string,
    audioPlayer: TextToSpeechAudioPlayer,
    playState: PlayState,
    setPlayState: Dispatch<SetStateAction<PlayState>>
  ) => void;
  pause: (
    audioPlayer: TextToSpeechAudioPlayer,
    setPlayState: Dispatch<SetStateAction<PlayState>>
  ) => void;
  reset: (
    audioPlayer: TextToSpeechAudioPlayer,
    setAudioPlayer: Dispatch<
      SetStateAction<TextToSpeechAudioPlayer | undefined>
    >,
    setPlayState: Dispatch<SetStateAction<PlayState>>
  ) => void;
}

const stub = (): never => {
  throw new Error('You forgot to wrap your component.');
};

const initialTextToSpeechContext: ITextToSpeechContext = {
  ...initialTextToSpeechState,
  play: stub,
  pause: stub,
  reset: stub,
};

export const TextToSpeechContext = React.createContext(
  initialTextToSpeechContext
);
