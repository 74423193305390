import ReactGA from 'react-ga4';

import { PlayState } from '../textToSpeech';
import { ContentType } from '../../utils';
import { QuizType } from '../../generated';
import { GARatingCategory } from './types';

export enum GACategory {
  OKR = 'OKR',
}
enum GAAction {
  GENERATE_SUMMARY = 'GENERATE_SUMMARY',
  COPY_SHARE_STUDY_MATERIAL_LINK = 'COPY_SHARE_STUDY_MATERIAL_LINK',
  COPY_SHARE_QURIE_LINK = 'COPY_SHARE_QURIE_LINK',
  SHARED_PAGE_VISIT = 'SHARED_PAGE_VISIT',
  QUIZ_STARTED_STATEMENTS = 'QUIZ_STARTED_STATEMENTS',
  QUIZ_STARTED_MULTICHOICES = 'QUIZ_STARTED_MULTICHOICES',
  QUIZ_START_OVER_STATEMENTS = 'QUIZ_START_OVER_STATEMENTS',
  QUIZ_START_OVER_MULTICHOICES = 'QUIZ_START_OVER_MULTICHOICES',
  QUIZ_RETRY_WRONG_ANSWERS_STATEMENTS = 'QUIZ_RETRY_WRONG_ANSWERS_STATEMENTS',
  QUIZ_RETRY_WRONG_ANSWERS_MULTICHOICES = 'QUIZ_RETRY_WRONG_ANSWERS_MULTICHOICES',
  QUIZ_FINISHED_STATEMENTS = 'QUIZ_FINISHED_STATEMENTS',
  QUIZ_FINISHED_MULTICHOICES = 'QUIZ_FINISHED_MULTICHOICES',
  FLASHCARD_FOCUSED = 'FLASHCARD_FOCUSED',
  FLASHCARD_FLIPPED = 'FLASHCARD_FLIPPED',
  EXAM_SUBMITTED_FOR_EVALUATION = 'EXAM_SUBMITTED_FOR_EVALUATION',
  RATING_SUBMITTED = 'RATING_SUBMITTED',
  RATING_APPEARED = 'RATING_APPEARED',
}

export const collectStudyMaterialGeneratedStats = () => {
  ReactGA.event({
    category: GACategory.OKR,
    action: GAAction.GENERATE_SUMMARY,
    value: 1,
  });
};

export const collectShareStudyMaterialUrlStats = () => {
  ReactGA.event({
    category: GACategory.OKR,
    action: GAAction.COPY_SHARE_STUDY_MATERIAL_LINK,
    value: 1,
  });
};
export const collectShareQurieUrlStats = () => {
  ReactGA.event({
    category: GACategory.OKR,
    action: GAAction.COPY_SHARE_QURIE_LINK,
    value: 1,
  });
};

export const collectSharedPagesVisitStats = (urlPath?: string) => {
  ReactGA.event({
    category: GACategory.OKR,
    action: GAAction.SHARED_PAGE_VISIT,
    value: 1,
    label: urlPath,
  });
};

export const collectStartedQuizStats = (quizType: QuizType) => {
  ReactGA.event({
    category: GACategory.OKR,
    action:
      quizType === QuizType.Statements
        ? GAAction.QUIZ_STARTED_STATEMENTS
        : GAAction.QUIZ_STARTED_MULTICHOICES,
    value: 1,
  });
};

export const collectStartOverQuizStats = (quizType: QuizType) => {
  ReactGA.event({
    category: GACategory.OKR,
    action:
      quizType === QuizType.Statements
        ? GAAction.QUIZ_START_OVER_STATEMENTS
        : GAAction.QUIZ_START_OVER_MULTICHOICES,
    value: 1,
  });
};

export const collectRetryWrongAnswersQuizStats = (quizType: QuizType) => {
  ReactGA.event({
    category: GACategory.OKR,
    action:
      quizType === QuizType.Statements
        ? GAAction.QUIZ_START_OVER_STATEMENTS
        : GAAction.QUIZ_START_OVER_MULTICHOICES,
    value: 1,
  });
};

export const collectFinishedQuizStats = (quizType: QuizType) => {
  ReactGA.event({
    category: GACategory.OKR,
    action:
      quizType === QuizType.Statements
        ? GAAction.QUIZ_FINISHED_STATEMENTS
        : GAAction.QUIZ_FINISHED_MULTICHOICES,
    value: 1,
  });
};

export const collectTextToSpeechStats = (
  onClickEvent: PlayState,
  contentType: ContentType
) => {
  ReactGA.event({
    category: GACategory.OKR,
    action: `TEXT_TO_SPEECH_${onClickEvent}_${contentType}`,
    value: 1,
  });
};

export const collectFlashcardFocusedStats = () => {
  ReactGA.event({
    category: GACategory.OKR,
    action: GAAction.FLASHCARD_FOCUSED,
    value: 1,
  });
};

export const collectFlashcardFlippedStats = () => {
  ReactGA.event({
    category: GACategory.OKR,
    action: GAAction.FLASHCARD_FLIPPED,
    value: 1,
  });
};

export const collectExamEvaluationStats = () => {
  ReactGA.event({
    category: GACategory.OKR,
    action: GAAction.EXAM_SUBMITTED_FOR_EVALUATION,
    value: 1,
  });
};

export const collectRatingStats = (
  rating: number,
  category: GARatingCategory
) => {
  ReactGA.event({
    category: GACategory.OKR,
    action: GAAction.RATING_SUBMITTED,
    value: rating,
    label: category,
  });
};

export const collectRatingAppearanceStats = () => {
  ReactGA.event({
    category: GACategory.OKR,
    action: GAAction.RATING_APPEARED,
    value: 1,
  });
};
// Add more collect functions here..
