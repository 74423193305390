import React from 'react';

import { QuizType } from '../../generated';
import { PlayState } from '../textToSpeech';
import { GAGenerateAction } from './collectResultSatisfaction';
import { ContentType } from '../../utils';
import { GARatingCategory } from './types';

export interface IUsageContext {
  collectStudyMaterialGeneratedStats: () => void;
  collectShareStudyMaterialUrlStats: () => void;
  collectShareQurieUrlStats: () => void;
  collectSharedPagesVisitStats: (urlPath?: string) => void;
  collectStartedQuizStats: (quizType: QuizType) => void;
  collectStartOverQuizStats: (quizType: QuizType) => void;
  collectRetryWrongAnswersQuizStats: (quizType: QuizType) => void;
  collectFinishedQuizStats: (quizType: QuizType) => void;
  collectResultSatisfaction: (action: GAGenerateAction) => void;
  collectTextToSpeechStats: (
    onClickEvent: PlayState,
    contentType: ContentType
  ) => void;
  collectFlashcardFocusedStats: () => void;
  collectFlashcardFlippedStats: () => void;
  collectExamEvaluationStats: () => void;
  collectRatingStats: (rating: number, category: GARatingCategory) => void;
  collectRatingAppearanceStats: () => void;
}

const stub = (): never => {
  throw new Error('You forgot to wrap your component.');
};

const initialUsageContext: IUsageContext = {
  collectStudyMaterialGeneratedStats: stub,
  collectShareStudyMaterialUrlStats: stub,
  collectShareQurieUrlStats: stub,
  collectSharedPagesVisitStats: stub,
  collectStartedQuizStats: stub,
  collectRetryWrongAnswersQuizStats: stub,
  collectStartOverQuizStats: stub,
  collectFinishedQuizStats: stub,
  collectResultSatisfaction: stub,
  collectTextToSpeechStats: stub,
  collectFlashcardFocusedStats: stub,
  collectFlashcardFlippedStats: stub,
  collectExamEvaluationStats: stub,
  collectRatingStats: stub,
  collectRatingAppearanceStats: stub,
};

export const UsageContext = React.createContext(initialUsageContext);
