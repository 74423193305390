import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography as MuiTypography, Box as MuiBox } from '@mui/material';

import { notFoundPageStrings as pageStrings } from '../../../assets/strings/sv';
import { Container } from './styles';
import { ButtonAtom } from '../../library/atoms';

export const NotFoundPage = () => {
  const navigate = useNavigate();

  const handleGoToStartPage = () => {
    navigate('/');
  };
  return (
    <Container container>
      <MuiBox>
        <MuiTypography variant="h1">{pageStrings.title}</MuiTypography>
        <MuiTypography style={{ marginBottom: 30 }} variant="body1">
          {pageStrings.text}
        </MuiTypography>
        <ButtonAtom
          variant="primary"
          text={pageStrings.goBack}
          onClick={handleGoToStartPage}
        />
      </MuiBox>
    </Container>
  );
};
