import React from 'react';
import { Box as MuiBox, Skeleton as MuiSkeleton } from '@mui/material';

interface ISummarySkeletonComponent {
  editMode?: boolean;
}

export const SummarySkeletonComponent = ({
  editMode,
}: ISummarySkeletonComponent) => {
  return (
    <MuiBox>
      <MuiBox
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: 10,
        }}
      >
        <MuiSkeleton
          animation="wave"
          variant={'rounded'}
          width={'30%'}
          height={40}
        />
        <MuiBox style={{ display: 'flex', gap: 10 }}>
          <MuiSkeleton
            animation="wave"
            variant={'rounded'}
            width={40}
            height={40}
          />
          {editMode && (
            <MuiSkeleton
              animation="wave"
              variant={'rounded'}
              width={40}
              height={40}
            />
          )}
        </MuiBox>
      </MuiBox>

      <MuiSkeleton animation="wave" variant={'rounded'} height={'60vh'} />
    </MuiBox>
  );
};
