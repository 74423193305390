import { Box as MuiBox, useTheme } from '@mui/material';
import {
  CheckOutlined,
  CloseOutlined,
  EditOutlined,
} from '@mui/icons-material';

import { IconButtonAtom } from '../../../../../../atoms';

interface IExamHeaderComponent {
  isEditModeActive: boolean;
  isEditModeAvailable: boolean;
  handleActivateEditMode: () => void;
  handleCancelEditMode: () => void;
  handleConfirmEdit: () => void;
}

export const ExamHeaderComponent = ({
  isEditModeActive,
  isEditModeAvailable,
  handleActivateEditMode,
  handleCancelEditMode,
  handleConfirmEdit,
}: IExamHeaderComponent) => {
  const theme = useTheme();

  if (!isEditModeAvailable) {
    return <></>;
  }

  return (
    <MuiBox display="flex" width="100%" justifyContent="flex-end">
      {!isEditModeActive ? (
        <IconButtonAtom
          icon={<EditOutlined />}
          onClick={handleActivateEditMode}
        />
      ) : (
        <MuiBox display="flex" columnGap={1.5}>
          <IconButtonAtom
            variant="primary"
            icon={<CheckOutlined />}
            onClick={handleConfirmEdit}
            sx={{ color: theme.palette.common.black }}
          />
          <IconButtonAtom
            icon={<CloseOutlined />}
            onClick={handleCancelEditMode}
          />
        </MuiBox>
      )}
    </MuiBox>
  );
};
