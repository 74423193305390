import { SpeechConfig } from 'microsoft-cognitiveservices-speech-sdk';
import * as SpeechSDK from 'microsoft-cognitiveservices-speech-sdk';

import { getLanguageCode, getVoiceName } from '../helpers';

export class SpeechConfiguration {
  speechConfig: SpeechConfig;
  constructor(language: string) {
    this.speechConfig = SpeechSDK.SpeechConfig.fromSubscription(
      `${process.env.REACT_APP_AZURE_SPEECH_KEY}`,
      'swedencentral'
    );

    this.speechConfig.speechSynthesisLanguage = getLanguageCode(language);
    this.speechConfig.speechSynthesisVoiceName = `${getLanguageCode(
      language
    )}-${getVoiceName(language)}`;
  }
}
