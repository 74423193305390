import {
  Box as MuiBox,
  Typography as MuiTypography,
  styled,
} from '@mui/material';

export const SnackbarContainer = styled(MuiBox)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
}));

export const SnackbarContent = styled(MuiBox)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
  padding: '16px',
  margin: '32px 16px',
  position: 'fixed',
  maxWidth: '450px',
  bottom: 0,
  zIndex: 100,
  border: `1px solid ${theme.palette.background.paper}`,
  borderRadius: '12px',
  background: theme.palette.grey[900],
}));

export const TitleContainer = styled(MuiBox)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
}));

export const TitleContent = styled(MuiBox)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'row',
  lineHeight: '22.2px',
}));

export const TitleLink = styled(MuiTypography)(({ theme }) => ({
  cursor: 'pointer',
  textDecoration: 'underline',

  '&:hover': {
    backgroundImage: 'linear-gradient(to left, #FD9BF4, #87FD93, #FD9BF4)',
    color: 'transparent',
    backgroundClip: 'text',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    backgroundSize: '400% 100%',
    animation: 'animate 8s linear infinite',
  },
  '@keyframes animate': {
    '0%': {
      backgroundPosition: '0% 0%',
    },
    '100%': {
      backgroundPosition: '400% 0%',
    },
  },
}));

export const IconContainer = styled(MuiBox)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  padding: '8px',
}));

export const Text = styled(MuiTypography)(({ theme }) => ({
  fontWeight: '500',
  lineHeight: '27.2px',
  letterSpacing: '0.32px',
}));
