import React from 'react';
import { useMediaQuery, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Box as MuiBox } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import { NotificationsOutlined, AutoAwesome } from '@mui/icons-material';

import { ButtonAtom, IconButtonAtom } from '../../../../atoms';
import { commonStrings } from '../../../../../../assets/strings/sv';
import { StreakComponent } from './components';

interface IHeaderComponent {
  streakNumber: number;
}

export const HeaderComponent = ({ streakNumber }: IHeaderComponent) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));

  const navigate = useNavigate();
  const { isAuthenticated, loginWithRedirect } = useAuth0();

  const { login, register } = commonStrings;

  const handleNavigateToGenerateStudyMaterialPage = () => {
    navigate('/generate');
  };

  const handleLoginUser = () => {
    // User will always be redirected to dashboard after logging in
    loginWithRedirect({
      appState: { returnTo: '/dashboard' },
    });
  };

  const handleCreateAccount = () => {
    loginWithRedirect({ authorizationParams: { screen_hint: 'signup' } });
  };

  return (
    <>
      <MuiBox display="flex" marginLeft="auto" columnGap={theme.spacing(2)}>
        {!isAuthenticated ? (
          <>
            <ButtonAtom
              variant="border"
              text={login}
              onClick={handleLoginUser}
            />
            <ButtonAtom
              variant="gradient"
              text={register}
              onClick={handleCreateAccount}
            />
          </>
        ) : (
          <>
            <IconButtonAtom
              icon={<NotificationsOutlined fontSize="small" />}
              onClick={() => {}}
              disabled
            />
            <StreakComponent streakNumber={streakNumber} />
            <ButtonAtom
              variant="gradient"
              endIcon={<AutoAwesome />}
              text={
                isDesktop
                  ? commonStrings.createStudyMaterial
                  : commonStrings.create
              }
              onClick={handleNavigateToGenerateStudyMaterialPage}
            />
          </>
        )}
      </MuiBox>
    </>
  );
};
