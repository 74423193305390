import { useEffect, useMemo, useState } from 'react';
import { Box as MuiBox, useTheme } from '@mui/material';
import { AddRounded } from '@mui/icons-material';
import { ulid } from 'ulid';
import _ from 'lodash';

import {
  useFlashcardContext,
  useStudyMaterialContext,
} from '../../../../../../hooks';
import { ModalMolecule } from '../../../../molecules';
import { ButtonAtom } from '../../../../atoms';
import { Flashcard } from '../../../../../../generated';
import { commonStrings } from '../../../../../../assets/strings/sv';
import { FlashcardItemComponent } from './components';
import { UnsavedFlashcard } from './types';

interface IEditModalComponent {
  isOpen: boolean;
  handleClose: () => void;
}

type FieldValidation = {
  answer: string;
  question: string;
};

export const EditModalComponent = ({
  isOpen,
  handleClose,
}: IEditModalComponent) => {
  const theme = useTheme();
  const { studyMaterial } = useStudyMaterialContext();
  const { saveFlashcards, updateFlashcard, deleteFlashcard } =
    useFlashcardContext();

  const [flashcards, setFlashcards] = useState(studyMaterial?.flashcards || []);
  const [unsavedFlashcards, setUnsavedFlashcards] = useState<
    UnsavedFlashcard[]
  >([]);
  const [deletedFlashcards, setDeletedFlashcards] = useState<Flashcard[]>([]);
  const [fieldValidations, setFieldValidations] = useState<FieldValidation[]>(
    []
  );

  const {
    question,
    add,
    edit,
    save,
    flashcards: flashcardsString,
    studyMaterialSection,
  } = commonStrings;
  const { errorMessages } = studyMaterialSection.flashcardSection;

  useEffect(() => {
    setFlashcards(studyMaterial?.flashcards || []);
  }, [studyMaterial]);

  const handleChange = (flashcard: Flashcard | UnsavedFlashcard) => {
    if ('id' in flashcard) {
      const typedFlashcard = flashcard as Flashcard;
      setFlashcards((prevState) =>
        prevState.map((_flashcard) =>
          _flashcard.id === typedFlashcard.id ? typedFlashcard : _flashcard
        )
      );
    } else {
      const typedFlashcard = flashcard as UnsavedFlashcard;
      setUnsavedFlashcards((prevState) =>
        prevState.map((_flashcard) =>
          _flashcard._id === typedFlashcard._id ? typedFlashcard : _flashcard
        )
      );
    }
  };

  const handleAddFlashcard = () => {
    setUnsavedFlashcards((prevState) => [
      ...prevState,
      { answer: '', question: '', _id: ulid() },
    ]);

    const container = document.getElementById('flashcardsScrollContainer');
    if (container) {
      setTimeout(() => {
        container.scrollTo({ top: container.scrollHeight, behavior: 'smooth' });
      }, 100); // Add timeout to give flashcard time to be added to state
    }
  };

  const handleDeleteFlashcard = (flashcard: Flashcard | UnsavedFlashcard) => {
    if ('id' in flashcard) {
      const typedFlashcard = flashcard as Flashcard;
      setDeletedFlashcards((prevState) => [...prevState, typedFlashcard]);
    } else {
      const typedFlashcard = flashcard as UnsavedFlashcard;
      setUnsavedFlashcards((prevState) =>
        prevState.filter((_flashcard) => _flashcard._id !== typedFlashcard._id)
      );
    }
  };

  const handleCloseModal = () => {
    handleClose();
    setDeletedFlashcards([]);
    setUnsavedFlashcards([]);
    setFieldValidations([]);
    setFlashcards(studyMaterial?.flashcards || []);
  };

  const validateFields = () => {
    const validations = _flashcards.map((flashcard) => {
      const fieldValidation = {
        answer: '',
        question: '',
      };

      if (!flashcard.question)
        fieldValidation.question = errorMessages.question;
      if (!flashcard.answer) fieldValidation.answer = errorMessages.answer;

      setFieldValidations((prevState) => [...prevState, fieldValidation]);
      return Object.values(fieldValidation).some((validation) => !!validation);
    });
    return validations.every((validation) => !!!validation);
  };

  const handleSaveChanges = () => {
    if (!validateFields() || !studyMaterial) return;

    deletedFlashcards.map((flashcard) => deleteFlashcard(flashcard.id));
    unsavedFlashcards.length &&
      saveFlashcards({
        studyMaterialId: studyMaterial.id,
        flashcards: unsavedFlashcards.map((flashcard) =>
          _.omit(flashcard, '_id')
        ),
      });
    const updatedFlashcards = filterUnchangedFlashcards(
      flashcards.filter((flashcard) =>
        deletedFlashcards.every((_flashcard) => flashcard.id !== _flashcard.id)
      )
    );
    updatedFlashcards.length &&
      updatedFlashcards.map(({ id, answer, question }) =>
        updateFlashcard(id, { answer, question })
      );
    handleCloseModal();
  };

  const filterUnchangedFlashcards = (flashcards: Flashcard[]) => {
    return flashcards.filter(
      (flashcard) =>
        !(studyMaterial?.flashcards || [[]]).some((_flashcard) =>
          _.isEqual(flashcard, _flashcard)
        )
    );
  };

  const _flashcards = useMemo(() => {
    return [
      ...flashcards.filter(
        (flashcard) =>
          !deletedFlashcards.some(
            (deletedFlashcard) => deletedFlashcard.id === flashcard.id
          )
      ),
      ...unsavedFlashcards,
    ];
  }, [unsavedFlashcards, flashcards, deletedFlashcards]);

  return (
    <ModalMolecule
      title={`${edit} ${flashcardsString.toLowerCase()}`}
      isOpen={isOpen}
      onClose={handleCloseModal}
      sx={{ width: 600, backgroundColor: theme.palette.background[800] }}
    >
      <MuiBox
        display="flex"
        flexDirection="column"
        rowGap={2}
        id="flashcardsScrollContainer"
        sx={{
          flex: 1,
          overflow: 'auto',
        }}
      >
        {_flashcards.map((flashcard, index) => (
          <FlashcardItemComponent
            key={index}
            flashcard={flashcard}
            number={index + 1}
            fieldValidation={fieldValidations[index]}
            handleDeleteFlashcard={handleDeleteFlashcard}
            handleChange={handleChange}
          />
        ))}
      </MuiBox>
      <MuiBox display="flex" paddingTop={2}>
        <ButtonAtom
          text={`${add} ${question.toLowerCase()}`}
          variant="border"
          sx={{ width: '100%', marginRight: 2 }}
          endIcon={<AddRounded />}
          onClick={handleAddFlashcard}
        />
        <ButtonAtom
          text={save}
          variant="primary"
          sx={{ width: '100%' }}
          onClick={handleSaveChanges}
        />
      </MuiBox>
    </ModalMolecule>
  );
};
