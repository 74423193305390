import React from 'react';
import FormControl from '@mui/material/FormControl';
import {
  SelectProps,
  SxProps,
  Theme,
  Typography,
  lighten,
  useTheme,
} from '@mui/material';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';

import { Select, MenuItem } from './styles';

interface ISelectorAtom {
  value: any;
  comparisonValue?: string;
  listItems: any[];
  onSelectedItem: (value: any) => void;
  placeholder?: string;
  renderProperty: string;
  sx?: SxProps<Theme>;
  variant?: SelectProps['variant'];
}

export const SelectorAtom = ({
  value,
  listItems,
  placeholder,
  renderProperty,
  comparisonValue,
  onSelectedItem,
  sx,
  variant,
}: ISelectorAtom) => {
  const theme = useTheme();

  let styles;

  switch (variant) {
    case 'outlined':
      styles = {
        '& .MuiSelect-select': {
          backgroundColor: theme.palette.background[800],
          border: `1px solid ${theme.palette.divider}`,
          padding: '16px 22px',
          borderRadius: `${theme.shape.borderRadius} px`,
          transition: 'background-color 200ms ease',
          '& span': {
            ...theme.typography.body1,
          },
        },
        ...sx,
      };
      break;
    case 'filled':
      styles = {
        '& .MuiSelect-select': {
          backgroundColor: theme.palette.background[500],
          border: 'none',
          padding: '16px 22px',
          borderRadius: `${theme.shape.borderRadius} px`,
          transition: 'background-color 200ms ease',
          '& span': {
            ...theme.typography.body1,
          },
          '&:hover': {
            backgroundColor: lighten(
              theme.palette.background?.[500] || '',
              0.02
            ),
          },
        },
        ...sx,
      };
      break;
    default:
      styles = {
        '& .MuiSelect-select': {
          backgroundColor: `${theme.palette.background[900]} !important`,
          padding: '16px 22px',
          borderRadius: `${theme.shape.borderRadius} px`,
          border: `1px solid ${theme.palette.divider}`,
          transition: 'background-color 200ms ease',
          '& span': {
            ...theme.typography.body1,
          },
          '&:hover': {
            backgroundColor: lighten(
              theme.palette.background?.[900] || '',
              0.02
            ),
          },
        },
        ...sx,
      };
      break;
  }

  return (
    <FormControl fullWidth size="small" sx={styles}>
      <Select
        IconComponent={KeyboardArrowDownRoundedIcon}
        displayEmpty
        value={value}
        onChange={(e: any) => {
          const value = e.target.value;

          if (value !== undefined) {
            onSelectedItem(value);
          }
        }}
        renderValue={(value) => {
          if (value === undefined) {
            return <Typography variant="button">{placeholder}</Typography>;
          }

          const selectedItem = listItems.find((item) =>
            comparisonValue
              ? item[comparisonValue] === value
              : // stringify is needed for comparison of objects
                JSON.stringify(item) === JSON.stringify(value)
          );

          return (
            <Typography variant="button">
              {selectedItem ? selectedItem[renderProperty] : placeholder}
            </Typography>
          );
        }}
      >
        {listItems.map((item, index: number) => (
          <MenuItem
            disabled={
              comparisonValue && !item.hasOwnProperty(comparisonValue)
                ? true
                : false
            }
            key={index}
            value={comparisonValue ? item[comparisonValue] : item}
          >
            <Typography variant="button">{item[renderProperty]}</Typography>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
