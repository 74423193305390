import { Box as MuiBox, styled } from '@mui/material';

export const TextContainer = styled(MuiBox)(({ theme }) => ({
  flex: 1,
  minHeight: 0,
  overflowX: 'auto',
  padding: 16,
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background[800],
}));
