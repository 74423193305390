import { useState } from 'react';
import {
  Box as MuiBox,
  useTheme,
  Typography as MuiTypography,
} from '@mui/material';
import {
  AddOutlined,
  CheckOutlined,
  CloseOutlined,
  EditOutlined,
} from '@mui/icons-material';

import { ModalMolecule } from '../../../../../../molecules';
import {
  ButtonAtom,
  IconButtonAtom,
  ScrollBoxAtom,
} from '../../../../../../atoms';
import {
  GetStudyMaterialQuery,
  MultipleChoices,
  QuizType,
  Statements,
} from '../../../../../../../../generated';
import { commonStrings } from '../../../../../../../../assets/strings/sv';
import {
  MultipleChoiceQuestionComponent,
  StatementQuestionComponent,
} from './components';
import { useQuizContext } from '../../../../../../../../hooks';
import { quizTypeToString } from '../../../../../../../helpers';

export type MultipleChoiceErrorMessage = {
  question: string;
  choices: { option: string }[];
};
export type StatementErrorMessage = {
  statement: string;
};

type QuizError = {
  statements: {
    error: boolean;
    errorMessages: StatementErrorMessage[];
  };
  multipleChoices: {
    error: boolean;
    errorMessages: MultipleChoiceErrorMessage[];
  };
};

interface IQuizEditModalComponent {
  quiz: GetStudyMaterialQuery['getStudyMaterial']['quiz'];
}

export const QuizEditModalComponent = ({ quiz }: IQuizEditModalComponent) => {
  const theme = useTheme();

  const [isOpen, setIsOpen] = useState(false);
  const [selectedQuizType, setSelectedQuizType] = useState(QuizType.Statements);

  const [multipleChoices, setMultipleChoices] = useState(
    (quiz.multipleChoices?.quiz as MultipleChoices[]) || []
  );
  const [statements, setStatements] = useState(
    (quiz.statements?.quiz as Statements[]) || []
  );
  const [errors, setErrors] = useState<QuizError>({
    statements: {
      errorMessages: [],
      error: false,
    },
    multipleChoices: {
      errorMessages: [],
      error: false,
    },
  });

  const { updateQuiz } = useQuizContext();

  const { quizSection } = commonStrings.studyMaterialSection;

  const handleOpenModal = () => setIsOpen(true);
  const handleCloseModal = () => {
    setMultipleChoices(quiz.multipleChoices?.quiz as MultipleChoices[]);
    setStatements(quiz.statements?.quiz as Statements[]);
    resetValidation();
    setIsOpen(false);
  };

  const scrollToBottom = () => {
    const scrollContainer = document.getElementById('quizEditScrollContainer');
    scrollContainer?.scrollTo({
      top: scrollContainer.scrollHeight,
      behavior: 'smooth',
    });
  };

  const handleChangeStatement = (segment: Statements, index: number) => {
    resetValidation();
    const updatedStatements = [...statements];
    updatedStatements[index] = segment;
    setStatements(updatedStatements);
  };

  const handleChangeMultipleChoices = (
    segment: MultipleChoices,
    index: number
  ) => {
    resetValidation();
    const updatedMultipleChoices = [...multipleChoices];
    updatedMultipleChoices[index] = segment;
    setMultipleChoices(updatedMultipleChoices);
  };

  const handleAddStatement = () => {
    const defaultStatement: Statements = {
      __typename: 'Statements',
      statement: '',
      isTrue: false,
    };
    setStatements((prevState) => [...prevState, defaultStatement]);
    scrollToBottom();
  };

  const handleAddMultipleChoice = () => {
    const defaultMultipleChoices: MultipleChoices = {
      __typename: 'MultipleChoices',
      question: '',
      choices: [{ option: '', isTrue: false }],
    };
    setMultipleChoices((prevState) => [...prevState, defaultMultipleChoices]);
    scrollToBottom();
  };

  const handleDeleteStatement = (index: number) => {
    const updatedStatements = statements.filter((_, i) => i !== index);
    setStatements(() => updatedStatements);
  };

  const handleDeleteMultipleChoices = (index: number) => {
    const updatedMultipleChoices = multipleChoices.filter(
      (_, i) => i !== index
    );
    setMultipleChoices(updatedMultipleChoices);
  };

  const handleValidateMultipleChoices = () => {
    const validation = multipleChoices.map((segment) => {
      let isValid = true;

      isValid =
        segment.choices.every((choice) => !!choice.option) &&
        !!segment.question &&
        !!segment.choices.length;

      setErrors((prevState) => ({
        ...prevState,
        multipleChoices: {
          ...prevState.multipleChoices,
          errorMessages: [
            ...prevState.multipleChoices.errorMessages,
            {
              question: !!segment.question
                ? ''
                : quizSection.errorMessages.question,
              choices: segment.choices.map((choice) => ({
                option: !!choice.option ? '' : quizSection.errorMessages.option,
              })),
            },
          ],
        },
      }));

      return isValid;
    });

    const isValid =
      validation.every((valid) => !!valid) && multipleChoices.length;
    setErrors((prevState) => ({
      ...prevState,
      multipleChoices: {
        ...prevState.multipleChoices,
        error: !isValid,
      },
    }));
    return isValid;
  };

  const handleValidateStatements = () => {
    const validation = statements.map((segment) => {
      let isValid = true;

      isValid = !!segment.statement;

      setErrors((prevState) => ({
        ...prevState,
        statements: {
          ...prevState.statements,
          errorMessages: [
            ...prevState.statements.errorMessages,
            {
              statement: !!segment.statement
                ? ''
                : quizSection.errorMessages.statement,
            },
          ],
        },
      }));

      return isValid;
    });

    const isValid = validation.every((valid) => !!valid) && statements.length;
    setErrors((prevState) => ({
      ...prevState,
      statements: {
        ...prevState.statements,
        error: !isValid,
      },
    }));
    return validation.every((valid) => !!valid);
  };

  const handleConfirmEdit = () => {
    resetValidation();
    const isMultipleChoicesValid = handleValidateMultipleChoices();
    const isStatementsValid = handleValidateStatements();

    if (!isMultipleChoicesValid || !isStatementsValid) {
      return;
    }

    quiz.multipleChoices &&
      updateQuiz(quiz.multipleChoices.id, {
        multipleChoices,
      });
    quiz.statements && updateQuiz(quiz.statements.id, { statements });
    setIsOpen(false);
  };

  const resetValidation = () => {
    setErrors({
      statements: {
        error: false,
        errorMessages: [],
      },
      multipleChoices: {
        error: false,
        errorMessages: [],
      },
    });
  };

  const renderContent = (selectedQuizType: QuizType) => {
    switch (selectedQuizType) {
      case QuizType.MultipleChoices:
        if (!multipleChoices.length) {
          return (
            <MuiBox padding={1.5}>
              <MuiTypography>{quizSection.noQuestions}</MuiTypography>
            </MuiBox>
          );
        }
        return multipleChoices.map((segment, index) => (
          <MultipleChoiceQuestionComponent
            key={index}
            segment={segment}
            index={index}
            errorMessage={errors.multipleChoices.errorMessages[index]}
            handleChangeMultipleChoices={handleChangeMultipleChoices}
            handleDeleteMultipleChoices={handleDeleteMultipleChoices}
          />
        ));
      default:
        if (!statements.length) {
          return (
            <MuiBox padding={1.5}>
              <MuiTypography>{quizSection.noQuestions}</MuiTypography>
            </MuiBox>
          );
        }
        return statements.map((segment, index) => (
          <StatementQuestionComponent
            key={index}
            segment={segment}
            index={index}
            errorMessage={errors.statements.errorMessages[index]}
            handleChangeStatement={handleChangeStatement}
            handleDeleteStatement={handleDeleteStatement}
          />
        ));
    }
  };

  return (
    <>
      <IconButtonAtom
        variant="light"
        icon={<EditOutlined />}
        onClick={handleOpenModal}
      />
      <ModalMolecule
        title={`${commonStrings.edit} ${commonStrings.quiz}`}
        isOpen={isOpen}
        onClose={handleCloseModal}
        sx={{ width: 600 }}
      >
        <MuiBox
          display="flex"
          flexDirection="column"
          height="100%"
          gap={1}
          overflow="hidden"
        >
          <MuiBox display="flex" gap={2}>
            <ScrollBoxAtom gradientColor={theme.palette.background[800]}>
              <MuiBox display="flex" alignItems="center" gap={1}>
                {[QuizType.Statements, QuizType.MultipleChoices].map(
                  (type, index) => (
                    <ButtonAtom
                      key={index}
                      text={quizSection.selectQuiz[quizTypeToString[type]]}
                      onClick={() => setSelectedQuizType(type)}
                      variant={
                        selectedQuizType === type
                          ? errors[quizTypeToString[type]].error
                            ? 'error'
                            : 'primary'
                          : errors[quizTypeToString[type]].error
                          ? 'errorBorder'
                          : 'border'
                      }
                      sx={{ whiteSpace: 'nowrap' }}
                    />
                  )
                )}
              </MuiBox>
            </ScrollBoxAtom>
            <MuiBox
              display="flex"
              flexDirection="row"
              alignItems="center"
              gap={1}
            >
              <IconButtonAtom
                variant="primary"
                icon={<CheckOutlined />}
                onClick={handleConfirmEdit}
              />
              <IconButtonAtom
                variant="dark"
                icon={<CloseOutlined />}
                onClick={() => {}}
              />
            </MuiBox>
          </MuiBox>
          <MuiBox
            display="flex"
            flexDirection="column"
            gap={2}
            overflow="auto"
            id="quizEditScrollContainer"
          >
            {renderContent(selectedQuizType)}
          </MuiBox>
          <MuiBox marginLeft="auto">
            <ButtonAtom
              variant="primary"
              text={quizSection.addQuestion}
              endIcon={<AddOutlined fontSize="small" />}
              onClick={
                selectedQuizType === QuizType.MultipleChoices
                  ? handleAddMultipleChoice
                  : handleAddStatement
              }
            />
          </MuiBox>
        </MuiBox>
      </ModalMolecule>
    </>
  );
};
