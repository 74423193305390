import { useEffect, useState } from 'react';
import { Box, useTheme } from '@mui/material';

import logo from '../../../../assets/images/logo.svg';
import { StatusMessage } from './components';
import { DotOne, DotThree, DotTwo, StatusContainer, StyledBox } from './styles';
import { ButtonAtom } from '../../atoms';
import { commonStrings } from '../../../../assets/strings/sv';

interface IStatusContainerMolecule {
  statusMessage: string;
  descriptionText?: string;
  onRetryClick?: () => void;
}

export const StatusContainerMolecule = ({
  statusMessage,
  descriptionText,
  onRetryClick,
}: IStatusContainerMolecule) => {
  const theme = useTheme();
  const [isRetryAvailable, setIsRetryAvailable] = useState(false);
  let retryTimeout: NodeJS.Timeout;

  const startRetryTimeout = () => {
    retryTimeout = setTimeout(() => {
      setIsRetryAvailable(true);
    }, 30 * 1000); // 30 seconds
  };

  useEffect(() => {
    startRetryTimeout();

    return () => clearTimeout(retryTimeout); // cleanup function
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRetryClick = () => {
    onRetryClick && onRetryClick();
    setIsRetryAvailable(false);
    startRetryTimeout();
  };

  const LoadingSuffix = () => {
    return (
      <>
        <DotOne>.</DotOne>
        <DotTwo>.</DotTwo>
        <DotThree>.</DotThree>
      </>
    );
  };

  return (
    <StatusContainer>
      <Box>
        <img src={logo} alt="qurie-logo" />
      </Box>
      <StyledBox>
        <StatusMessage text={statusMessage} variant={'body1'} />
        <LoadingSuffix />
      </StyledBox>
      {descriptionText && (
        <StatusMessage
          text={descriptionText}
          variant="subtitle2"
          sx={{ color: theme.palette.grey[500] }}
        />
      )}
      {isRetryAvailable && onRetryClick && (
        <ButtonAtom
          text={commonStrings.retry}
          onClick={handleRetryClick}
          variant="border"
        />
      )}
    </StatusContainer>
  );
};
