import {
  Box as MuiBox,
  Typography as MuiTypography,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import { DrawerComponent } from '../../../../library/organisms';

export const HeaderComponent = () => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <MuiBox
      display="flex"
      marginBottom="50px"
      alignItems="center"
      justifyContent="space-between"
    >
      <MuiBox display="flex" alignItems="center">
        <DrawerComponent />
        {isDesktop && <MuiTypography variant="h5">Utforska</MuiTypography>}
      </MuiBox>
    </MuiBox>
  );
};
