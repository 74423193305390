import { Components, Theme } from '@mui/material';

export const MuiTooltipOverride = (theme: Theme) => {
  const MuiTooltip: Components = {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontWeight: theme.typography.body1.fontWeight,
          backgroundColor: theme.palette.background.paper,
          color: theme.palette.text.secondary,
          borderRadius: 6,
        },
        arrow: {
          color: theme.palette.background.paper,
        },
      },
    },
  };
  return MuiTooltip;
};
