import { Box as MuiBox, styled } from '@mui/material';

export const buttonProps = {
  width: '100%',
  minHeight: '50px',
};

export const Container = styled(MuiBox)(({ theme }) => ({
  width: '100%',
  height: 'auto',
}));
