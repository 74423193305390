import React from 'react';
import ReactGA from 'react-ga4';
import { InMemoryCache } from '@apollo/client';
import { LocalStorageWrapper, persistCache } from 'apollo3-cache-persist';
import { SnackbarProvider } from 'notistack';
import { createRoot } from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';

import { CustomApolloProvider } from './customApolloProvider';
import { ThemeConfig } from './theme';
import { App } from './App';
import './index.css';

// Initialize Google Analytics (only in prod, possible to test in localhost)
ReactGA.initialize(
  `${process.env.REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID}` || ''
);

// Initialize cache persist with top-level await
const cache = new InMemoryCache();
await persistCache({
  cache,
  storage: new LocalStorageWrapper(window.localStorage),
}).catch((error) => {
  console.error('Persist cache error', error);
});

createRoot(
  document.getElementById('root') as Element | DocumentFragment
).render(
  <React.StrictMode>
    <HelmetProvider>
      <CustomApolloProvider cache={cache}>
        <ThemeConfig mode="dark">
          <SnackbarProvider maxSnack={5}>
            <App cache={cache} />
          </SnackbarProvider>
        </ThemeConfig>
      </CustomApolloProvider>
    </HelmetProvider>
  </React.StrictMode>
);
