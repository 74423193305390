import React from 'react';

interface ICustomErrorBoundary {
  children: React.ReactNode;
  postErrorMessage: (errorMessage: string) => void;
}

export class CustomErrorBoundary extends React.Component<ICustomErrorBoundary> {
  componentDidCatch(error: Error, info: React.ErrorInfo): void {
    this.props.postErrorMessage(
      `A client error occurred. Error: ${error} Info: ${JSON.stringify(info)}`
    );
    console.error(`A client error occurred.`, { error, info });
  }

  render() {
    return this.props.children;
  }
}
