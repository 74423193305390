import { TextToSpeechAudioPlayer } from '../../classes';

export interface ITextToSpeechState {
  activeAudioPlayer?: TextToSpeechAudioPlayer;
  sentenceBoundaryCharacters: string[];
  sentenceBoundaryRegex: RegExp;
}

export const initialTextToSpeechState: ITextToSpeechState = {
  activeAudioPlayer: undefined,
  sentenceBoundaryCharacters: [],
  sentenceBoundaryRegex: new RegExp(''),
};
