import React from 'react';

import { IFlashcardState, initialFlashcardState } from './state';
import {
  CreateFlashcardsInput,
  SaveFlashcardsInput,
  UpdateFlashcardInput,
} from '../../generated';

export interface IFlashcardContext extends IFlashcardState {
  createFlashcards: (input: CreateFlashcardsInput) => void;
  deleteFlashcard: (id: string) => Promise<void>;
  saveFlashcards: (input: SaveFlashcardsInput) => Promise<void>;
  updateFlashcard: (id: string, update: UpdateFlashcardInput) => Promise<void>;
}

const stub = (): never => {
  throw new Error('You forgot to wrap your component.');
};

const initialFlashcardContext: IFlashcardContext = {
  ...initialFlashcardState,
  createFlashcards: stub,
  deleteFlashcard: stub,
  saveFlashcards: stub,
  updateFlashcard: stub,
};

export const FlashcardContext = React.createContext(initialFlashcardContext);
