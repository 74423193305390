import { useTheme, Box as MuiBox } from '@mui/material';

import { commonStrings } from '../../../../../assets/strings/sv';
import { useSubjectContext } from '../../../../../hooks';
import { ScrollBoxAtom, ChipAtom } from '../../../../library/atoms';

export const FilterSectionComponent = () => {
  const theme = useTheme();

  const { subjects, selectedSubject, handleChangeSelectedSubject } =
    useSubjectContext();

  const { exploreSection, defaultSubject } = commonStrings;

  return (
    <ScrollBoxAtom
      gradientColor={theme.palette.background[800]}
      sx={{ marginBottom: '20px' }}
    >
      <MuiBox display="flex" gap={1}>
        <ChipAtom
          label={exploreSection.allSubjectsButton}
          onClick={() => handleChangeSelectedSubject(undefined)}
          isActive={!selectedSubject}
        />
        {subjects.map((subject, index) => (
          <ChipAtom
            key={index}
            label={subject.subject}
            onClick={() => handleChangeSelectedSubject(subject)}
            isActive={selectedSubject?.id === subject.id}
          />
        ))}
        <ChipAtom
          label={defaultSubject.subject}
          onClick={() => handleChangeSelectedSubject(defaultSubject)}
          isActive={selectedSubject?.id === defaultSubject.id}
        />
      </MuiBox>
    </ScrollBoxAtom>
  );
};
