import {
  Typography as MuiTypography,
  Grid as MuiGrid,
  Box as MuiBox,
  useTheme,
  useMediaQuery,
} from '@mui/material';

import { PaperConstraintAtom, ButtonAtom } from '../../../../atoms';
import { GetStudyMaterialQuery } from '../../../../../../generated';
import { EvaluationConfig } from '../../ExamOrganism';
import { commonStrings } from '../../../../../../assets/strings/sv';
import { ExamContainer, BetaContainer, MarkContainer } from './styles';

interface IExamIntroComponent {
  exam: GetStudyMaterialQuery['getStudyMaterial']['exam'];
  evaluationConfig: EvaluationConfig;
  handleOpenExamModal: () => void;
  handleOpenEvaluationModal: () => void;
}

export const ExamIntroComponent = ({
  exam,
  evaluationConfig,
  handleOpenExamModal,
  handleOpenEvaluationModal,
}: IExamIntroComponent) => {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.up('sm'));
  const { examSection } = commonStrings.studyMaterialSection;

  return (
    <PaperConstraintAtom>
      <ExamContainer>
        <BetaContainer>
          <MuiTypography variant="body1" color={theme.palette.text.secondary}>
            {commonStrings.beta}
          </MuiTypography>
        </BetaContainer>

        <MuiBox textAlign="center" maxWidth={400}>
          <MuiTypography variant="h2" marginBottom={1}>
            {examSection.introTitle}
          </MuiTypography>
          <MuiTypography>{examSection.introDescription}</MuiTypography>
        </MuiBox>

        <MuiGrid
          container
          direction="row"
          spacing={isTablet ? 3 : 2}
          alignItems="center"
          justifyContent="center"
        >
          {Object.entries(evaluationConfig).map(([mark, config], index) => (
            <MuiGrid
              item
              display="flex"
              flexDirection={isTablet ? 'row' : 'column'}
              alignItems="center"
              rowGap={1}
              columnGap={1}
              key={index}
            >
              <MarkContainer>
                <MuiTypography variant="h4">{mark}</MuiTypography>
                <MuiTypography
                  variant="body1"
                  color={theme.palette.text.secondary}
                >
                  {config.percentage}% {commonStrings.correct}
                </MuiTypography>
              </MarkContainer>
            </MuiGrid>
          ))}
        </MuiGrid>
        <MuiBox display="flex" flexDirection="column" gap={2}>
          <ButtonAtom
            variant="primary"
            text={commonStrings.start}
            onClick={handleOpenExamModal}
          />
          {exam?.evaluations?.[0] && (
            <ButtonAtom
              text={examSection.openEvaluation}
              onClick={handleOpenEvaluationModal}
              variant="border"
            />
          )}
        </MuiBox>
      </ExamContainer>
    </PaperConstraintAtom>
  );
};
