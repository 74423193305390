import React from 'react';
import MuiMarkdown from 'mui-markdown';
import { textVide } from 'text-vide';

export interface ITextVideAtom {
  regularText: string;
}

export const TextVideAtom = ({ regularText }: ITextVideAtom) => {
  const textVideString = textVide(regularText)
    // Convert to markdown
    .replaceAll('<b>', `<strong>`)
    .replaceAll('</b>', '</strong>')
    .replaceAll('\n', '<br />');

  return <MuiMarkdown>{textVideString}</MuiMarkdown>;
};
