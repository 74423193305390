import { useState } from 'react';
import { useMediaQuery, useTheme } from '@mui/material';
import { LibraryAdd } from '@mui/icons-material';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import {
  ButtonAtom,
  IconButtonAtom,
} from '../../../../../../../../library/atoms';
import { commonStrings } from '../../../../../../../../../assets/strings/sv';
import { useStudyMaterialContext } from '../../../../../../../../../hooks';
import { GetStudyMaterialQuery } from '../../../../../../../../../generated';
import { AuthPromptComponent } from './components';

interface ISaveComponent {
  studyMaterial: GetStudyMaterialQuery['getStudyMaterial'];
}

export const SaveComponent = ({ studyMaterial }: ISaveComponent) => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { isAuthenticated } = useAuth0();
  const { studyMaterialId, menuItem } = useParams();

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));

  const { saveStudyMaterial } = useStudyMaterialContext();

  const { statusMessages } = commonStrings;

  const [isAuthPromptOpen, setIsAuthPromptOpen] = useState(false);

  const handleSaveStudyMaterial = async () => {
    enqueueSnackbar(statusMessages.studyMaterial.save.pending, {
      variant: 'info',
    });

    const { id } = studyMaterial;
    const savedStudyMaterial = await saveStudyMaterial(id);

    if (savedStudyMaterial) {
      navigate(`/material/${savedStudyMaterial.id}/${menuItem}`);
      enqueueSnackbar(statusMessages.studyMaterial.save.success, {
        variant: 'success',
      });
    } else {
      enqueueSnackbar(statusMessages.studyMaterial.save.error, {
        variant: 'error',
      });
    }
  };

  const handleSaveClick = () => {
    if (isAuthenticated) {
      handleSaveStudyMaterial();
    } else {
      setIsAuthPromptOpen(true);
    }
  };

  return (
    <>
      {isAuthPromptOpen && (
        <AuthPromptComponent
          studyMaterialId={studyMaterialId}
          isOpen={isAuthPromptOpen}
          handleClose={() => {
            setIsAuthPromptOpen(false);
          }}
        />
      )}
      {isDesktop ? (
        <ButtonAtom
          variant="primary"
          text={commonStrings.save}
          onClick={handleSaveClick}
          endIcon={<LibraryAdd />}
        />
      ) : (
        <IconButtonAtom
          variant="primary"
          icon={<LibraryAdd fontSize="small" />}
          onClick={handleSaveClick}
        />
      )}
    </>
  );
};
