import { FileUploadOutlined } from '@mui/icons-material';
import { useMediaQuery, useTheme } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';

import {
  commonStrings,
  generateStudyMaterialPageStrings as pageStrings,
} from '../../../../../../../assets/strings/sv';
import { ButtonAtom, IconButtonAtom } from '../../../../../../library/atoms';
import { compressFile, convertFileToBase64 } from './helpers';

interface IImageUploadButton {
  handleImageToText: (base64Image: string) => Promise<void>;
  isLoading: boolean;
}

export const ImageUploadButton = ({
  handleImageToText,
  isLoading,
}: IImageUploadButton) => {
  const { enqueueSnackbar } = useSnackbar();
  const { analyze } = commonStrings.statusMessages.image;
  const { tooltips } = commonStrings;

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));

  const [file, setFile] = useState<File | null>(null);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files?.item(0)) {
      setFile(e.target.files.item(0));
    }
  };

  useEffect(() => {
    const imageToText = async (file: File) => {
      try {
        const maxFileSizeBytes = 50000;
        const compressedFile =
          file.size > maxFileSizeBytes
            ? await compressFile(file, maxFileSizeBytes)
            : file;

        if (!compressedFile) {
          return enqueueSnackbar(analyze.error, { variant: 'error' });
        }

        const base64File = await convertFileToBase64(compressedFile);
        handleImageToText(base64File);
      } catch (error) {
        console.error('Could not convert text to image');
        enqueueSnackbar(analyze.error, { variant: 'error' });
      }
    };

    file && imageToText(file);
    setFile(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]);

  const handleFileSelectClick = () => {
    const input = document.getElementById('file-analysis-input');
    input?.click();
  };
  return (
    <>
      {/* Hidden file input */}
      <input
        id="file-analysis-input"
        type="file"
        max={1}
        accept=".jpg,.png,.jpeg"
        hidden
        name="filename"
        onChange={handleFileChange}
      />
      {isDesktop ? (
        <ButtonAtom
          variant="primary"
          startIcon={<FileUploadOutlined />}
          onClick={handleFileSelectClick}
          text={pageStrings.imageToText}
          loading={isLoading}
        />
      ) : (
        <IconButtonAtom
          onClick={handleFileSelectClick}
          icon={<FileUploadOutlined />}
          tooltipTitle={tooltips.imageToText}
        />
      )}
    </>
  );
};
