import { Box as MuiBox, styled } from '@mui/material';

export const Container = styled(MuiBox)(({ theme }) => ({
  display: 'flex',
  columnGap: 16,
  padding: 25,
  backgroundColor: theme.palette.background[800],
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
  '& > p': {
    color: theme.palette.text.secondary,
  },
}));
