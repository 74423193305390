import { ArrowBack } from '@mui/icons-material';
import {
  Box as MuiBox,
  Typography as MuiTypography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { IconButtonAtom } from '../../../../library/atoms';
import { StudyMaterialDraft } from '../../types';
import { DraftStudyMaterialComponent } from './components';
import { AddMaterialButton } from './components/draftStudyMaterial/components';

interface IGenerateStudyMaterialHeader {
  draftStudyMaterials: StudyMaterialDraft[];
  setDraftStudyMaterials: React.Dispatch<
    React.SetStateAction<StudyMaterialDraft[]>
  >;
  selectedDraft: StudyMaterialDraft;
  setSelectedDraft: React.Dispatch<React.SetStateAction<StudyMaterialDraft>>;
  fieldErrors: Record<string, string>;
  handleAddDraft: () => void;
}

export const GenerateStudyMaterialHeaderComponent = ({
  draftStudyMaterials,
  setDraftStudyMaterials,
  selectedDraft,
  setSelectedDraft,
  fieldErrors,
  handleAddDraft,
}: IGenerateStudyMaterialHeader) => {
  const navigate = useNavigate();

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));

  const handleOnBack = () => {
    navigate('..');
  };

  return (
    <MuiBox
      display="flex"
      alignItems="center"
      gap={4}
      width="100%"
      marginBottom={isDesktop ? '50px' : '20px'}
    >
      <IconButtonAtom
        onClick={() => handleOnBack()}
        icon={<ArrowBack />}
        shape="round"
      />
      <MuiTypography fontWeight={700}>Skapa lektion</MuiTypography>
      <MuiBox display="flex" alignItems="center" flex={1} minWidth={0}>
        <MuiBox flex={1} minWidth={0}>
          <DraftStudyMaterialComponent
            draftStudyMaterials={draftStudyMaterials}
            setDraftStudyMaterials={setDraftStudyMaterials}
            selectedDraft={selectedDraft}
            setSelectedDraft={setSelectedDraft}
            fieldErrors={fieldErrors}
          />
        </MuiBox>
        <MuiBox marginLeft={1}>
          <AddMaterialButton
            handleAddStudyMaterial={handleAddDraft}
            draftStudyMaterials={draftStudyMaterials}
          />
        </MuiBox>
      </MuiBox>
    </MuiBox>
  );
};
