import React, { useState } from 'react';
import {
  CheckOutlined,
  ClearOutlined,
  EditOutlined,
} from '@mui/icons-material';
import { Box as MuiBox } from '@mui/material';

import { commonStrings } from '../../../../../../../../assets/strings/sv';
import { paperWidth } from '../../../../../../../utils';
import {
  HighlightedTextAtom,
  IconButtonAtom,
  TextInputAtom,
} from '../../../../../../atoms';
import {
  AudioPlayerMolecule,
  ModalMolecule,
} from '../../../../../../molecules';
import { ContentType } from '../../../../../../../../utils';
import { useStudyMaterialContext } from '../../../../../../../../hooks';
import { GetStudyMaterialQuery } from '../../../../../../../../generated';
import { TextContainer } from './styles';

interface IOriginalTextModalComponent {
  studyMaterial: GetStudyMaterialQuery['getStudyMaterial'];
  isOpen: boolean;
  placeholder?: string;
  isEditModeAvailable: boolean;
  handleClose: () => void;
}

export const OriginalTextModalComponent = ({
  studyMaterial,
  isOpen,
  isEditModeAvailable,
  handleClose,
}: IOriginalTextModalComponent) => {
  const { updateStudyMaterial } = useStudyMaterialContext();

  const { tooltips, originalTextSection } = commonStrings;

  const [originalText, setOriginalText] = useState(studyMaterial.text);
  const [error, setError] = useState<string | undefined>();
  const [textHighlightIndexes, setTextHighlightIndexes] = useState({
    start: 0,
    end: 0,
  });
  const [isEditModeActive, setIsEditModeActive] = useState<boolean>(false);

  const validateInput = (text: string) => {
    if (!text) {
      setError(originalTextSection.errorMessages.text);
      return false;
    }
    return true;
  };

  const handleConfirmEdit = () => {
    if (!validateInput(originalText)) return;

    updateStudyMaterial(studyMaterial.id, { text: originalText });

    setError(undefined);
    setIsEditModeActive(false);
  };

  const handleActivateEdit = () => setIsEditModeActive(true);
  const handleCancelEdit = () => {
    setOriginalText(studyMaterial.text);
    setError(undefined);
    setIsEditModeActive(false);
  };

  const handleCloseOriginalTextModal = () => {
    handleClose();
    handleCancelEdit();
  };

  return (
    <ModalMolecule
      title={originalTextSection.title}
      description={originalTextSection.description}
      onClose={handleCloseOriginalTextModal}
      isOpen={isOpen}
      sx={{ minWidth: paperWidth }}
    >
      <MuiBox
        display="flex"
        alignItems="center"
        justifyContent="flex-end"
        marginBottom={2}
        gap={1}
      >
        {isEditModeAvailable && (
          <>
            {isEditModeActive ? (
              <>
                <IconButtonAtom
                  variant="primary"
                  onClick={handleConfirmEdit}
                  icon={<CheckOutlined />}
                  tooltipTitle={tooltips.save}
                />
                <IconButtonAtom
                  onClick={handleCancelEdit}
                  icon={<ClearOutlined />}
                  tooltipTitle={tooltips.cancel}
                />
              </>
            ) : (
              <>
                <IconButtonAtom
                  onClick={handleActivateEdit}
                  icon={<EditOutlined />}
                  tooltipTitle={tooltips.edit}
                />
                <AudioPlayerMolecule
                  statsContentType={ContentType.ORIGINAL_TEXT}
                  text={originalText}
                  setCaptionedCharacterIndexes={(start, end) =>
                    setTextHighlightIndexes({ start, end })
                  }
                  language={studyMaterial.language.name}
                  displayResetButton
                />
              </>
            )}
          </>
        )}
      </MuiBox>
      {isEditModeActive ? (
        <TextInputAtom
          variant="outlined"
          multiline
          fullWidth
          onChange={(value) => setOriginalText(value)}
          value={originalText}
          errorMessage={error}
        />
      ) : (
        <TextContainer>
          <HighlightedTextAtom
            text={originalText}
            startHighlightIndex={textHighlightIndexes.start}
            endHighlightIndex={textHighlightIndexes.end}
          />
        </TextContainer>
      )}
    </ModalMolecule>
  );
};
