import { useNavigate } from 'react-router-dom';
import {
  Bookmark,
  BookmarkBorderOutlined,
  VisibilityOutlined,
} from '@mui/icons-material';
import {
  Typography as MuiTypography,
  Box as MuiBox,
  useTheme,
} from '@mui/material';

import avatarPlaceholder from '../../../../../assets/images/avatar-placeholder.svg';
import { commonStrings } from '../../../../../assets/strings/sv';
import { StudyMaterial } from '../../../../../generated';
import {
  AvatarAtom,
  IconAtom,
  IconButtonAtom,
  ModuleContainerAtom,
} from '../../../atoms';
import { useStudyMaterialContext } from '../../../../../hooks';

interface IBookmarkedStudyMaterialModuleMolecule {
  avatarSrc?: string | null;
  studyMaterial: StudyMaterial;
}

export const BookmarkedStudyMaterialModuleMolecule = ({
  avatarSrc,
  studyMaterial,
}: IBookmarkedStudyMaterialModuleMolecule) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { bookmarkStudyMaterial } = useStudyMaterialContext();
  const { defaultSubject } = commonStrings;

  const onModuleClick = (studyMaterialId?: string) => {
    if (!studyMaterialId) return;
    const url = `/explore/published/material/${studyMaterialId}/summary`;
    navigate(url);
  };

  const handleBookmarkStudyMaterial = (
    e: React.MouseEvent<HTMLButtonElement>
  ) => {
    e.stopPropagation();
    if (!studyMaterial) return;
    bookmarkStudyMaterial(studyMaterial.id);
  };

  return (
    <ModuleContainerAtom onClick={() => onModuleClick(studyMaterial?.id)}>
      <MuiBox display="flex" alignItems="center" justifyContent="space-between">
        <AvatarAtom alt="module_avatar" src={avatarSrc || avatarPlaceholder} />
        {studyMaterial.bookmark ? (
          <IconButtonAtom
            shape="round"
            icon={<Bookmark fontSize="small" />}
            onClick={handleBookmarkStudyMaterial}
          />
        ) : (
          <IconButtonAtom
            shape="round"
            icon={<BookmarkBorderOutlined fontSize="small" />}
            onClick={handleBookmarkStudyMaterial}
          />
        )}
      </MuiBox>

      <MuiBox
        flex={1}
        gap={1.5}
        display="flex"
        flexDirection="column"
        justifyContent="flex-end"
      >
        <MuiTypography variant="h6" sx={{ wordBreak: 'break-word' }}>
          {studyMaterial.title}
        </MuiTypography>

        <MuiBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <MuiTypography variant="caption" color={theme.palette.text.secondary}>
            {studyMaterial.subject?.subject || defaultSubject.subject}
          </MuiTypography>

          {studyMaterial.views && (
            <MuiBox display="flex" alignItems="center">
              <IconAtom
                color={theme.palette.text.secondary}
                icon={<VisibilityOutlined fontSize="inherit" />}
              />
              <MuiTypography
                variant="caption"
                color={theme.palette.text.secondary}
              >
                {studyMaterial.views}
              </MuiTypography>
            </MuiBox>
          )}
        </MuiBox>
      </MuiBox>
    </ModuleContainerAtom>
  );
};
