import { useState } from 'react';

import {
  HighlightedTextAtom,
  IconButtonAtom,
  TextInputAtom,
} from '../../../../../../atoms';
import {
  AnalysisQuestion as AnalysisQuestionType,
  Language,
} from '../../../../../../../../generated';
import { ContentType } from '../../../../../../../../utils';
import { AudioPlayerMolecule } from '../../../../../../molecules';
import { AnalysisQuestion } from './styles';
import { commonStrings } from '../../../../../../../../assets/strings/sv';
import { UnsavedAnalysisQuestion } from '../../../../types';
import { AnalysisQuestionFieldError } from '../../../../../../../utils';
import { DeleteOutline } from '@mui/icons-material';

interface IAnalysisQuestionComponent {
  analysisQuestion: AnalysisQuestionType | UnsavedAnalysisQuestion;
  language?: Language;
  isEditModeActive: boolean;
  fieldError?: AnalysisQuestionFieldError;
  handleChangeAnalysisQuestion: (
    question: AnalysisQuestionType | UnsavedAnalysisQuestion
  ) => void;
  handleDeleteAnalysisQuestion: (
    question: AnalysisQuestionType | UnsavedAnalysisQuestion
  ) => void;
}

export const AnalysisQuestionComponent = ({
  analysisQuestion,
  language,
  isEditModeActive,
  fieldError,
  handleChangeAnalysisQuestion,
  handleDeleteAnalysisQuestion,
}: IAnalysisQuestionComponent) => {
  const [highlightIndexes, setHighlightIndexes] = useState({
    end: 0,
    start: 0,
  });

  if (isEditModeActive) {
    return (
      <AnalysisQuestion>
        <TextInputAtom
          variant={isEditModeActive ? 'filled' : 'outlined'}
          value={analysisQuestion.question}
          onChange={(value) =>
            handleChangeAnalysisQuestion({
              ...analysisQuestion,
              question: value,
            })
          }
          multiline
          fullWidth
          errorMessage={fieldError?.question}
        />
        <IconButtonAtom
          icon={<DeleteOutline />}
          onClick={() => handleDeleteAnalysisQuestion(analysisQuestion)}
        />
      </AnalysisQuestion>
    );
  }

  return (
    <AnalysisQuestion>
      <HighlightedTextAtom
        text={analysisQuestion.question}
        startHighlightIndex={highlightIndexes.start}
        endHighlightIndex={highlightIndexes.end}
      />
      <AudioPlayerMolecule
        statsContentType={ContentType.ANALYSIS_QUESTION}
        key={analysisQuestion.question}
        text={analysisQuestion.question}
        language={language?.code || commonStrings.defaultLanguage}
        setCaptionedCharacterIndexes={(startIndex, endIndex) =>
          setHighlightIndexes({ start: startIndex, end: endIndex })
        }
      />
    </AnalysisQuestion>
  );
};
