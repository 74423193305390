import {
  Typography as MuiTypography,
  Box as MuiBox,
  useTheme,
} from '@mui/material';

import strings from '../../../../../../../../assets/strings/sv/common.json';
import { ToggleAtom } from '../../../../../../atoms';
import {
  ContentStatus,
  QuizType,
  Status,
} from '../../../../../../../../generated';
import { HorizontalContainer } from './styles';

interface IQuizSelectorComponent {
  status?: ContentStatus['quiz'];
  selectedQuizType?: QuizType;
  handleSelectQuizType: (type: QuizType) => void;
}

export const QuizSelectorComponent: React.FC<IQuizSelectorComponent> = ({
  status,
  selectedQuizType,
  handleSelectQuizType,
}) => {
  const theme = useTheme();
  const { studyMaterialSection } = strings;

  return (
    <MuiBox display="flex" flexDirection="column" gap={2}>
      <MuiTypography variant="body1" color={theme.palette.text.secondary}>
        Typ av frågor
      </MuiTypography>
      <HorizontalContainer>
        <MuiTypography variant="body1">
          {studyMaterialSection.quizSection.selectQuiz.statements}
        </MuiTypography>
        <ToggleAtom
          isDisabled={status?.statements === Status.Pending}
          size="small"
          isToggled={selectedQuizType === QuizType.Statements}
          handleToggle={() => handleSelectQuizType(QuizType.Statements)}
        />
      </HorizontalContainer>
      <HorizontalContainer>
        <MuiTypography variant="body1">
          {studyMaterialSection.quizSection.selectQuiz.multipleChoices}
        </MuiTypography>
        <ToggleAtom
          isDisabled={status?.multipleChoices === Status.Pending}
          size="small"
          isToggled={selectedQuizType === QuizType.MultipleChoices}
          handleToggle={() => handleSelectQuizType(QuizType.MultipleChoices)}
        />
      </HorizontalContainer>
    </MuiBox>
  );
};
