import { Box as MuiBox, styled } from '@mui/material';

export const Header = styled(MuiBox)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '24px 50px',
  [theme.breakpoints.down('md')]: {
    padding: '24px 20px',
  },
}));
