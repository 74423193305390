import { Grid, styled } from '@mui/material';

export const IconTag = styled(Grid, {
  shouldForwardProp: (prop) => prop !== 'hasText',
})<{ hasText: boolean }>(({ theme, hasText }) => ({
  color: theme.palette.grey[600],
  '& svg': {
    marginRight: hasText ? 8 : 0,
    height: 18,
    width: 18,
  },
}));
