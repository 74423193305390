import { useState } from 'react';
import {
  ArticleOutlined,
  CheckOutlined,
  ClearOutlined,
  EditOutlined,
  FontDownloadOutlined,
  MoreVert,
} from '@mui/icons-material';
import { Box as MuiBox, useTheme } from '@mui/material';

import { commonStrings } from '../../../../../../assets/strings/sv';
import { GetStudyMaterialQuery } from '../../../../../../generated';
import { TextMode } from '../../../../../utils';
import { IconButtonAtom } from '../../../../atoms';
import {
  AudioPlayerMolecule,
  PopperMenuItem,
  PopperMenuMolecule,
} from '../../../../molecules';
import { OriginalTextModalComponent } from './components';
import { ContentType } from '../../../../../../utils';

interface ISummaryHeaderComponent {
  studyMaterial: GetStudyMaterialQuery['getStudyMaterial'];
  isEditModeAvailable: boolean;
  isEditModeActive?: boolean;
  textMode?: TextMode;
  handleActivateEdit: () => void;
  handleCancelEdit: () => void;
  handleConfirmEditSummary: () => void;
  handleTextModeSwitch: () => void;
  setHighlightIndexes: (startIndex: number, endIndex: number) => void;
}

export const SummaryHeaderComponent = ({
  studyMaterial,
  isEditModeAvailable,
  isEditModeActive,
  textMode,
  handleActivateEdit,
  handleCancelEdit,
  handleConfirmEditSummary,
  handleTextModeSwitch,
  setHighlightIndexes,
}: ISummaryHeaderComponent) => {
  const theme = useTheme();

  const { tooltips, studyMaterialSection, edit } = commonStrings;
  const { summarySection } = studyMaterialSection;
  const { menuItems } = summarySection;

  const [isOriginalTextModalOpen, setIsOriginalTextModalOpen] =
    useState<boolean>(false);

  const handleCloseOriginalTextModal = () => {
    setIsOriginalTextModalOpen(false);
  };

  const handleOpenOriginalTextModal = () => {
    setIsOriginalTextModalOpen(true);
  };

  const baseMenuItems: PopperMenuItem[] = [
    {
      text: menuItems.originalText,
      onClick: handleOpenOriginalTextModal,
      icon: <ArticleOutlined fontSize="small" />,
    },
    {
      text: `${menuItems.textMode.prefix} ${
        textMode === TextMode.TEXT_VIDE
          ? menuItems.textMode.regular
          : menuItems.textMode.vide
      }`,
      onClick: handleTextModeSwitch,
      icon: <FontDownloadOutlined fontSize="small" />,
    },
  ];

  const editMenuItems = [
    {
      text: edit,
      onClick: handleActivateEdit,
      icon: <EditOutlined fontSize="small" />,
    },
  ];

  return (
    <MuiBox display="flex" justifyContent="flex-end" gap={1} marginBottom={2}>
      {!isEditModeActive ? (
        <>
          <AudioPlayerMolecule
            statsContentType={ContentType.SUMMARY}
            text={studyMaterial.summary?.summary || ''}
            setCaptionedCharacterIndexes={setHighlightIndexes}
            displayResetButton
            language={studyMaterial.language.name}
          />
          <PopperMenuMolecule
            icon={<MoreVert />}
            tooltipTitle={tooltips.options}
            menuItems={[
              ...baseMenuItems,
              ...(isEditModeAvailable ? editMenuItems : []),
            ]}
            sx={{ backgroundColor: theme.palette.background.paper }}
          />
        </>
      ) : (
        <>
          <IconButtonAtom
            variant="primary"
            onClick={handleConfirmEditSummary}
            icon={<CheckOutlined />}
            tooltipTitle={tooltips.save}
          />
          <IconButtonAtom
            onClick={handleCancelEdit}
            icon={<ClearOutlined />}
            tooltipTitle={tooltips.cancel}
          />
        </>
      )}
      <OriginalTextModalComponent
        studyMaterial={studyMaterial}
        isOpen={isOriginalTextModalOpen}
        handleClose={handleCloseOriginalTextModal}
        isEditModeAvailable={isEditModeAvailable}
      />
    </MuiBox>
  );
};
