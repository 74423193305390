import {
  Typography as MuiTypography,
  useTheme,
  Box as MuiBox,
  Typography,
} from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import { DeleteOutline } from '@mui/icons-material';

import { ExamQuestion } from '../../../../../../../../generated';
import {
  IconButtonAtom,
  SelectorAtom,
  TextInputAtom,
} from '../../../../../../atoms';
import { NumberContainer, OuterContainer, QuestionSection } from './styles';
import { commonStrings } from '../../../../../../../../assets/strings/sv';
import { UnsavedExamQuestion } from '../../types';
import { ExamFieldValidation } from '../../ExamComponent';

interface IEditExamQuestionComponent {
  examQuestion: ExamQuestion | UnsavedExamQuestion;
  number: number;
  fieldValidation?: ExamFieldValidation;
  handleDeleteExamQuestion: (
    examQuestion: ExamQuestion | UnsavedExamQuestion
  ) => void;
  handleChangeExamQuestion: (
    examQuestion: ExamQuestion | UnsavedExamQuestion
  ) => void;
}

export const EditExamQuestionComponent = ({
  examQuestion,
  number,
  fieldValidation,
  handleDeleteExamQuestion,
  handleChangeExamQuestion,
}: IEditExamQuestionComponent) => {
  const { isAuthenticated } = useAuth0();
  const theme = useTheme();

  const { question, answer, maxScore } = examQuestion;
  const { tooltips, studyMaterialSection } = commonStrings;

  const maxScores = [
    { maxScore: 2 },
    { maxScore: 4 },
    { maxScore: 6 },
    { maxScore: 8 },
    { maxScore: 10 },
    { maxScore: 12 },
  ];

  const _maxScore =
    maxScores.find((score) => score.maxScore === maxScore) || maxScores[0]; // maxScore object value passed to select needs to be an instance of the list items to avoid errors

  return (
    <OuterContainer>
      <NumberContainer>
        <MuiTypography variant="body1">{number}</MuiTypography>
      </NumberContainer>
      <QuestionSection>
        <TextInputAtom
          placeholder={studyMaterialSection.examSection.exampleQuestion}
          variant="filled"
          multiline={true}
          value={question}
          errorMessage={fieldValidation?.question}
          onChange={(value) =>
            handleChangeExamQuestion({ ...examQuestion, question: value })
          }
          sx={{
            display: 'flex',
            flex: 1,
            '& .MuiInputBase-root': {
              display: 'flex',
              alignItems: 'start',
              flex: 1,
            },
          }}
        />
        <MuiBox display="flex" alignItems="start" columnGap={2} flex={1}>
          <TextInputAtom
            placeholder={studyMaterialSection.examSection.exampleAnswer}
            variant="filled"
            multiline={true}
            errorMessage={fieldValidation?.answer}
            value={answer}
            disabled={!isAuthenticated}
            onChange={(value) =>
              handleChangeExamQuestion({ ...examQuestion, answer: value })
            }
            sx={{
              width: '100%',
              '& .MuiInputBase-root': {
                display: 'flex',
                alignItems: 'start',
                flex: 1,
              },
            }}
          />
          <IconButtonAtom
            tooltipTitle={tooltips.examAnswer}
            shape="round"
            variant="dark"
            icon={<Typography fontWeight={800}>i</Typography>}
            onClick={() => {}}
            sx={{
              marginTop: '14px',
              height: 30,
              width: 30,
            }}
          />
        </MuiBox>
        <MuiBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <MuiBox display="flex" alignItems="center" columnGap={2}>
            <MuiTypography color={theme.palette.text.disabled}>
              {commonStrings.max} {commonStrings.points}:
            </MuiTypography>
            <SelectorAtom
              value={_maxScore}
              listItems={maxScores}
              renderProperty="maxScore"
              onSelectedItem={(value) =>
                handleChangeExamQuestion({
                  ...examQuestion,
                  ...value,
                })
              }
            />
            <IconButtonAtom
              tooltipTitle={tooltips.examMaxPoints}
              variant="dark"
              shape="round"
              icon={<Typography fontWeight={800}>i</Typography>}
              onClick={() => {}}
              sx={{
                height: 30,
                width: 30,
              }}
            />
          </MuiBox>
          <IconButtonAtom
            icon={<DeleteOutline />}
            onClick={() => handleDeleteExamQuestion(examQuestion)}
          />
        </MuiBox>
      </QuestionSection>
    </OuterContainer>
  );
};
