import { Box as MuiBox, styled } from '@mui/material';

export const Home = styled(MuiBox)(({ theme }) => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: theme.palette.background[900],
}));

export const Content = styled(MuiBox)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  rowGap: 160,
  paddingTop: 75,
  paddingBottom: 75,
  [theme.breakpoints.down('md')]: {
    rowGap: 80,
  },
}));

export const InnerContent = styled(MuiBox)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  paddingRight: 50,
  paddingLeft: 50,
  rowGap: 160,
  [theme.breakpoints.down('md')]: {
    paddingRight: 20,
    paddingLeft: 20,
    rowGap: 80,
  },
}));
