import { Box as MuiBox, styled } from '@mui/material';

export const GradientBorderChip = styled(MuiBox)(({ theme }) => ({
  position: 'relative',
  borderRadius: '28px',
  border: '1px solid transparent',
  backgroundClip: 'padding-box',
  '&::before': {
    position: 'absolute',
    top: -1,
    left: -1,
    right: -1,
    bottom: -1,
    background: theme.palette.gradient,
    content: '""',
    zIndex: 0,
    borderRadius: 'inherit',
  },
}));

export const Chip = styled(MuiBox)<{ isActive: boolean }>(
  ({ theme, isActive }) => ({
    cursor: 'pointer',
    position: 'relative',
    padding: '4px 16px',
    borderRadius: '28px',
    border: isActive ? 'none' : `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.background[800],
    color: isActive ? theme.palette.text.primary : theme.palette.text.secondary,
    zIndex: 1,
    '&:hover': {
      backgroundColor: theme.palette.background[600],
    },
  })
);

// export const Chip = styled(MuiChip, {
//   shouldForwardProp: (prop) => prop !== 'isActive',
// })<{ isActive?: boolean }>(({ theme, isActive }) => ({
//   padding: '6px 16px',
//   background: isActive ? theme.palette.gradient : theme.palette.grey[900],
//   color: isActive ? theme.palette.grey[900] : theme.palette.text.primary,
//   borderRadius: '28px',
//   '&:hover': {
//     background: isActive ? theme.palette.gradient : theme.palette.grey[900],
//     color: isActive ? theme.palette.grey[900] : theme.palette.text.primary,
//   },
//   '&:focus': {
//     background: theme.palette.gradient,
//     color: theme.palette.grey[900],
//   },
//   '& .MuiChip-label': {
//     ...theme.typography.body1,
//     padding: 0,
//   },
// }));
