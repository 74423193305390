import {
  Grid as MuiGrid,
  Typography as MuiTypography,
  useTheme,
} from '@mui/material';

import { dashboardPageStrings as pageStrings } from '../../../../../assets/strings/sv';
import { ModalMolecule } from '../../../../library/molecules';
import getMaterial from './images/getMaterial.png';
import pasteIn from './images/pasteIn.png';
import study from './images/study.png';
import { Container, ImageContainer, TextContainer } from './styles';

interface IStarterGuideModalComponent {
  showInfoModal: boolean;
  handleCloseStarterGuideModal: () => void;
}
export const StarterGuideModalComponent = ({
  showInfoModal,
  handleCloseStarterGuideModal,
}: IStarterGuideModalComponent) => {
  const theme = useTheme();
  const renderSectionImage = (index: number) => {
    switch (index) {
      case 0:
        return (
          <img
            style={{ width: '100%', maxWidth: 400 }}
            src={pasteIn}
            alt="pasteIn"
          />
        );
      case 1:
        return (
          <img
            style={{ width: '100%', maxWidth: 400 }}
            src={getMaterial}
            alt="getMaterial"
          />
        );
      case 2:
        return (
          <img
            style={{ width: '100%', maxWidth: 400 }}
            src={study}
            alt="study"
          />
        );
      case 3:
        return (
          <img
            style={{ width: '100%', maxWidth: 400 }}
            src={study}
            alt="study"
          />
        );
    }
  };
  return (
    <ModalMolecule
      isOpen={showInfoModal}
      onClose={handleCloseStarterGuideModal}
    >
      <Container container>
        <MuiGrid item xs={12}>
          <TextContainer>
            <MuiTypography variant="h5">
              {pageStrings.starterGuideModal.title}
            </MuiTypography>
          </TextContainer>
        </MuiGrid>
        <MuiGrid container gap={4}>
          {pageStrings.starterGuideModal.textSections.map(
            (textSection, index) => (
              <MuiGrid key={index} container spacing={4}>
                <MuiGrid
                  item
                  xs={12}
                  lg={6}
                  order={{ xs: 1, lg: index % 2 === 0 ? 2 : 1 }}
                >
                  <ImageContainer isIndexEven={index % 2 === 0}>
                    {renderSectionImage(index)}
                  </ImageContainer>
                </MuiGrid>

                <MuiGrid
                  item
                  xs={12}
                  lg={6}
                  alignSelf={'center'}
                  order={{ xs: 2, lg: index % 2 === 0 ? 1 : 2 }}
                >
                  <MuiTypography variant="h6" paddingBottom={2}>
                    {textSection.title}
                  </MuiTypography>
                  <MuiTypography
                    variant="body1"
                    color={theme.palette.grey[300]}
                  >
                    {textSection.text}
                  </MuiTypography>
                </MuiGrid>
              </MuiGrid>
            )
          )}
        </MuiGrid>
      </Container>
    </ModalMolecule>
  );
};
