import { Box as MuiBox } from '@mui/material';

import { TextOption } from './types';
import { ChipAtom } from '../../../../../../../../library/atoms';

interface ITextFieldOptionsComponent {
  selectedOption?: TextOption;
  options: TextOption[];
  changeOption: (option: TextOption) => void;
}

export const TextFieldOptionsComponent = ({
  selectedOption,
  options,
  changeOption,
}: ITextFieldOptionsComponent) => {
  return (
    <MuiBox display="flex" flexDirection="row" columnGap={1.5}>
      {options.map((option, index) => (
        <ChipAtom
          label={option.title}
          isActive={selectedOption === option}
          onClick={() => changeOption(option)}
        />
      ))}
    </MuiBox>
  );
};
