import { Box as MuiBox, Typography as MuiTypography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ArrowBack } from '@mui/icons-material';

import { ActionsComponent } from './components';
import { GetStudyMaterialQuery } from '../../../../../generated';
import { HeaderAction } from './types';
import { IconButtonAtom } from '../../../../library/atoms';

interface IStudyMaterialHeaderComponent {
  studyMaterial?: GetStudyMaterialQuery['getStudyMaterial'];
  actions?: HeaderAction[];
}

export const StudyMaterialHeaderComponent = ({
  studyMaterial,
  actions = [],
}: IStudyMaterialHeaderComponent) => {
  const navigate = useNavigate();

  const handleOnBack = () => {
    navigate('..');
  };

  return (
    <MuiBox
      width="100%"
      display="flex"
      alignItems="center"
      paddingBottom="50px"
    >
      <MuiBox flex={1}>
        <IconButtonAtom
          onClick={handleOnBack}
          icon={<ArrowBack />}
          shape="round"
        />
      </MuiBox>
      <MuiBox flex={1} textAlign="center">
        <MuiTypography variant="body1" fontWeight={700}>
          {studyMaterial?.title || 'Lektion utan titel'}
        </MuiTypography>
      </MuiBox>
      <MuiBox flex={1} display="flex" gap={2} justifyContent="flex-end">
        {studyMaterial && (
          <ActionsComponent actions={actions} studyMaterial={studyMaterial} />
        )}
      </MuiBox>
    </MuiBox>
  );
};
