import { useMediaQuery, useTheme } from '@mui/material';

export type ScreenSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

export const useScreenSize = (): ScreenSize => {
  const theme = useTheme();

  const sm = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const md = useMediaQuery(theme.breakpoints.between('md', 'lg'));
  const lg = useMediaQuery(theme.breakpoints.between('lg', 'xl'));
  const xl = useMediaQuery(theme.breakpoints.up('xl'));

  if (sm) return 'sm';
  if (md) return 'md';
  if (lg) return 'lg';
  if (xl) return 'xl';
  return 'xs';
};
