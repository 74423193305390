import React from 'react';
import { IStudyMaterialState, initialStudyMaterialState } from './state';
import {
  CreateStudyMaterialInput,
  CreateStudyMaterialMutation,
  SaveStudyMaterialMutation,
  UpdateStudyMaterialInput,
} from '../../generated';

export interface IStudyMaterialContext extends IStudyMaterialState {
  bookmarkStudyMaterial: (studyMaterialId: string) => void;
  deleteStudyMaterial: (id: string) => void;
  publishStudyMaterial: (id: string) => void;
  saveStudyMaterial: (
    id: string
  ) => Promise<SaveStudyMaterialMutation['saveStudyMaterial'] | undefined>;
  updateStudyMaterial: (id: string, update: UpdateStudyMaterialInput) => void;
  createStudyMaterial: (
    input: CreateStudyMaterialInput
  ) => Promise<CreateStudyMaterialMutation['createStudyMaterial'] | undefined>;
}

const stub = (): never => {
  throw new Error('You forgot to wrap your component.');
};

const initialStudyMaterialContext: IStudyMaterialContext = {
  ...initialStudyMaterialState,
  saveStudyMaterial: stub,
  bookmarkStudyMaterial: stub,
  deleteStudyMaterial: stub,
  publishStudyMaterial: stub,
  updateStudyMaterial: stub,
  createStudyMaterial: stub,
};

export const StudyMaterialContext = React.createContext(
  initialStudyMaterialContext
);
