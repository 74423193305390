import {
  Typography as MuiTypography,
  Box as MuiBox,
  Grid as MuiGrid,
} from '@mui/material';
import { DeleteOutline } from '@mui/icons-material';

import { Flashcard } from '../../../../../../../../generated';
import { UnsavedFlashcard } from '../../types';
import { IconButtonAtom, TextInputAtom } from '../../../../../../atoms';
import { commonStrings } from '../../../../../../../../assets/strings/sv';
import { ContentContainer } from './styles';

interface IFlashcardItemComponent {
  flashcard: Flashcard | UnsavedFlashcard;
  number: number;
  fieldValidation?: {
    answer: string;
    question: string;
  };
  handleDeleteFlashcard: (flashcard: Flashcard | UnsavedFlashcard) => void;
  handleChange: (flashcard: Flashcard | UnsavedFlashcard) => void;
}

export const FlashcardItemComponent = ({
  flashcard,
  number,
  fieldValidation,
  handleDeleteFlashcard,
  handleChange,
}: IFlashcardItemComponent) => {
  const { question, answer } = commonStrings;

  return (
    <MuiBox display="flex">
      <ContentContainer
        width="50px"
        height="45px"
        alignItems="center"
        marginRight={2}
      >
        <MuiTypography variant="body1">{number}</MuiTypography>
      </ContentContainer>
      <ContentContainer width="100%" alignItems="flex-end">
        <MuiBox display="flex" flexDirection="column" rowGap={2} width="100%">
          <MuiGrid
            container
            item
            direction="column"
            marginBottom={1}
            spacing={1}
          >
            <MuiGrid item display="flex" flexDirection="column">
              <MuiTypography variant="subtitle2" marginBottom={1}>
                {question}
              </MuiTypography>
              <TextInputAtom
                multiline
                variant="outlined"
                value={flashcard.question}
                errorMessage={fieldValidation?.question}
                onChange={(value) =>
                  handleChange({ ...flashcard, question: value })
                }
              />
            </MuiGrid>
          </MuiGrid>

          <MuiGrid container item direction="column" spacing={1}>
            <MuiGrid item display="flex" flexDirection="column">
              <MuiTypography variant="subtitle2" marginBottom={1}>
                {answer}
              </MuiTypography>
              <TextInputAtom
                variant="outlined"
                multiline
                value={flashcard.answer}
                errorMessage={fieldValidation?.answer}
                onChange={(value) =>
                  handleChange({ ...flashcard, answer: value })
                }
              />
            </MuiGrid>
          </MuiGrid>
        </MuiBox>
        <MuiBox marginLeft={2}>
          <IconButtonAtom
            icon={<DeleteOutline />}
            onClick={() => handleDeleteFlashcard(flashcard)}
          />
        </MuiBox>
      </ContentContainer>
    </MuiBox>
  );
};
