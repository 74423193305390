import { FormControlLabel, Radio, SxProps, useTheme } from '@mui/material';

interface IRadioButtonAtom {
  value: string;
  label: string;
  controlIcon?: JSX.Element;
  onClick: () => void;
  disabled?: boolean;
  sx?: SxProps;
}

export const RadioButtonAtom = ({
  value,
  label,
  controlIcon,
  onClick,
  disabled,
  sx,
}: IRadioButtonAtom) => {
  const theme = useTheme();

  const styling = {
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: disabled ? 'transparent' : theme.palette.background[600],
    },
    ...sx,
  };

  return (
    <>
      <FormControlLabel
        value={value}
        control={
          controlIcon || (
            <Radio sx={{ '&:hover': { backgroundColor: 'transparent' } }} />
          )
        }
        label={label}
        onClick={onClick}
        disabled={disabled}
        sx={styling}
      />
    </>
  );
};
