import { Box as MuiBox, styled } from '@mui/material';

export const Title = styled(MuiBox)(({ theme }) => ({
  display: 'flex',
  gap: '16px',
  alignItems: 'center',
}));

export const ButtonContainer = styled(MuiBox)(({ theme }) => ({
  display: 'flex',
  gap: '16px',
  flexDirection: 'column',
  width: '100%',
}));
