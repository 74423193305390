import { ReactElement } from 'react';
import { SxProps } from '@mui/material';

import { GradientContainer, ScrollBoxContainer } from './styles';

interface IScrollBoxAtom {
  children: ReactElement;
  sx?: SxProps;
  gradientColor?: string;
}

export const ScrollBoxAtom = ({
  children,
  sx,
  gradientColor,
}: IScrollBoxAtom) => {
  return (
    <>
      <GradientContainer gradientColor={gradientColor}>
        <ScrollBoxContainer sx={sx}>{children}</ScrollBoxContainer>
      </GradientContainer>
    </>
  );
};
