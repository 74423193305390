import { Box as MuiBox } from '@mui/material';

import streakIcon from './images/streak-icon.png';
import { StreakContainer, ContentContainer, IconContainer } from './styles';
import { useGetUserStreakQuery } from '../../../../../../../generated';

export const StreakComponent = () => {
  const { data } = useGetUserStreakQuery({
    fetchPolicy: 'cache-and-network',
  });

  return (
    <StreakContainer>
      <ContentContainer>
        <MuiBox>{data?.getUserStreak?.dailyStreak || 1}</MuiBox>
        <IconContainer>
          <img src={streakIcon} alt="Flame icon" />
        </IconContainer>
      </ContentContainer>
    </StreakContainer>
  );
};
