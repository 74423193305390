import { Box as MuiBox, useTheme } from '@mui/material';

import { ButtonAtom, IconButtonAtom, ScrollBoxAtom } from '../../../../atoms';
import {
  CheckOutlined,
  CloseOutlined,
  EditOutlined,
} from '@mui/icons-material';

interface IQuestionHeaderComponent {
  menuItems: any[];
  selectedMenuItem: any;
  isEditModeAvailable: boolean;
  isEditModeActive: boolean;
  handleChangeSelectedMenuItem: (item: any) => void;
  handleActivateEditMode: () => void;
  handleCancelEditMode: () => void;
  handleConfirm: () => void;
}

export const QuestionHeaderComponent = ({
  menuItems,
  selectedMenuItem,
  isEditModeAvailable,
  isEditModeActive,
  handleChangeSelectedMenuItem,
  handleActivateEditMode,
  handleCancelEditMode,
  handleConfirm,
}: IQuestionHeaderComponent) => {
  const theme = useTheme();

  return (
    <MuiBox display="flex" gap={2}>
      <ScrollBoxAtom gradientColor={theme.palette.background[900]}>
        <MuiBox display="flex" alignItems="center" gap={1}>
          {menuItems.map((item, index) => (
            <ButtonAtom
              key={index}
              variant={
                item.text === selectedMenuItem.text ? 'primary' : undefined
              }
              text={item.text}
              onClick={() => handleChangeSelectedMenuItem(item)}
              sx={{
                whiteSpace: 'nowrap',
              }}
            />
          ))}
        </MuiBox>
      </ScrollBoxAtom>
      {isEditModeAvailable && (
        <MuiBox display="flex" alignItems="center" gap={1}>
          {isEditModeActive ? (
            <>
              <IconButtonAtom
                variant="primary"
                icon={<CheckOutlined />}
                onClick={handleConfirm}
                sx={{
                  whiteSpace: 'nowrap',
                }}
              />
              <IconButtonAtom
                icon={<CloseOutlined />}
                onClick={handleCancelEditMode}
              />
            </>
          ) : (
            <IconButtonAtom
              icon={<EditOutlined />}
              onClick={handleActivateEditMode}
            />
          )}
        </MuiBox>
      )}
    </MuiBox>
  );
};
