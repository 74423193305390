import React from 'react';

import { ButtonGroup } from './styles';

interface IButtonGroupAtom {
  buttons: React.JSX.Element[];
}

export const ButtonGroupAtom = ({ buttons }: IButtonGroupAtom) => {
  return (
    <ButtonGroup>
      {buttons.map((button, index) => (
        <React.Fragment key={index}>{button}</React.Fragment>
      ))}
    </ButtonGroup>
  );
};
