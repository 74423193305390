import {
  Drawer as MuiDrawer,
  Toolbar as MuiToolbar,
  Box as MuiBox,
  Divider as MuiDivider,
  styled,
} from '@mui/material';

import { drawerWidthLarge } from '../../../../../utils';

export const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'isDrawerPermanent',
})<{ isDrawerPermanent: boolean }>(({ theme, isDrawerPermanent }) => ({
  '& .MuiDrawer-paper': {
    boxSizing: 'border-box',
    border: 'none',
    backgroundImage: 'none',
    background: isDrawerPermanent
      ? 'transparent'
      : theme.palette.background.paper,
  },
}));

export const InnerDrawer = styled(MuiBox)(({ theme }) => ({
  width: drawerWidthLarge,
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
}));

export const Toolbar = styled(MuiToolbar)(({ theme }) => ({
  padding: '30px 0px 70px 40px !important',
}));

export const MenuContainer = styled(MuiBox)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  padding: '10px 30px 30px 30px',
  '& > *:not(:last-child)': {
    marginBottom: '10px',
  },
}));

export const Divider = styled(MuiDivider)(({ theme }) => ({
  height: 1,
  margin: '10px 0px',
}));

export const AuthButtonContainer = styled(MuiBox)(({ theme }) => ({
  flex: 1,
  display: 'flex',
  alignItems: 'end',
}));

export const HeaderContainer = styled(MuiBox)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
}));
