import { LockOutlined } from '@mui/icons-material';
import { Box as MuiBox, Typography as MuiTypography } from '@mui/material';
import { useMemo, useState } from 'react';

import { commonStrings } from '../../../../assets/strings/sv';
import { useScreenSize } from '../../../../hooks';
import { LinkAtom } from '../../atoms';
import { ModuleGridMolecule } from '../moduleGrid';
import { CategoryHeader, Container, LockedContainer, Overlay } from './styles';

interface IToggleableModuleGridMolecule {
  modules: JSX.Element[];
  isLoading?: boolean;
  title?: string;
  shouldRenderedModulesBeLimited?: boolean;
  isLocked?: boolean;
}

export const ToggleableModuleGridMolecule = ({
  modules,
  isLoading,
  title,
  shouldRenderedModulesBeLimited = true,
  isLocked,
}: IToggleableModuleGridMolecule) => {
  const [isAllModulesShown, setIsAllModulesShown] = useState(false);
  const screenSize = useScreenSize();

  const cardsPerRow = {
    xs: 2,
    sm: 3,
    md: 3,
    lg: 4,
    xl: 6,
  };
  const defaultCardsToShow = cardsPerRow[screenSize] * 2;

  const shouldTooggleButtonBeRendered = useMemo(() => {
    return Boolean(
      shouldRenderedModulesBeLimited && modules.length > defaultCardsToShow
    );
  }, [shouldRenderedModulesBeLimited, modules.length, defaultCardsToShow]);

  const renderedModules = useMemo(() => {
    if (isAllModulesShown || !shouldRenderedModulesBeLimited) {
      return modules;
    } else {
      return modules.slice(0, defaultCardsToShow);
    }
  }, [
    isAllModulesShown,
    modules,
    defaultCardsToShow,
    shouldRenderedModulesBeLimited,
  ]);

  const toggleShowAllModules = () => {
    setIsAllModulesShown(!isAllModulesShown);
  };

  return (
    <Container>
      <CategoryHeader>
        {title && <MuiTypography variant="body1">{title}</MuiTypography>}
        {shouldTooggleButtonBeRendered && (
          <LinkAtom
            text={
              isAllModulesShown
                ? commonStrings.showLess
                : `${commonStrings.showAll} (${modules.length})`
            }
            onClick={toggleShowAllModules}
          />
        )}
      </CategoryHeader>

      <MuiBox position={isLocked ? 'relative' : 'unset'}>
        <ModuleGridMolecule isLoading={isLoading} modules={renderedModules} />
        {isLocked && (
          <Overlay>
            <LockedContainer>
              <LockOutlined />
              <MuiTypography>{commonStrings.comingSoon}</MuiTypography>
            </LockedContainer>
          </Overlay>
        )}
      </MuiBox>
    </Container>
  );
};
