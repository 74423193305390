import {
  Typography as MuiTypography,
  Divider as MuiDivider,
  Link as MuiLink,
  useTheme,
} from '@mui/material';

import { Card } from './styles';

interface ICardComponent {
  title: string;
  text: string;
  buttonText: string;
  onClick: () => void;
}

export const CardComponent = ({
  title,
  text,
  buttonText,
  onClick,
}: ICardComponent) => {
  const theme = useTheme();

  return (
    <Card>
      <MuiTypography variant="body1">{title}</MuiTypography>
      <MuiDivider sx={{ borderColor: theme.palette.primary.main }} />
      <MuiTypography variant="body1">{text}</MuiTypography>
      <MuiLink marginTop="auto" variant="button" onClick={onClick}>
        {buttonText}
      </MuiLink>
    </Card>
  );
};
