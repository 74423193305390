import React from 'react';
import { Box as MuiBox } from '@mui/material';

interface IPageConstraintAtom {
  children: React.ReactNode;
}

export const PageConstraintAtom = ({ children }: IPageConstraintAtom) => {
  return (
    <MuiBox
      width="100%"
      maxWidth="1600px"
      margin="0 auto"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
      }}
    >
      {children}
    </MuiBox>
  );
};
