import { AddOutlined } from '@mui/icons-material';
import { Typography as MuiTypography, Skeleton } from '@mui/material';

import {
  FailureReason,
  Language,
  Question,
  Status,
} from '../../../../../../generated';
import { QuestionComponent } from './components';
import { UnsavedQuestion } from '../../types';
import { ButtonAtom } from '../../../../atoms';
import { QuestionFieldError } from '../../../../../utils';
import {
  ErrorContainer,
  QuestionSection,
  QuestionsContainer,
  SectionHeader,
} from './styles';
import { commonStrings } from '../../../../../../assets/strings/sv';

interface IQuestionSectionComponent {
  questions: (Question | UnsavedQuestion)[];
  language?: Language;
  isEditModeActive: boolean;
  fieldErrors: QuestionFieldError[];
  status: Status;
  failureReason?: FailureReason | null;
  handleChangeQuestion: (question: Question | UnsavedQuestion) => void;
  handleDeleteQuestion: (question: Question | UnsavedQuestion) => void;
  handleAddQuestion: () => void;
  handleCreateQuestions: () => void;
}

export const QuestionSectionComponent = ({
  questions,
  language,
  isEditModeActive,
  fieldErrors,
  status,
  failureReason,
  handleChangeQuestion,
  handleDeleteQuestion,
  handleAddQuestion,
  handleCreateQuestions,
}: IQuestionSectionComponent) => {
  const { statusMessages, studyMaterialSection } = commonStrings;
  const { questionsSection } = studyMaterialSection;

  if (status === Status.Pending) {
    const skeletonArray = Array.from(Array(4).keys());
    return (
      <QuestionSection>
        <SectionHeader>
          <MuiTypography variant="h6">
            {questionsSection.questions}
          </MuiTypography>
        </SectionHeader>
        <QuestionsContainer>
          {skeletonArray.map(() => (
            <Skeleton variant="rounded" width="100%" height="72px" />
          ))}
        </QuestionsContainer>
      </QuestionSection>
    );
  }

  if (status === Status.Failed) {
    return (
      <QuestionSection>
        <SectionHeader>
          <MuiTypography variant="h6">
            {questionsSection.questions}
          </MuiTypography>
        </SectionHeader>
        <ErrorContainer>
          <MuiTypography>
            {
              statusMessages.questions.create.error.default[
                failureReason === FailureReason.ContentViolation
                  ? 'contentViolation'
                  : 'default'
              ]
            }
          </MuiTypography>
          <ButtonAtom text="Försök igen" onClick={handleCreateQuestions} />
        </ErrorContainer>
      </QuestionSection>
    );
  }

  return (
    <QuestionSection>
      <SectionHeader>
        <MuiTypography variant="h6">{questionsSection.questions}</MuiTypography>
        {isEditModeActive && (
          <ButtonAtom
            variant="primary"
            text={questionsSection.newQuestion}
            onClick={handleAddQuestion}
            endIcon={<AddOutlined />}
          />
        )}
      </SectionHeader>
      <QuestionsContainer>
        {questions.length ? (
          <>
            {questions.map((question, index) => (
              <QuestionComponent
                key={index}
                question={question}
                language={language}
                isEditModeActive={isEditModeActive}
                handleChangeQuestion={handleChangeQuestion}
                handleDeleteQuestion={handleDeleteQuestion}
                fieldError={fieldErrors?.[index]}
              />
            ))}
          </>
        ) : (
          <MuiTypography variant="body1">
            {questionsSection.questionsPlaceholder}
          </MuiTypography>
        )}
      </QuestionsContainer>
    </QuestionSection>
  );
};
