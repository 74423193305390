import { Language, LanguageCode } from '../types';

export const getLanguageCode = (language: string) => {
  switch (language.toUpperCase()) {
    case Language.SWEDISH:
      return LanguageCode.SWEDISH;
    case Language.ENGLISH:
      return LanguageCode.ENGLISH;
    default:
      return LanguageCode.SWEDISH;
  }
};
