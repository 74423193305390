import { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import {
  Box as MuiBox,
  Typography as MuiTypography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';
import { ulid } from 'ulid';

import {
  commonStrings,
  generateStudyMaterialPageStrings as pageStrings,
} from '../../../assets/strings/sv';
import {
  CreateStudyMaterialInput,
  CreateStudyMaterialMutation,
  useImageToTextLazyQuery,
} from '../../../generated';
import {
  useApiCall,
  useLocalStorageState,
  useStudyMaterialContext,
  useUsageContext,
} from '../../../hooks';
import { GraphicLayout, SeoComponent } from '../../layout';
import { PaperConstraintAtom } from '../../library/atoms';
import { StatusContainerMolecule } from '../../library/molecules';
import {
  LocalStorageKeys,
  maxCharacterCount,
  minCharacterCount,
} from '../../utils';
import {
  GenerateMaterialTextFields,
  GenerateStudyMaterialHeaderComponent,
} from './components';
import { StudyMaterialDraft } from './types';

export const GenerateStudyMaterialPage = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const { isAuthenticated } = useAuth0();
  const { handleApiCallWithSnackbars } = useApiCall();
  const { createStudyMaterial } = useStudyMaterialContext();
  const navigate = useNavigate();

  const { statusMessages, disclaimer } = commonStrings;

  const { collectStudyMaterialGeneratedStats } = useUsageContext();

  const [imageToText, { data: imageToTextData, loading: imageToTextLoading }] =
    useImageToTextLazyQuery({
      fetchPolicy: 'network-only',
    });

  const initalDraftStudyMaterial: StudyMaterialDraft = {
    id: ulid(),
    title: '',
    text: '',
    subjectId: undefined,
  };

  const [fieldErrors, setFieldErrors] = useState<Record<string, string>>({});
  const [resolvedStudyMaterials, setResolvedStudyMaterials] = useState<
    CreateStudyMaterialMutation['createStudyMaterial'][]
  >([]);
  const [draftStudyMaterials, setDraftStudyMaterials] = useLocalStorageState<
    StudyMaterialDraft[]
  >([initalDraftStudyMaterial], LocalStorageKeys.STUDY_MATERIAL_DRAFTS);
  const [isCreateStudyMaterialLoading, setIsCreateStudyMaterialLoading] =
    useState(false);
  const [selectedDraft, setSelectedDraft] = useState(draftStudyMaterials[0]);

  useEffect(() => {
    if (imageToTextData) {
      const updatedText = selectedDraft.text
        ? `${selectedDraft.text}\n\n${imageToTextData.imageToText}`
        : imageToTextData.imageToText;

      handleChange('text', updatedText);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageToTextData]);

  useEffect(() => {
    if (resolvedStudyMaterials.length) {
      handleStudyMaterialNavigation(resolvedStudyMaterials);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resolvedStudyMaterials]);

  const validateFields = (draftStudyMaterials: StudyMaterialDraft[]) => {
    setFieldErrors({});

    const validationResults = draftStudyMaterials.map((material) => {
      const errors = Object.entries(material).map(([key, value]) => {
        if (
          key === 'text' &&
          typeof value === 'string' &&
          (value.length < minCharacterCount || value.length > maxCharacterCount)
        ) {
          setFieldErrors((prevErrors) => ({
            ...prevErrors,
            [material.id]: pageStrings.errorMessages[key as 'text'],
          }));
          return false;
        }
        return true;
      });

      return errors.every((error) => error);
    });

    const validation = validationResults.every((result) => result);

    return validation;
  };

  const resetFieldErrors = (selectedDraftId?: string) => {
    setFieldErrors((prevErrors) => {
      if (selectedDraftId) {
        const { [selectedDraftId]: omit, ...restErrors } = prevErrors;
        return restErrors;
      } else {
        return {};
      }
    });
  };

  const handleChange = (
    key: keyof CreateStudyMaterialInput,
    value: string | undefined
  ) => {
    setDraftStudyMaterials((prevState) => {
      return prevState.map((item, index) => {
        if (item.id === selectedDraft.id) {
          const updatedDraft = { ...item, [key]: value };
          setSelectedDraft(updatedDraft);

          return updatedDraft;
        }
        return item;
      });
    });

    resetFieldErrors(selectedDraft.id);
  };

  const handleCreateStudyMaterial = async () => {
    if (!validateFields(draftStudyMaterials)) return;

    setIsCreateStudyMaterialLoading(true);
    const studyMaterials = await Promise.all(
      draftStudyMaterials.map(async (item) => {
        const { title, subjectId, text } = item;
        return await createStudyMaterial({
          title,
          subjectId,
          text,
        });
      })
    );

    const resolvedStudyMaterials = studyMaterials.filter(
      (
        studyMaterial
      ): studyMaterial is CreateStudyMaterialMutation['createStudyMaterial'] =>
        !!studyMaterial
    );

    if (resolvedStudyMaterials.length) {
      collectStudyMaterialGeneratedStats();
      setDraftStudyMaterials([initalDraftStudyMaterial]);
      setResolvedStudyMaterials(resolvedStudyMaterials);
    } else {
      setIsCreateStudyMaterialLoading(false);
      enqueueSnackbar(statusMessages.studyMaterial.create.error, {
        variant: 'error',
      });
    }
  };

  const handleStudyMaterialNavigation = (
    studyMaterial: CreateStudyMaterialMutation['createStudyMaterial'][]
  ) => {
    if (isAuthenticated) {
      if (studyMaterial.length > 1) {
        navigate('/');
      } else if (studyMaterial.length === 1) {
        navigate(`/material/${studyMaterial[0].id}/summary`);
      }
    } else {
      navigate(`/result/${studyMaterial[0].id}/summary`);
    }
  };

  const handleImageToText = async (base64Image: string) => {
    handleApiCallWithSnackbars(
      async () =>
        imageToText({
          variables: {
            base64Image,
          },
          fetchPolicy: 'network-only',
        }),
      {
        error: statusMessages.image.analyze.error,
      }
    );
  };

  const handleAddDraft = () => {
    if (draftStudyMaterials.length > 5 || !isAuthenticated) return;

    setDraftStudyMaterials((prevState) => {
      const newDraft = {
        ...initalDraftStudyMaterial,
        id: ulid(),
      };

      setSelectedDraft(newDraft);

      return [...prevState, newDraft];
    });
  };

  if (isCreateStudyMaterialLoading) {
    return (
      <GraphicLayout>
        <StatusContainerMolecule
          statusMessage={statusMessages.studyMaterial.create.pending}
          descriptionText={statusMessages.studyMaterial.create.descriptionText}
        />
      </GraphicLayout>
    );
  }

  return (
    <GraphicLayout>
      <SeoComponent title={pageStrings.seo.title} />
      <GenerateStudyMaterialHeaderComponent
        draftStudyMaterials={draftStudyMaterials}
        setDraftStudyMaterials={setDraftStudyMaterials}
        selectedDraft={selectedDraft}
        setSelectedDraft={setSelectedDraft}
        fieldErrors={fieldErrors}
        handleAddDraft={handleAddDraft}
      />

      <PaperConstraintAtom
        sx={{ minHeight: isMobile ? 'auto' : 0, height: 'auto' }}
      >
        <GenerateMaterialTextFields
          input={selectedDraft}
          isImageAnalysisLoading={imageToTextLoading}
          handleImageToText={handleImageToText}
          handleChange={handleChange}
          draftStudyMaterials={draftStudyMaterials}
          fieldError={fieldErrors[selectedDraft.id]}
          maxCharacterCount={maxCharacterCount}
          minCharacterCount={minCharacterCount}
          handleGenerateStudyMaterial={handleCreateStudyMaterial}
        />
        <MuiBox>
          <MuiTypography
            marginTop={4}
            color="grey"
            variant="subtitle2"
            textAlign="center"
          >
            {disclaimer}
          </MuiTypography>
        </MuiBox>
      </PaperConstraintAtom>
    </GraphicLayout>
  );
};
