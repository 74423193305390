import { Language, MoreHoriz, VisibilityOutlined } from '@mui/icons-material';
import {
  Grid as MuiGrid,
  Tooltip as MuiTooltip,
  Typography as MuiTypography,
  Box as MuiBox,
  useTheme,
} from '@mui/material';

import { commonStrings } from '../../../../../assets/strings/sv';
import { StudyMaterial } from '../../../../../generated';
import {
  AvatarAtom,
  IconAtom,
  ModuleContainerAtom,
  TooltipAtom,
} from '../../../atoms';
import { PopperMenuItem, PopperMenuMolecule } from '../../../molecules';

interface IStudyMaterialModuleMolecule {
  avatarSrc: string;
  studyMaterial: StudyMaterial;
  menuItems?: PopperMenuItem[];
  onClick?: () => void;
}

export const StudyMaterialModuleMolecule = ({
  avatarSrc,
  studyMaterial,
  menuItems,
  onClick,
}: IStudyMaterialModuleMolecule) => {
  const theme = useTheme();
  const { defaultSubject, tooltips } = commonStrings;

  const StudyMaterialPopper = ({
    menuItems,
  }: {
    menuItems: PopperMenuItem[];
  }) => {
    return (
      <MuiGrid item>
        <PopperMenuMolecule
          icon={<MoreHoriz />}
          shape="round"
          menuItems={menuItems}
          data={studyMaterial}
        />
      </MuiGrid>
    );
  };

  return (
    <ModuleContainerAtom onClick={onClick}>
      <MuiBox display="flex" alignItems="center" justifyContent="space-between">
        <MuiBox display="flex" gap={1}>
          <AvatarAtom alt="module_avatar" src={avatarSrc} />
          {studyMaterial.flags?.isPublished && (
            <MuiGrid item display="flex" alignItems="center">
              <TooltipAtom
                title={commonStrings.tooltips.studyMaterialIsPublished}
              >
                <Language color="disabled" />
              </TooltipAtom>
            </MuiGrid>
          )}
        </MuiBox>
        {menuItems && <StudyMaterialPopper menuItems={menuItems} />}
      </MuiBox>

      <MuiBox
        flex={1}
        gap={1.5}
        display="flex"
        flexDirection="column"
        justifyContent="flex-end"
      >
        <MuiTypography variant="h6" sx={{ wordBreak: 'break-word' }}>
          {studyMaterial.title}
        </MuiTypography>

        <MuiBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <MuiTypography variant="caption" color={theme.palette.text.secondary}>
            {studyMaterial.subject?.subject || defaultSubject.subject}
          </MuiTypography>

          {studyMaterial.views && studyMaterial.flags?.isPublished && (
            <MuiTooltip
              title={tooltips.sharedLessonViews}
              placement="top"
              arrow
            >
              <MuiBox display="flex" alignItems="center">
                <IconAtom
                  color={theme.palette.text.secondary}
                  icon={<VisibilityOutlined fontSize="inherit" />}
                />
                <MuiTypography
                  variant="caption"
                  color={theme.palette.text.secondary}
                >
                  {studyMaterial.views}
                </MuiTypography>
              </MuiBox>
            </MuiTooltip>
          )}
        </MuiBox>
      </MuiBox>
    </ModuleContainerAtom>
  );
};
