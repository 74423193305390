import { useEffect, useMemo } from 'react';
import {
  Grid as MuiGrid,
  Box as MuiBox,
  Typography as MuiTypography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import _ from 'lodash';

import { GetStudyMaterialQuery, Language } from '../../../../../../generated';
import { ExamQuestionEvaluationComponent } from './components';
import { PaperConstraintAtom } from '../../../../atoms';
import {
  AudioPlayerContainer,
  Container,
  DarkContainer,
  FeedbackContainer,
} from './styles';
import { commonStrings } from '../../../../../../assets/strings/sv';
import { EvaluationConfig } from '../../ExamOrganism';
import { AudioPlayerMolecule } from '../../../../molecules';
import { useRatingContext } from '../../../../../../hooks';
import { GARatingCategory } from '../../../../../../context';

interface IExamEvaluationComponent {
  exam: GetStudyMaterialQuery['getStudyMaterial']['exam'];
  evaluationConfig: EvaluationConfig;
  language?: Language;
}

export const ExamEvaluationComponent = ({
  exam,
  evaluationConfig,
  language,
}: IExamEvaluationComponent) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const isTablet = useMediaQuery(theme.breakpoints.up('sm'));

  const { handleOpenPopup } = useRatingContext();

  useEffect(() => {
    handleOpenPopup(commonStrings.rating.exam, GARatingCategory.EXAM, 3);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { examSection } = commonStrings.studyMaterialSection;

  const totalScore = useMemo(
    () =>
      exam?.evaluations?.[0]?.questions?.reduce(
        (acc, currQuestion) => acc + (currQuestion.score || 0),
        0
      ) || 0,
    [exam?.evaluations]
  );

  const maxTotalScore = useMemo(
    () =>
      exam?.questions?.reduce(
        (acc, currQuestion) => acc + currQuestion.maxScore,
        0
      ) || 0,
    [exam?.questions]
  );

  const correctPercentage = useMemo(
    () => (totalScore / maxTotalScore) * 100,
    [totalScore, maxTotalScore]
  );

  const filteredMarks = Object.entries(evaluationConfig).filter(
    ([_, config]) => config.percentage <= correctPercentage
  );

  const examEvaluationMark = filteredMarks.reduce(
    (prevMark, currMark) =>
      currMark[1].percentage > prevMark[1].percentage ? currMark : prevMark,
    filteredMarks[0]
  );

  return (
    <PaperConstraintAtom>
      <MuiBox display="flex" flexDirection="column" rowGap={2} columnGap={2}>
        <MuiGrid container spacing={2}>
          <MuiGrid item xs={isDesktop ? 2.5 : isTablet ? 6 : 12}>
            <Container textAlign="center">
              <MuiTypography>{examSection.yourResult}</MuiTypography>
              <DarkContainer display="flex">
                <MuiTypography color={theme.palette.primary[300]}>
                  {totalScore}
                </MuiTypography>
                <MuiTypography>/</MuiTypography>
                <MuiTypography>{maxTotalScore}</MuiTypography>
              </DarkContainer>
            </Container>
          </MuiGrid>

          <MuiGrid item xs={isDesktop ? 2.5 : isTablet ? 6 : 12}>
            <Container textAlign="center">
              <MuiTypography>{examSection.yourMark}</MuiTypography>
              <DarkContainer>
                <MuiTypography color={theme.palette.primary[300]}>
                  {examEvaluationMark?.[0] || 'F'}
                </MuiTypography>
              </DarkContainer>
            </Container>
          </MuiGrid>

          <MuiGrid item xs>
            <FeedbackContainer>
              <MuiBox sx={{ display: 'flex', flexDirection: 'row' }}>
                <MuiTypography>
                  {exam?.evaluations?.[0]?.feedback}
                </MuiTypography>
                <AudioPlayerContainer>
                  <AudioPlayerMolecule
                    text={exam?.evaluations?.[0]?.feedback || ''}
                    language={
                      language ? language.name : commonStrings.defaultLanguage
                    }
                  />
                </AudioPlayerContainer>
              </MuiBox>
            </FeedbackContainer>
          </MuiGrid>
        </MuiGrid>
        {_.orderBy(exam?.evaluations?.[0]?.questions, 'maxScore', 'asc').map(
          (evaluation, index) => (
            <ExamQuestionEvaluationComponent
              key={index}
              examQuestion={evaluation}
              number={index + 1}
              langauge={language}
            />
          )
        )}
      </MuiBox>
    </PaperConstraintAtom>
  );
};
