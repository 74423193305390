import { QuizType } from '../../../../../../../../../generated';
import { ButtonMode, ButtonType } from '../types';

export const createInitialButtonTypes = (type: QuizType): ButtonMode => {
  const initialButtonTypes: ButtonMode = {};

  switch (type) {
    case QuizType.Statements:
      for (let i = 0; i < 2; i++) {
        initialButtonTypes[i] = ButtonType.UNDEFINED;
      }
      break;
    case QuizType.MultipleChoices:
      for (let i = 0; i < 4; i++) {
        initialButtonTypes[i] = ButtonType.UNDEFINED;
      }
      break;
    default:
      break;
  }

  return initialButtonTypes;
};
