import React from 'react';
import {
  Typography as MuiTypography,
  SxProps,
  Theme,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import { IconAtom } from '../../../../atoms';
import { MenuItem } from './styles';

interface IMenuItemComponent {
  text?: string;
  icon: JSX.Element;
  isSelected?: boolean;
  disabled?: boolean;
  sx?: SxProps<Theme>;
  onClick: () => void;
}

export const MenuItemComponent = ({
  text,
  icon,
  isSelected,
  disabled,
  sx,
  onClick,
}: IMenuItemComponent) => {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.only('md'));

  return (
    <MenuItem
      gap={1.5}
      onClick={onClick}
      sx={sx}
      disabled={disabled}
      isSelected={isSelected}
    >
      <IconAtom
        icon={icon}
        color={
          disabled
            ? theme.palette.text.disabled
            : isSelected
            ? theme.palette.common.white
            : theme.palette.text.secondary
        }
      />
      {!isTablet && (
        <MuiTypography
          color={
            disabled
              ? theme.palette.text.disabled
              : isSelected
              ? theme.palette.text.primary
              : theme.palette.text.secondary
          }
        >
          {text}
        </MuiTypography>
      )}
    </MenuItem>
  );
};
