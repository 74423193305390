import {
  Box as MuiBox,
  Typography as MuiTypography,
  lighten,
  styled,
} from '@mui/material';

export const Container = styled(MuiBox, {
  shouldForwardProp: (prop) => prop !== 'isCardSelected',
})<{ isCardSelected: boolean | undefined }>(({ theme, isCardSelected }) => ({
  display: isCardSelected ? 'flex' : 'inline',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const CardFront = styled(MuiBox, {
  shouldForwardProp: (prop) => prop !== 'isCardSelected',
})<{ isCardSelected: boolean | undefined }>(({ theme, isCardSelected }) => ({
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'center',
  alignItems: 'center',
  padding: '15px',
  height: !isCardSelected ? '300px' : '500px',
  width: !isCardSelected ? 'auto' : '500px',
  minHeight: '300px',
  flex: 1,
  borderRadius: 24,
  backgroundColor: theme.palette.background[800],
  position: 'relative',
  cursor: 'pointer',
  transition: 'background-color 200ms ease',
  '&:hover': {
    backgroundColor: lighten(theme.palette.background?.[800] || '', 0.02),
  },
  '&:after': {
    position: 'absolute',
    top: -1,
    left: -1,
    right: -1,
    bottom: -1,
    background: theme.palette.divider,
    content: '""',
    zIndex: -1,
    borderRadius: 24,
  },
  [theme.breakpoints.down('sm')]: {
    width: '90vw',
  },
}));

export const CardBack = styled(MuiBox, {
  shouldForwardProp: (prop) => prop !== 'isCardSelected',
})<{ isCardSelected: boolean | undefined }>(({ theme, isCardSelected }) => ({
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'center',
  alignItems: 'center',
  padding: '15px',
  height: !isCardSelected ? '300px' : '500px',
  width: !isCardSelected ? 'auto' : '500px',
  minHeight: '300px',
  flex: 1,
  borderRadius: 24,
  backgroundColor: theme.palette.background[900],
  position: 'relative',
  cursor: 'pointer',
  transition: 'background-color 200ms ease',
  '&:hover': {
    backgroundColor: lighten(theme.palette.background?.[900] || '', 0.02),
  },
  '&:after': {
    position: 'absolute',
    top: -1,
    left: -1,
    right: -1,
    bottom: -1,
    background: theme.palette.gradient,
    content: '""',
    zIndex: -1,
    borderRadius: 24,
  },
  [theme.breakpoints.down('sm')]: {
    width: '90vw',
  },
}));

export const CardHeader = styled(MuiBox)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  zIndex: '10',
}));

export const TextContainer = styled(MuiBox)(({ theme }) => ({
  flex: 1,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const SubContainer = styled(MuiBox)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));

export const ChoiceContainer = styled(MuiBox)(({ theme }) => ({
  display: 'flex',
  gap: '15px',
  margin: '10px 0',
}));

export const ClickToFlipText = styled(MuiTypography)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '5px',
  color: theme.palette.grey[600],
}));
