import { Box as MuiBox, styled } from '@mui/material';

export const Container = styled(MuiBox)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: 48,
  backgroundColor: theme.palette.background[800],
  borderRadius: theme.shape.borderRadius,
  border: `1px solid ${theme.palette.divider}`,
  [theme.breakpoints.down('md')]: {
    padding: 24,
  },
}));

export const AuthenticationContainer = styled(MuiBox)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
  gap: 10,
  border: `1px solid ${theme.palette.error.dark}`,
  color: theme.palette.error.light,
  borderRadius: theme.shape.borderRadius,
  padding: 16,
}));
