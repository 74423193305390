import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Box as MuiBox } from '@mui/material';

import { Page } from './Page';
import { LoaderAtom } from '../../library/atoms';
import {
  useGetUserStreakQuery,
  useUpdateUserStreakMutation,
} from '../../../generated';
import { StreakPopupComponent } from './components';

export const ProtectedPage = () => {
  const {
    isAuthenticated,
    isLoading,
    loginWithRedirect,
    isLoading: isAuthenticationLoading,
  } = useAuth0();

  const [showStreakModal, setShowStreakModal] = useState(false);

  const { data: getUserStreakData } = useGetUserStreakQuery({
    fetchPolicy: 'cache-and-network',
  });
  const [updateUserStreak] = useUpdateUserStreakMutation();

  useEffect(() => {
    if (!isAuthenticated && !isLoading) {
      loginWithRedirect({
        appState: {
          returnTo: window.location.pathname,
        },
      });
    }

    if (isAuthenticated) {
      handleUpdateStreak();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  const handleCloseStreakModal = () => {
    setShowStreakModal(false);
  };

  const handleUpdateStreak = async () => {
    const { data } = await updateUserStreak({
      onError: (error) =>
        console.error('Could not update user streak', { error }),
    });

    if (!data) return;

    const { dailyStreak, daysSinceLastLogin } = data.updateUserStreak;

    if (dailyStreak && daysSinceLastLogin === 1) {
      setShowStreakModal(true);
    }
  };

  if (isAuthenticationLoading)
    return (
      <MuiBox
        flex={1}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <LoaderAtom />
      </MuiBox>
    );

  return (
    <>
      <Page />
      <StreakPopupComponent
        showStreakModal={showStreakModal}
        handleCloseStreakModal={handleCloseStreakModal}
        streakNumber={getUserStreakData?.getUserStreak?.dailyStreak || 1}
      />
    </>
  );
};
