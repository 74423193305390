import { TextField as MuiTextField, lighten, styled } from '@mui/material';

export const TransparentTextField = styled(MuiTextField)(({ theme }) => ({
  '& .MuiInputBase-root': {
    backgroundColor: 'transparent',
    transition: 'background-color 200ms ease',
    '&:hover': {
      backgroundColor: 'rgba(255,255,255,0.02)',
    },
    '&.Mui-focused': {
      backgroundColor: 'rgba(255,255,255,0.02)',
    },
    '&::before, &::after': {
      border: 'none !important',
    },
  },
  '& input': {
    padding: '16px',
  },
}));

export const OutlinedTextField = styled(MuiTextField)(({ theme, size }) => ({
  border: 'none',
  '& .MuiInputBase-root': {
    backgroundColor: theme.palette.background[800],
    border: `1px solid ${theme.palette.divider}`,
    transition: 'background-color 200ms ease, border-color 250ms ease',
    '&:hover, &.Mui-focused': {
      border: `1px solid ${theme.palette.divider}`,
      backgroundColor: theme.palette.background[600],
    },
    '&::before, &::after, & fieldset': {
      border: 'none !important',
    },
  },
  '& input': {
    padding: '16px',
  },
}));

export const FilledTextField = styled(MuiTextField)(({ theme }) => ({
  border: 'none',
  '& .MuiInputBase-root': {
    backgroundColor: theme.palette.background[900],
    border: `1px solid ${theme.palette.divider}`,
    transition: 'background-color 200ms ease, border-color 250ms ease',
    '&:hover, &.Mui-focused': {
      border: `1px solid ${theme.palette.divider}`,
      backgroundColor: lighten(theme.palette.background?.[900] || '', 0.02),
    },
    '&::before, &::after, & fieldset': {
      border: 'none !important',
    },
  },
  '& input': {
    padding: '16px',
  },
}));

export const StandardTextField = styled(MuiTextField)(({ theme }) => ({
  border: 'none',
  '& .MuiInputBase-root': {
    backgroundColor: theme.palette.background[800],
  },
  '& ::before': {
    borderBottom: 'none',
  },
  '& input': {
    padding: '16px',
  },
}));
