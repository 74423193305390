import {
  Select as MuiSelect,
  styled,
  MenuItem as MuiMenuItem,
} from '@mui/material';

export const Select = styled(MuiSelect)<{}>(({ theme }) => ({
  padding: 0,
  '& .MuiOutlinedInput-input': {
    backgroundColor: theme.palette.background.paper,
    padding: '16px',
    ':hover': {
      backgroundColor: theme.palette.grey[800],
    },
    '& .Mui-selected': {
      backgroundColor: theme.palette.grey[800],
    },
  },
  '.MuiOutlinedInput-notchedOutline': {
    border: 'none !important',
  },
}));

export const MenuItem = styled(MuiMenuItem)<{}>(({ theme, selected }) => ({
  backgroundColor: selected ? `${theme.palette.grey[600]} !important` : 'none',
  ':hover': {
    backgroundColor: theme.palette.grey[700],
  },
}));
