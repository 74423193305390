import React from 'react';
import { Typography as MuiTypography, Box as MuiBox } from '@mui/material';
import { FolderOutlined, MoreHoriz } from '@mui/icons-material';

import { ModuleContainerAtom, TagAtom } from '../../../atoms';
import { PopperMenuItem, PopperMenuMolecule } from '../../../molecules';

interface IFolderModuleMolecule {
  title: string;
  tagText: string;
  menuItems?: PopperMenuItem[];
  data?: any;
}

export const FolderModuleMolecule = ({
  title,
  tagText,
  menuItems,
  data,
}: IFolderModuleMolecule) => {
  return (
    <ModuleContainerAtom>
      <MuiBox display="flex" alignItems="center" justifyContent="space-between">
        <FolderOutlined />
        {menuItems && (
          <PopperMenuMolecule
            icon={<MoreHoriz />}
            shape="round"
            menuItems={menuItems}
            data={data}
          />
        )}
      </MuiBox>

      <MuiBox
        flex={1}
        gap={1.5}
        display="flex"
        flexDirection="column"
        justifyContent="flex-end"
      >
        <MuiTypography variant="h6" sx={{ wordBreak: 'break-word' }}>
          {title}
        </MuiTypography>

        <MuiBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <TagAtom text={tagText} />
        </MuiBox>
      </MuiBox>
    </ModuleContainerAtom>
  );
};
