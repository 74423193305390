import { gql } from '@apollo/client';

export const LIST_PUBLISHED_STUDY_MATERIAL = gql`
  query listPublishedStudyMaterial(
    $options: ListStudyMaterialOptionsInput
    $subjectId: ID
  ) {
    listStudyMaterial(
      options: $options
      filter: {
        subjectId: $subjectId
        flags: { isPublished: true, deleted: null }
      }
    ) {
      id
      title
      user {
        id
        picture
      }
      flags {
        isPublished
        deleted
      }
      subject {
        id
        subject
      }
      views
      bookmark {
        id
      }
    }
  }
`;
