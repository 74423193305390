import React from 'react';
import {
  Typography as MuiTypography,
  Modal as MuiModal,
  Box as MuiBox,
  SxProps,
  useTheme,
} from '@mui/material';
import { CloseOutlined } from '@mui/icons-material';

import {
  CloseButton,
  ChildContainer,
  ModalContent,
  ModalInnerContent,
} from './styles';
import { GradientBorderAtom } from '../../atoms';

interface IModalMolecule {
  children: React.ReactNode;
  title?: string;
  description?: string;
  isOpen: boolean;
  sx?: SxProps;
  onClose: () => void;
  type?: 'borderGradient';
}

export const ModalMolecule = ({
  title,
  description,
  children,
  isOpen,
  sx,
  type,
  onClose,
}: IModalMolecule) => {
  return (
    <MuiModal open={isOpen} onClose={onClose}>
      <>
        <ModalContentContainer sx={sx} type={type}>
          <CloseButton onClick={onClose}>
            <CloseOutlined />
          </CloseButton>

          <MuiBox display="flex" flexDirection="column" gap={1}>
            {title && <MuiTypography variant="h6">{title}</MuiTypography>}
            {description && (
              <MuiTypography variant="body1">{description}</MuiTypography>
            )}
          </MuiBox>
          <ChildContainer>{children}</ChildContainer>
        </ModalContentContainer>
      </>
    </MuiModal>
  );
};

interface IModalContentContainer {
  children: React.ReactNode;
  type?: 'borderGradient';
  sx?: SxProps;
}

const ModalContentContainer = ({
  children,
  type,
  sx,
}: IModalContentContainer) => {
  const theme = useTheme();

  switch (type) {
    case 'borderGradient':
      return (
        <ModalContent sx={{ padding: 0, ...sx }}>
          <GradientBorderAtom
            sx={{
              padding: theme.spacing(4),
              backgroundColor: theme.palette.grey[800],
            }}
          >
            <ModalInnerContent>{children}</ModalInnerContent>
          </GradientBorderAtom>
        </ModalContent>
      );
    default:
      return (
        <ModalContent sx={sx}>
          <ModalInnerContent>{children}</ModalInnerContent>
        </ModalContent>
      );
  }
};
