import { Bookmark } from '@mui/icons-material';
import { useAuth0 } from '@auth0/auth0-react';

import { GetStudyMaterialQuery } from '../../../../../../../../../generated';
import { IconButtonAtom } from '../../../../../../../../library/atoms';
import { useStudyMaterialContext } from '../../../../../../../../../hooks';

interface IBookmarkComponent {
  studyMaterial: GetStudyMaterialQuery['getStudyMaterial'];
}

export const BookmarkComponent = ({ studyMaterial }: IBookmarkComponent) => {
  const { isAuthenticated } = useAuth0();

  const { bookmarkStudyMaterial } = useStudyMaterialContext();

  const handleBookmarkStudyMaterial = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.stopPropagation();
    bookmarkStudyMaterial(studyMaterial.id);
  };

  return (
    <IconButtonAtom
      disabled={!isAuthenticated}
      icon={
        <Bookmark
          fontSize="small"
          color={studyMaterial.bookmark?.id ? 'warning' : 'inherit'}
        />
      }
      onClick={handleBookmarkStudyMaterial}
      shape="round"
    />
  );
};
