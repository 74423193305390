import {
  FormControl,
  Typography as MuiTypography,
  RadioGroup,
  useTheme,
} from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';

import {
  Choice,
  Language,
  QuizSegment,
  QuizType,
} from '../../../../../../../../generated';
import { UserAnswer } from '../../../../types';
import {
  QuizRadioButtonComponent,
  QuizStatementButtonComponent,
} from './components';
import { createInitialButtonTypes } from './helpers';
import {
  ButtonContainer,
  Card,
  CardHeader,
  Container,
  InnerContainer,
  TypographyContainer,
} from './styles';
import { ButtonMode, ButtonText, ButtonType } from './types';
import { AudioPlayerMolecule } from '../../../../../../molecules';
import { commonStrings } from '../../../../../../../../assets/strings/sv';
import { ContentType } from '../../../../../../../../utils';

interface IQuizCardComponent {
  index: number;
  totalAmount: number;
  item: QuizSegment;
  language?: Language;
  quizType: QuizType;
  userAnswer?: UserAnswer;
  handleUserAnswer: (answer: UserAnswer) => void;
  setIsQuizAnswerButtonDisabled: React.Dispatch<React.SetStateAction<boolean>>;
  isQuizAnswerButtonDisabled: boolean;
  handleNextCardTimeout: () => void;
}

export const QuizCardComponent = ({
  index,
  totalAmount,
  item,
  language,
  quizType,
  userAnswer,
  handleUserAnswer,
  setIsQuizAnswerButtonDisabled,
  isQuizAnswerButtonDisabled,
  handleNextCardTimeout,
}: IQuizCardComponent) => {
  const theme = useTheme();

  const [buttonTypes, setButtonTypes] = useState<ButtonMode>(
    createInitialButtonTypes(quizType)
  );
  const [borderColor, setBorderColor] = useState<string>();

  const question = useMemo(() => {
    switch (item.__typename) {
      case 'Statements':
        return item.statement;
      case 'MultipleChoices':
        return item.question;
      default:
        return '';
    }
  }, [item]);

  const getCorrectAnswerIndex = (segment: QuizSegment | UserAnswer) => {
    switch (segment.__typename) {
      case 'Statements': {
        return segment.isTrue ? 0 : 1;
      }
      case 'MultipleChoices': {
        return segment.choices.findIndex((choice) => choice.isTrue);
      }
      default: {
        return -1;
      }
    }
  };

  const isAnswerCorrect = (selectedAnswerIndex: number) => {
    const correctAnswerIndex = getCorrectAnswerIndex(item);

    return correctAnswerIndex === selectedAnswerIndex;
  };

  const handleSelectAnswer = (
    selectedChoiceText: string,
    selectedChoiceIndex: number
  ) => {
    if (isQuizAnswerButtonDisabled) return;
    setIsQuizAnswerButtonDisabled(true);

    const answer: UserAnswer = {
      ...item,
      isUserAnswerCorrect: isAnswerCorrect(selectedChoiceIndex),
      selectedChoiceText,
      selectedChoiceIndex,
    };

    handleUserAnswer(answer);
  };

  useEffect(() => {
    handleControlUserAnswer(userAnswer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userAnswer]);

  const handleControlUserAnswer = (userAnswer?: UserAnswer) => {
    if (!userAnswer) {
      setBorderColor(undefined);
      setButtonTypes(createInitialButtonTypes(quizType));
    } else {
      if (userAnswer.isUserAnswerCorrect) {
        setBorderColor(`${theme.palette.success.main}`);
        setButtonTypes((prevTypes: ButtonMode) => ({
          ...prevTypes,
          [userAnswer.selectedChoiceIndex]: ButtonType.CORRECT_SELECTED,
        }));
      } else {
        const correctAnswerIndex = getCorrectAnswerIndex(userAnswer);
        setBorderColor(`${theme.palette.error[200]}`);
        setButtonTypes((prevTypes: ButtonMode) => ({
          ...prevTypes,
          [userAnswer.selectedChoiceIndex]: ButtonType.INCORRECT_SELECTED,
          [correctAnswerIndex]: ButtonType.CORRECT_NOT_SELECTED,
        }));
      }
      handleNextCardTimeout();
    }
  };

  const renderQuizItem = () => {
    switch (item.__typename) {
      case 'Statements':
        return (
          <>
            <TypographyContainer variant="h6" textAlign={'center'}>
              {item.statement}
            </TypographyContainer>

            <ButtonContainer>
              <QuizStatementButtonComponent
                text={ButtonText.TRUE}
                buttonType={buttonTypes[0]}
                onClick={(text) => handleSelectAnswer(text, 0)}
                disabled={isQuizAnswerButtonDisabled}
              />
              <QuizStatementButtonComponent
                text={ButtonText.FALSE}
                buttonType={buttonTypes[1]}
                onClick={(text) => handleSelectAnswer(text, 1)}
                disabled={isQuizAnswerButtonDisabled}
              />
            </ButtonContainer>
          </>
        );

      case 'MultipleChoices':
        return (
          <>
            <TypographyContainer variant="h6" textAlign={'center'}>
              {item.question}
            </TypographyContainer>
            <FormControl>
              <RadioGroup
                aria-labelledby="demo-QuizRadioButtonGroup-label"
                name="QuizRadioButtonGroup"
              >
                {item.choices?.map((choice: Choice, index: number) => (
                  <QuizRadioButtonComponent
                    key={index}
                    text={choice.option}
                    buttonType={buttonTypes[index]}
                    onClick={() => handleSelectAnswer(choice.option, index)}
                    disabled={isQuizAnswerButtonDisabled}
                    label={choice.option}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </>
        );
    }
  };

  return (
    <Container>
      <Card borderColor={borderColor}>
        <InnerContainer>
          <CardHeader>
            <MuiTypography variant="body1">
              {index}/{totalAmount}
            </MuiTypography>
            <AudioPlayerMolecule
              statsContentType={ContentType.QUIZ}
              text={question}
              language={language?.name || commonStrings.defaultLanguage}
            />
          </CardHeader>
          {renderQuizItem()}
        </InnerContainer>
      </Card>
    </Container>
  );
};
