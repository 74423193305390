import { FetchResult } from '@apollo/client';
import { useSnackbar } from 'notistack';

type SnackbarMessages = {
  error?: string;
  info?: string;
  success?: string;
};

export const useApiCall = () => {
  const { enqueueSnackbar } = useSnackbar();

  const handleApiCallWithSnackbars = async <TData>(
    callback: () => Promise<FetchResult<TData>>,
    snackbarMessages?: SnackbarMessages
  ) => {
    snackbarMessages?.info &&
      enqueueSnackbar(snackbarMessages.info, { variant: 'info' });

    const response = await callback();

    response.errors &&
      snackbarMessages?.error &&
      enqueueSnackbar(snackbarMessages.error, { variant: 'error' });

    response.data &&
      snackbarMessages?.success &&
      enqueueSnackbar(snackbarMessages.success, { variant: 'success' });

    return response;
  };

  return { handleApiCallWithSnackbars };
};
