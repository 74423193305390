import {
  Box as MuiBox,
  Typography as MuiTypography,
  useTheme,
} from '@mui/material';
import { DeleteOutline } from '@mui/icons-material';

import { Statements } from '../../../../../../../../../../generated';
import { StatementQuestion, NumberContainer, ToggleContainer } from './styles';
import {
  IconButtonAtom,
  TextInputAtom,
  ToggleAtom,
} from '../../../../../../../../atoms';
import { StatementErrorMessage } from '../../QuizEditModalComponent';
import { commonStrings } from '../../../../../../../../../../assets/strings/sv';

interface IStatementQuestion {
  segment: Statements;
  index: number;
  errorMessage?: StatementErrorMessage;
  handleChangeStatement: (statement: Statements, index: number) => void;
  handleDeleteStatement: (index: number) => void;
}

export const StatementQuestionComponent = ({
  segment,
  index,
  errorMessage,
  handleChangeStatement,
  handleDeleteStatement,
}: IStatementQuestion) => {
  const theme = useTheme();

  const { quizSection } = commonStrings.studyMaterialSection;

  return (
    <StatementQuestion>
      <NumberContainer>{index + 1}</NumberContainer>
      <MuiBox
        flex={1}
        display="flex"
        flexDirection="column"
        gap={1}
        padding={2}
        sx={{
          backgroundColor: theme.palette.background[500],
          borderRadius: `${theme.shape.borderRadius}px`,
        }}
      >
        <TextInputAtom
          variant="filled"
          multiline
          value={segment.statement}
          errorMessage={errorMessage?.statement}
          onChange={(statement) =>
            handleChangeStatement({ ...segment, statement }, index)
          }
        />
        <MuiBox
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <ToggleContainer>
            <MuiTypography>{quizSection.statementIsTrue}</MuiTypography>
            <ToggleAtom
              size="small"
              isToggled={segment.isTrue}
              handleToggle={() =>
                handleChangeStatement(
                  { ...segment, isTrue: !segment.isTrue },
                  index
                )
              }
            />
          </ToggleContainer>
          <IconButtonAtom
            variant="light"
            icon={<DeleteOutline />}
            onClick={() => handleDeleteStatement(index)}
          />
        </MuiBox>
      </MuiBox>
    </StatementQuestion>
  );
};
