import { useState } from 'react';

import { ISubjectContext, SubjectContext } from './context';
import {
  ListSubjectsQuery,
  Subject,
  useListSubjectsQuery,
} from '../../generated';

interface IStudyMaterialProvider {
  children: React.ReactNode;
}

const SubjectProvider = ({ children }: IStudyMaterialProvider) => {
  const [subjects, setSubjects] = useState<ListSubjectsQuery['listSubjects']>(
    []
  );
  const [selectedSubject, setSelectedSubject] = useState<Subject | undefined>();

  useListSubjectsQuery({
    fetchPolicy: 'cache-and-network',
    onCompleted: ({ listSubjects }) => {
      setSubjects(listSubjects);
    },
    onError: (error) => {
      console.error('Could not list subjects', { error });
    },
  });

  const handleChangeSelectedSubject = (subject?: Subject) => {
    setSelectedSubject(subject);
  };

  const config: ISubjectContext = {
    subjects,
    selectedSubject,
    handleChangeSelectedSubject,
  };

  return (
    <SubjectContext.Provider value={config}>{children}</SubjectContext.Provider>
  );
};

export { SubjectProvider };
