import { Box as MuiBox, styled } from '@mui/material';

export const ParentContainer = styled(MuiBox)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  flex: 1,
  rowGap: 20,
}));

export const TextContainer = styled(MuiBox)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  rowGap: 5,
  marginBottom: '15px',
  width: 500,
  maxWidth: '100%',
}));
