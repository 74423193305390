import { ArrowBackIosNew, ArrowForwardIos } from '@mui/icons-material';
import {
  Typography as MuiTypography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useState } from 'react';
import { useSwipeable } from 'react-swipeable';

import { commonStrings } from '../../../../../../assets/strings/sv';
import { Flashcard, Language } from '../../../../../../generated';
import { SwipeDirection, swipeThreshold } from '../../../../../utils';
import { IconButtonAtom } from '../../../../atoms';
import { FlashcardsHeader } from './components';
import { FlipFlashcardComponent } from '../flipFlashcard';
import {
  BodyContainer,
  CardContainer,
  CarouselContainer,
  SelectedFlashCardContainer,
  SubContainer,
} from './styles';
import { SwitchToCard } from './types';

interface ISelectedFlashcardComponent {
  title?: string | null | undefined;
  flashcards: Flashcard[];
  language?: Language;
  selectedCardId?: string;
  setSelectedCard: React.Dispatch<React.SetStateAction<string | undefined>>;
  handleSelectCard: (
    e: React.MouseEvent<HTMLButtonElement>,
    index: number
  ) => void;
  handleFlipCard: (index: number) => void;
  isAnswerVisibleArray: boolean[];
  setIsAnswerVisibleArray: React.Dispatch<React.SetStateAction<boolean[]>>;
}

export const SelectedFlashcardComponent = ({
  title,
  flashcards,
  language,
  selectedCardId,
  setSelectedCard,
  handleSelectCard,
  handleFlipCard,
  isAnswerVisibleArray,
  setIsAnswerVisibleArray,
}: ISelectedFlashcardComponent) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));
  const { swipeToSwitch } = commonStrings.studyMaterialSection.flashcardSection;

  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const handleSwipe = (direction: string) => {
    if (direction === SwipeDirection.LEFT) {
      handleSwitchCard(SwitchToCard.NEXT);
    } else if (direction === SwipeDirection.RIGHT) {
      handleSwitchCard(SwitchToCard.PREVIOUS);
    }
  };

  const handlers = useSwipeable({
    onSwiped: ({ deltaX }) => {
      if (deltaX > swipeThreshold) {
        handleSwipe(SwipeDirection.RIGHT);
      } else if (deltaX < -swipeThreshold) {
        handleSwipe(SwipeDirection.LEFT);
      }
    },
  });

  const currentIndex = flashcards.findIndex(
    (card) => card.id === selectedCardId
  );

  const handleSwitchCard = (switchDirection: SwitchToCard) => {
    const getNewIndex = () => {
      if (switchDirection === SwitchToCard.PREVIOUS) {
        return currentIndex - 1;
      } else if (switchDirection === SwitchToCard.NEXT) {
        return currentIndex + 1;
      }
      return currentIndex;
    };

    const newIndex = getNewIndex();
    if (newIndex >= 0 && newIndex < flashcards.length) {
      if (isAnswerVisibleArray[currentIndex]) {
        setIsAnswerVisibleArray((prevArray) => {
          return prevArray.map((answerVisible, i) =>
            i === currentIndex ? !answerVisible : answerVisible
          );
        });
        setIsButtonDisabled(true);

        // This setTimeout is for the animation of the card flip to complete before the card is switched
        setTimeout(() => {
          setSelectedCard(flashcards[newIndex].id);
          setIsButtonDisabled(false);
        }, 610);
      } else {
        setSelectedCard(flashcards[newIndex].id);
      }
    }
  };

  const renderArrowButton = (direction: SwitchToCard, icon: JSX.Element) => {
    return (
      isDesktop && (
        <IconButtonAtom
          onClick={() => handleSwitchCard(direction)}
          icon={icon}
          disabled={isButtonDisabled}
        />
      )
    );
  };

  return (
    <>
      <SelectedFlashCardContainer>
        <FlashcardsHeader
          index={currentIndex + 1}
          title={title}
          totalAmount={flashcards.length}
          handleSelectCard={handleSelectCard}
        />
        <BodyContainer {...handlers}>
          <SubContainer>
            {!isDesktop && (
              <MuiTypography variant="h3" color={theme.palette.grey[700]}>
                {swipeToSwitch}
              </MuiTypography>
            )}
            {renderArrowButton(SwitchToCard.PREVIOUS, <ArrowBackIosNew />)}
            <CarouselContainer>
              {flashcards.map((card: Flashcard, index: number) => (
                <CardContainer key={index} currentIndex={currentIndex}>
                  <FlipFlashcardComponent
                    flashcard={card}
                    language={language}
                    handleSelectCard={(e) => handleSelectCard(e, currentIndex)}
                    handleFlipCard={() => handleFlipCard(currentIndex)}
                    index={index}
                    isCardSelected={true}
                    isAnswerVisibleArray={isAnswerVisibleArray}
                  />
                </CardContainer>
              ))}
            </CarouselContainer>
            {renderArrowButton(SwitchToCard.NEXT, <ArrowForwardIos />)}
          </SubContainer>
        </BodyContainer>
      </SelectedFlashCardContainer>
    </>
  );
};
