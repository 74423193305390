import { Box as MuiBox, alpha, styled } from '@mui/material';

export const StatementQuestion = styled(MuiBox)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '16px',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },
}));

export const ToggleContainer = styled(MuiBox)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '16px',
  padding: '8px 12px',
  backgroundColor: alpha(theme.palette.common.white, 0.07),
  borderRadius: `${theme.shape.borderRadius}px`,
}));

export const NumberContainer = styled(MuiBox)(({ theme }) => ({
  height: 'fit-content',
  width: 'fit-content',
  padding: '0px 8px',
  minWidth: '30px',
  textAlign: 'center',
  borderRadius: theme.shape.borderRadius,
  marginTop: '6px',
  backgroundColor: theme.palette.common.white,
  color: theme.palette.common.black,
}));
