export enum Language {
  SWEDISH = 'SWEDISH',
  ENGLISH = 'ENGLISH',
}

export enum LanguageCode {
  SWEDISH = 'sv-SE',
  ENGLISH = 'en-GB',
}

export enum Voice {
  SWEDISH = 'SofieNeural',
  ENGLISH = 'SoniaNeural',
}
