import { Box as MuiBox, styled } from '@mui/material';

export const buttonProps = {
  width: '100%',
  minHeight: '50px',
  margin: '5px 0',
  borderRadius: '10px',
};

export const Container = styled(MuiBox)(({ theme }) => ({
  width: '100%',
  height: 'auto',
  padding: '0'
}));
