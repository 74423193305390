import { Close } from '@mui/icons-material';
import {
  Slide as MuiSlide,
  Box as MuiBox,
  useTheme,
  SxProps,
  Theme,
} from '@mui/material';

interface IPopupAtom {
  children: React.ReactNode;
  isOpen: boolean;
  handleClose: () => void;
  sx?: SxProps<Theme>;
}

export const PopupAtom = ({
  children,
  isOpen,
  handleClose,
  sx,
}: IPopupAtom) => {
  const theme = useTheme();

  return (
    <MuiSlide direction="up" in={isOpen} mountOnEnter unmountOnExit>
      <MuiBox
        sx={{
          backgroundColor: theme.palette.background[900],
          border: `1px solid ${theme.palette.divider}`,
          ...sx,
        }}
        position="fixed"
        bottom={0}
        right={0}
        zIndex={9999}
        padding={3}
        paddingTop={2}
        margin={2}
        borderRadius={`${theme.shape.borderRadius}px`}
      >
        <MuiBox paddingBottom={1} display="flex" justifyContent="flex-end">
          <Close
            fontSize="small"
            onClick={handleClose}
            sx={{ cursor: 'pointer' }}
          />
        </MuiBox>
        {children}
      </MuiBox>
    </MuiSlide>
  );
};
