import { CloseOutlined } from '@mui/icons-material';
import {
  Box as MuiBox,
  Typography as MuiTypography,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import { IconButtonAtom } from '../../../../../atoms';
import { Container, ContainerItem } from './styles';

interface IFlashcardsHeader {
  title?: string | null | undefined;
  totalAmount: number;
  handleSelectCard: (
    event: React.MouseEvent<HTMLButtonElement>,
    index: number
  ) => void;
  index: number;
}

export const FlashcardsHeader = ({
  title,
  totalAmount,
  handleSelectCard,
  index,
}: IFlashcardsHeader) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <Container>
      <ContainerItem>
        <MuiTypography variant="h6">{`${index}/${totalAmount}`}</MuiTypography>
        {isDesktop && <MuiTypography variant="h6">{title}</MuiTypography>}
        <MuiBox sx={{ display: 'flex', gap: '15px', alignItems: 'center' }}>
          <IconButtonAtom
            onClick={(e) => handleSelectCard(e, index)}
            icon={<CloseOutlined />}
            shape="round"
          />
        </MuiBox>
      </ContainerItem>
      {!isDesktop && (
        <MuiBox
          sx={{
            display: 'flex',
            justifyContent: 'space-evenly',
            alignItems: 'center',
            gap: '15px',
          }}
        >
          <MuiTypography variant="h6">{title}</MuiTypography>
        </MuiBox>
      )}
    </Container>
  );
};
