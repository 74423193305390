import { Box as MuiBox, styled } from '@mui/material';

export const OuterContainer = styled(MuiBox)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '16px',
  width: '100%',
  paddingBottom: 40,
  marginBottom: 40,
  borderBottom: `1px solid ${theme.palette.divider}`,
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    paddingBottom: 24,
    marginBottom: 24,
  },
}));

export const QuestionSection = styled(MuiBox)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

export const NumberContainer = styled(MuiBox)(({ theme }) => ({
  height: 'fit-content',
  width: 'fit-content',
  padding: '0px 8px',
  minWidth: '30px',
  textAlign: 'center',
  borderRadius: theme.shape.borderRadius,
  marginTop: '6px',
  backgroundColor: theme.palette.common.white,
  color: theme.palette.common.black,
}));

export const AudioPlayerContainer = styled(MuiBox)(({ theme }) => ({
  marginLeft: 2,
}));
