import {
  Typography as MuiTypography,
  Divider as MuiDivider,
} from '@mui/material';
import { ArrowForward } from '@mui/icons-material';

import { Card, Link } from './styles';

interface ICardComponent {
  title: string;
  links: {
    text: string;
    href: string;
  }[];
}

export const CardComponent = ({ title, links }: ICardComponent) => {
  return (
    <Card>
      <MuiTypography>{title}</MuiTypography>
      <MuiDivider />
      {links.map((link, index) => (
        <Link href={link.href} key={index}>
          <MuiTypography>{link.text}</MuiTypography>
          <ArrowForward color="primary" fontSize="inherit" />
        </Link>
      ))}
    </Card>
  );
};
