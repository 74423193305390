import { CancelRounded, CheckCircleRounded } from '@mui/icons-material';
import { useTheme } from '@mui/material';

import { ButtonAtom } from '../../../../../../../../atoms';
import { ButtonType } from '../../types';
import { Container, buttonProps } from './style';

interface IQuizStatementButtonComponent {
  text: string;
  buttonType: ButtonType;
  onClick: (text: string) => void;
  disabled: boolean;
}
export const QuizStatementButtonComponent = ({
  text,
  buttonType,
  onClick,
  disabled,
}: IQuizStatementButtonComponent) => {
  const theme = useTheme();
  const renderButton = (buttonType: ButtonType) => {
    switch (buttonType) {
      case ButtonType.UNDEFINED:
        return (
          <Container>
            <ButtonAtom
              variant="border"
              sx={{
                ...buttonProps,
                backgroundColor: theme.palette.background[600],
              }}
              onClick={() => onClick(text)}
              text={text}
              disabled={disabled}
            />
          </Container>
        );
      case ButtonType.CORRECT_SELECTED:
        return (
          <Container>
            <ButtonAtom
              sx={{
                ...buttonProps,
                border: `1px solid ${theme.palette.success.main}`,
              }}
              text={text}
              onClick={() => onClick(text)}
              endIcon={
                <CheckCircleRounded
                  sx={{ color: theme.palette.success.main }}
                />
              }
              disabled={disabled}
            />
          </Container>
        );
      case ButtonType.INCORRECT_SELECTED:
        return (
          <Container>
            <ButtonAtom
              sx={{
                ...buttonProps,
                backgroundColor: theme.palette.background[600],
                border: `1px solid ${theme.palette.error[200]}`,
              }}
              text={text}
              onClick={() => onClick(text)}
              endIcon={
                <CancelRounded sx={{ color: theme.palette.error[200] }} />
              }
              disabled={disabled}
            />
          </Container>
        );
      case ButtonType.CORRECT_NOT_SELECTED:
        return (
          <Container>
            <ButtonAtom
              variant="border"
              sx={{
                ...buttonProps,
                backgroundColor: theme.palette.background[600],
              }}
              text={text}
              onClick={() => onClick(text)}
              type={undefined}
              endIcon={<CheckCircleRounded color={'success'} />}
              disabled={disabled}
            />
          </Container>
        );
    }
  };
  return <>{renderButton(buttonType)}</>;
};
