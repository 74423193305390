import { Box as MuiBox, styled } from '@mui/material';

export const Bulletpoints = styled(MuiBox)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  rowGap: 32,
  padding: 42,
  marginRight: 50,
  marginLeft: 50,
  backgroundColor: theme.palette.background.readable,
  borderRadius: theme.shape.borderRadius,
  color: theme.palette.common.black,
  [theme.breakpoints.down('md')]: {
    padding: '42px 18px',
    margin: 0,
    borderRadius: 0,
  },
}));
