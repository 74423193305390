import { styled, Box as MuiBox } from '@mui/material';

export const Gradient = styled(MuiBox)(({ theme }) => ({
  position: 'fixed',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'flex-end',
  backgroundImage: `linear-gradient(to top, ${theme.palette.background[900]}, transparent)`,
  left: '50%',
  height: 100,
  width: '100%',
  transform: 'translate(-50%, 0)',
  bottom: 0,
  paddingBottom: 50,
  zIndex: 99,
}));

export const Navigation = styled(MuiBox)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background[900],
  padding: 10,
  border: `1px solid ${theme.palette.divider}`,
}));
