import {
  Typography as MuiTypography,
  Box as MuiBox,
  useTheme,
} from '@mui/material';

import logo from '../../../../../../../../assets/images/logo.png';
import {
  Language,
  UserExamQuestionEvaluation,
} from '../../../../../../../../generated';
import {
  Label,
  QuestionSection,
  QuestionContainer,
  NumberContainer,
  FeedbackContainer,
  FeedbackHeader,
  AudioPlayerContainer,
  LogoContainer,
} from './styles';
import { AvatarAtom, TextInputAtom } from '../../../../../../atoms';
import { commonStrings } from '../../../../../../../../assets/strings/sv';
import { AudioPlayerMolecule } from '../../../../../../molecules';

interface IExamQuestionEvaluationComponent {
  examQuestion: UserExamQuestionEvaluation;
  number: number;
  langauge?: Language;
}

export const ExamQuestionEvaluationComponent = ({
  examQuestion,
  number,
  langauge,
}: IExamQuestionEvaluationComponent) => {
  const theme = useTheme();
  const { question, feedback, score, maxScore, userAnswer } = examQuestion;

  return (
    <QuestionContainer>
      <Label>
        <QuestionSection>
          <NumberContainer>
            <MuiTypography variant="body1">{number}</MuiTypography>
          </NumberContainer>
          <MuiTypography variant="body1">{question}</MuiTypography>
        </QuestionSection>
      </Label>

      <TextInputAtom
        variant="filled"
        multiline={true}
        value={userAnswer}
        onChange={() => {}}
        disabled
        sx={{
          '& .MuiInputBase-root': {
            display: 'flex',
            alignItems: 'start',
            flex: 1,
          },
        }}
      />

      <FeedbackContainer>
        <FeedbackHeader>
          <LogoContainer>
            <AvatarAtom src={logo} alt="qurie-logo" />
            <MuiTypography>{commonStrings.qurie}</MuiTypography>
          </LogoContainer>
          <AudioPlayerContainer>
            <AudioPlayerMolecule
              text={feedback}
              language={
                langauge ? langauge.name : commonStrings.defaultLanguage
              }
            />
          </AudioPlayerContainer>
        </FeedbackHeader>

        <MuiTypography>{feedback}</MuiTypography>
        <MuiBox display="flex" columnGap="5px">
          <MuiTypography color={theme.palette.primary[300]}>
            {score}
          </MuiTypography>
          <MuiTypography>
            / {maxScore} {commonStrings.points}
          </MuiTypography>
        </MuiBox>
      </FeedbackContainer>
    </QuestionContainer>
  );
};
