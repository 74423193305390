import { ApolloCache, DocumentNode } from '@apollo/client';

export const deleteItemFromCache = (
  cache: ApolloCache<any>,
  id: string | number,
  __typename: string
) => {
  return cache.evict({
    id: cache.identify({
      id,
      __typename,
    }),
  });
};

const getQueryDefinition = (
  query: QueryDefinition | NestedQueryDefinition,
  item: any,
  existingData: any
): any => {
  const definition = {
    [query.queryName]: {
      ...[
        ...existingData[query.queryName],
        ...(query.nestedQuery
          ? getQueryDefinition(
              query.nestedQuery,
              item,
              existingData[query.queryName]
            )
          : [item]),
      ],
    },
  };

  return definition;
};

interface QueryDefinition {
  query: DocumentNode;
  queryName: string;
  variables?: any;
  nestedQuery?: NestedQueryDefinition;
}

interface NestedQueryDefinition {
  queryName: string;
  nestedQuery?: NestedQueryDefinition;
}

export const addItemToCache = <TData>(
  cache: ApolloCache<any>,
  item: any,
  query: QueryDefinition
): void => {
  const existingData: any = cache.readQuery<TData>({
    query: query.query,
    variables: query.variables,
  });

  if (item && existingData) {
    const queryDefinition = getQueryDefinition(query, item, existingData);
    cache.writeQuery({
      query: query.query,
      data: queryDefinition,
    });
  }
};
