import { useEffect, useState } from 'react';
import _ from 'lodash';

import {
  ContentStatus,
  Status,
  useGetStudyMaterialLazyQuery,
} from '../../generated';
import { StudyMaterialStatusContext } from './context';

interface IStudyMaterialStatusProvider {
  children: React.ReactNode;
  id?: string;
}

type FlattenKeysToString<T extends object> = {
  [K in keyof T & string]: T[K] extends object
    ? `${K}.${FlattenKeysToString<T[K]>}`
    : K extends '__typename'
    ? never
    : `${K}`;
}[keyof T & string];

export const StudyMaterialStatusProvider = ({
  children,
  id,
}: IStudyMaterialStatusProvider) => {
  const [studyMaterialStatus, setStudyMaterialStatus] =
    useState<Partial<ContentStatus>>();

  const [_getStudyMaterial, { data }] = useGetStudyMaterialLazyQuery({
    fetchPolicy: 'network-only',
    pollInterval: 5000,
    onError: (error) => {
      console.error('Could not get study material', { error });
    },
  });

  useEffect(() => {
    if (id) {
      _getStudyMaterial({
        variables: {
          id,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (data) {
      setStudyMaterialStatus((prevState) => ({
        ...prevState,
        ...data.getStudyMaterial.status,
      }));
    }
  }, [data]);

  const updateStudyMaterialStatus = (
    updatedStatus: Partial<{
      [key in FlattenKeysToString<ContentStatus>]: Status;
    }>
  ) => {
    Object.keys(updatedStatus).map((path) => {
      const key = path as FlattenKeysToString<ContentStatus>;
      return setStudyMaterialStatus((prevStatus) =>
        _.set(prevStatus || {}, path, updatedStatus[key])
      );
    });
  };

  const config = {
    status: studyMaterialStatus,
    updateStudyMaterialStatus,
  };

  return (
    <StudyMaterialStatusContext.Provider value={config}>
      {children}
    </StudyMaterialStatusContext.Provider>
  );
};
