import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import {
  useGetUserStreakQuery,
  useUpdateUserStreakMutation,
} from '../../../../generated';
import {
  DrawerComponent,
  HeaderComponent,
  StreakModalComponent,
} from './components';
import { RootContainer } from './styles';

interface IPageNavigationOrganism {
  isHeaderVisible?: boolean;
  isDrawerPermanent?: boolean;
}

export const PageNavigationOrganism = ({
  isHeaderVisible = true,
  isDrawerPermanent = true,
}: IPageNavigationOrganism) => {
  const { isAuthenticated } = useAuth0();

  const [showStreakModal, setShowStreakModal] = useState(false);

  const { data: getUserStreakData } = useGetUserStreakQuery({
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (isAuthenticated) {
      handleUpdateStreak();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  const handleCloseStreakModal = () => {
    setShowStreakModal(false);
  };

  const [updateUserStreak] = useUpdateUserStreakMutation();

  const handleUpdateStreak = async () => {
    const { data } = await updateUserStreak({
      onError: (error) =>
        console.error('Could not update user streak', { error }),
    });

    if (!data) return;

    const { dailyStreak, daysSinceLastLogin } = data.updateUserStreak;

    if (dailyStreak && daysSinceLastLogin === 1) {
      setShowStreakModal(true);
    }
  };

  return (
    <RootContainer isHeaderVisible={isHeaderVisible}>
      {showStreakModal && (
        <StreakModalComponent
          showStreakModal={showStreakModal}
          handleCloseStreakModal={handleCloseStreakModal}
          streakNumber={getUserStreakData?.getUserStreak?.dailyStreak || 1}
        />
      )}
      <DrawerComponent isDrawerPermanent={isDrawerPermanent} />
      {isHeaderVisible && (
        <HeaderComponent
          streakNumber={getUserStreakData?.getUserStreak?.dailyStreak || 1}
        />
      )}
    </RootContainer>
  );
};
