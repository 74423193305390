import { SxProps, Typography } from '@mui/material';

interface IStatusMessage {
  text: string;
  sx?: SxProps;
  variant:
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'h6'
    | 'subtitle1'
    | 'subtitle2'
    | 'body1'
    | 'body1'
    | 'caption'
    | 'button'
    | 'overline'
    | 'inherit';
}

export const StatusMessage = ({ text, variant, sx }: IStatusMessage) => {
  return (
    <>
      <Typography variant={variant} sx={sx}>
        {text}
      </Typography>
    </>
  );
};
