import { Box as MuiBox, styled } from '@mui/material';

export const Container = styled(MuiBox)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  textAlign: 'center',
  flexDirection: 'column',
  padding: '25px 40px',
  [theme.breakpoints.down('sm')]: {
    padding: '10px 15px',
  },
}));

export const ContainerItem = styled(MuiBox)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  textAlign: 'center',
  alignItems: 'center',
  gap: '15px',
}));

