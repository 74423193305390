import { useMemo } from 'react';
import {
  Box as MuiBox,
  Typography as MuiTypography,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import { generateStudyMaterialPageStrings as pageStrings } from '../../../../../assets/strings/sv';
import { CreateStudyMaterialInput } from '../../../../../generated';
import { StudyMaterialDraft } from '../../types';
import { ImageUploadButton, StepperComponent } from './components';
import {
  ButtonAtom,
  SelectorAtom,
  TextInputAtom,
} from '../../../../library/atoms';
import { useSubjectContext } from '../../../../../hooks';

interface IGenerateMaterialTextFields {
  input: StudyMaterialDraft;
  handleImageToText: (base64Image: string) => Promise<void>;
  isImageAnalysisLoading: boolean;
  handleChange: (
    key: keyof CreateStudyMaterialInput,
    value: string | undefined
  ) => void;
  handleGenerateStudyMaterial: () => Promise<void>;
  draftStudyMaterials: StudyMaterialDraft[];
  fieldError: string;
  maxCharacterCount: number;
  minCharacterCount: number;
}

export const GenerateMaterialTextFields = ({
  input,
  handleImageToText,
  handleChange,
  isImageAnalysisLoading,
  draftStudyMaterials,
  fieldError,
  maxCharacterCount,
  minCharacterCount,
  handleGenerateStudyMaterial,
}: IGenerateMaterialTextFields) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const { subjects } = useSubjectContext();

  const steps = useMemo(
    () => [
      <TextInputAtom
        variant="outlined"
        placeholder="Namn på lektion"
        fullWidth
        value={input.title}
        onChange={(value) => handleChange('title', value)}
      />,
      <SelectorAtom
        value={input.subjectId}
        listItems={subjects}
        placeholder="Välj ett ämne"
        renderProperty="subject"
        comparisonValue="id"
        onSelectedItem={(id) => handleChange('subjectId', id)}
      />,
      <ButtonAtom
        variant="gradient"
        fullWidth
        disabled={!!!input.title || input.subjectId === undefined}
        text={
          draftStudyMaterials.length > 1 ? 'Skapa lektioner' : 'Skapa lektion'
        }
        onClick={handleGenerateStudyMaterial}
      />,
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [input, draftStudyMaterials, subjects]
  );

  const { textField } = pageStrings;
  return (
    <MuiBox
      display="flex"
      flexDirection={isMobile ? 'column' : 'row'}
      flex={1}
      minHeight={isMobile ? 'auto' : 0}
      gap={4}
    >
      <MuiBox display="flex" flexDirection="column" flex={1.5}>
        <MuiBox display="flex" flexDirection="column" flex={1} minHeight={0}>
          <TextInputAtom
            variant="outlined"
            fullWidth
            multiline
            inputProps={{ maxLength: maxCharacterCount }}
            placeholder={textField.placeholder}
            value={input.text}
            errorMessage={fieldError}
            onChange={(text) => handleChange('text', text)}
          />
        </MuiBox>
        <MuiBox
          marginTop={2}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <MuiBox>
            <MuiTypography
              style={{
                color:
                  input.text.length > maxCharacterCount ||
                  input.text.length < minCharacterCount
                    ? theme.palette.error.light
                    : 'unset',
              }}
              display="inline"
            >
              {input.text.length}{' '}
            </MuiTypography>
            <MuiTypography display="inline">
              {textField.numberOfCharactersPrefix} {maxCharacterCount}{' '}
              {textField.numberOfCharactersSuffix}
            </MuiTypography>
          </MuiBox>
          <ImageUploadButton
            handleImageToText={handleImageToText}
            isLoading={isImageAnalysisLoading}
          />
        </MuiBox>
      </MuiBox>
      <MuiBox
        flex={1}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <StepperComponent steps={steps} />
      </MuiBox>
    </MuiBox>
  );
};
