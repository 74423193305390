import { Skeleton as MuiSkeleton } from '@mui/material';

export const ModuleSkeletonComponent = () => {
  return (
    <MuiSkeleton
      animation="wave"
      variant={'rounded'}
      height={200}
      style={{
        borderRadius: 10,
      }}
    />
  );
};
