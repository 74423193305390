import { Box as MuiBox } from '@mui/material';

import streakIcon from '../../../images/streak-icon.png';
import { ContentContainer, IconContainer, StreakContainer } from './styles';

interface IStreakComponent {
  streakNumber: number;
}

export const StreakComponent = ({ streakNumber }: IStreakComponent) => {
  return (
    <StreakContainer>
      <ContentContainer>
        <MuiBox>{streakNumber}</MuiBox>
        <IconContainer>
          <img src={streakIcon} alt="Flame icon" />
        </IconContainer>
      </ContentContainer>
    </StreakContainer>
  );
};
