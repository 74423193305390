import React, { useState } from 'react';
import { Grid as MuiGrid, Typography, useTheme } from '@mui/material';

import { ButtonAtom, GradientBorderAtom } from '../../atoms';
import { commonStrings } from '../../../../assets/strings/sv';

interface IShareLinkMolecule {
  url: string;
  handleCollectGaStats: () => void;
}

export const ShareLinkMolecule = ({
  url,
  handleCollectGaStats,
}: IShareLinkMolecule) => {
  const theme = useTheme();
  const { shareButton } = commonStrings;
  const [shareButtonText, setShareButtonText] = useState<string>(
    shareButton.copyShareLink
  );

  const onClick = async () => {
    try {
      await navigator.clipboard.writeText(url);
      setShareButtonText(shareButton.copiedShareLink);
      handleCollectGaStats();
    } catch (error) {
      console.error('Failed to copy text to clipboard:', error);
    }
  };

  return (
    <GradientBorderAtom>
      <MuiGrid
        container
        padding="20px 12px"
        alignItems="center"
        justifyContent="space-between"
        spacing={2}
      >
        <MuiGrid item container xs overflow="hidden">
          <MuiGrid item overflow="hidden" xs>
            <Typography variant="body1" color={theme.palette.text.secondary}>
              {url}
            </Typography>
          </MuiGrid>
          <MuiGrid item xs="auto">
            <Typography variant="body1" color={theme.palette.text.secondary}>
              ...
            </Typography>
          </MuiGrid>
        </MuiGrid>
        <MuiGrid item xs="auto">
          <ButtonAtom
            variant="border"
            text={shareButtonText}
            onClick={onClick}
          />
        </MuiGrid>
      </MuiGrid>
    </GradientBorderAtom>
  );
};
