import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import {
  Box as MuiBox,
  Typography as MuiTypography,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import { ModalMolecule } from '../../../../../../../../../../library/molecules';
import { ButtonAtom } from '../../../../../../../../../../library/atoms';
import { useLocalStorageState } from '../../../../../../../../../../../hooks';
import { LocalStorageKeys } from '../../../../../../../../../../utils';
import { StudyMaterial } from '../../../../../../../../../../../generated';
import {
  sharedStudyMaterialPageStrings as pageStrings,
  commonStrings,
} from '../../../../../../../../../../../assets/strings/sv';

interface IAuthPromptComponent {
  studyMaterialId?: string;
  isOpen: boolean;
  handleClose: () => void;
}

export const AuthPromptComponent = ({
  isOpen,
  handleClose,
  studyMaterialId,
}: IAuthPromptComponent) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));
  const { loginWithRedirect } = useAuth0();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setPendingStudyMaterial] = useLocalStorageState<{
    id?: StudyMaterial['id'];
  }>(
    {
      id: studyMaterialId,
    },
    LocalStorageKeys.PENDING_STUDY_MATERIAL
  );

  const { login, register } = commonStrings;
  const { authPrompt } = pageStrings;

  const handleLogin = () => {
    setPendingStudyMaterial({ id: studyMaterialId });
    loginWithRedirect();
  };

  const handleRegister = () => {
    setPendingStudyMaterial({ id: studyMaterialId });
    loginWithRedirect({
      authorizationParams: {
        screen_hint: 'signup',
      },
    });
  };

  return (
    <ModalMolecule
      title={authPrompt.title}
      isOpen={isOpen}
      onClose={handleClose}
    >
      <MuiBox display="flex" flexDirection="column" rowGap="30px">
        <MuiTypography variant="body1">{authPrompt.description}</MuiTypography>
        <MuiBox
          display="flex"
          flexDirection={isDesktop ? 'row' : 'column'}
          columnGap="10px"
          rowGap="10px"
        >
          <ButtonAtom
            variant="border"
            text={login}
            sx={{ width: isDesktop ? 'unset' : '100%' }}
            onClick={handleLogin}
          />
          <ButtonAtom
            variant="primary"
            sx={{ width: isDesktop ? 'unset' : '100%' }}
            text={register}
            onClick={handleRegister}
          />
        </MuiBox>
      </MuiBox>
    </ModalMolecule>
  );
};
