import { Box as MuiBox, styled } from '@mui/material';

export const Hero = styled(MuiBox)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  columnGap: 24,
  minHeight: '65vh',
  justifyContent: 'space-between',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },
}));

export const TextSection = styled(MuiBox)(({ theme }) => ({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'left',
  rowGap: 18,
  marginTop: -200,
  [theme.breakpoints.down('md')]: {
    textAlign: 'center',
    marginTop: 0,
  },
}));
