import { PaletteOptions } from '@mui/material';

export const lightPalette: PaletteOptions = {
  mode: 'light',
  gradient: `linear-gradient(to left, #F699FE 20%, #A1FE99 80%)`,
  highlight: {
    paper: '#b7e0ed',
    regular: '#0c4b54',
  },
  primary: {
    main: '#86FB7F',
    '50': '#F0FFEE',
    '100': '#D9FFD4',
    '200': '#BFFFB8',
    '300': '#A1FE99',
    '400': '#86FB7F',
    '500': '#72F76B',
    '600': '#61E561',
    '700': '#48CF56',
    '800': '#30B94C',
    '900': '#00953B',
  },
  secondary: {
    main: '#ED6DF9',
    '50': '#FDE7FE',
    '100': '#F9C3FD',
    '200': '#F699FE',
    '300': '#ED6DF9',
    '400': '#E249EF',
    '500': '#D723E7',
    '600': '#C620E1',
    '700': '#B01CD9',
    '800': '#9C1BD3',
    '900': '#7718C6',
  },
  grey: {
    '50': '#F8F8F8',
    '100': '#E2E3E4',
    '200': '#CDCDD0',
    '300': '#B7B8BB',
    '400': '#A2A3A7',
    '500': '#828489',
    '600': '#64656A',
    '700': '#46474A',
    '800': '#28282A',
    '900': '#0A0A0B',
  },
  background: {
    '50': '#38393D',
    '100': '#35363A',
    '200': '#333438',
    '300': '#2E3033',
    '400': '#2C2E31',
    '500': '#27292C',
    '600': '#25272A',
    '700': '#232428',
    '800': '#1F2024',
    '900': '#131418',
    readable: '#F8F3EF',
  },
  common: {
    black: '#000000',
    white: '#ffffff',
  },
  divider: 'rgba(255,255,255,0.1)',
  text: {
    primary: '#ffffff',
    secondary: '#B7B8BB',
    disabled: '#828489',
  },
  warning: {
    main: '#FDC43A',
    '300': '#FDF17A',
    '700': '#FDC43A',
    '900': '#F58523',
  },
  error: {
    main: '#FF4343',
    '50': '#FFEAEE',
    '200': '#FD9695',
    '400': '#FF4343',
    '900': '#C7000B',
  },
  success: {
    main: '#A1FE99',
    '300': '#A1FE99',
    '900': '#00953B',
  },
};
