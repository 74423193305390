import { createContext } from 'react';

import { IRatingState, initialRatingState } from './state';
import { GARatingCategory } from '../usage';

const stub = (): never => {
  throw new Error('You forgot to wrap your component.');
};

interface IRatingContext extends IRatingState {
  handleChangeRating: (rating: number | null) => void;
  handleOpenPopup: (
    title: string,
    category: GARatingCategory,
    delaySeconds?: number
  ) => void;
  handleClosePopup: () => void;
}

const initialRatingContext: IRatingContext = {
  ...initialRatingState,
  handleChangeRating: stub,
  handleOpenPopup: stub,
  handleClosePopup: stub,
};

export const RatingContext = createContext(initialRatingContext);
