import { Box as MuiBox, styled } from '@mui/material';

export const ContentContainer = styled(MuiBox)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  rowGap: 2,
  padding: theme.spacing(2),
  backgroundColor: theme.palette.background[400],
  borderRadius: `${theme.shape.borderRadius}px`,
}));
