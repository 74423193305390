import { useEffect, useMemo, useState } from 'react';
import { Box as MuiBox, Typography as MuiTypography } from '@mui/material';
import _ from 'lodash';
import { ulid } from 'ulid';

import { commonStrings } from '../../../../assets/strings/sv';
import { AnalysisQuestion, Question, Status } from '../../../../generated';
import {
  AnalysisQuestionSectionComponent,
  QuestionHeaderComponent,
  QuestionSectionComponent,
} from './components';
import {
  State,
  UnsavedAnalysisQuestion,
  UnsavedQuestion,
  initialState,
} from './types';
import {
  useAnalysisQuestionContext,
  useQuestionContext,
  useStudyMaterialContext,
} from '../../../../hooks';
import { PaperConstraintAtom } from '../../atoms';

export interface IQuestionsOrganism {
  isEditModeAvailable?: boolean;
}

export type ContentMenuItem = {
  text: string;
};

export const QuestionsOrganism = ({
  isEditModeAvailable = false,
}: IQuestionsOrganism) => {
  const { createQuestions, updateQuestions, deleteQuestions, saveQuestions } =
    useQuestionContext();
  const {
    createAnalysisQuestions,
    updateAnalysisQuestions,
    deleteAnalysisQuestions,
    saveAnalysisQuestions,
  } = useAnalysisQuestionContext();
  const { studyMaterial } = useStudyMaterialContext();

  const { studyMaterialSection } = commonStrings;
  const { questionsSection } = studyMaterialSection;

  const menuItems: ContentMenuItem[] = [
    { text: questionsSection.allAnswers },
    { text: questionsSection.questions },
    { text: questionsSection.analysisQuestions },
  ];

  const [isEditModeActive, setIsEditModeActive] = useState<boolean>(false);
  const [questions, setQuestions] = useState(studyMaterial?.questions || []);
  const [analysisQuestions, setAnalysisQuestions] = useState(
    studyMaterial?.analysisQuestions || []
  );

  const [selectedMenuItem, setSelectedMenuItem] = useState(menuItems[0]);
  const [state, setState] = useState<State>(initialState);

  useEffect(() => {
    setQuestions(studyMaterial?.questions || []);
    setAnalysisQuestions(studyMaterial?.analysisQuestions || []);
  }, [studyMaterial]);

  const validateQuestions = () => {
    const questionErrors = _questions.map((question) => {
      const validation = {
        question: '',
        answer: '',
      };

      validation.answer =
        !question.answer && questionsSection.errorMessages
          ? questionsSection.errorMessages?.answer
          : '';
      validation.question =
        !question.question && questionsSection.errorMessages
          ? questionsSection.errorMessages?.question
          : '';
      return validation;
    });

    setState((prevState) => ({
      ...prevState,
      questionsFieldErrors: questionErrors,
    }));

    const questionsValidation = questionErrors.every(
      (validation) => !validation.answer && !validation.question
    );

    return questionsValidation;
  };

  const validateAnalysisQuestions = () => {
    const analysisErrors = _analysisQuestions.map((question) => {
      const analysisValidation = {
        question: '',
      };

      analysisValidation.question =
        !question.question && questionsSection.errorMessages
          ? questionsSection.errorMessages?.question
          : '';
      return analysisValidation;
    });

    setState((prevState) => ({
      ...prevState,
      analysisQuestionsFieldErrors: analysisErrors,
    }));

    const analysisQuestionsValidation = analysisErrors.every(
      (validation) => !validation.question
    );

    return analysisQuestionsValidation;
  };

  const _questions = useMemo(() => {
    return [
      ...questions.filter(
        (question) =>
          !state.deletedQuestions.some(
            (deletedQuestion) => deletedQuestion.id === question.id
          )
      ),
      ...state.unsavedQuestions,
    ];
  }, [questions, state.deletedQuestions, state.unsavedQuestions]);

  const _analysisQuestions = useMemo(() => {
    return [
      ...analysisQuestions.filter(
        (question) =>
          !state.deletedAnalysisQuestions.some(
            (deletedAnalysisQuestion) =>
              deletedAnalysisQuestion.id === question.id
          )
      ),
      ...state.unsavedAnalysisQuestions,
    ];
  }, [
    analysisQuestions,
    state.deletedAnalysisQuestions,
    state.unsavedAnalysisQuestions,
  ]);

  const handleDeleteAnalysisQuestion = (
    question: AnalysisQuestion | UnsavedAnalysisQuestion
  ) => {
    const q = question as AnalysisQuestion | UnsavedQuestion;
    if ('id' in q) {
      setState((prevState) => ({
        ...prevState,
        deletedAnalysisQuestions: [...prevState.deletedAnalysisQuestions, q],
      }));
    } else if ('_id' in q) {
      setState((prevState) => ({
        ...prevState,
        unsavedAnalysisQuestions: prevState.unsavedAnalysisQuestions.filter(
          (prevQuestion) => prevQuestion._id !== q._id
        ),
      }));
    }
  };

  const handleDeleteQuestion = (question: Question | UnsavedQuestion) => {
    const q = question as Question | UnsavedQuestion;
    if ('id' in q) {
      setState((prevState) => ({
        ...prevState,
        deletedQuestions: [...prevState.deletedQuestions, q],
      }));
    } else if ('_id' in q) {
      setState((prevState) => ({
        ...prevState,
        unsavedQuestions: prevState.unsavedQuestions.filter(
          (prevQuestion) => prevQuestion._id !== q._id
        ),
      }));
    }
  };

  const handleAddAnalysisQuestion = () => {
    const newQuestionItem: UnsavedAnalysisQuestion = {
      question: '',
      _id: ulid(),
    };

    setState((prevState) => ({
      ...prevState,
      unsavedAnalysisQuestions: [
        ...prevState.unsavedAnalysisQuestions,
        newQuestionItem,
      ],
    }));
  };

  const handleAddQuestion = () => {
    const newQuestion: UnsavedQuestion = {
      question: '',
      answer: '',
      _id: ulid(),
    };

    setState((prevState) => ({
      ...prevState,
      unsavedQuestions: [...prevState.unsavedQuestions, newQuestion],
    }));
  };

  const handleChangeQuestion = (question: Question | UnsavedQuestion) => {
    setState((prevState) => ({
      ...prevState,
      questionsfieldErrors: [],
    }));
    if ('id' in question) {
      setQuestions((prevState) =>
        prevState.map((q) => (q.id === question.id ? question : q))
      );
    } else if ('_id' in question) {
      setState((prevState) => ({
        ...prevState,
        unsavedQuestions: prevState.unsavedQuestions.map((prevQuestion) =>
          prevQuestion._id === question._id ? question : prevQuestion
        ),
      }));
    }
  };

  const handleChangeAnalysisQuestion = (
    question: AnalysisQuestion | UnsavedAnalysisQuestion
  ) => {
    setState((prevState) => ({
      ...prevState,
      analysisQuestionsFieldErrors: [],
    }));

    if ('id' in question) {
      setAnalysisQuestions((prevState) =>
        prevState.map((q) => (q.id === question.id ? question : q))
      );
    } else if ('_id' in question) {
      setState((prevState) => ({
        ...prevState,
        unsavedAnalysisQuestions: prevState.unsavedAnalysisQuestions.map(
          (prevQuestion) =>
            prevQuestion._id === question._id ? question : prevQuestion
        ),
      }));
    }
  };

  const filterUnchangedQuestions = (questions: Question[]) => {
    return questions.filter(
      (question) =>
        !studyMaterial?.questions?.some((prevQuestion) =>
          _.isEqual(question, prevQuestion)
        )
    );
  };

  const filterUnchangedAnalysisQuestions = (questions: AnalysisQuestion[]) => {
    return questions.filter(
      (question) =>
        !studyMaterial?.analysisQuestions?.some((prevQuestion) =>
          _.isEqual(question, prevQuestion)
        )
    );
  };

  const handleConfirmEditAllQuestions = async () => {
    const questionsValid = validateQuestions();
    const analysisQuestionsValid = validateAnalysisQuestions();

    if (
      (!questionsValid && !analysisQuestionsValid) ||
      !questionsValid ||
      !analysisQuestionsValid ||
      !studyMaterial
    ) {
      return;
    }

    const changedQuestions = filterUnchangedQuestions(questions);
    const changedAnalysisQuestions =
      filterUnchangedAnalysisQuestions(analysisQuestions);

    changedQuestions.length && updateQuestions(changedQuestions);
    changedAnalysisQuestions.length &&
      updateAnalysisQuestions(changedAnalysisQuestions);
    state.deletedAnalysisQuestions.length &&
      deleteAnalysisQuestions(state.deletedAnalysisQuestions);
    state.deletedQuestions.length && deleteQuestions(state.deletedQuestions);
    state.unsavedQuestions.length &&
      saveQuestions({
        studyMaterialId: studyMaterial.id,
        questions: state.unsavedQuestions.map((question) =>
          _.omit(question, '_id')
        ),
      });
    state.unsavedAnalysisQuestions.length &&
      saveAnalysisQuestions({
        studyMaterialId: studyMaterial.id,
        questions: state.unsavedAnalysisQuestions.map((analysisQuestion) =>
          _.omit(analysisQuestion, '_id')
        ),
      });

    handleCancelEditMode();
  };

  const handleActivateEditMode = () => {
    setIsEditModeActive(true);
  };

  const handleCancelEditMode = () => {
    setState(initialState);
    setQuestions(studyMaterial?.questions || []);
    setAnalysisQuestions(studyMaterial?.analysisQuestions || []);
    setIsEditModeActive(false);
  };

  const handleCreateAnalysisQuestions = () => {
    if (!studyMaterial) return;

    const { id, language, text } = studyMaterial;
    createAnalysisQuestions({ studyMaterialId: id, language, text });
  };

  const handleCreateQuestions = () => {
    if (!studyMaterial) return;

    const { id, language, text } = studyMaterial;
    createQuestions({ studyMaterialId: id, language, text });
  };

  const renderMenuItem = (menuItem: ContentMenuItem) => {
    switch (menuItem.text) {
      case questionsSection.questions:
        return (
          <QuestionSectionComponent
            questions={_questions}
            language={studyMaterial?.language}
            status={studyMaterial?.status.questions || Status.Pending}
            failureReason={studyMaterial?.failureReason}
            isEditModeActive={isEditModeActive}
            fieldErrors={state.questionsFieldErrors}
            handleChangeQuestion={handleChangeQuestion}
            handleDeleteQuestion={handleDeleteQuestion}
            handleAddQuestion={handleAddQuestion}
            handleCreateQuestions={handleCreateQuestions}
          />
        );
      case questionsSection.analysisQuestions:
        return (
          <AnalysisQuestionSectionComponent
            analysisQuestions={_analysisQuestions}
            language={studyMaterial?.language}
            fieldErrors={state.analysisQuestionsFieldErrors}
            status={studyMaterial?.status.analysisQuestions || Status.Pending}
            failureReason={studyMaterial?.failureReason}
            isEditModeActive={isEditModeActive}
            handleChangeAnalysisQuestion={handleChangeAnalysisQuestion}
            handleDeleteAnalysisQuestion={handleDeleteAnalysisQuestion}
            handleAddAnalysisQuestion={handleAddAnalysisQuestion}
            handleCreateAnalysisQuestions={handleCreateAnalysisQuestions}
          />
        );
      default:
        return (
          <>
            <AnalysisQuestionSectionComponent
              analysisQuestions={_analysisQuestions}
              language={studyMaterial?.language}
              fieldErrors={state.analysisQuestionsFieldErrors}
              status={studyMaterial?.status.analysisQuestions || Status.Pending}
              failureReason={studyMaterial?.failureReason}
              isEditModeActive={isEditModeActive}
              handleChangeAnalysisQuestion={handleChangeAnalysisQuestion}
              handleDeleteAnalysisQuestion={handleDeleteAnalysisQuestion}
              handleAddAnalysisQuestion={handleAddAnalysisQuestion}
              handleCreateAnalysisQuestions={handleCreateAnalysisQuestions}
            />
            <QuestionSectionComponent
              questions={_questions}
              language={studyMaterial?.language}
              status={studyMaterial?.status.questions || Status.Pending}
              failureReason={studyMaterial?.failureReason}
              isEditModeActive={isEditModeActive}
              fieldErrors={state.questionsFieldErrors}
              handleChangeQuestion={handleChangeQuestion}
              handleDeleteQuestion={handleDeleteQuestion}
              handleAddQuestion={handleAddQuestion}
              handleCreateQuestions={handleCreateQuestions}
            />
          </>
        );
    }
  };

  return (
    <PaperConstraintAtom>
      <MuiBox width="100%" display="flex" flexDirection="column" gap={6}>
        <QuestionHeaderComponent
          menuItems={menuItems}
          selectedMenuItem={selectedMenuItem}
          isEditModeAvailable={isEditModeAvailable}
          isEditModeActive={isEditModeActive}
          handleChangeSelectedMenuItem={(item) => setSelectedMenuItem(item)}
          handleActivateEditMode={handleActivateEditMode}
          handleCancelEditMode={handleCancelEditMode}
          handleConfirm={handleConfirmEditAllQuestions}
        />
        {renderMenuItem(selectedMenuItem)}
      </MuiBox>
      <MuiBox marginTop={3}>
        <MuiTypography color="grey" variant="subtitle2" textAlign="center">
          {commonStrings.disclaimer}
        </MuiTypography>
      </MuiBox>
    </PaperConstraintAtom>
  );
};
