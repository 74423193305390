import { Box as MuiBox, styled } from '@mui/material';

export const AnalysisQuestion = styled(MuiBox)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: 16,
  padding: 16,
  backgroundColor: theme.palette.background[800],
  borderRadius: theme.shape.borderRadius,
}));
