import { Box as MuiBox, styled } from '@mui/material';

export const Gallery = styled(MuiBox)(({ theme }) => ({
  width: '100%',
  paddingRight: 150,
  paddingLeft: 150,
  [theme.breakpoints.down('md')]: {
    paddingLeft: 0,
    paddingRight: 0,
  },
}));
