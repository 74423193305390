import {
  SpeakerAudioDestination,
  AudioConfig,
} from 'microsoft-cognitiveservices-speech-sdk';
import * as SpeechSDK from 'microsoft-cognitiveservices-speech-sdk';

export class AudioConfiguration {
  audioConfig: AudioConfig;
  constructor(player: SpeakerAudioDestination) {
    this.audioConfig = SpeechSDK.AudioConfig.fromSpeakerOutput(player);
  }
}
