import React from 'react';
import {
  Divider,
  Box as MuiBox,
  Typography as MuiTypography,
} from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';

import { ButtonAtom, PaperConstraintAtom } from '../../../../atoms';
import { QuizEditModalComponent, QuizSelectorComponent } from './components';
import { commonStrings } from '../../../../../../assets/strings/sv';
import { Container, InnerContainer } from './styles';
import {
  ContentStatus,
  GetStudyMaterialQuery,
  QuizType,
  Status,
} from '../../../../../../generated';
import { quizTypeToString } from '../../../../../helpers';

interface IQuizIntroComponent {
  quiz?: GetStudyMaterialQuery['getStudyMaterial']['quiz'];
  userId?: string | null;
  selectedQuizType: QuizType;
  status?: ContentStatus['quiz'];
  selectedAmountOfQuestions: number;
  handleStartQuiz: () => void;
  setSelectedQuizType: React.Dispatch<React.SetStateAction<QuizType>>;
  setSelectedAmountOfQuestions: React.Dispatch<React.SetStateAction<number>>;
}

export const QuizIntroComponent = ({
  quiz,
  userId,
  selectedQuizType,
  status,
  selectedAmountOfQuestions,
  handleStartQuiz,
  setSelectedQuizType,
  setSelectedAmountOfQuestions,
}: IQuizIntroComponent) => {
  const { isAuthenticated, user } = useAuth0();
  const { quizSection } = commonStrings.studyMaterialSection;

  const isStartQuizDisabled = () => {
    return (
      (status?.multipleChoices === Status.Pending &&
        status?.statements === Status.Pending) ||
      status?.[quizTypeToString[selectedQuizType]] === Status.Pending
    );
  };

  return (
    <PaperConstraintAtom>
      <Container>
        <InnerContainer>
          <MuiBox
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            gap={4}
          >
            <MuiTypography variant="h6">{quizSection.title}</MuiTypography>
            {isAuthenticated && user?.sub === userId && quiz && (
              <QuizEditModalComponent quiz={quiz} />
            )}
          </MuiBox>
          <MuiBox
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            gap={4}
          >
            <MuiTypography variant="body1">
              {quizSection.amountOfQuestionsSection.heading}
            </MuiTypography>
            <MuiBox display="flex" gap={1}>
              {quizSection.amountOfQuestionsSection.amountOfQuestionsItems.map(
                (item, index) => (
                  <ButtonAtom
                    variant={
                      selectedAmountOfQuestions === item ? 'primary' : 'border'
                    }
                    key={index}
                    text={JSON.stringify(item)}
                    onClick={() => setSelectedAmountOfQuestions(item)}
                    sx={{ height: 32, width: 30, minWidth: 0 }}
                  />
                )
              )}
            </MuiBox>
          </MuiBox>
          <Divider />
          <QuizSelectorComponent
            status={status}
            selectedQuizType={selectedQuizType}
            handleSelectQuizType={setSelectedQuizType}
          />
          <ButtonAtom
            variant="gradient"
            disabled={isStartQuizDisabled()}
            text={commonStrings.start}
            onClick={handleStartQuiz}
          />
        </InnerContainer>
      </Container>
      <MuiBox marginTop={3}>
        <MuiTypography color="grey" variant="subtitle2" textAlign="center">
          {commonStrings.disclaimer}
        </MuiTypography>
      </MuiBox>
    </PaperConstraintAtom>
  );
};
