import { useEffect, useState } from 'react';

import { useLocalStorageState } from '../../../../../hooks';
import { getDaysSinceLastVisit, getTodayUnix } from '../../../../helpers';
import { LocalStorageKeys } from '../../../../utils';
import { LoginBenefitsSnackbar } from './components';

export const LoginBenefitsComponent = () => {
  const [open, setOpen] = useState(false);
  const [lastVisitNotAuth, setLastVisitNotAuth] = useLocalStorageState<
    number | null
  >(null, LocalStorageKeys.LAST_VISIT_NOT_AUTH);

  const handleSnackbarOnVisit = () => {
    const today = getTodayUnix();

    if (!lastVisitNotAuth) {
      displaySnackbar();
    } else {
      const daysSinceLastVisit = getDaysSinceLastVisit(lastVisitNotAuth);

      if (daysSinceLastVisit >= 1) {
        displaySnackbar();
      }
    }
    setLastVisitNotAuth(today);
  };

  const displaySnackbar = () => {
    // Sets a timer for non-logged-in users - the snackbar appears after two minutes.
    const snackbarTimer = 120_000;

    const timer = setTimeout(() => {
      setOpen(true);
    }, snackbarTimer);

    return () => clearTimeout(timer);
  };

  useEffect(() => {
    handleSnackbarOnVisit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  return <LoginBenefitsSnackbar open={open} onClose={handleClose} />;
};
