import {
  CreateSummaryInput,
  Status,
  UpdateSummaryInput,
  useCreateSummaryMutation,
  useUpdateSummaryMutation,
} from '../../generated';
import { useStudyMaterialStatusContext } from '../../hooks';
import { SummaryContext } from './context';

interface ISummaryProvider {
  children: React.ReactNode;
}

export const SummaryProvider = ({ children }: ISummaryProvider) => {
  const { updateStudyMaterialStatus } = useStudyMaterialStatusContext();

  const [_createSummary] = useCreateSummaryMutation();
  const [_updateSummary] = useUpdateSummaryMutation();

  const createSummary = async (input: CreateSummaryInput) => {
    updateStudyMaterialStatus({ summary: Status.Pending });
    await _createSummary({
      variables: {
        input,
      },
      onError: (error) => {
        console.error('Could not create summary', { error });
      },
    });
  };

  const updateSummary = async (id: string, update: UpdateSummaryInput) => {
    await _updateSummary({
      variables: {
        updateSummaryId: id,
        update,
      },
      onError: (error) => {
        console.error('Could not update summary', { error });
      },
    });
  };

  const config = {
    createSummary,
    updateSummary,
  };

  return (
    <SummaryContext.Provider value={config}>{children}</SummaryContext.Provider>
  );
};
