import { Box as MuiBox, styled } from '@mui/material';

export const Icon = styled(MuiBox)<{
  color?: string;
  backgroundColor?: string;
  borderColor?: string;
}>(({ theme, color, backgroundColor, borderColor }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: 4,
  width: 'fit-content',
  borderRadius: 100,
  border: borderColor ? `3px solid ${borderColor}` : undefined,
  color: color ?? theme.palette.text.primary,
  backgroundColor: backgroundColor ?? 'transparent',
}));
