import { Box as MuiBox, styled } from '@mui/material';

export const StreakContainer = styled(MuiBox)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  width: 'auto',
  padding: '0 12px',
  height: 40,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: theme.shape.borderRadius,
  rowGap: 10,
  position: 'relative',
}));

export const ContentContainer = styled(MuiBox)(({ theme }) => ({
  display: 'flex',
  gap: '8px',
}));

export const IconContainer = styled(MuiBox)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
}));
