import { Box as MuiBox, styled } from '@mui/material';

export const QuestionContainer = styled(MuiBox)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  rowGap: 16,
  padding: 24,
  backgroundColor: theme.palette.background[800],
  borderRadius: theme.shape.borderRadius,
  border: `1px solid ${theme.palette.divider}`,
}));

export const Label = styled(MuiBox)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  columnGap: '75px',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column-reverse',
    columnGap: 0,
    rowGap: '5px',
  },
}));

export const NumberContainer = styled(MuiBox)(({ theme }) => ({
  backgroundColor: theme.palette.background[900],
  color: theme.palette.common.white,
  height: 'fit-content',
  padding: '0 12px',
  borderRadius: theme.shape.borderRadius,
}));

export const QuestionSection = styled(MuiBox)(({ theme }) => ({
  display: 'flex',
  columnGap: '10px',
}));

export const FeedbackContainer = styled(MuiBox)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  border: `1px solid ${theme.palette.secondary[200]}`,
  backgroundColor: theme.palette.background[900],
  borderRadius: theme.shape.borderRadius,
  padding: 16,
  rowGap: 16,
}));

export const FeedbackHeader = styled(MuiBox)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
}));

export const LogoContainer = styled(MuiBox)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  columnGap: 8,
}));

export const AudioPlayerContainer = styled(MuiBox)(({ theme }) => ({
  marginLeft: 2,
}));
