import { Typography as MuiTypography } from '@mui/material';
import { CloseOutlined } from '@mui/icons-material';

import backgroundImage from '../../../../assets/images/backgroundImage.svg';
import { IconButtonAtom } from '../../atoms';
import {
  ButtonContainer,
  ChildContainer,
  HeaderContainer,
  Modal,
  ModalContent,
} from './styles';

interface IFullscreenModalMolecule {
  isOpen: boolean;
  title: string;
  headerElement?: JSX.Element;
  handleClose: () => void;
  children?: React.ReactNode;
}
export const FullscreenModalMolecule = ({
  isOpen,
  title,
  headerElement,
  handleClose,
  children,
}: IFullscreenModalMolecule) => {
  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      backgroundImage={backgroundImage}
      hideBackdrop
    >
      <ModalContent>
        <HeaderContainer>
          <MuiTypography variant="subtitle2">{title}</MuiTypography>
          <ButtonContainer>
            {headerElement && headerElement}
            <IconButtonAtom
              onClick={handleClose}
              icon={<CloseOutlined />}
              shape="round"
            />
          </ButtonContainer>
        </HeaderContainer>
        <ChildContainer>{children}</ChildContainer>
      </ModalContent>
    </Modal>
  );
};
