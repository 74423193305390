import { styled, Box as MuiBox } from '@mui/material';

export const Container = styled(MuiBox)(({ theme }) => ({
  display: 'flex',
  overflowX: 'hidden',
  width: '100%',
}));

export const CardContainer = styled(MuiBox)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  flex: '0 0 100%',
  width: '100%',
  transition: 'margin-left 0.5s ease-in-out',
}));
