import React from 'react';
import {
  Typography as MuiTypography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { AutoAwesome } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

import {
  commonStrings,
  dashboardPageStrings as pageStrings,
} from '../../../../../assets/strings/sv';
import { ButtonAtom } from '../../../../library/atoms';
import { Container, CenteredBox, ButtonBox, GradientText } from './styles';

interface IWelcomeSectionComponent {
  handleOpenStarterGuideModal: () => void;
}

export const WelcomeSectionComponent = ({
  handleOpenStarterGuideModal,
}: IWelcomeSectionComponent) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));

  const handleNavigateToGenerateStudyMaterialPage = () => {
    navigate('/generate');
  };

  return (
    <Container>
      <CenteredBox>
        <MuiTypography variant="h5">
          {pageStrings.welcomeSection.title}{' '}
          <GradientText component={'span'}>
            {pageStrings.welcomeSection.url}
          </GradientText>
        </MuiTypography>
        <MuiTypography variant="body1" color={theme.palette.grey['400']}>
          {pageStrings.welcomeSection.text.auth}
        </MuiTypography>
      </CenteredBox>
      <ButtonBox>
        <ButtonAtom
          variant="gradient"
          endIcon={<AutoAwesome />}
          text={
            isDesktop ? commonStrings.createStudyMaterial : commonStrings.create
          }
          onClick={handleNavigateToGenerateStudyMaterialPage}
        />
        <ButtonAtom
          variant="primary"
          text={pageStrings.howDoIdo}
          sx={{ minWidth: '100%' }}
          onClick={handleOpenStarterGuideModal}
        />
      </ButtonBox>
    </Container>
  );
};
