import {
  SpeakerAudioDestination,
  SpeechSynthesizer,
} from 'microsoft-cognitiveservices-speech-sdk';
import * as SpeechSDK from 'microsoft-cognitiveservices-speech-sdk';

import { AudioConfiguration } from './audioConfig';
import { SpeechConfiguration } from './speechConfig';

export class TextToSpeechAudioPlayer {
  private audioConfig: AudioConfiguration;
  private speechConfig: SpeechConfiguration;
  player: SpeakerAudioDestination;
  synthesizer: SpeechSynthesizer;

  constructor(language: string) {
    this.player = new SpeakerAudioDestination();
    this.audioConfig = new AudioConfiguration(this.player);
    this.speechConfig = new SpeechConfiguration(language);
    this.synthesizer = new SpeechSDK.SpeechSynthesizer(
      this.speechConfig.speechConfig,
      this.audioConfig.audioConfig
    );
  }

  play = (texts: string[]) => {
    this.synthesizeSpeech(texts);
  };

  pause = () => {
    this.player.pause();
  };

  resume = () => {
    this.player.resume();
  };

  private synthesizeSpeech = async (texts: string[]) => {
    await Promise.all(
      texts.map((text) => {
        return new Promise((resolve) => {
          return this.synthesizer.speakTextAsync(
            `${text}`,
            (result) => {
              resolve(result.audioData);
            },
            this.error_callback
          );
        });
      })
    );
    this.synthesizer.close();
  };

  private error_callback = (error: string) => {
    console.log('error_callback result', error);
  };
}
