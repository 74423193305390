import { SpeechSynthesisWordBoundaryEventArgs } from 'microsoft-cognitiveservices-speech-sdk';

import { ISentenceBoundary } from '../types';

export const formatSentenceBoundary = (
  wordBoundaryEventArgs: SpeechSynthesisWordBoundaryEventArgs,
  sentenceBoundaries: ISentenceBoundary[],
  sentences: string[],
  textToAudioOffsetMillisec: number
) => {
  const currentSentenceIndex = sentenceBoundaries.length;
  const currentSentence = sentences[currentSentenceIndex];
  const sentenceEndIndex = currentSentence.length;
  const prevSentence = sentenceBoundaries[sentenceBoundaries.length - 1];
  const sentenceDuration =
    (wordBoundaryEventArgs.duration + wordBoundaryEventArgs.audioOffset) /
      10000 +
    textToAudioOffsetMillisec;

  const sentenceBoundary: ISentenceBoundary = {
    ...wordBoundaryEventArgs,
    endIndex: sentenceEndIndex + (prevSentence?.endIndex || 0),
    millisecDuration: sentenceDuration,
  };

  return sentenceBoundary;
};
