import { useEffect, useMemo, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import { Status, UserExamAnswer } from '../../../../generated';
import {
  ErrorStatusContainerMolecule,
  FullscreenModalMolecule,
  StatusContainerMolecule,
} from '../../molecules';
import {
  ExamEvaluationComponent,
  ExamComponent,
  ExamIntroComponent,
} from './components';
import { commonStrings } from '../../../../assets/strings/sv';
import {
  useExamContext,
  useLocalStorageState,
  useStudyMaterialContext,
} from '../../../../hooks';
import { LocalStorageKeys } from '../../../utils';
import { Root } from './styles';
import { GradientLayout } from '../../../layout';

enum Mark {
  A = 'A',
  C = 'C',
  E = 'E',
}

type MarkData = {
  percentage: number;
};

export type EvaluationConfig = { [key in Mark]: MarkData };

interface IExamOrganism {
  isEditModeAvailable?: boolean;
}

export const ExamOrganism = ({ isEditModeAvailable }: IExamOrganism) => {
  const { isAuthenticated } = useAuth0();
  const { createExam, createExamEvaluation } = useExamContext();
  const { studyMaterial } = useStudyMaterialContext();

  const [userExamAnswers, setUserExamAnswers] = useLocalStorageState<
    UserExamAnswer[]
  >([], `${LocalStorageKeys.EXAM_ANSWERS}${studyMaterial?.exam?.id}`);
  const [isExamModalOpen, setIsExamModalOpen] = useState(false);
  const [isExamEvaluationModalOpen, setIsExamEvaluationModalOpen] =
    useState(false);

  const evaluationConfig: EvaluationConfig = {
    [Mark.E]: {
      percentage: 40,
    },
    [Mark.C]: {
      percentage: 60,
    },
    [Mark.A]: {
      percentage: 80,
    },
  };
  const { statusMessages, studyMaterialSection } = commonStrings;

  const lastestEvaluationAnswersEqualExamAnswers = useMemo(
    () =>
      studyMaterial?.exam?.evaluations?.[0]?.questions?.every((question) =>
        userExamAnswers.some(
          ({ userAnswer }) => userAnswer === question.userAnswer
        )
      ),
    [userExamAnswers, studyMaterial?.exam]
  );

  useEffect(() => {
    if (lastestEvaluationAnswersEqualExamAnswers) {
      // exam answers have been successfully evaluated, clear exam answer state
      setUserExamAnswers([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    studyMaterial?.exam?.evaluations,
    lastestEvaluationAnswersEqualExamAnswers,
  ]);

  const handleChangeAnswer = (answer: UserExamAnswer) => {
    const prevAnswerIndex = userExamAnswers.findIndex(
      (prevAnswer) => prevAnswer.examQuestionId === answer.examQuestionId
    );

    if (prevAnswerIndex === -1) {
      setUserExamAnswers((prevAnswers) => [...prevAnswers, answer]);
    } else {
      const updatedAnswers = [...userExamAnswers];
      updatedAnswers[prevAnswerIndex] = answer;
      setUserExamAnswers(updatedAnswers);
    }
  };

  const handleOpenExamEvaluationModal = () => {
    setIsExamEvaluationModalOpen(true);
  };

  const handleStartExam = () => {
    if (!studyMaterial) return;

    const failedExamCreation =
      studyMaterial.status.exam === Status.Failed ||
      (studyMaterial.status.exam === Status.Completed &&
        !studyMaterial?.exam?.questions?.length);

    if (failedExamCreation) {
      const { id, language, text } = studyMaterial;
      createExam({ studyMaterialId: id, language, text });
    }

    setIsExamModalOpen(true);
  };

  const handleCloseModals = () => {
    setIsExamModalOpen(false);
    setIsExamEvaluationModalOpen(false);
  };

  const handleSubmitExam = () => {
    if (!isAuthenticated || !studyMaterial || !studyMaterial?.exam) return;
    const { exam, id } = studyMaterial;

    // filter out saved answers for non existing (deleted) questions
    const filteredUserAnswers = userExamAnswers.filter((a) =>
      exam?.questions?.some((q) => a.examQuestionId === q.id)
    );

    createExamEvaluation({
      studyMaterialId: id,
      examId: exam.id,
      userExamAnswers: filteredUserAnswers,
    });
    setIsExamEvaluationModalOpen(true);
  };

  if (studyMaterial?.status.exam === Status.Pending)
    return (
      <Root>
        <StatusContainerMolecule
          statusMessage={statusMessages.exam.create.pending}
          descriptionText={statusMessages.exam.create.descriptionText}
        />
      </Root>
    );

  if (isExamEvaluationModalOpen)
    return (
      <FullscreenModalMolecule
        isOpen={isExamEvaluationModalOpen}
        handleClose={handleCloseModals}
        title={studyMaterial?.title || ''}
      >
        {studyMaterial?.exam?.evaluations?.[0]?.status === Status.Completed ? (
          <ExamEvaluationComponent
            exam={studyMaterial?.exam}
            evaluationConfig={evaluationConfig}
            language={studyMaterial?.language}
          />
        ) : studyMaterial?.exam?.evaluations?.[0]?.status === Status.Failed ? (
          <ErrorStatusContainerMolecule
            title={studyMaterialSection.unknownErrorTitle}
            description={statusMessages.examEvaluation.create.error}
          />
        ) : (
          <StatusContainerMolecule
            statusMessage={statusMessages.examEvaluation.create.pending}
            descriptionText={
              statusMessages.examEvaluation.create.descriptionText
            }
          />
        )}
      </FullscreenModalMolecule>
    );

  if (isExamModalOpen)
    return (
      <FullscreenModalMolecule
        isOpen={isExamModalOpen}
        handleClose={handleCloseModals}
        title={studyMaterial?.title || ''}
      >
        <ExamComponent
          exam={studyMaterial?.exam}
          userExamAnswers={userExamAnswers}
          handleChangeAnswer={handleChangeAnswer}
          handleSubmitExam={handleSubmitExam}
          language={studyMaterial?.language}
          isEditModeAvailable={isEditModeAvailable}
        />
      </FullscreenModalMolecule>
    );

  return (
    <Root>
      <GradientLayout>
        <ExamIntroComponent
          exam={studyMaterial?.exam}
          evaluationConfig={evaluationConfig}
          handleOpenExamModal={handleStartExam}
          handleOpenEvaluationModal={handleOpenExamEvaluationModal}
        />
      </GradientLayout>
    </Root>
  );
};
