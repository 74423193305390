import { Box as MuiBox, styled } from '@mui/material';

export const Container = styled(MuiBox)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  justifyContent: 'center',
  textAlign: 'center',
  width: 'fit-content',
  margin: 'unset auto',
  gap: 16,
}));

export const InnerContainer = styled(MuiBox)(({ theme }) => ({
  backgroundColor: theme.palette.background[800],
  flexGrow: 1,
  flexBasis: 0,
  borderRadius: '16px',
  padding: '24px',
  maxWidth: '495px',
  alignSelf: 'center',
  overflowX: 'auto',
  [theme.breakpoints.down('sm')]: {
    padding: '16px',
  },
}));

export const ScoreContainer = styled(MuiBox)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

export const PointsContainer = styled(MuiBox)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
}));

export const PointsContent = styled(MuiBox)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  background: theme.palette.background[900],
  borderRadius: '8px',
  padding: '4px 8px',
}));

export const Swish = styled(MuiBox, {
  shouldForwardProp: (prop) => prop !== 'swish',
})<{ swish: string }>(({ theme, swish }) => ({
  backgroundImage: `url(${swish})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  margin: '24px 0',
  height: '10px',
}));
