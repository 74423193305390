import { Box as MuiBox, styled } from '@mui/material';

export const GraphicBackground = styled(MuiBox, {
  shouldForwardProp: (prop) => prop !== 'backgroundImage',
})<{ backgroundImage: string }>(({ theme, backgroundImage }) => ({
  zIndex: 0,
  width: '100vw',
  height: '100%',
  backgroundImage: `url(${backgroundImage})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundColor: theme.palette.background[900],
  padding: '25px 40px',
  display: 'flex',
  flexDirection: 'column',
  overflowY: 'auto',
  [theme.breakpoints.down('sm')]: {
    padding: 20,
  },
}));
