import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { Box as MuiBox } from '@mui/material';

import {
  commonStrings,
  studyMaterialPageStrings as pageStrings,
} from '../../../assets/strings/sv';
import { useStudyMaterialContext, useUsageContext } from '../../../hooks';
import { GraphicLayout, SeoComponent } from '../../layout';
import { PageConstraintAtom } from '../../library/atoms';
import {
  ErrorStatusContainerMolecule,
  StatusContainerMolecule,
} from '../../library/molecules';
import {
  ContentNavigationOrganism,
  ExamOrganism,
  FlashcardOrganism,
  QuestionsOrganism,
  QuizOrganism,
  SummaryOrganism,
} from '../../library/organisms';
import { ContentURL } from '../../utils';
import { HeaderAction, StudyMaterialHeaderComponent } from './components';
import { GetStudyMaterialQuery } from '../../../generated';

interface IStudyMaterialPage {
  isEditModeAvailable?: boolean;
  actions?: HeaderAction[];
  countViews?: boolean;
  collectSharedPageVisitStats?: boolean;
  isProtected?: boolean;
}

export const StudyMaterialPage = ({
  isEditModeAvailable,
  actions,
  countViews,
  collectSharedPageVisitStats,
  isProtected,
}: IStudyMaterialPage) => {
  const navigate = useNavigate();
  const { user } = useAuth0();

  const { menuItem, studyMaterialId } = useParams();

  const { studyMaterial, loading, error, updateStudyMaterial } =
    useStudyMaterialContext();

  const { collectSharedPagesVisitStats } = useUsageContext();

  const { studyMaterialSection, statusMessages } = commonStrings;

  useEffect(() => {
    if (isProtected && studyMaterial && user) {
      if (studyMaterial.userId !== user.sub) {
        // If page is protected and user is not owner of study material, redirect user to shared page
        const shareUrl = `${window.location.origin}/shared/material/${studyMaterialId}/${menuItem}`;
        window.location.href = shareUrl;
      }
    }
  }, [isProtected, studyMaterial, user, menuItem, studyMaterialId]);

  useEffect(() => {
    // Google Analytics stats collection
    collectSharedPageVisitStats &&
      collectSharedPagesVisitStats(window.location.pathname);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collectSharedPageVisitStats]);

  useEffect(() => {
    if (studyMaterial && countViews) {
      updateViews(studyMaterial);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [studyMaterial?.id, countViews]);

  const updateViews = (
    studyMaterial: GetStudyMaterialQuery['getStudyMaterial']
  ) => {
    const { views, id } = studyMaterial;

    const updatedViews = (views || 0) + 1;

    updateStudyMaterial(id, { views: updatedViews });
  };

  const renderMenuContent = (url?: ContentURL) => {
    switch (url) {
      case ContentURL.QUESTIONS:
        return <QuestionsOrganism isEditModeAvailable={isEditModeAvailable} />;
      case ContentURL.QUIZ:
        return <QuizOrganism />;
      case ContentURL.FLASHCARD:
        return <FlashcardOrganism isEditModeAvailable={isEditModeAvailable} />;
      case ContentURL.EXAM:
        return <ExamOrganism isEditModeAvailable={isEditModeAvailable} />;
      default:
        return <SummaryOrganism isEditModeAvailable={isEditModeAvailable} />;
    }
  };

  if (error)
    return (
      <GraphicLayout>
        <SeoComponent
          title={
            pageStrings.seo.title.prefix +
            (studyMaterial?.title || pageStrings.seo.title.fallbackSuffix)
          }
        />
        <ErrorStatusContainerMolecule
          title={studyMaterialSection.notFoundTitle}
          description={statusMessages.studyMaterial.get.error}
          buttonText={studyMaterialSection.homeButton}
          onClick={() => navigate('..')}
        />
      </GraphicLayout>
    );

  if (loading || !studyMaterial) {
    return (
      <GraphicLayout>
        <SeoComponent
          title={
            pageStrings.seo.title.prefix +
            (studyMaterial?.title || pageStrings.seo.title.fallbackSuffix)
          }
        />
        <ContentNavigationOrganism />
        <StudyMaterialHeaderComponent actions={actions} />
        <PageConstraintAtom>
          <StatusContainerMolecule
            statusMessage={statusMessages.studyMaterial.get.pending}
          />
        </PageConstraintAtom>
      </GraphicLayout>
    );
  }

  return (
    <GraphicLayout>
      <SeoComponent
        title={pageStrings.seo.title.prefix + studyMaterial.title}
      />
      <ContentNavigationOrganism />

      <StudyMaterialHeaderComponent
        studyMaterial={studyMaterial}
        actions={actions}
      />
      <MuiBox display="flex" flexDirection="column" flex={1} paddingBottom={14}>
        <PageConstraintAtom>
          {renderMenuContent(menuItem as ContentURL)}
        </PageConstraintAtom>
      </MuiBox>
    </GraphicLayout>
  );
};
