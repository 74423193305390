import {
  Box as MuiBox,
  Typography as MuiTypography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { DemoSectionComponent } from './components';
import { Hero, TextSection } from './styles';
import {
  commonStrings,
  homePageStrings,
} from '../../../../../assets/strings/sv';
import { ButtonAtom } from '../../../../library/atoms';

export const HeroComponent = () => {
  const navigate = useNavigate();

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  const handleNavigateToGenerate = () => {
    navigate('/generate');
  };

  return (
    <Hero>
      <TextSection>
        <MuiTypography variant="h1">{homePageStrings.hero.title}</MuiTypography>
        <MuiTypography variant="body1" marginRight={isDesktop ? 4 : 0}>
          {homePageStrings.hero.text}
        </MuiTypography>
        {!isDesktop && (
          <ButtonAtom
            variant="gradient"
            sx={{ width: 'fit-content', alignSelf: 'center' }}
            text={commonStrings.createStudyMaterial}
            onClick={handleNavigateToGenerate}
          />
        )}
      </TextSection>
      {isDesktop && (
        <MuiBox flex={1} position="relative">
          <DemoSectionComponent />
        </MuiBox>
      )}
    </Hero>
  );
};
