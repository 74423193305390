import { Typography as MuiTypography } from '@mui/material';

import { CardComponent } from './components';
import { homePageStrings } from '../../../../../assets/strings/sv';
import { Audience, CardContainer } from './styles';

export const AudienceComponent = () => {
  const { audience } = homePageStrings;

  return (
    <Audience>
      <MuiTypography variant="h3">{audience.title}</MuiTypography>
      <CardContainer>
        {audience.audiences.map((audience) => (
          <CardComponent title={audience.title} links={audience.links} />
        ))}
      </CardContainer>
    </Audience>
  );
};
