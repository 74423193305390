import { Highlight, Text } from './styles';

export interface IHighlightedTextAtom {
  text: string;
  startHighlightIndex: number;
  endHighlightIndex: number;
}

export const HighlightedTextAtom = ({
  text,
  startHighlightIndex,
  endHighlightIndex,
}: IHighlightedTextAtom) => {
  const preNonHighlightedText = text.substring(0, startHighlightIndex);
  const highlightedText = text.substring(
    startHighlightIndex,
    endHighlightIndex
  );
  const postNonHighlightedText = text.substring(endHighlightIndex);

  return (
    <Text variant="body1">
      {preNonHighlightedText}
      <Highlight>{highlightedText}</Highlight>
      {postNonHighlightedText}
    </Text>
  );
};
