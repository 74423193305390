import { ListSubjectsQuery, Subject } from '../../generated';

export interface ISubjectState {
  subjects: ListSubjectsQuery['listSubjects'];
  selectedSubject?: Subject;
}

export const initialSubjectState: ISubjectState = {
  subjects: [],
  selectedSubject: undefined,
};
