import { Typography as MuiTypography, SxProps, Theme } from '@mui/material';

import { Chip, GradientBorderChip } from './style';

interface IChip {
  label: string;
  isActive: boolean;
  onClick: () => void;
  sx?: SxProps<Theme>;
}

export const ChipAtom = ({ label, isActive, onClick, sx }: IChip) => {
  if (isActive)
    return (
      <GradientBorderChip>
        <Chip onClick={onClick} isActive={true}>
          <MuiTypography variant="body1">{label}</MuiTypography>
        </Chip>
      </GradientBorderChip>
    );

  return (
    <Chip onClick={onClick} isActive={false}>
      <MuiTypography variant="body1">{label}</MuiTypography>
    </Chip>
  );
};
