import { Box as MuiBox, styled } from '@mui/material';

export const MenuItem = styled(MuiBox)<{ disabled?: boolean }>(
  ({ theme, disabled }) => ({
    display: 'flex',
    alignItems: 'center',
    cursor: disabled ? 'default' : 'pointer',
    padding: '10px 10px 10px 10px',
    borderRadius: theme.shape.borderRadius,
    transition: 'background-color 200ms ease',
    '&:hover': {
      backgroundColor: disabled ? 'transparent' : 'rgba(999,999,999,0.1)',
    },
  })
);
