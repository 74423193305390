import Compressor from 'compressorjs';

export const compressFile = async (
  file: File | Blob,
  maxByteSize: number,
  iteration = 0
): Promise<File | Blob | null> => {
  const quality =
    iteration !== 0 ? Math.floor((maxByteSize / file.size) * 100) / 100 : 0.6; // Too large of an initial quality decrease can compromise the image integrity

  return await new Promise((resolve) => {
    new Compressor(file, {
      maxHeight: 600,
      maxWidth: 600,
      quality: Number(quality),
      resize: 'contain',
      success: (file) => {
        if (file.size <= maxByteSize || iteration > 10) {
          resolve(file);
        } else {
          resolve(compressFile(file, maxByteSize, iteration + 1));
        }
      },
      error: (error) => {
        console.error('Could not compress the file', { error });
        resolve(null);
      },
    });
  });
};
