import React from 'react';
import { ISubjectState, initialSubjectState } from './state';
import { Subject } from '../../generated';

export interface ISubjectContext extends ISubjectState {
  handleChangeSelectedSubject: (subject?: Subject) => void;
}

const stub = (): never => {
  throw new Error('You forgot to wrap your component.');
};

const initialSubjectContext: ISubjectContext = {
  ...initialSubjectState,
  handleChangeSelectedSubject: stub,
};

export const SubjectContext = React.createContext(initialSubjectContext);
