import React from 'react';

import { IQuizState, initialQuizState } from './state';
import { CreateQuizInput, UpdateQuizInput } from '../../generated';

export interface IQuizContext extends IQuizState {
  createQuiz: (input: CreateQuizInput) => void;
  updateQuiz: (id: string, update: UpdateQuizInput) => void;
}

const stub = (): never => {
  throw new Error('You forgot to wrap your component.');
};

const initialQuizContext: IQuizContext = {
  ...initialQuizState,
  createQuiz: stub,
  updateQuiz: stub,
};

export const QuizContext = React.createContext(initialQuizContext);
