import { gql } from '@apollo/client';

export const LIST_STUDY_MATERIAL = gql`
  query listStudyMaterial(
    $options: ListStudyMaterialOptionsInput
    $filter: ListStudyMaterialFilterInput
  ) {
    listStudyMaterial(options: $options, filter: $filter) {
      id
      title
      user {
        id
        picture
      }
      flags {
        isPublished
        deleted
      }
      subject {
        id
        subject
      }
      views
      bookmark {
        id
      }
    }
  }
`;
