import { Question, AnalysisQuestion } from '../../../../generated';
import { AnalysisQuestionFieldError, QuestionFieldError } from '../../../utils';

export type UnsavedQuestion = Pick<Question, 'question' | 'answer'> & {
  _id: string;
};

export type UnsavedAnalysisQuestion = Pick<Question, 'question'> & {
  _id: string;
};

export type State = {
  deletedQuestions: Question[];
  deletedAnalysisQuestions: AnalysisQuestion[];
  unsavedQuestions: UnsavedQuestion[];
  unsavedAnalysisQuestions: UnsavedAnalysisQuestion[];
  questionsFieldErrors: QuestionFieldError[];
  analysisQuestionsFieldErrors: AnalysisQuestionFieldError[];
};

export const initialState: State = {
  deletedQuestions: [],
  deletedAnalysisQuestions: [],
  unsavedQuestions: [],
  unsavedAnalysisQuestions: [],
  questionsFieldErrors: [],
  analysisQuestionsFieldErrors: [],
};
