import {
  GetStudyMaterialQuery,
  ListBookmarksQuery,
  ListStudyMaterialQuery,
} from '../../generated';

export interface IStudyMaterialState {
  id?: string;
  studyMaterial?: GetStudyMaterialQuery['getStudyMaterial'];
  studyMaterials: ListStudyMaterialQuery['listStudyMaterial'];
  studyMaterialsLoading: boolean;
  bookmarkedStudyMaterials: ListBookmarksQuery['listBookmarks'];
  bookmarkedStudyMaterialsLoading: boolean;
  exampleStudyMaterials: ListStudyMaterialQuery['listStudyMaterial'];
  exampleStudyMaterialsLoading: boolean;
  publishedStudyMaterials: ListStudyMaterialQuery['listStudyMaterial'];
  publishedStudyMaterialsLoading: boolean;
  loading: boolean;
  error: boolean;
}

export const initialStudyMaterialState: IStudyMaterialState = {
  id: undefined,
  studyMaterial: undefined,
  studyMaterials: [],
  studyMaterialsLoading: true,
  bookmarkedStudyMaterials: [],
  bookmarkedStudyMaterialsLoading: true,
  exampleStudyMaterials: [],
  exampleStudyMaterialsLoading: true,
  publishedStudyMaterials: [],
  publishedStudyMaterialsLoading: true,
  loading: true,
  error: false,
};
