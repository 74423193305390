import { useMemo } from 'react';
import { Box as MuiBox, useMediaQuery, useTheme } from '@mui/material';
import {
  DrawOutlined,
  EditNote,
  EmojiObjectsOutlined,
  StyleOutlined,
  ViewAgendaOutlined,
} from '@mui/icons-material';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { ContentMenuItem, ContentURL } from '../../../utils';
import { commonStrings } from '../../../../assets/strings/sv';
import { Gradient, Navigation } from './styles';
import { ButtonAtom, IconButtonAtom } from '../../atoms';

export const ContentNavigationOrganism = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const { menuItem } = useParams();

  const { summary, quiz, questions, flashcards, exam } = commonStrings;

  const menuItems: ContentMenuItem[] = [
    { id: ContentURL.SUMMARY, text: summary, icon: <DrawOutlined /> },
    { id: ContentURL.QUESTIONS, text: questions, icon: <ViewAgendaOutlined /> },
    { id: ContentURL.QUIZ, text: quiz, icon: <EmojiObjectsOutlined /> },
    { id: ContentURL.FLASHCARD, text: flashcards, icon: <StyleOutlined /> },
    { id: ContentURL.EXAM, text: exam, icon: <EditNote /> },
  ];

  const activeMenuItem = useMemo(
    () => menuItems.find((item) => item.id === menuItem) || menuItems[0],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [menuItem]
  );

  const handleUpdateUrl = (newMenuItem: ContentMenuItem) => {
    if (newMenuItem !== activeMenuItem) {
      const prevMenuItemRegex = new RegExp(`/${menuItem}(/|$)`); // search string is at the end of string or followed by "/"
      const newPath = location.pathname.replace(
        prevMenuItemRegex,
        `/${newMenuItem.id}`
      );

      navigate(newPath);
    }
  };

  return (
    <Gradient>
      <Navigation>
        <MuiBox display="flex" gap={2}>
          {menuItems.map((item, index) => {
            const isSelected = activeMenuItem.id === item?.id;
            if (isMobile) {
              return (
                <IconButtonAtom
                  key={index}
                  icon={item.icon}
                  disabled={!item.hasOwnProperty('id')}
                  sx={{
                    '& svg': {
                      color: isSelected
                        ? theme.palette.secondary[200]
                        : 'inherit',
                    },
                  }}
                  onClick={
                    item.hasOwnProperty('id') // item without id is disabled
                      ? () => handleUpdateUrl(item)
                      : () => {}
                  }
                />
              );
            }
            return (
              <ButtonAtom
                key={index}
                startIcon={item.icon}
                disabled={!item.hasOwnProperty('id')}
                variant={isSelected ? undefined : 'inactive'}
                sx={{
                  '& svg': {
                    color: isSelected
                      ? theme.palette.secondary[200]
                      : 'inherit',
                  },
                }}
                text={item.text}
                onClick={
                  item.hasOwnProperty('id') // item without id is disabled
                    ? () => handleUpdateUrl(item)
                    : () => {}
                }
              />
            );
          })}
        </MuiBox>
      </Navigation>
    </Gradient>
  );
};
