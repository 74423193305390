import { Box as MuiBox, styled } from '@mui/material';

export const TextLinkContainer = styled(MuiBox)(({ theme }) => ({
  height: 'fit-content',
  width: 'fit-content',
  '& *': {
    textDecoration: 'underlined',
    color: theme.palette.grey[400],
  },
}));
