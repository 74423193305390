import { addItemToCache, deleteItemFromCache } from '../helpers';
import {
  CreateAnalysisQuestionsInput,
  AnalysisQuestion,
  useCreateAnalysisQuestionsMutation,
  useDeleteAnalysisQuestionMutation,
  useUpdateAnalysisQuestionMutation,
  useSaveAnalysisQuestionsMutation,
  SaveAnalysisQuestionsInput,
  Status,
} from '../../generated';
import { AnalysisQuestionContext } from './context';
import { GET_STUDY_MATERIAL } from '../../gql/queries';
import { useStudyMaterialStatusContext } from '../../hooks';

interface IAnalysisQuestionProvider {
  children: React.ReactNode;
}

export const AnalysisQuestionProvider = ({
  children,
}: IAnalysisQuestionProvider) => {
  const { updateStudyMaterialStatus } = useStudyMaterialStatusContext();

  const [_createAnalysisQuestion] = useCreateAnalysisQuestionsMutation();
  const [_updateAnalysisQuestion] = useUpdateAnalysisQuestionMutation();
  const [_deleteAnalysisQuestion] = useDeleteAnalysisQuestionMutation();
  const [_saveAnalysisQuestions] = useSaveAnalysisQuestionsMutation();

  const createAnalysisQuestions = async (
    input: CreateAnalysisQuestionsInput
  ) => {
    updateStudyMaterialStatus({ questions: Status.Pending });
    await _createAnalysisQuestion({
      variables: {
        input,
      },
      onError: (error) => {
        console.error('Could not create analysis questions', { error });
      },
    });
  };

  const updateAnalysisQuestions = async (
    analysisQuestions: AnalysisQuestion[]
  ) => {
    analysisQuestions.map(
      async ({ id, question }) =>
        await _updateAnalysisQuestion({
          variables: {
            id,
            update: { question },
          },
          onError: (error) => {
            console.error('Could not update analysis questions', { error });
          },
        })
    );
  };

  const deleteAnalysisQuestions = async (
    analysisQuestions: AnalysisQuestion[]
  ) => {
    analysisQuestions.map(
      async ({ id }) =>
        await _deleteAnalysisQuestion({
          variables: {
            id,
          },
          update: (cache, _, { variables }) => {
            if (variables?.id) {
              deleteItemFromCache(cache, variables.id, 'AnalysisQuestion');
            }
          },
          onError: (error) => {
            console.error('Could not delete analysis question', { error });
          },
        })
    );
  };

  const saveAnalysisQuestions = async (input: SaveAnalysisQuestionsInput) => {
    await _saveAnalysisQuestions({
      variables: {
        input,
      },
      update: (cache, { data }) => {
        data?.saveAnalysisQuestions.map((question) =>
          addItemToCache(cache, question, {
            query: GET_STUDY_MATERIAL,
            variables: {
              getStudyMaterialId: input.studyMaterialId,
            },
            queryName: 'getStudyMaterial',
            nestedQuery: {
              queryName: 'analysisQuestions',
            },
          })
        );
      },
      onError: (error) => {
        console.error('Could not save analysis questions', { error });
      },
    });
  };

  const config = {
    createAnalysisQuestions,
    updateAnalysisQuestions,
    deleteAnalysisQuestions,
    saveAnalysisQuestions,
  };

  return (
    <AnalysisQuestionContext.Provider value={config}>
      {children}
    </AnalysisQuestionContext.Provider>
  );
};
