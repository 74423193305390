import { Box as MuiBox, Grid as MuiGrid, styled } from '@mui/material';

export const Container = styled(MuiBox)(({ theme }) => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignContent: 'center',
}));

export const InnerContainer = styled(MuiGrid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  maxWidth: '100%',
  width: 450,
  padding: '32px',
  margin: 'auto',
  gap: 32,
  backgroundColor: theme.palette.background[800],
  borderRadius: theme.shape.borderRadius,
}));
