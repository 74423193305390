import React from 'react';

import { IExamState, initialExamState } from './state';
import {
  CreateExamEvaluationInput,
  CreateExamInput,
  ExamQuestion,
  SaveExamQuestionsInput,
} from '../../generated';

export interface IExamContext extends IExamState {
  createExam: (input: CreateExamInput) => void;
  createExamEvaluation: (input: CreateExamEvaluationInput) => void;
  updateExamQuestions: (examQuestions: ExamQuestion[]) => void;
  saveExamQuestions: (input: SaveExamQuestionsInput) => void;
  deleteExamQuestions: (examQuestions: ExamQuestion[]) => void;
}

const stub = (): never => {
  throw new Error('You forgot to wrap your component.');
};

const initialExamContext: IExamContext = {
  ...initialExamState,
  createExam: stub,
  createExamEvaluation: stub,
  updateExamQuestions: stub,
  saveExamQuestions: stub,
  deleteExamQuestions: stub,
};

export const ExamContext = React.createContext(initialExamContext);
