import {
  Typography as MuiTypography,
  Box as MuiBox,
  useTheme,
} from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';

import {
  GetStudyMaterialQuery,
  Language,
  UserExamAnswer,
} from '../../../../../../../../generated';
import { TextInputAtom } from '../../../../../../atoms';
import {
  AudioPlayerContainer,
  NumberContainer,
  OuterContainer,
  QuestionSection,
} from './styles';
import { commonStrings } from '../../../../../../../../assets/strings/sv';
import { AudioPlayerMolecule } from '../../../../../../molecules';
import { UnsavedExamQuestion } from '../../types';

interface IExamQuestionComponent {
  examQuestion:
    | NonNullable<
        NonNullable<
          GetStudyMaterialQuery['getStudyMaterial']['exam']
        >['questions']
      >[0]
    | UnsavedExamQuestion;
  answer: string;
  number: number;
  language?: Language;
  handleChangeAnswer: (answer: UserExamAnswer) => void;
}

export const ExamQuestionComponent = ({
  examQuestion,
  number,
  answer,
  language,
  handleChangeAnswer,
}: IExamQuestionComponent) => {
  const { isAuthenticated } = useAuth0();

  const theme = useTheme();
  const { question, maxScore } = examQuestion;
  const id = 'id' in examQuestion ? examQuestion['id'] : examQuestion?.['_id'];

  return (
    <OuterContainer>
      <NumberContainer>
        <MuiTypography variant="body1">{number}</MuiTypography>
      </NumberContainer>
      <MuiBox flex={1} display="flex" flexDirection="column" gap={1}>
        <QuestionSection>
          <MuiTypography variant="body1">{question}</MuiTypography>
          <AudioPlayerContainer>
            <AudioPlayerMolecule
              text={question}
              key={id}
              language={language?.name || commonStrings.defaultLanguage}
            />
          </AudioPlayerContainer>
        </QuestionSection>
        <TextInputAtom
          variant="filled"
          multiline={true}
          value={answer}
          disabled={!isAuthenticated}
          onChange={(value) =>
            handleChangeAnswer({
              userAnswer: value,
              examQuestionId: id,
            })
          }
          sx={{
            '& .MuiInputBase-root': {
              display: 'flex',
              alignItems: 'start',
              flex: 1,
            },
          }}
        />
        <MuiTypography color={theme.palette.text.disabled}>
          {commonStrings.max} {commonStrings.points}: {maxScore}
        </MuiTypography>
      </MuiBox>
    </OuterContainer>
  );
};
