import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box as MuiBox, Grid as MuiGrid } from '@mui/material';

import {
  GetStudyMaterialQuery,
  Status,
} from '../../../../../../../../../generated';
import { commonStrings } from '../../../../../../../../../assets/strings/sv';
import { useUsageContext } from '../../../../../../../../../hooks';
import { ButtonAtom } from '../../../../../../../../library/atoms';
import {
  ModalMolecule,
  ShareLinkMolecule,
} from '../../../../../../../../library/molecules';

interface IShareComponent {
  studyMaterial: GetStudyMaterialQuery['getStudyMaterial'];
  isModalOpen: boolean;
  handleCloseModal: () => void;
}

export const ShareComponent = ({
  studyMaterial,
  isModalOpen,
  handleCloseModal,
}: IShareComponent) => {
  const { studyMaterialId, menuItem } = useParams();
  const { collectShareStudyMaterialUrlStats } = useUsageContext();

  const [isErrorModalOpen, setIsErrorModalOpen] = useState<boolean>(true);

  const shareUrl = `${window.location.origin}/shared/material/${studyMaterialId}/${menuItem}`;

  const hasStudyMaterialError = Object.values(studyMaterial.status).some(
    (status) => status === Status.Failed
  );

  const { studyMaterialSection, cancel, proceed } = commonStrings;
  const { shareModal } = studyMaterialSection;

  const handleCloseErrorModal = () => setIsErrorModalOpen(false);

  const handleErrorModalConfirm = () => {
    setIsErrorModalOpen(false);
  };

  const title =
    shareModal[
      isErrorModalOpen && hasStudyMaterialError ? 'errorTitle' : 'title'
    ];
  const description =
    shareModal[
      isErrorModalOpen && hasStudyMaterialError
        ? 'errorDescription'
        : 'description'
    ];

  return (
    <>
      <ModalMolecule
        title={title}
        description={description}
        onClose={handleCloseModal}
        isOpen={isModalOpen}
      >
        {hasStudyMaterialError && isErrorModalOpen ? (
          <MuiGrid container direction="row" spacing={2}>
            <MuiGrid item>
              <ButtonAtom
                variant="border"
                text={cancel}
                onClick={handleCloseErrorModal}
              />
            </MuiGrid>
            <MuiGrid item>
              <ButtonAtom
                variant="border"
                text={proceed}
                onClick={handleErrorModalConfirm}
              />
            </MuiGrid>
          </MuiGrid>
        ) : (
          <MuiBox
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: { sx: 350, sm: 380, md: 350, lg: 350 },
            }}
            gap={2}
          >
            <ShareLinkMolecule
              url={shareUrl}
              handleCollectGaStats={collectShareStudyMaterialUrlStats}
            />
          </MuiBox>
        )}
      </ModalMolecule>
    </>
  );
};
