import { Box as MuiBox, styled } from '@mui/material';

export const Container = styled(MuiBox)<{}>(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
}));

export const BetaBadgeContainer = styled(MuiBox)<{}>(({ theme }) => ({
  display: 'flex',
  marginLeft: 8,
}));
