import {
  Box as MuiBox,
  ClickAwayListener as MuiClickAwayListener,
  Menu as MuiMenu,
  MenuItem as MuiMenuItem,
  Typography as MuiTypography,
  SxProps,
  useTheme,
} from '@mui/material';
import React from 'react';

import { IconButtonAtom } from '../../atoms';
import { PopperMenuItem } from './types';

interface IPopperMenu {
  icon: JSX.Element;
  shape?: 'round' | 'square';
  tooltipTitle?: string;
  sx?: SxProps;
  menuItems: PopperMenuItem[];
  data?: any;
}

export const PopperMenuMolecule = ({
  icon,
  shape,
  tooltipTitle,
  sx,
  menuItems,
  data,
}: IPopperMenu) => {
  const theme = useTheme();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleOpenMenu = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };
  const handleCloseMenu = (e?: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    e?.preventDefault();
    setAnchorEl(null);
  };

  const MenuPopper = () => {
    return (
      <MuiMenu
        onClick={(e) => e.stopPropagation()}
        anchorEl={anchorEl}
        open={open}
        onClose={() => handleCloseMenu()}
        sx={{
          '& .MuiPaper-root': {
            backgroundColor: theme.palette.background[800],
          },
        }}
      >
        {menuItems.map((item, index) => (
          <MuiMenuItem
            key={index}
            onClick={(e) => {
              item.onClick(data);
              handleCloseMenu(e);
            }}
          >
            <MuiBox display="flex" flexDirection="row" alignItems="center">
              {item.icon && (
                <MuiBox display="flex" marginRight={1.5}>
                  {item.icon}
                </MuiBox>
              )}
              <MuiTypography>{item.text}</MuiTypography>
            </MuiBox>
          </MuiMenuItem>
        ))}
      </MuiMenu>
    );
  };

  return (
    <>
      <MuiClickAwayListener
        onClickAway={(e) => {
          e.stopPropagation();
        }}
      >
        <>
          <IconButtonAtom
            sx={sx}
            onClick={handleOpenMenu}
            tooltipTitle={tooltipTitle}
            shape={shape}
            icon={icon}
          />
          <MenuPopper />
        </>
      </MuiClickAwayListener>
    </>
  );
};
