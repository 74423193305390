import {
  Box as MuiBox,
  Typography as MuiTypography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { ButtonAtom } from '../../../../library/atoms';
import { StreakComponent } from './components/streak/StreakComponent';
import { DrawerComponent } from '../../../../library/organisms';

export const HeaderComponent = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const navigate = useNavigate();

  const handleNavigateToGenerate = () => {
    navigate('/generate');
  };

  return (
    <MuiBox
      display="flex"
      marginBottom="50px"
      alignItems="center"
      justifyContent="space-between"
    >
      <MuiBox display="flex" alignItems="center">
        <DrawerComponent />
        {!isMobile && <MuiTypography variant="h5">Lektioner</MuiTypography>}
      </MuiBox>

      <MuiBox display="flex" alignItems="center" gap={2}>
        <StreakComponent />
        <ButtonAtom
          text="Skapa lektion"
          variant="gradient"
          onClick={handleNavigateToGenerate}
        />
      </MuiBox>
    </MuiBox>
  );
};
