import MuiKeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Box as MuiBox, Typography as MuiTypography } from '@mui/material';
import MuiAccordion from '@mui/material/Accordion';

import { useState } from 'react';
import { commonStrings } from '../../../../../../../../assets/strings/sv';
import { UserAnswer } from '../../../../types';
import { ButtonText } from '../../../quizCarousel/components/quizCard/types';
import cancel from './images/cancel.svg';
import check from './images/check.svg';
import {
  AccordionSummary,
  AnswerContainer,
  Container,
  CorrectAnswerIcon,
  IconContainer,
  QuestionContainer,
  QuestionNumber,
  ResultContainer,
  WrongAnswerContainer,
  WrongAnswerIcon,
} from './styles';
import { AnswerProps } from './types';

interface IQuizResultComponent {
  questionNumber: number;
  isFirstListItem: boolean;
  isLastListItem: boolean;
  userAnswers: UserAnswer;
}

export const QuizResultComponent = ({
  questionNumber,
  isFirstListItem,
  isLastListItem,
  userAnswers,
}: IQuizResultComponent) => {
  const { studyMaterialSection } = commonStrings;
  const [showResult, setShowResult] = useState(false);

  const Answer: React.FC<AnswerProps> = ({ isCorrect, label, answer }) => (
    <MuiBox>
      <MuiTypography variant="caption">{label}</MuiTypography>
      <AnswerContainer className="hover-effect-with-container">
        <IconContainer>
          {isCorrect ? (
            <CorrectAnswerIcon check={check} />
          ) : (
            <WrongAnswerIcon cancel={cancel} />
          )}
        </IconContainer>
        <MuiTypography>{answer}</MuiTypography>
      </AnswerContainer>
    </MuiBox>
  );

  const renderResult = () => {
    switch (userAnswers.__typename) {
      case 'Statements':
        const correctStatement = userAnswers.isTrue
          ? ButtonText.TRUE
          : ButtonText.FALSE;
        const incorrectStatement =
          correctStatement === ButtonText.TRUE
            ? ButtonText.FALSE
            : ButtonText.TRUE;
        const renderedStatement =
          userAnswers.selectedChoiceText === correctStatement
            ? incorrectStatement
            : correctStatement;

        return (
          <>
            <Answer
              isCorrect={userAnswers.isUserAnswerCorrect}
              label={studyMaterialSection.quizSection.quizIsDone.yourAnswer}
              answer={userAnswers.selectedChoiceText}
            />
            {!userAnswers.isUserAnswerCorrect && (
              <WrongAnswerContainer>
                <Answer
                  isCorrect={true}
                  label={
                    studyMaterialSection.quizSection.quizIsDone.correctAnswer
                  }
                  answer={renderedStatement}
                />
              </WrongAnswerContainer>
            )}
          </>
        );

      case 'MultipleChoices':
        const correctChoice = userAnswers.choices.find(
          (choice) => choice.isTrue
        )?.option;

        return (
          <>
            <Answer
              isCorrect={userAnswers.isUserAnswerCorrect}
              label={studyMaterialSection.quizSection.quizIsDone.yourAnswer}
              answer={userAnswers.selectedChoiceText}
            />
            {!userAnswers.isUserAnswerCorrect && (
              <WrongAnswerContainer>
                <Answer
                  isCorrect={true}
                  label={
                    studyMaterialSection.quizSection.quizIsDone.correctAnswer
                  }
                  answer={correctChoice}
                />
              </WrongAnswerContainer>
            )}
          </>
        );

      default:
        return null;
    }
  };

  return (
    <MuiAccordion
      expanded={showResult}
      onChange={() => setShowResult(!showResult)}
      sx={{ width: '100%' }}
    >
      <AccordionSummary>
        <Container
          isFirstListItem={isFirstListItem}
          isLastListItem={isLastListItem}
        >
          <QuestionContainer isCorrect={userAnswers.isUserAnswerCorrect}>
            <QuestionNumber>
              <MuiTypography>{questionNumber}</MuiTypography>
            </QuestionNumber>
            <MuiTypography>
              {'question' in userAnswers
                ? userAnswers.question
                : userAnswers.statement}
            </MuiTypography>
          </QuestionContainer>
          <ResultContainer>
            {showResult ? (
              <MuiTypography>
                {studyMaterialSection.quizSection.quizIsDone.hideResult}
              </MuiTypography>
            ) : (
              <MuiTypography>
                {studyMaterialSection.quizSection.quizIsDone.showResult}
              </MuiTypography>
            )}
            <AccordionSummary
              expandIcon={<MuiKeyboardArrowDownIcon />}
            ></AccordionSummary>
          </ResultContainer>
          {/* This following part is rendered in the extended accordion. */}
          {showResult && <>{renderResult()}</>}
        </Container>
      </AccordionSummary>
    </MuiAccordion>
  );
};
