import { ReactNode, useEffect } from 'react';
import {
  CssBaseline,
  PaletteMode,
  StyledEngineProvider,
  ThemeProvider,
  createTheme,
} from '@mui/material';

import { darkPalette, lightPalette } from './paletteModes';
import ComponentsOverrides from './overrides';

declare module '@mui/material' {
  interface PaletteColor {
    50?: string;
    100?: string;
    200?: string;
    300?: string;
    400?: string;
    500?: string;
    600?: string;
    700?: string;
    800?: string;
    900?: string;
  }

  interface PaletteOptions {
    gradient: string;
    highlight: {
      regular: string;
      paper: string;
    };
  }

  interface Palette {
    gradient: string;
    highlight: {
      regular: string;
      paper: string;
    };
  }

  interface TypeBackground extends PaletteColor {
    readable: string;
  }
}

const FONT_PRIMARY = ['Manrope', 'sans-serif'].join(',');

const baseTheme = createTheme({
  typography: {
    fontFamily: FONT_PRIMARY,
    h1: {
      lineHeight: '56px',
      fontWeight: 800,
      fontSize: 48,
    },
    h2: {
      lineHeight: '47px',
      fontWeight: 800,
      fontSize: 39,
    },
    h3: {
      lineHeight: '40px',
      fontWeight: 800,
      fontSize: 33,
    },
    h4: {
      lineHeight: '34px',
      fontWeight: 800,
      fontSize: 28,
    },
    h5: {
      lineHeight: '28px',
      fontWeight: 700,
      fontSize: 23,
    },
    h6: {
      lineHeight: '23px',
      fontWeight: 700,
      fontSize: 19,
    },
    subtitle1: {
      lineHeight: '24px',
      fontWeight: 700,
      fontSize: 20,
    },
    body1: {
      lineHeight: '25.6px',
      fontWeight: 500,
      fontSize: 16,
    },
    caption: {
      lineHeight: '19px',
      fontWeight: 500,
      fontSize: 12,
    },
    button: {
      lineHeight: '24px',
      fontWeight: 600,
      fontSize: 14,
      textTransform: 'none',
    },
  },
  shape: {
    borderRadius: 8,
  },
});

export const darkTheme = createTheme({
  ...baseTheme,
  palette: darkPalette,
});

export const lightTheme = createTheme({
  ...baseTheme,
  palette: lightPalette,
});

lightTheme.components = ComponentsOverrides(lightTheme);
darkTheme.components = ComponentsOverrides(darkTheme);

type ThemeConfigProps = {
  children: ReactNode;
  mode: PaletteMode;
};

export const ThemeConfig = ({ children, mode }: ThemeConfigProps) => {
  useEffect(() => {
    const themeColorMetaTag = document.getElementById('meta-theme-color');
    if (mode === 'dark') {
      themeColorMetaTag?.setAttribute(
        'content',
        darkPalette.background?.['900'] || '#000000'
      );
    } else {
      themeColorMetaTag?.setAttribute('content', '#ffffff');
    }
  }, [mode]);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={mode === 'dark' ? darkTheme : lightTheme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  );
};
