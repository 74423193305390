import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box as MuiBox } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';

import { explorePageStrings as pageStrings } from '../../../assets/strings/sv';
import logo from '../../../assets/images/logo.png';
import { StudyMaterial } from '../../../generated';
import {
  PublishedStudyMaterialModuleMolecule,
  StudyMaterialModuleMolecule,
  ToggleableModuleGridMolecule,
} from '../../library/molecules';
import { FilterSectionComponent, HeaderComponent } from './components';
import { ContentContainer, Explore } from './styles';
import { useStudyMaterialContext } from '../../../hooks';
import { LoaderAtom } from '../../library/atoms';

export const ExplorePage = () => {
  const { isLoading: isAuthenticationLoading } = useAuth0();
  const navigate = useNavigate();

  const {
    exampleStudyMaterials,
    exampleStudyMaterialsLoading,
    publishedStudyMaterials,
    publishedStudyMaterialsLoading,
  } = useStudyMaterialContext();

  const moduleSections = useMemo(() => {
    return [
      {
        modules: publishedStudyMaterials.map((studyMaterial, index) => (
          <PublishedStudyMaterialModuleMolecule
            key={index}
            avatarSrc={studyMaterial.user?.picture}
            studyMaterial={studyMaterial as StudyMaterial}
          />
        )),
        isLoading: publishedStudyMaterialsLoading,
        title: pageStrings.categories.published,
      },
      {
        modules: exampleStudyMaterials.map((studyMaterial, index) => (
          <StudyMaterialModuleMolecule
            key={index}
            avatarSrc={logo}
            studyMaterial={studyMaterial as StudyMaterial}
            onClick={() =>
              navigate(`/explore/example/${studyMaterial.id}/summary`)
            }
          />
        )),
        isLoading: publishedStudyMaterialsLoading,
        title: pageStrings.categories.example,
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    publishedStudyMaterials,
    publishedStudyMaterialsLoading,
    exampleStudyMaterials,
    exampleStudyMaterialsLoading,
  ]);

  if (isAuthenticationLoading)
    return (
      <Explore>
        <ContentContainer>
          <HeaderComponent />
          <MuiBox
            height="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <LoaderAtom />
          </MuiBox>
        </ContentContainer>
      </Explore>
    );

  return (
    <Explore>
      <ContentContainer>
        <HeaderComponent />
        <FilterSectionComponent />

        <MuiBox display="flex" flexDirection="column" rowGap={5}>
          {moduleSections.map(({ title, isLoading, modules }, index) => (
            <MuiBox
              display="flex"
              flexDirection="column"
              rowGap={1}
              key={index}
            >
              <ToggleableModuleGridMolecule
                title={title}
                isLoading={isLoading}
                modules={modules}
              />
            </MuiBox>
          ))}
        </MuiBox>
      </ContentContainer>
    </Explore>
  );
};
