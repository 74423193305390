import React from 'react';

import { IQuestionState, initialQuestionState } from './state';
import {
  CreateQuestionsInput,
  Question,
  SaveQuestionsInput,
} from '../../generated';

export interface IQuestionContext extends IQuestionState {
  createQuestions: (input: CreateQuestionsInput) => void;
  updateQuestions: (questions: Question[]) => void;
  deleteQuestions: (questions: Question[]) => void;
  saveQuestions: (input: SaveQuestionsInput) => void;
}

const stub = (): never => {
  throw new Error('You forgot to wrap your component.');
};

const initialQuestionContext: IQuestionContext = {
  ...initialQuestionState,
  createQuestions: stub,
  updateQuestions: stub,
  deleteQuestions: stub,
  saveQuestions: stub,
};

export const QuestionContext = React.createContext(initialQuestionContext);
