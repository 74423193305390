import React, { useEffect, useMemo, useState } from 'react';
import { Box as MuiBox, Typography as MuiTypography } from '@mui/material';
import { AddOutlined, ArrowForward } from '@mui/icons-material';
import { useAuth0 } from '@auth0/auth0-react';
import _ from 'lodash';
import { ulid } from 'ulid';

import {
  ExamQuestion,
  GetStudyMaterialQuery,
  Language,
  UserExamAnswer,
} from '../../../../../../generated';
import { ButtonAtom, PaperConstraintAtom } from '../../../../atoms';
import { commonStrings } from '../../../../../../assets/strings/sv';
import {
  ExamQuestionComponent,
  EditExamQuestionComponent,
  ExamHeaderComponent,
  InformationContainerComponent,
} from './components';
import { Container, AuthenticationContainer } from './styles';
import { UnsavedExamQuestion } from './types';
import { useExamContext } from '../../../../../../hooks';

export type ExamFieldValidation = {
  answer: string;
  question: string;
};

interface IExamComponent {
  exam: GetStudyMaterialQuery['getStudyMaterial']['exam'];
  userExamAnswers: UserExamAnswer[];
  language?: Language;
  isEditModeAvailable?: boolean;
  handleChangeAnswer: (answer: UserExamAnswer) => void;
  handleSubmitExam: () => void;
}

export const ExamComponent = ({
  exam,
  userExamAnswers,
  language,
  isEditModeAvailable,
  handleChangeAnswer,
  handleSubmitExam,
}: IExamComponent) => {
  const { isAuthenticated, loginWithRedirect } = useAuth0();
  const { updateExamQuestions, saveExamQuestions, deleteExamQuestions } =
    useExamContext();

  const { examSection } = commonStrings.studyMaterialSection;

  const [examQuestions, setExamQuestions] = useState<ExamQuestion[]>([]);
  const [unsavedExamQuestions, setUnsavedExamQuestions] = useState<
    UnsavedExamQuestion[]
  >([]);
  const [deletedExamQuestions, setDeletedExamQuestions] = useState<
    ExamQuestion[]
  >([]);
  const [fieldValidations, setFieldValidations] = useState<
    ExamFieldValidation[]
  >([]);

  const [isEditModeActive, setIsEditModeActive] = useState(false);

  useEffect(() => {
    setExamQuestions(_.sortBy(exam?.questions, 'maxScore', 'asc'));
  }, [exam]);

  const handleLogin = () => {
    loginWithRedirect({
      appState: {
        returnTo: window.location.pathname,
      },
    });
  };

  const handleActivateEditMode = () => {
    setIsEditModeActive(true);
  };
  const handleCancelEditMode = () => {
    resetExamEdits();
    setIsEditModeActive(false);
  };

  const handleConfirmEdit = () => {
    if (!validateFields() || !exam) return;
    const updatedExamQuestions = filterUnchangedExamQuestions(
      examQuestions.filter((question) =>
        deletedExamQuestions.every((_question) => question.id !== _question.id)
      )
    );

    deletedExamQuestions.length && deleteExamQuestions(deletedExamQuestions);
    unsavedExamQuestions.length &&
      saveExamQuestions({
        studyMaterialId: exam.studyMaterialId,
        examId: exam.id,
        questions: unsavedExamQuestions.map((question) =>
          _.omit(question, '_id')
        ),
      });
    updatedExamQuestions.length && updateExamQuestions(updatedExamQuestions);
    setIsEditModeActive(false);
  };

  const validateFields = () => {
    const validations = (_exam?.questions || []).map((question) => {
      const fieldValidation: ExamFieldValidation = {
        answer: '',
        question: '',
      };

      if (!question.question)
        fieldValidation.question = examSection.errorMessages.question;
      if (!question.answer)
        fieldValidation.answer = examSection.errorMessages.answer;

      setFieldValidations((prevState) => [...prevState, fieldValidation]);
      return Object.values(fieldValidation).some((validation) => !!validation);
    });
    return validations.every((validation) => !!!validation);
  };

  const resetExamEdits = () => {
    setUnsavedExamQuestions([]);
    setDeletedExamQuestions([]);
    setExamQuestions(_.sortBy(exam?.questions, 'maxScore', 'asc'));
  };

  const filterUnchangedExamQuestions = (questions: ExamQuestion[]) => {
    return questions.filter(
      (question) =>
        !(exam?.questions || [[]]).some((_question) =>
          _.isEqual(question, _question)
        )
    );
  };

  const handleAddExamQuestion = () => {
    const initialQuestion: UnsavedExamQuestion = {
      _id: ulid(),
      question: '',
      answer: '',
      maxScore: 2,
    };
    setUnsavedExamQuestions((prevState) => [...prevState, initialQuestion]);
  };

  const handleDeleteExamQuestion = (
    examQuestion: UnsavedExamQuestion | ExamQuestion
  ) => {
    if ('id' in examQuestion) {
      setDeletedExamQuestions((prevState) => [...prevState, examQuestion]);
    } else {
      // An exam question which only exists locally (has not yet been saved to the database) has no 'id' property, and need to be filtered out of the unsaved questions rather than deleted from the database
      setUnsavedExamQuestions((prevState) =>
        prevState.filter((q) => q._id !== examQuestion._id)
      );
    }
  };

  const handleChangeExamQuestion = (
    examQuestion: ExamQuestion | UnsavedExamQuestion
  ) => {
    if ('id' in examQuestion) {
      setExamQuestions((prevState) =>
        prevState.map((question) =>
          question.id === examQuestion.id ? examQuestion : question
        )
      );
    } else {
      // An exam question which only exists locally (has not yet been saved to the database) has no 'id' property, and needs to be updated within the unsaved questions list rather than within the original exam questions list
      setUnsavedExamQuestions((prevState) =>
        prevState.map((question) =>
          question._id === examQuestion._id ? examQuestion : question
        )
      );
    }
  };

  const isExamSubmissionOpen = useMemo(
    () => {
      // filter out saved answers for non existing (deleted) questions
      const filteredUserAnswers = userExamAnswers.filter((a) =>
        exam?.questions?.some((q) => a.examQuestionId === q.id)
      );
      return (
        filteredUserAnswers.length === exam?.questions?.length &&
        filteredUserAnswers.every(({ userAnswer }) => !!userAnswer)
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [userExamAnswers]
  );

  const _exam = useMemo(() => {
    if (!exam) return exam;
    return {
      ...exam,
      questions: [
        ...examQuestions.filter(
          (question) =>
            !deletedExamQuestions.some(
              (deletedQuestion) => deletedQuestion.id === question.id
            )
        ),
        ...unsavedExamQuestions,
      ],
    };
  }, [exam, unsavedExamQuestions, deletedExamQuestions, examQuestions]);

  return (
    <PaperConstraintAtom
      sx={{
        display: 'flex',
        flexDirection: 'column',
        rowGap: '16px',
        height: '100%',
        overflow: 'auto',
      }}
    >
      <ExamHeaderComponent
        isEditModeAvailable={!!isEditModeAvailable}
        isEditModeActive={isEditModeActive}
        handleActivateEditMode={handleActivateEditMode}
        handleCancelEditMode={handleCancelEditMode}
        handleConfirmEdit={handleConfirmEdit}
      />
      {!isAuthenticated && (
        <AuthenticationContainer>
          <MuiTypography>
            {commonStrings.studyMaterialSection.examSection.autenticationPrompt}
          </MuiTypography>
          <ButtonAtom
            variant="gradient"
            text={commonStrings.login}
            onClick={handleLogin}
          />
        </AuthenticationContainer>
      )}
      <Container>
        <MuiBox sx={{ width: '100%' }}>
          {_exam?.questions.map((question, index) => {
            if (isEditModeActive) {
              return (
                <EditExamQuestionComponent
                  key={index}
                  examQuestion={question}
                  fieldValidation={fieldValidations[index]}
                  number={index + 1}
                  handleDeleteExamQuestion={handleDeleteExamQuestion}
                  handleChangeExamQuestion={handleChangeExamQuestion}
                />
              );
            }
            return (
              <ExamQuestionComponent
                key={index}
                examQuestion={question}
                number={index + 1}
                answer={
                  userExamAnswers.find(
                    (answer) =>
                      answer.examQuestionId ===
                      ('id' in question ? question['id'] : question['_id'])
                  )?.userAnswer || ''
                }
                handleChangeAnswer={handleChangeAnswer}
                language={language}
              />
            );
          })}
        </MuiBox>
        {isEditModeActive ? (
          <ButtonAtom
            text={`${
              commonStrings.new
            } ${commonStrings.question.toLowerCase()}`}
            variant="primary"
            onClick={handleAddExamQuestion}
            endIcon={<AddOutlined />}
            sx={{ width: '100%' }}
          />
        ) : (
          <ButtonAtom
            variant="gradient"
            sx={{ width: '100%' }}
            text={examSection.evaluateExam}
            disabled={!isExamSubmissionOpen}
            onClick={handleSubmitExam}
            endIcon={<ArrowForward />}
          />
        )}
      </Container>
      {isEditModeActive && <InformationContainerComponent />}
    </PaperConstraintAtom>
  );
};
