import _ from 'lodash';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { Box as MuiBox } from '@mui/material';

import { commonStrings } from '../../../../assets/strings/sv';
import { Quiz, QuizSegment, QuizType, Status } from '../../../../generated';
import {
  useQuizContext,
  useStudyMaterialContext,
  useUsageContext,
} from '../../../../hooks';
import {
  ErrorStatusContainerMolecule,
  StatusContainerMolecule,
} from '../../molecules';
import {
  QuizCarouselComponent,
  QuizIntroComponent,
  QuizModalComponent,
  QuizSummaryComponent,
} from './components';
import { quizTypeToString } from '../../../helpers';
import { QuizState } from '../../../utils';
import { UserAnswer } from './types';

export const QuizOrganism = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { createQuiz } = useQuizContext();
  const { studyMaterial } = useStudyMaterialContext();
  const { studyMaterialSection, statusMessages } = commonStrings;

  const [selectedQuiz, setSelectedQuiz] = useState<Quiz | null>();
  const [activeQuizSegments, setActiveQuizSegments] = useState<QuizSegment[]>(
    []
  );
  const [savedQuizSegments, setSavedQuizSegments] = useState<QuizSegment[]>([]);
  const [selectedQuizType, setSelectedQuizType] = useState<QuizType>(
    QuizType.Statements
  );

  const [isQuizModalOpen, setIsQuizModalOpen] = useState<boolean>(false);
  const [quizState, setQuizState] = useState<QuizState>(QuizState.UNDEFINED);

  const [currentCardIndex, setCurrentCardIndex] = useState<number>(0);
  const [userAnswers, setUserAnswers] = useState<UserAnswer[]>([]);
  const [isQuizAnswerButtonDisabled, setIsQuizAnswerButtonDisabled] =
    useState(false);
  const [selectedAmountOfQuestions, setSelectedAmountOfQuestions] =
    useState(10);
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | undefined>(
    undefined
  );

  const incorrectAnswers = userAnswers.filter(
    (answer) => !answer.isUserAnswerCorrect
  );
  const correctAnswers = userAnswers.filter(
    (answer) => answer.isUserAnswerCorrect
  );

  const {
    collectStartedQuizStats,
    collectStartOverQuizStats,
    collectRetryWrongAnswersQuizStats,
    collectFinishedQuizStats,
  } = useUsageContext();

  useEffect(() => {
    const selectedQuiz =
      studyMaterial?.quiz?.[quizTypeToString[selectedQuizType]];
    setSelectedQuiz(selectedQuiz);
    if (selectedQuiz) {
      shuffleQuizSegments(selectedQuiz.quiz, selectedAmountOfQuestions);
    }
  }, [studyMaterial?.quiz, selectedQuizType, selectedAmountOfQuestions]);

  useEffect(() => {
    // If an error occurs while user waits for quiz to start, close quiz modal and show error snackbar
    if (
      studyMaterial?.status.quiz[quizTypeToString[selectedQuizType]] ===
        Status.Failed &&
      quizState !== QuizState.UNDEFINED
    ) {
      handleCancelQuiz();
      enqueueSnackbar(
        statusMessages.quiz.create[quizTypeToString[selectedQuizType]].error,
        {
          preventDuplicate: true,
          variant: 'error',
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [studyMaterial?.status, selectedQuizType]);

  const handleNextCard = () => {
    if (currentCardIndex < activeQuizSegments.length - 1) {
      setCurrentCardIndex(currentCardIndex + 1);
    } else {
      setQuizState(QuizState.CLOSE);
      selectedQuiz && collectFinishedQuizStats(selectedQuiz.type);
    }
    setIsQuizAnswerButtonDisabled(false);
  };

  const handleStartQuiz = () => {
    if (!studyMaterial) return;
    if (!selectedQuiz || selectedQuiz.quiz.length < selectedAmountOfQuestions) {
      const { language, id, text } = studyMaterial;
      createQuiz({
        type: selectedQuizType,
        studyMaterialId: id,
        language,
        text,
      });
    } else {
      shuffleQuizSegments(selectedQuiz.quiz, selectedAmountOfQuestions);
    }
    setIsQuizModalOpen(true);
    setQuizState(QuizState.ACTIVE);

    selectedQuizType && collectStartedQuizStats(selectedQuizType);
  };

  const handleRestartQuiz = () => {
    setActiveQuizSegments(savedQuizSegments);

    setUserAnswers([]);
    setQuizState(QuizState.ACTIVE);
    setCurrentCardIndex(0);

    selectedQuiz && collectStartOverQuizStats(selectedQuiz.type);
  };

  const handleRetryWrongAnswers = () => {
    setActiveQuizSegments(incorrectAnswers);

    setUserAnswers([]);
    setQuizState(QuizState.ACTIVE);
    setCurrentCardIndex(0);

    selectedQuiz && collectRetryWrongAnswersQuizStats(selectedQuiz.type);
  };

  const handleNextCardTimeout = () => {
    clearTimeout(timeoutId);
    const newTimeoutId = setTimeout(() => {
      handleNextCard();
    }, 1500);
    setTimeoutId(newTimeoutId);
  };

  const handleCancelQuiz = () => {
    clearTimeout(timeoutId);
    setIsQuizAnswerButtonDisabled(false);
    setUserAnswers([]);
    setQuizState(QuizState.UNDEFINED);
    setCurrentCardIndex(0);
    setIsQuizModalOpen(false);
  };

  const shuffleQuizSegments = (segments: QuizSegment[], amount: number) => {
    setActiveQuizSegments(_.shuffle(segments).slice(0, amount));
    setSavedQuizSegments(segments.slice(0, amount));
  };

  const handleUserAnswer = (answer: UserAnswer) => {
    setUserAnswers((prevAnswers) => [...prevAnswers, { ...answer }]);
  };

  return (
    <MuiBox flex={1} display="flex" flexDirection="column">
      {quizState === QuizState.UNDEFINED && (
        <QuizIntroComponent
          quiz={studyMaterial?.quiz}
          userId={studyMaterial?.userId}
          status={studyMaterial?.status.quiz}
          selectedQuizType={selectedQuizType}
          setSelectedQuizType={setSelectedQuizType}
          selectedAmountOfQuestions={selectedAmountOfQuestions}
          setSelectedAmountOfQuestions={setSelectedAmountOfQuestions}
          handleStartQuiz={handleStartQuiz}
        />
      )}
      <QuizModalComponent
        handleCloseModal={handleCancelQuiz}
        isQuizModalOpen={isQuizModalOpen}
        incorrectAnswers={incorrectAnswers}
        title={studyMaterial?.title}
        quizState={quizState}
        handleRestartQuiz={handleRestartQuiz}
        handleRetryWrongAnswers={handleRetryWrongAnswers}
      >
        {studyMaterial?.status.quiz[quizTypeToString[selectedQuizType]] ===
        Status.Failed ? (
          <ErrorStatusContainerMolecule
            title={studyMaterialSection.unknownErrorTitle}
            onClick={handleStartQuiz}
            buttonText={studyMaterialSection.retryButtonText}
            description={
              statusMessages.quiz.create[quizTypeToString[selectedQuizType]]
                .error
            }
          />
        ) : selectedQuiz ? (
          <>
            {quizState === QuizState.ACTIVE && (
              <QuizCarouselComponent
                segments={activeQuizSegments}
                language={studyMaterial?.language}
                type={selectedQuiz.type}
                currentCardIndex={currentCardIndex}
                userAnswers={userAnswers}
                handleUserAnswer={handleUserAnswer}
                setIsQuizAnswerButtonDisabled={setIsQuizAnswerButtonDisabled}
                isQuizAnswerButtonDisabled={isQuizAnswerButtonDisabled}
                handleNextCardTimeout={handleNextCardTimeout}
              />
            )}
            {quizState === QuizState.CLOSE && (
              <QuizSummaryComponent
                incorrectAnswers={incorrectAnswers}
                correctAnswers={correctAnswers}
                handleRestartQuiz={handleRestartQuiz}
                handleRetryWrongAnswers={handleRetryWrongAnswers}
                userAnswers={userAnswers}
              />
            )}
          </>
        ) : (
          <StatusContainerMolecule
            statusMessage={statusMessages.quiz.create.pending}
            descriptionText={statusMessages.quiz.create.descriptionText}
          />
        )}
      </QuizModalComponent>
    </MuiBox>
  );
};
