import { addItemToCache, deleteItemFromCache } from '../helpers';
import {
  CreateQuestionsInput,
  Question,
  SaveQuestionsInput,
  Status,
  useCreateQuestionsMutation,
  useDeleteQuestionMutation,
  useSaveQuestionsMutation,
  useUpdateQuestionMutation,
} from '../../generated';
import { GET_STUDY_MATERIAL } from '../../gql/queries';
import { useStudyMaterialStatusContext } from '../../hooks';
import { QuestionContext } from './context';

interface IQuestionProvider {
  children: React.ReactNode;
}

export const QuestionProvider = ({ children }: IQuestionProvider) => {
  const { updateStudyMaterialStatus } = useStudyMaterialStatusContext();

  const [_createQuestion] = useCreateQuestionsMutation();
  const [_updateQuestion] = useUpdateQuestionMutation();
  const [_deleteQuestion] = useDeleteQuestionMutation();
  const [_saveQuestions] = useSaveQuestionsMutation();

  const createQuestions = async (input: CreateQuestionsInput) => {
    updateStudyMaterialStatus({ questions: Status.Pending });
    await _createQuestion({
      variables: {
        input,
      },
      onError: (error) => {
        console.error('Could not create questions', { error });
      },
    });
  };

  const updateQuestions = async (questions: Question[]) => {
    questions.map(
      async ({ id, studyMaterialId, ...update }) =>
        await _updateQuestion({
          variables: {
            id,
            update,
          },
          onError: (error) => {
            console.error('Could not update question', { error });
          },
        })
    );
  };

  const deleteQuestions = async (questions: Question[]) => {
    questions.map(
      async ({ id }) =>
        await _deleteQuestion({
          variables: {
            deleteQuestionId: id,
          },
          update: (cache, _, { variables }) => {
            if (variables?.deleteQuestionId) {
              deleteItemFromCache(
                cache,
                variables.deleteQuestionId,
                'Question'
              );
            }
          },
          onError: (error) => {
            console.error('Could not delete question', { error });
          },
        })
    );
  };

  const saveQuestions = async (input: SaveQuestionsInput) => {
    await _saveQuestions({
      variables: {
        input,
      },
      update: (cache, { data }) => {
        data?.saveQuestions.map((question) =>
          addItemToCache(cache, question, {
            query: GET_STUDY_MATERIAL,
            variables: {
              getStudyMaterialId: input.studyMaterialId,
            },
            queryName: 'getStudyMaterial',
            nestedQuery: {
              queryName: 'questions',
            },
          })
        );
      },
      onError: (error) => {
        console.error('Could not save questions', { error });
      },
    });
  };

  const config = {
    createQuestions,
    updateQuestions,
    deleteQuestions,
    saveQuestions,
  };

  return (
    <QuestionContext.Provider value={config}>
      {children}
    </QuestionContext.Provider>
  );
};
