import { Box as MuiBox, styled } from '@mui/material';
import MuiCircularProgress from '@mui/material/CircularProgress';

export const GrayCircle = styled(MuiCircularProgress)(({ theme }) => ({
  color: theme.palette.grey[800],
  position: 'absolute',
  width: '104px !important',
  height: '104px !important',
}));

export const ProgressCircle = styled(MuiCircularProgress)(({ theme }) => ({
  width: '104px !important',
  height: '104px !important',
  filter: 'drop-shadow(0px 4px 20px rgba(221, 183, 217, 0.25))',
}));

export const Percentage = styled(MuiBox)(({ theme }) => ({
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));
