import { Box as MuiBox, styled } from '@mui/material';

export const Container = styled(MuiBox)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  alignSelf: 'center',
  justifyContent: 'center',
}));

export const CenteredBox = styled(MuiBox)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'center',
}));

export const ButtonBox = styled(MuiBox)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  gap: 10,
  marginTop: 17,
}));

export const GradientText = styled(MuiBox)(({ theme }) => ({
  backgroundImage: theme.palette.gradient,
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
}));
