import { Box as MuiBox, styled } from '@mui/material';

export const Tag = styled(MuiBox)(({ theme }) => ({
  width: 'fit-content',
  padding: '1px 13px 4px 13px',
  borderRadius: '24px',
  backgroundColor: theme.palette.background[800],
  textAlign: 'center',
  '& span': {
    lineHeight: '0 !important',
  },
}));
