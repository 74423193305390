import { Box as MuiBox } from '@mui/material';
import { Step, StepContainer, StepIndicator } from './styles';

interface IStepperComponent {
  steps: React.ReactElement[];
}

export const StepperComponent = ({ steps }: IStepperComponent) => {
  const stepHeight = 80;

  return (
    <StepContainer
      margin={`${stepHeight / 2}px 0px ${stepHeight / 2}px 20px`}
      height={`calc(${stepHeight * steps.length}px)`}
    >
      {steps.map((step, index) => (
        <>
          <Step
            height={stepHeight}
            top={`calc((100% / ${
              steps.length - 1
            } * ${index} - (${stepHeight}px / 2)))`}
          >
            <StepIndicator>{index + 1}</StepIndicator>
            <MuiBox width="100%" paddingLeft="16px">
              {step}
            </MuiBox>
          </Step>
        </>
      ))}
    </StepContainer>
  );
};
