import {
  PauseOutlined,
  ReplayOutlined,
  VolumeUpOutlined,
} from '@mui/icons-material';
import { useEffect, useMemo, useRef, useState } from 'react';

import { commonStrings } from '../../../../assets/strings/sv';
import { TextToSpeechAudioPlayer } from '../../../../classes';
import { PlayState } from '../../../../context';
import { collectTextToSpeechStats } from '../../../../context/usage/helpers';
import { useTextToSpeechContext } from '../../../../hooks';
import { ButtonGroupAtom, IconButtonAtom } from '../../atoms';
import { formatSentenceBoundary } from './helpers';
import { ISentenceBoundary } from './types';
import { ContentType } from '../../../../utils';

interface IAudioPlayer {
  text: string;
  language: string;
  statsContentType?: ContentType;
  displayResetButton?: boolean;
  setCaptionedCharacterIndexes?: (
    startCaptionIndex: number,
    endCaptionIndex: number
  ) => void;
  handlePlayCallback?: () => void;
}

interface ICaptionState {
  hasAudioStarted: boolean;
  currentSentenceIndex: number;
  textToAudioOffsetMillisec: number;
}

export const AudioPlayerMolecule = ({
  text,
  statsContentType,
  language,
  displayResetButton,
  setCaptionedCharacterIndexes,
  handlePlayCallback,
}: IAudioPlayer) => {
  const {
    play,
    pause,
    reset,
    activeAudioPlayer,
    sentenceBoundaryCharacters,
    sentenceBoundaryRegex,
  } = useTextToSpeechContext();

  const [captionState, setCaptionState] = useState<ICaptionState>({
    hasAudioStarted: false,
    currentSentenceIndex: 0,
    textToAudioOffsetMillisec: 900,
  });
  const [millisecCounter, setMillisecCounter] = useState<number>(0);
  const timerIntervalRef = useRef<NodeJS.Timer>();

  const [audioPlayer, setAudioPlayer] = useState<TextToSpeechAudioPlayer>();
  const [playState, setPlayState] = useState<PlayState>(
    PlayState.NOT_INITIATED
  );
  const playStateRef = useRef<PlayState>(PlayState.NOT_INITIATED);

  const [sentenceBoundaries, setSentenceBoundaries] = useState<
    ISentenceBoundary[]
  >([]);
  // Refs for accurate states in audioplayer functions
  const sentenceRef = useRef<ISentenceBoundary[]>([]);

  const { tooltips } = commonStrings;

  useEffect(() => {
    sentenceRef.current = sentenceBoundaries;
    playStateRef.current = playState;
  }, [sentenceBoundaries, playState]);

  useEffect(() => {
    // Cleanup function, stops audio on component unmount
    return () => {
      handleResetAudio();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audioPlayer]);

  const sentences = useMemo(() => {
    return text.match(sentenceBoundaryRegex) || [];
  }, [text, sentenceBoundaryRegex]);

  const textEndsWithSentenceBoundary = useMemo(() => {
    const lastCharacter = text.slice(text.length - 1);
    return sentenceBoundaryCharacters.some(
      (character) => lastCharacter === character
    );
  }, [text, sentenceBoundaryCharacters]);

  useEffect(() => {
    if (playState === PlayState.PLAYING && captionState.hasAudioStarted) {
      startAudioTimer(millisecCounter);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playState, captionState.hasAudioStarted]);

  const resetCaption = () => {
    clearInterval(timerIntervalRef.current);
    setCaptionState({
      hasAudioStarted: false,
      currentSentenceIndex: 0,
      textToAudioOffsetMillisec: 900,
    });
    setSentenceBoundaries([]);
    setCaptionedCharacterIndexes?.(0, 0);
    setMillisecCounter(0);
  };

  const startAudioTimer = (currentTime: number) => {
    // Ensures that the start time is correct even when player has been paused and restarted
    const timerStartTimeMillisec = Date.now() - currentTime;
    timerIntervalRef.current = setInterval(() => {
      setMillisecCounter(Date.now() - timerStartTimeMillisec);
    });
  };

  const stopAudioTimer = () => {
    clearInterval(timerIntervalRef.current);
    setMillisecCounter(0);
  };

  const handlePlayAudio = (
    e?: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e?.stopPropagation();
    let audioPlayerInstance;
    if (!audioPlayer) {
      audioPlayerInstance = new TextToSpeechAudioPlayer(language);
      setAudioPlayer(audioPlayerInstance);
    } else {
      audioPlayerInstance = audioPlayer;
    }
    play(text, audioPlayerInstance, playState, setPlayState);
    handlePlayCallback?.();

    statsContentType &&
      collectTextToSpeechStats(PlayState.PLAYING, statsContentType);
  };

  const handlePauseAudio = (
    e?: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e?.stopPropagation();

    if (audioPlayer) {
      pause(audioPlayer, setPlayState);
      clearInterval(timerIntervalRef.current);
    }
  };

  const handleResetAudio = (
    e?: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e?.stopPropagation();

    if (audioPlayer) {
      reset(audioPlayer, setAudioPlayer, setPlayState);
      resetCaption();
    }
  };

  useEffect(() => {
    const { currentSentenceIndex } = captionState;

    const nonSentenceBoundaryEndingSentence =
      !textEndsWithSentenceBoundary &&
      currentSentenceIndex === sentences.length - 1;

    const previousSentenceDuration =
      sentenceBoundaries[currentSentenceIndex - 1]?.millisecDuration || 0;
    const hasPreviousSentenceBeenRead =
      millisecCounter > previousSentenceDuration;

    const isCurrentSentenceBoundaryValid =
      sentenceBoundaries[currentSentenceIndex] ||
      nonSentenceBoundaryEndingSentence;

    const isNewSentenceRead =
      sentenceBoundaries.length &&
      hasPreviousSentenceBeenRead &&
      isCurrentSentenceBoundaryValid;

    if (isNewSentenceRead) {
      stopAudioTimer();

      const isLastSentenceBoundarySentenceRead =
        sentenceBoundaries.length === sentences.length &&
        currentSentenceIndex === sentenceBoundaries.length - 1;
      const isLastNonSentenceBoundarySentenceRead =
        nonSentenceBoundaryEndingSentence &&
        sentenceBoundaries.length === sentences.length - 1;

      const isLastSentenceRead =
        isLastSentenceBoundarySentenceRead ||
        isLastNonSentenceBoundarySentenceRead;

      const startIndex =
        sentenceBoundaries[currentSentenceIndex - 1]?.endIndex || 0;

      if (isLastSentenceRead) {
        // Last sentence is being read, set the last character to be hightlighted as the last character in the text
        setCaptionedCharacterIndexes?.(startIndex, text.length);
      } else {
        // Last sentence is not being read, set the last character to be highlighted as the last character in the current sentence
        const endIndex = sentenceBoundaries[currentSentenceIndex].endIndex;
        setCaptionedCharacterIndexes?.(startIndex, endIndex);
      }

      setCaptionState((prevState) => ({
        ...prevState,
        currentSentenceIndex: prevState.currentSentenceIndex + 1,
      }));
      startAudioTimer(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [millisecCounter]);

  if (audioPlayer) {
    audioPlayer.player.onAudioStart = () => {
      setCaptionState((prevState) => ({
        ...prevState,
        hasAudioStarted: true,
      }));
    };

    audioPlayer.player.onAudioEnd = () => {
      handleResetAudio();
    };

    audioPlayer.synthesizer.synthesisCompleted = () => {
      const sentenceBoundaries = sentenceRef.current;
      if (!sentenceBoundaries.length && !PlayState.NOT_INITIATED) {
        // If the synthesis is complete and no sentence boundaries has been identified, highlight the entire text
        setCaptionedCharacterIndexes?.(0, text.length);
      }
    };
    audioPlayer.synthesizer.wordBoundary = async (e, wordBoundaryEventArgs) => {
      const { textToAudioOffsetMillisec } = captionState;
      const sentenceBoundaries = sentenceRef.current;
      const playState = playStateRef.current;

      // Only save wordBoundaryEventArgs in state if they match any of the sentenceBoundaryCharacters
      const wordBoundaryIncludesSentenceBoundaryCharacter =
        sentenceBoundaryCharacters.some((character) =>
          wordBoundaryEventArgs.text.includes(character)
        );

      // Only format & save sentence boundary audio is still playing and not all sentences has been synthesized
      if (
        wordBoundaryIncludesSentenceBoundaryCharacter &&
        sentenceBoundaries.length < sentences.length &&
        playState === PlayState.PLAYING
      ) {
        const sentenceBoundary = formatSentenceBoundary(
          wordBoundaryEventArgs,
          sentenceBoundaries,
          sentences,
          textToAudioOffsetMillisec
        );

        setSentenceBoundaries((prevState) => [...prevState, sentenceBoundary]);
      }
    };
  }

  useEffect(() => {
    if (
      audioPlayer &&
      activeAudioPlayer &&
      activeAudioPlayer.player.id() !== audioPlayer.player.id() &&
      playState === PlayState.PLAYING
    ) {
      // Pauses the audio player if a different audio player is playing
      handlePauseAudio();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeAudioPlayer, audioPlayer, playState]);

  if (!displayResetButton) {
    return (
      <>
        {playState === PlayState.PLAYING ? (
          <IconButtonAtom
            icon={<PauseOutlined />}
            variant="dark"
            onClick={handlePauseAudio}
            tooltipTitle={tooltips.pause}
          />
        ) : (
          <IconButtonAtom
            icon={<VolumeUpOutlined />}
            variant="dark"
            onClick={handlePlayAudio}
            tooltipTitle={tooltips.play}
          />
        )}
      </>
    );
  }

  return (
    <ButtonGroupAtom
      buttons={[
        <>
          {playState === PlayState.PLAYING ? (
            <IconButtonAtom
              icon={<PauseOutlined />}
              onClick={handlePauseAudio}
              tooltipTitle={tooltips.pause}
            />
          ) : (
            <IconButtonAtom
              icon={<VolumeUpOutlined />}
              onClick={handlePlayAudio}
              tooltipTitle={tooltips.play}
            />
          )}
        </>,
        <IconButtonAtom
          icon={<ReplayOutlined />}
          onClick={handleResetAudio}
          tooltipTitle={tooltips.reset}
        />,
      ]}
    />
  );
};
