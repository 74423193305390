import { Box as MuiBox, styled } from '@mui/material';

export const Walkthrough = styled(MuiBox)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  gap: 42,
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },
}));

export const VideoPlaceholder = styled(MuiBox)(({ theme }) => ({
  flex: 1,
  minHeight: 400,
  width: '100%',
  backgroundColor: theme.palette.grey[900],
  borderRadius: theme.shape.borderRadius,
}));

export const TextSection = styled(MuiBox)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flex: 1,
}));

export const InnerTextSection = styled(MuiBox)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  rowGap: 16,
  flex: 0.8,
  [theme.breakpoints.down('md')]: {
    flex: 1,
  },
}));
