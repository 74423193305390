import {
  Box as MuiBox,
  Typography as MuiTypography,
  useTheme,
} from '@mui/material';
import { GrayCircle, Percentage, ProgressCircle } from './styles';

const GradientDefs = () => {
  const theme = useTheme();

  return (
    <svg width="104" height="104" style={{ position: 'absolute' }}>
      <defs>
        <linearGradient id="my_gradient" x1="0%" y1="0%" x2="0%" y2="100%">
          <stop offset="0%" stopColor={theme.palette.primary.main} />
          <stop offset="100%" stopColor={theme.palette.secondary.main} />
        </linearGradient>
      </defs>
    </svg>
  );
};

export const CircularProgressComponent = (props: any) => {
  return (
    <MuiBox position="relative" display="inline-flex">
      <GradientDefs />
      <GrayCircle variant="determinate" value={100} thickness={5} />
      <ProgressCircle
        sx={{ 'svg circle': { stroke: 'url(#my_gradient)' } }}
        variant="determinate"
        thickness={5}
        {...props}
      />
      <Percentage>
        <MuiTypography variant="h6" component="div">
          {`${props.value}%`}
        </MuiTypography>
      </Percentage>
    </MuiBox>
  );
};
