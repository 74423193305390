import { Box as MuiBox, styled } from '@mui/material';

export const QuestionSection = styled(MuiBox)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: 32,
}));

export const SectionHeader = styled(MuiBox)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

export const QuestionsContainer = styled(MuiBox)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: 16,
}));

export const ErrorContainer = styled(MuiBox)(({ theme }) => ({
  padding: 16,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: 16,
  borderRadius: theme.shape.borderRadius,
  border: `1px solid ${theme.palette.error[200]}`,
}));
