import { Box as MuiBox, styled } from '@mui/material';

export const Features = styled(MuiBox)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  rowGap: 32,
  paddingTop: 10,
  paddingBottom: 10,
}));

export const CardContainer = styled(MuiBox)(({ theme }) => ({
  display: 'flex',
  flex: 1,
  flexDirection: 'row',
  gap: 24,
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },
}));
