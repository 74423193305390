import React from 'react';

import { ModalMolecule, ShareLinkMolecule } from '../../../../../../molecules';
import { useUsageContext } from '../../../../../../../../hooks';
import {
  dashboardPageStrings as pageStrings,
  commonStrings,
} from '../../../../../../../../assets/strings/sv';

interface IShareComponent {
  isOpen: boolean;
  handleClose: () => void;
}

export const ShareComponent = ({ isOpen, handleClose }: IShareComponent) => {
  const { collectShareQurieUrlStats } = useUsageContext();
  const { productionLinkText } = commonStrings;
  const { shareQurieModal } = pageStrings;

  return (
    <ModalMolecule
      title={shareQurieModal.title}
      description={shareQurieModal.description}
      onClose={() => {
        handleClose();
      }}
      isOpen={isOpen}
    >
      <ShareLinkMolecule
        url={productionLinkText}
        handleCollectGaStats={collectShareQurieUrlStats}
      />
    </ModalMolecule>
  );
};
