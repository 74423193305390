import { Box as MuiBox, styled } from '@mui/material';

export const ButtonGroup = styled(MuiBox)(({ theme }) => ({
  '& button:last-of-type': {
    borderTopLeftRadius: '0px',
    borderBottomLeftRadius: '0px',
  },
  '& button:first-of-type': {
    borderTopRightRadius: '0px',
    borderBottomRightRadius: '0px',
  },
}));
