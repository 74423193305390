import { Box as MuiBox, styled } from '@mui/material';

export const Card = styled(MuiBox)(({ theme }) => ({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  rowGap: 12,
  padding: '24px 18px 18px 18px',
  backgroundColor: theme.palette.background[800],
  borderRadius: theme.shape.borderRadius,
}));
