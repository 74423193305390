import { Box as MuiBox, styled } from '@mui/material';

export const GradientContainer = styled(MuiBox, {
  shouldForwardProp: (prop) => prop !== 'gradientColor',
})<{ gradientColor?: string }>(({ theme, gradientColor }) => ({
  position: 'relative',
  display: 'flex',
  flex: '1',
  minWidth: 0,
  '&:before': {
    content: '""',
    position: 'absolute',
    left: -1,
    background: `linear-gradient(to right, ${
      gradientColor ? gradientColor : 'transparent'
    } 10%, transparent 90%, transparent 100%)`,
    minHeight: '100%',
    width: '10px',
    zIndex: '1',
    borderRadius: theme.shape.borderRadius,
  },
  '&:after': {
    content: '""',
    position: 'absolute',
    right: -1,
    background: `linear-gradient(to left, ${gradientColor} 10%, transparent 90%, transparent 100%)`,
    minHeight: '100%',
    width: '10px',
    zIndex: '1',
    borderRadius: theme.shape.borderRadius,
  },
}));

export const ScrollBoxContainer = styled(MuiBox)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '15px',
  overflowX: 'auto',
  width: '100%',
  borderRadius: theme.shape.borderRadius,
  paddingLeft: '8px',
  '&::-webkit-scrollbar': {
    height: '.5px',
  },
  '&::-webkit-scrollbar-thumb': {
    background: 'transparent',
  },
  '&::-webkit-scrollbar-track': {
    background: 'transparent',
  },
}));
