import {
  Box as MuiBox,
  Typography as MuiTypography,
  styled,
} from '@mui/material';

export const Container = styled(MuiBox)(({ theme }) => ({
  flex: 1,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  [theme.breakpoints.down('sm')]: {
    height: 'auto',
  },
}));

export const Card = styled(MuiBox)<{ borderColor: string | undefined }>(
  ({ theme, borderColor }) => ({
    width: 500,
    borderRadius: 24,
    border: `1px solid ${borderColor ? borderColor : theme.palette.grey[600]}`,
    backgroundColor: theme.palette.background[800],
    padding: 24,
    minHeight: '500px',
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      width: '90vw',
    },
  })
);

export const CardHeader = styled(MuiBox)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

export const InnerContainer = styled(MuiBox)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
}));

export const ButtonContainer = styled(MuiBox)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-around',
  gap: '12px',
  flexWrap: 'wrap',
}));

export const TypographyContainer = styled(MuiTypography)(({ theme }) => ({
  padding: '16px',
  [theme.breakpoints.down('sm')]: {
    padding: '0 8px 16px 8px',
  },
}));
