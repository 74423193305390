import { useEffect, useState } from 'react';
import { Box as MuiBox, Typography as MuiTypography } from '@mui/material';

import { commonStrings } from '../../../../assets/strings/sv';
import { FailureReason, Status, StudyMaterial } from '../../../../generated';
import {
  useLocalStorageState,
  useStudyMaterialContext,
  useSummaryContext,
} from '../../../../hooks';
import { LocalStorageKeys, TextMode, maxCharacterCount } from '../../../utils';
import {
  GradientBorderAtom,
  HighlightedTextAtom,
  PaperConstraintAtom,
  TextInputAtom,
  TextVideAtom,
} from '../../atoms';
import {
  ErrorStatusContainerMolecule,
  StatusContainerMolecule,
} from '../../molecules';
import { SummaryHeaderComponent, SummarySkeletonComponent } from './components';

export type SummaryOrganismFields = keyof Pick<StudyMaterial, 'summary'>;

export type SummaryFields = {
  [key in SummaryOrganismFields]: string;
};

interface ISummaryOrganism {
  isEditModeAvailable?: boolean;
}

export const SummaryOrganism = ({
  isEditModeAvailable = false,
}: ISummaryOrganism) => {
  const { createSummary, updateSummary } = useSummaryContext();
  const { studyMaterial } = useStudyMaterialContext();

  const [isEditModeActive, setIsEditModeActive] = useState<boolean>(false);
  const [textMode, setTextMode] = useLocalStorageState(
    TextMode.REGULAR,
    LocalStorageKeys.SELECTED_TEXT_MODE
  );

  const { studyMaterialSection, statusMessages } = commonStrings;
  const { summarySection } = studyMaterialSection;

  const [highlightIndexes, setHighlightIndexes] = useState({
    end: 0,
    start: 0,
  });

  const [summary, setSummary] = useState(studyMaterial?.summary?.summary || '');
  const [fieldErrors, setFieldErrors] = useState({
    summary: '',
  });

  useEffect(() => {
    resetFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [studyMaterial]);

  const resetFields = () => {
    setSummary(studyMaterial?.summary?.summary || '');
  };

  const resetFieldErrors = () => {
    setFieldErrors({
      summary: '',
    });
  };

  const validateSummary = (summary: string) => {
    if (!summary) {
      setFieldErrors({ summary: summarySection.errorMessages.summary });
      return false;
    }
    return true;
  };

  const handleChangeSummary = (summary: string) => {
    resetFieldErrors();
    setSummary(summary);
  };

  const handleActivateEdit = () => setIsEditModeActive(true);
  const handleCancelEdit = () => {
    resetFields();
    resetFieldErrors();
    setIsEditModeActive(false);
  };

  const handleConfirmEdit = () => {
    if (!validateSummary(summary) || !studyMaterial) return;

    updateSummary(studyMaterial.summary?.id || '', { summary });

    resetFieldErrors();
    setIsEditModeActive(false);
  };

  const handleHighlight = (startIndex: number, endIndex: number) => {
    setHighlightIndexes({
      start: startIndex,
      end: endIndex,
    });
  };

  const handleTextModeSwitch = () => {
    const selectedTextMode =
      textMode === TextMode.REGULAR ? TextMode.TEXT_VIDE : TextMode.REGULAR;
    setTextMode(selectedTextMode);
  };

  if (studyMaterial?.status.summary === Status.Failed) {
    const { id, language, text } = studyMaterial;
    const summaryErrorMessage =
      statusMessages.summary.create.error.default[
        studyMaterial.failureReason === FailureReason.ContentViolation
          ? 'contentViolation'
          : 'default'
      ];

    return (
      <ErrorStatusContainerMolecule
        title={studyMaterialSection.unknownErrorTitle}
        buttonText={studyMaterialSection.retryButtonText}
        description={summaryErrorMessage}
        onClick={() => createSummary({ studyMaterialId: id, language, text })}
      />
    );
  }

  if (!studyMaterial || studyMaterial?.status.summary === Status.Pending) {
    return (
      <PaperConstraintAtom>
        <SummarySkeletonComponent editMode={isEditModeAvailable} />
        <StatusContainerMolecule
          statusMessage={statusMessages.summary.create.pending}
          descriptionText={statusMessages.summary.create.descriptionText}
        />
      </PaperConstraintAtom>
    );
  }

  return (
    <PaperConstraintAtom>
      <SummaryHeaderComponent
        studyMaterial={studyMaterial}
        isEditModeAvailable={isEditModeAvailable}
        isEditModeActive={isEditModeActive}
        textMode={textMode}
        handleActivateEdit={handleActivateEdit}
        handleCancelEdit={handleCancelEdit}
        handleConfirmEditSummary={handleConfirmEdit}
        handleTextModeSwitch={handleTextModeSwitch}
        setHighlightIndexes={(start, end) => handleHighlight(start, end)}
      />
      <GradientBorderAtom>
        {isEditModeActive ? (
          <TextInputAtom
            variant="transparent"
            placeholder={summarySection.placeholder}
            value={summary || summarySection.placeholder}
            onChange={handleChangeSummary}
            inputProps={{ maxLength: maxCharacterCount }}
            multiline
            fullWidth
            errorMessage={fieldErrors.summary}
          />
        ) : textMode === TextMode.REGULAR ? (
          <MuiBox padding={2}>
            <HighlightedTextAtom
              text={summary || summarySection.placeholder}
              startHighlightIndex={highlightIndexes.start}
              endHighlightIndex={highlightIndexes.end}
            />
          </MuiBox>
        ) : (
          <MuiBox padding={2}>
            <TextVideAtom regularText={summary || summarySection.placeholder} />
          </MuiBox>
        )}
      </GradientBorderAtom>
      <MuiBox marginTop="auto" justifySelf="flex-end">
        <MuiTypography
          marginTop={3}
          color="grey"
          variant="subtitle2"
          textAlign="center"
        >
          {commonStrings.disclaimer}
        </MuiTypography>
      </MuiBox>
    </PaperConstraintAtom>
  );
};
