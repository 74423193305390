import { Typography as MuiTypography } from '@mui/material';

import { Container } from './styles';
import { IconButtonAtom } from '../../../../../../atoms';
import { commonStrings } from '../../../../../../../../assets/strings/sv';

export const InformationContainerComponent = () => {
  return (
    <Container>
      <IconButtonAtom
        shape="round"
        variant="dark"
        icon={<MuiTypography fontWeight={800}>i</MuiTypography>}
        onClick={() => {}}
        sx={{
          height: 30,
          width: 30,
        }}
      />
      <MuiTypography>
        {commonStrings.studyMaterialSection.examSection.editInformation}
      </MuiTypography>
    </Container>
  );
};
