import {
  CreateFlashcardsInput,
  SaveFlashcardsInput,
  Status,
  UpdateFlashcardInput,
  useCreateFlashcardsMutation,
  useDeleteFlashcardMutation,
  useSaveFlashcardsMutation,
  useUpdateFlashcardMutation,
} from '../../generated';
import { GET_STUDY_MATERIAL } from '../../gql/queries';
import { useStudyMaterialStatusContext } from '../../hooks';
import { addItemToCache, deleteItemFromCache } from '../helpers';
import { FlashcardContext } from './context';

interface IFlashcardProvider {
  children: React.ReactNode;
}

export const FlashcardProvider = ({ children }: IFlashcardProvider) => {
  const { updateStudyMaterialStatus } = useStudyMaterialStatusContext();

  const [_createFlashcards] = useCreateFlashcardsMutation();
  const [_deleteFlashcard] = useDeleteFlashcardMutation();
  const [_saveFlashcards] = useSaveFlashcardsMutation();
  const [_updateFlashcard] = useUpdateFlashcardMutation();

  const createFlashcards = async (input: CreateFlashcardsInput) => {
    updateStudyMaterialStatus({ flashcards: Status.Pending });
    await _createFlashcards({
      variables: {
        input,
      },
      onError: (error) => {
        console.error('Could not create flashcards', { error });
      },
    });
  };

  const saveFlashcards = async (input: SaveFlashcardsInput) => {
    await _saveFlashcards({
      variables: {
        input,
      },
      update: (cache, { data }) => {
        data?.saveFlashcards.map((flashcard) =>
          addItemToCache(cache, flashcard, {
            query: GET_STUDY_MATERIAL,
            variables: {
              getStudyMaterialId: input.studyMaterialId,
            },
            queryName: 'getStudyMaterial',
            nestedQuery: {
              queryName: 'flashcards',
            },
          })
        );
      },
    });
  };

  const updateFlashcard = async (id: string, update: UpdateFlashcardInput) => {
    await _updateFlashcard({
      variables: {
        id,
        update,
      },
      onError: (error) => {
        console.error('Could not update flashcard', { error });
      },
    });
  };

  const deleteFlashcard = async (id: string) => {
    await _deleteFlashcard({
      variables: {
        id,
      },
      update: (cache, _, { variables }) => {
        if (variables?.id) {
          deleteItemFromCache(cache, variables.id, 'Flashcard');
        }
      },
      onError: (error) => {
        console.error('Could not delete flashcard', { error });
      },
    });
  };

  const config = {
    createFlashcards,
    deleteFlashcard,
    saveFlashcards,
    updateFlashcard,
  };

  return (
    <FlashcardContext.Provider value={config}>
      {children}
    </FlashcardContext.Provider>
  );
};
