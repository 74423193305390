import {
  Box as MuiBox,
  Typography as MuiTypography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import {
  AddOutlined,
  DeleteOutline,
  RemoveOutlined,
} from '@mui/icons-material';

import {
  MultipleChoices,
  Choice as ChoiceType,
} from '../../../../../../../../../../generated';
import {
  ButtonAtom,
  IconButtonAtom,
  TextInputAtom,
  ToggleAtom,
} from '../../../../../../../../atoms';
import {
  Choice,
  ChoiceActionContainer,
  ChoiceContainer,
  MultipleChoiceQuestion,
  NumberContainer,
  QuestionContainer,
  SegmentContainer,
  ToggleContainer,
} from './styles';
import { MultipleChoiceErrorMessage } from '../../QuizEditModalComponent';
import { commonStrings } from '../../../../../../../../../../assets/strings/sv';

interface IMultipleChoiceQuestion {
  segment: MultipleChoices;
  index: number;
  errorMessage?: MultipleChoiceErrorMessage;
  handleChangeMultipleChoices: (
    segment: MultipleChoices,
    index: number
  ) => void;
  handleDeleteMultipleChoices: (index: number) => void;
}

export const MultipleChoiceQuestionComponent = ({
  segment,
  index,
  errorMessage,
  handleChangeMultipleChoices,
  handleDeleteMultipleChoices,
}: IMultipleChoiceQuestion) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const { quizSection } = commonStrings.studyMaterialSection;

  const handleChangeChoice = (choice: ChoiceType, _index: number) => {
    const updatedChoices = segment.choices.map((_choice, i) =>
      i === _index ? choice : _choice
    );
    handleChangeMultipleChoices({ ...segment, choices: updatedChoices }, index);
  };

  const handleDeleteChoice = (_index: number) => {
    const updatedChoices = segment.choices.filter((_, i) => i !== _index);
    handleChangeMultipleChoices({ ...segment, choices: updatedChoices }, index);
  };

  const handleAddChoice = () => {
    const defaultChoice: ChoiceType = {
      option: '',
      isTrue: false,
    };

    handleChangeMultipleChoices(
      { ...segment, choices: [...segment.choices, defaultChoice] },
      index
    );
  };

  return (
    <MultipleChoiceQuestion>
      <NumberContainer>{index + 1}</NumberContainer>
      <SegmentContainer>
        <QuestionContainer>
          <TextInputAtom
            variant="filled"
            multiline
            errorMessage={errorMessage?.question}
            value={segment.question}
            onChange={(question) =>
              handleChangeMultipleChoices({ ...segment, question }, index)
            }
            sx={{ width: '100%' }}
          />
          <IconButtonAtom
            variant="light"
            icon={<DeleteOutline />}
            onClick={() => handleDeleteMultipleChoices(index)}
          />
        </QuestionContainer>
        <MuiBox display="flex" flexDirection="column" gap={1}>
          {segment.choices.length ? (
            <>
              {segment.choices.map((choice, _index) => (
                <Choice key={_index}>
                  <ChoiceContainer>
                    <TextInputAtom
                      variant="filled"
                      multiline
                      size="small"
                      errorMessage={errorMessage?.choices?.[_index]?.option}
                      value={choice.option}
                      onChange={(option) =>
                        handleChangeChoice({ ...choice, option }, _index)
                      }
                      sx={{ width: '100%' }}
                    />
                    <ChoiceActionContainer>
                      <ToggleContainer>
                        <MuiTypography>
                          {quizSection.statementIsTrue}
                        </MuiTypography>
                        <ToggleAtom
                          size="small"
                          isToggled={choice.isTrue}
                          handleToggle={() =>
                            handleChangeChoice(
                              { ...choice, isTrue: !choice.isTrue },
                              _index
                            )
                          }
                        />
                      </ToggleContainer>
                      {isMobile ? (
                        <IconButtonAtom
                          variant="light"
                          shape="round"
                          size="small"
                          icon={<RemoveOutlined />}
                          onClick={() => handleDeleteChoice(_index)}
                        />
                      ) : (
                        <ButtonAtom
                          text={quizSection.deleteChoice}
                          variant="border"
                          onClick={() => handleDeleteChoice(_index)}
                        />
                      )}
                    </ChoiceActionContainer>
                  </ChoiceContainer>
                </Choice>
              ))}
            </>
          ) : (
            <MuiTypography>{quizSection.noChoices}</MuiTypography>
          )}
          <MuiBox marginLeft="auto">
            <ButtonAtom
              text={quizSection.addChoice}
              variant="primary"
              endIcon={<AddOutlined fontSize="small" />}
              onClick={handleAddChoice}
            />
          </MuiBox>
        </MuiBox>
      </SegmentContainer>
    </MultipleChoiceQuestion>
  );
};
