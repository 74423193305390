import { Components, Theme } from '@mui/material';

export const MuiTextFieldOverride = (theme: Theme) => {
  const MuiTextField: Components = {
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiInputBase-root': {
            ...theme.typography.body1,
            borderRadius: theme.shape.borderRadius,
            fontWeight: 400,
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          color: `${theme.palette.error.light} !important`,
        },
      },
    },
  };
  return MuiTextField;
};
