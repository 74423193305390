import { Box as MuiBox, alpha, styled } from '@mui/material';

export const Switch = styled(MuiBox, {
  shouldForwardProp: (prop) => prop !== 'isToggled' && prop !== 'isDisabled',
})<{ isToggled: boolean; isDisabled: boolean; size: 'large' | 'small' }>(
  ({ theme, isToggled, isDisabled, size }) => ({
    width: size === 'small' ? '40px' : '56px',
    height: size === 'small' ? '24px' : '32px',
    padding: '4px',
    borderRadius: size === 'small' ? '32px' : '40px',
    background: isToggled
      ? theme.palette.primary[400]
      : alpha(theme.palette.common.white, 0.2),
    position: 'relative',
    cursor: 'pointer',
    pointerEvents: isDisabled ? 'none' : 'auto',
    opacity: isDisabled ? 0.5 : 1,
  })
);

export const Ball = styled(MuiBox, {
  shouldForwardProp: (prop) => prop !== 'isToggled',
})<{ isToggled: boolean; size: 'small' | 'large' }>(
  ({ theme, isToggled, size }) => ({
    position: 'absolute',
    top: '12.5%',
    left:
      size === 'small'
        ? isToggled
          ? '18px'
          : '4px'
        : isToggled
        ? '28px'
        : '4px',
    padding: '4px',
    width: size === 'small' ? '18px' : '24px',
    height: size === 'small' ? '18px' : '24px',
    borderRadius: '40px',
    background: theme.palette.background[800],
    transition: 'left 0.3s',
  })
);
