import React, { useEffect, useState } from 'react';

import { Language, QuizSegment, QuizType } from '../../../../../../generated';
import { UserAnswer } from '../../types';
import { QuizCardComponent } from './components';
import { CardContainer, Container } from './styles';

interface IQuizCarouselComponent {
  segments: QuizSegment[];
  language?: Language;
  type: QuizType;
  currentCardIndex: number;
  userAnswers: UserAnswer[];
  handleUserAnswer: (asnwer: UserAnswer) => void;
  setIsQuizAnswerButtonDisabled: React.Dispatch<React.SetStateAction<boolean>>;
  isQuizAnswerButtonDisabled: boolean;
  handleNextCardTimeout: () => void;
}

export const QuizCarouselComponent = ({
  segments,
  type,
  language,
  userAnswers,
  currentCardIndex,
  handleUserAnswer,
  setIsQuizAnswerButtonDisabled,
  isQuizAnswerButtonDisabled,
  handleNextCardTimeout,
}: IQuizCarouselComponent) => {
  const [cardsPositionPercentage, setCardsPositionPercentage] = useState<
    number[]
  >(segments.map(() => 0));
  const [cardsVisiblity, setCardsVisibility] = useState<boolean[]>(
    segments.map(() => true)
  );

  useEffect(() => {
    // animate inactive cards to move to left of screen
    const position = segments.map((_, index) =>
      index < currentCardIndex ? -100 : 0
    );
    setCardsPositionPercentage(position);

    const visibility = segments.map((_, index) => index >= currentCardIndex);
    setTimeout(() => {
      // unmount inactive cards to ensure audio stops playing
      setCardsVisibility(visibility);
    }, 500);
  }, [currentCardIndex, segments]);

  return (
    <Container>
      {segments.map((segment: QuizSegment, index: number) => {
        if (cardsVisiblity[index]) {
          return (
            <CardContainer
              key={index}
              sx={{
                marginLeft: `${cardsPositionPercentage[index]}%`,
              }}
            >
              <QuizCardComponent
                userAnswer={userAnswers[index]}
                handleUserAnswer={handleUserAnswer}
                item={segment}
                language={language}
                index={index + 1}
                quizType={type}
                totalAmount={segments.length}
                setIsQuizAnswerButtonDisabled={setIsQuizAnswerButtonDisabled}
                isQuizAnswerButtonDisabled={isQuizAnswerButtonDisabled}
                handleNextCardTimeout={handleNextCardTimeout}
              />
            </CardContainer>
          );
        }

        return <div key={index}></div>;
      })}
    </Container>
  );
};
