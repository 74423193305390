import React from 'react';
import {
  Typography as MuiTypography,
  SxProps,
  Theme,
  useTheme,
} from '@mui/material';

import { IconAtom } from '../../../../../../atoms';
import { MenuItem } from './styles';

interface IMenuItemComponent {
  text?: string;
  icon: JSX.Element;
  isSelected?: boolean;
  disabled?: boolean;
  sx?: SxProps<Theme>;
  onClick: () => void;
}

export const MenuItemComponent = ({
  text,
  icon,
  isSelected,
  disabled,
  sx,
  onClick,
}: IMenuItemComponent) => {
  const theme = useTheme();
  return (
    <MenuItem gap={1.5} onClick={onClick} sx={sx} disabled={disabled}>
      <IconAtom
        icon={icon}
        color={
          disabled
            ? theme.palette.text.disabled
            : isSelected
            ? theme.palette.getContrastText(theme.palette.text.primary)
            : theme.palette.text.secondary
        }
        backgroundColor={
          isSelected ? theme.palette.text.primary : 'transparent'
        }
      />
      <MuiTypography
        color={
          disabled
            ? theme.palette.text.disabled
            : isSelected
            ? theme.palette.text.primary
            : theme.palette.text.secondary
        }
      >
        {text}
      </MuiTypography>
    </MenuItem>
  );
};
