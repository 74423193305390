import features from './images/features.svg';
import { Gallery } from './styles';

export const GalleryComponent = () => {
  return (
    <Gallery>
      <img width={'100%'} src={features} alt="featuer_gallery" />
    </Gallery>
  );
};
