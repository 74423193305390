import React from 'react';

import backgroundImage from '../../../assets/images/backgroundImage.svg';
import { GraphicBackground } from './styles';

interface IGraphicLayout {
  children: React.ReactNode;
}
export const GraphicLayout = ({ children }: IGraphicLayout) => {
  return (
    <GraphicBackground backgroundImage={backgroundImage}>
      {children}
    </GraphicBackground>
  );
};
