import { Helmet } from 'react-helmet-async';

import { commonStrings } from '../../../assets/strings/sv';

interface ISeoComponent {
  title?: string;
  description?: string;
  image?: string;
}

export const SeoComponent = ({ title, description, image }: ISeoComponent) => {
  const { defaultSeo } = commonStrings;

  return (
    <Helmet prioritizeSeoTags>
      <title>{title || defaultSeo.title}</title>
      <meta name="title" content={title || defaultSeo.title} />
      <meta
        name="description"
        content={description || defaultSeo.description}
      />
      <meta name="image" content={image || defaultSeo.image} />

      {/* Open Graph / Facebook */}
      <meta property="og:type" content="website" />
      <meta property="og:url" content={window.location.origin} />
      <meta property="og:title" content={title || defaultSeo.title} />
      <meta
        property="og:description"
        content={description || defaultSeo.description}
      />
      <meta property="og:image" content={image || defaultSeo.image} />

      {/* Twitter */}
      <meta property="twitter:url" content={window.location.origin} />
      <meta property="twitter:title" content={title || defaultSeo.title} />
      <meta
        property="twitter:description"
        content={description || defaultSeo.description}
      />
      <meta property="twitter:image" content={image || defaultSeo.image} />
    </Helmet>
  );
};
