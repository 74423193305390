import {
  Dialog as MuiDialog,
  Typography as MuiTypography,
} from '@mui/material';
import Lottie from 'lottie-react';

import { commonStrings } from '../../../../../assets/strings/sv';
import streakIcon from './images/streak-icon.png';
import animationData from './animations/medal.json';
import {
  AnimationContainer,
  ModalContainer,
  StreakTagContainer,
  SubTitleContainer,
  TextContainer,
  TitleContainer,
} from './styles';

interface IStreakPopupComponent {
  showStreakModal: boolean;
  handleCloseStreakModal: () => void;
  streakNumber: number;
}

export const StreakPopupComponent = ({
  showStreakModal,
  handleCloseStreakModal,
  streakNumber,
}: IStreakPopupComponent) => {
  return (
    <MuiDialog
      open={showStreakModal}
      onClose={handleCloseStreakModal}
      sx={{
        '& .MuiPaper-root': {
          backgroundImage: 'none !important',
        },
      }}
    >
      <ModalContainer>
        <TextContainer gap={'16px'}>
          <StreakTagContainer>
            <img src={streakIcon} alt="Icon of a flame." />
            <MuiTypography variant="caption">
              {commonStrings.streakModal.tagText}
            </MuiTypography>
          </StreakTagContainer>

          <TextContainer gap={'8px'}>
            <TitleContainer variant="h6">
              {streakNumber} {commonStrings.streakModal.title}
            </TitleContainer>
            <SubTitleContainer variant="subtitle2">
              {commonStrings.streakModal.subtitle}
            </SubTitleContainer>
          </TextContainer>
        </TextContainer>
        <AnimationContainer>
          <Lottie
            animationData={animationData}
            style={{ width: '88px', height: '93px' }}
          />
        </AnimationContainer>
      </ModalContainer>
    </MuiDialog>
  );
};
