import { CancelRounded, CheckCircleRounded } from '@mui/icons-material';
import { useTheme } from '@mui/material';

import { RadioButtonAtom } from '../../../../../../../../atoms';
import { ButtonType } from '../../types';
import { Container, buttonProps } from './styles';

interface IQuizRadioButtonComponent {
  text: string;
  buttonType: ButtonType;
  onClick: (text: string) => void;
  disabled?: boolean;
  label: string;
}

export const QuizRadioButtonComponent = ({
  text,
  buttonType,
  onClick,
  disabled,
  label,
}: IQuizRadioButtonComponent) => {
  const theme = useTheme();

  const renderButton = (buttonType: ButtonType) => {
    switch (buttonType) {
      case ButtonType.UNDEFINED:
        return (
          <Container>
            <RadioButtonAtom
              sx={buttonProps}
              value={text}
              label={label}
              onClick={() => onClick(text)}
              disabled={disabled}
            />
          </Container>
        );
      case ButtonType.CORRECT_SELECTED:
        return (
          <Container>
            <RadioButtonAtom
              sx={{
                ...buttonProps,
                border: `1px solid ${theme.palette.success.main}`,
              }}
              value={text}
              label={label}
              controlIcon={
                <CheckCircleRounded
                  color={'success'}
                  sx={{ margin: '0 9px' }}
                />
              }
              onClick={() => onClick(text)}
              disabled={disabled}
            />
          </Container>
        );
      case ButtonType.INCORRECT_SELECTED:
        return (
          <Container>
            <RadioButtonAtom
              sx={{
                ...buttonProps,
                border: `1px solid ${theme.palette.error[200]}`,
              }}
              value={text}
              label={label}
              controlIcon={
                <CancelRounded
                  sx={{ margin: '0 9px', color: theme.palette.error[200] }}
                />
              }
              onClick={() => onClick(text)}
              disabled={disabled}
            />
          </Container>
        );
      case ButtonType.CORRECT_NOT_SELECTED:
        return (
          <Container>
            <RadioButtonAtom
              sx={{
                ...buttonProps,
                border: `1px solid ${theme.palette.divider}`,
              }}
              value={text}
              label={label}
              controlIcon={
                <CheckCircleRounded
                  color={'success'}
                  sx={{ margin: '0 9px' }}
                />
              }
              onClick={() => onClick(text)}
              disabled={disabled}
            />
          </Container>
        );
    }
  };

  return <>{renderButton(buttonType)}</>;
};
