import React from 'react';
import { Typography as MuiTypography } from '@mui/material';

import { Tag } from './styles';

interface ITagAtom {
  text: string;
}

export const TagAtom = ({ text }: ITagAtom) => {
  return (
    <Tag>
      <MuiTypography variant="caption">{text}</MuiTypography>
    </Tag>
  );
};
