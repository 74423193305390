import { Box as MuiBox, Link as MuiLink, alpha, styled } from '@mui/material';

export const Card = styled(MuiBox)(({ theme }) => ({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  rowGap: 12,
  padding: '24px 18px 18px 18px',
  backgroundColor: theme.palette.background[800],
  borderRadius: theme.shape.borderRadius,
}));

export const Link = styled(MuiLink)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  columnGap: 6,
  color: theme.palette.common.white,
  textDecorationColor: alpha(theme.palette.common.white, 0.5),
}));
