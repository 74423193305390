import { Box as MuiBox, alpha, styled } from '@mui/material';

export const Container = styled(MuiBox)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 10,
  marginBottom: 15,
}));

export const CategoryHeader = styled(MuiBox)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
}));

export const Overlay = styled(MuiBox)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  right: 0,
  left: 0,
  bottom: 0,
  backgroundColor: alpha(theme.palette.background[800] || '', 0.7),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const LockedContainer = styled(MuiBox)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  rowGap: '10px',
  padding: '20px 40px',
  border: `1px ${theme.palette.grey[600]} solid`,
  borderRadius: theme.shape.borderRadius,
}));
