import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useMemo, useState } from 'react';
import { Box as MuiBox } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import logo from '../../../assets/images/logo.png';
import { dashboardPageStrings as pageStrings } from '../../../assets/strings/sv';
import { StudyMaterial } from '../../../generated';
import { useLocalStorageState, useStudyMaterialContext } from '../../../hooks';
import { LocalStorageKeys } from '../../utils';
import {
  StarterGuideModalComponent,
  WelcomeSectionComponent,
  HeaderComponent,
} from './components';
import { Dashboard, ContentContainer } from './styles';
import {
  BookmarkedStudyMaterialModuleMolecule,
  FolderModuleMolecule,
  StudyMaterialModuleMolecule,
  ToggleableModuleGridMolecule,
} from '../../library/molecules';

export const DashboardPage = () => {
  const { user } = useAuth0();
  const navigate = useNavigate();
  const {
    studyMaterials,
    studyMaterialsLoading,
    bookmarkedStudyMaterials,
    bookmarkedStudyMaterialsLoading,
    deleteStudyMaterial,
    publishStudyMaterial,
    saveStudyMaterial,
  } = useStudyMaterialContext();

  const [showInfoModal, setShowInfoModal] = useState(false);
  const [pendingStudyMaterial, setPendingStudyMaterial] = useLocalStorageState<{
    id?: StudyMaterial['id'];
  }>(
    {
      id: undefined,
    },
    LocalStorageKeys.PENDING_STUDY_MATERIAL
  );

  const { folderSection, studyMaterialSection, bookmarkedSection } =
    pageStrings;

  useEffect(() => {
    if (pendingStudyMaterial?.id) {
      saveStudyMaterial(pendingStudyMaterial.id);
      setPendingStudyMaterial({ id: undefined });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pendingStudyMaterial]);

  const handleMenuItems = (studyMaterial: StudyMaterial) => [
    {
      text: pageStrings.moduleMenuItems.deleteStudyMaterial,
      onClick: () => deleteStudyMaterial(studyMaterial.id),
    },
    {
      text: studyMaterial.flags?.isPublished
        ? pageStrings.moduleMenuItems.unpublishStudyMaterial
        : pageStrings.moduleMenuItems.publishStudyMaterial,
      onClick: () => publishStudyMaterial(studyMaterial.id),
    },
  ];

  const handleOpenStarterGuideModal = () => {
    setShowInfoModal(true);
  };
  const handleCloseStarterGuideModal = () => {
    setShowInfoModal(false);
  };

  const moduleSections = useMemo(() => {
    return [
      {
        modules: studyMaterials.map((studyMaterial, index) => (
          <StudyMaterialModuleMolecule
            key={index}
            avatarSrc={user?.picture || logo}
            studyMaterial={studyMaterial as StudyMaterial}
            menuItems={handleMenuItems(studyMaterial as StudyMaterial)}
            onClick={() => navigate(`/material/${studyMaterial.id}/summary`)}
          />
        )),
        isLoading: studyMaterialsLoading,
        title: studyMaterialSection.title,
      },
      {
        modules: bookmarkedStudyMaterials
          .filter(({ studyMaterial }) => studyMaterial)
          .map(({ studyMaterial }, index) => (
            <BookmarkedStudyMaterialModuleMolecule
              key={index}
              avatarSrc={studyMaterial?.user?.picture}
              studyMaterial={studyMaterial as StudyMaterial}
            />
          )),
        isLoading: bookmarkedStudyMaterialsLoading,
        title: bookmarkedSection.title,
      },
      {
        modules: folderSection.exampleFolders.map((folder, index) => (
          <FolderModuleMolecule
            key={index}
            title={folder.title}
            tagText={`${folder.studyMaterial.length} ${
              folder.studyMaterial.length > 1
                ? folderSection.lessons
                : folderSection.lesson
            }`}
            menuItems={[]}
          />
        )),
        isLocked: true,
        isLoading: false,
        title: folderSection.title.auth,
      },
    ];

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    studyMaterials,
    studyMaterialsLoading,
    bookmarkedStudyMaterials,
    bookmarkedStudyMaterialsLoading,
  ]);

  const renderMenuContent = () => {
    return (
      <>
        {moduleSections.every(
          (section) => !section.isLoading && !section.modules.length
        ) ? (
          <WelcomeSectionComponent
            handleOpenStarterGuideModal={handleOpenStarterGuideModal}
          />
        ) : (
          <MuiBox display="flex" flexDirection="column" rowGap={5}>
            {moduleSections.map(
              ({ title, isLoading, modules, isLocked }, index) => (
                <MuiBox
                  display="flex"
                  flexDirection="column"
                  rowGap={1}
                  key={index}
                >
                  <ToggleableModuleGridMolecule
                    title={title}
                    isLocked={isLocked}
                    isLoading={isLoading}
                    modules={modules}
                  />
                </MuiBox>
              )
            )}
          </MuiBox>
        )}
      </>
    );
  };

  return (
    <Dashboard>
      <StarterGuideModalComponent
        showInfoModal={showInfoModal}
        handleCloseStarterGuideModal={handleCloseStarterGuideModal}
      />

      <ContentContainer>
        <HeaderComponent />
        {renderMenuContent()}
      </ContentContainer>
    </Dashboard>
  );
};
