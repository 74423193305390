import { Ball, Switch } from './styles';

interface IToggleAtom {
  handleToggle: () => void;
  isToggled: boolean;
  isDisabled?: boolean;
  size?: 'small' | 'large';
}

export const ToggleAtom = ({
  handleToggle,
  isToggled,
  isDisabled = false,
  size = 'large',
}: IToggleAtom) => {
  return (
    <Switch
      size={size}
      onClick={handleToggle}
      isToggled={isToggled}
      isDisabled={isDisabled}
    >
      <Ball isToggled={isToggled} size={size} />
    </Switch>
  );
};
