import { useState } from 'react';
import { Box as MuiBox } from '@mui/material';
import { capitalize } from 'lodash';

import { GetStudyMaterialQuery } from '../../../../../../../../../generated';
import {
  useStudyMaterialContext,
  useSubjectContext,
} from '../../../../../../../../../hooks';
import {
  ButtonAtom,
  SelectorAtom,
  TextInputAtom,
} from '../../../../../../../../library/atoms';
import { ModalMolecule } from '../../../../../../../../library/molecules';
import { commonStrings } from '../../../../../../../../../assets/strings/sv';

interface IEditComponent {
  studyMaterial: GetStudyMaterialQuery['getStudyMaterial'];
  isModalOpen: boolean;
  handleCloseModal: () => void;
}

export const EditComponent = ({
  studyMaterial,
  isModalOpen,
  handleCloseModal,
}: IEditComponent) => {
  const [subjectId, setSubjectId] = useState(
    studyMaterial.subject?.id || commonStrings.defaultSubject.id
  );
  const [title, setTitle] = useState(studyMaterial.title);
  const [error, setError] = useState('');

  const { updateStudyMaterial } = useStudyMaterialContext();
  const { subjects } = useSubjectContext();

  const handleChangeTitle = (title: string) => {
    setError('');
    setTitle(title);
  };

  const handleClose = () => {
    setTitle(studyMaterial.title);
    handleCloseModal();
  };

  const handleSaveChanges = () => {
    if (!title) {
      return setError(commonStrings.studyMaterialSection.errorMessages.title);
    }

    updateStudyMaterial(studyMaterial.id, { title, subjectId });
    handleClose();
  };

  return (
    <ModalMolecule
      title={capitalize(`${commonStrings.edit} ${commonStrings.studyMaterial}`)}
      isOpen={isModalOpen}
      onClose={handleClose}
    >
      <MuiBox display="flex" flexDirection="column" gap={2}>
        <MuiBox display="flex" flexDirection="column" gap={1}>
          <TextInputAtom
            variant="outlined"
            errorMessage={error}
            value={title}
            onChange={handleChangeTitle}
          />
          <SelectorAtom
            variant="outlined"
            value={subjectId}
            listItems={[...subjects, commonStrings.defaultSubject]}
            comparisonValue="id"
            renderProperty="subject"
            onSelectedItem={(id) => setSubjectId(id)}
          />
        </MuiBox>
        <MuiBox display="flex" alignItems="center" gap={1}>
          <ButtonAtom
            text={commonStrings.save}
            fullWidth
            variant="primary"
            onClick={handleSaveChanges}
          />
          <ButtonAtom
            variant="border"
            fullWidth
            text={commonStrings.cancel}
            onClick={handleClose}
          />
        </MuiBox>
      </MuiBox>
    </ModalMolecule>
  );
};
