import { Box as MuiBox, styled } from '@mui/material';
import MuiAccordionSummary from '@mui/material/AccordionSummary';

export const Container = styled(MuiBox, {
  shouldForwardProp: (prop) =>
    prop !== 'isFirstListItem' && prop !== 'isLastListItem',
})<{ isFirstListItem: boolean; isLastListItem: boolean }>(
  ({ theme, isFirstListItem, isLastListItem }) => ({
    background: theme.palette.background[400],
    borderBottom: `2px solid ${theme.palette.background.default}`,
    padding: '16px',
    width: '100%',
    borderTopLeftRadius: isFirstListItem ? '8px' : '0px',
    borderTopRightRadius: isFirstListItem ? '8px' : '0px',
    borderBottomLeftRadius: isLastListItem ? '8px' : '0px',
    borderBottomRightRadius: isLastListItem ? '8px' : '0px',
    [theme.breakpoints.down('sm')]: {
      padding: '12px',
    },
    '&:hover': {
      background: theme.palette.background[100],
    },
    '&:hover .hover-effect-with-container': {
      background: theme.palette.background[500],
    },
  })
);

export const QuestionContainer = styled(MuiBox, {
  shouldForwardProp: (prop) => prop !== 'isCorrect',
})<{ isCorrect: boolean }>(({ theme, isCorrect }) => ({
  background: theme.palette.background[800],
  border: isCorrect
    ? `1px solid ${theme.palette.primary.main}`
    : `1px solid ${theme.palette.error.light}`,
  borderRadius: '8px',
  padding: '8px 12px',
  gap: '12px',
  display: 'flex',
  alignItems: 'center',
}));

export const QuestionNumber = styled(MuiBox)(({ theme }) => ({
  background: theme.palette.background[200],
  borderRadius: '8px',
  padding: '1px 8px',
  width: '24px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const AccordionSummary = styled(MuiAccordionSummary)(({ theme }) => ({
  minHeight: '0 !important',
  padding: '0 !important',
  margin: '0 !important',
  '&.Mui-expanded': {
    minHeight: '0 !important',
    padding: '0 !important',
    margin: '0 !important',
  },
  '& .MuiAccordionSummary-content': {
    minHeight: '0 !important',
    margin: '0 !important',
    padding: '0 !important',
  },
}));

export const ResultContainer = styled(MuiBox)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  gap: '8px',
  marginTop: '8px',
}));

export const AnswerContainer = styled(MuiBox)(({ theme }) => ({
  backgroundColor: theme.palette.background[800],
  borderRadius: '8px',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: '100%',
  gap: '12px',
  marginTop: '8px',
  padding: '8px 12px',
}));

export const IconContainer = styled(MuiBox)(({ theme }) => ({
  width: '24px',
}));

export const CorrectAnswerIcon = styled(MuiBox, {
  shouldForwardProp: (prop) => prop !== 'check',
})<{ check: string }>(({ theme, check }) => ({
  backgroundImage: `url(${check})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  width: '24px',
  height: '24px',
}));

export const WrongAnswerIcon = styled(MuiBox, {
  shouldForwardProp: (prop) => prop !== 'cancel',
})<{ cancel: string }>(({ theme, cancel }) => ({
  backgroundImage: `url(${cancel})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  width: '24px',
  height: '24px',
}));

export const WrongAnswerContainer = styled(MuiBox)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
}));
