import { Box as MuiBox, styled } from '@mui/material';

import backgroundImage from '../../../../../../assets/images/backgroundImage.svg';

export const SelectedFlashCardContainer = styled(MuiBox)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  display: 'flex',
  flexDirection: 'column',
  paddingBottom: '25px',
  backgroundImage: `url(${backgroundImage})`,
  backgroundSize: 'cover',
  backgroundColor: theme.palette.background.default,
}));

export const BodyContainer = styled(MuiBox)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  flex: 1,
  justifyContent: 'center',
  gap: 16,
  position: 'relative',
}));

export const SubContainer = styled(MuiBox)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  flex: 1,
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

export const CarouselContainer = styled(MuiBox)(({ theme }) => ({
  display: 'flex',
  overflowX: 'hidden',
  maxWidth: '710px',
  flex: 1,
  height: '100%',
  [theme.breakpoints.down('sm')]: {
    maxWidth: '100%',
  },
}));

export const CardContainer = styled(MuiBox, {
  shouldForwardProp: (prop) => prop !== 'currentIndex',
})<{ currentIndex: number }>(({ theme, currentIndex }) => ({
  display: 'flex',
  justifyContent: 'center',
  flex: '0 0 100%',
  transition: 'transform 0.5s ease-in-out',
  transform: `translateX(-${currentIndex * 100}%)`,
}));
