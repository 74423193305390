import { InMemoryCache } from '@apollo/client';
import { BrowserRouter } from 'react-router-dom';

import { Routes } from './router';
import { usePostErrorMessageMutation } from './generated';
import { RatingProvider, TextToSpeechProvider, UsageProvider } from './context';
import { CustomErrorBoundary } from './customErrorBoundary';
import { CustomAuth0Provider } from './customAuth0Provider';
import { CustomApolloProvider } from './customApolloProvider';

export const App = ({ cache }: { cache: InMemoryCache }) => {
  const [postErrorMessage] = usePostErrorMessageMutation();

  const handlePostErrorMessage = async (errorMessage: string) => {
    try {
      await postErrorMessage({
        variables: {
          errorMessage,
        },
      });
    } catch (error) {
      console.error('Error reporting issue', { error });
    }
  };

  return (
    <CustomErrorBoundary postErrorMessage={handlePostErrorMessage}>
      <BrowserRouter>
        <CustomAuth0Provider>
          <TextToSpeechProvider>
            <CustomApolloProvider cache={cache}>
              <UsageProvider>
                <RatingProvider>
                  <Routes />
                </RatingProvider>
              </UsageProvider>
            </CustomApolloProvider>
          </TextToSpeechProvider>
        </CustomAuth0Provider>
      </BrowserRouter>
    </CustomErrorBoundary>
  );
};
