import { Typography as MuiTypography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { CardComponent } from './components';
import { homePageStrings } from '../../../../../assets/strings/sv';
import { Features, CardContainer } from './styles';

export const FeaturesComponent = () => {
  const navigate = useNavigate();

  const { features } = homePageStrings;

  const navigateToQuiz = () => {
    // Link is prod specific
    navigate('explore/example/650c066d4b2fde04db3a78e1/quiz');
  };
  const navigateToFlashcards = () => {
    // Link is prod specific
    navigate('explore/example/650c0c7fe966c1ef42614592/flashcard');
  };
  const navigateToExam = () => {
    // Link is prod specific
    navigate('explore/example/650c066d4b2fde04db3a78e1/exam');
  };

  return (
    <Features>
      <MuiTypography variant="h3">{features.title}</MuiTypography>
      <CardContainer>
        <CardComponent
          title={features.quiz.title}
          text={features.quiz.text}
          buttonText={features.quiz.button}
          onClick={navigateToQuiz}
        />
        <CardComponent
          title={features.flashcard.title}
          text={features.flashcard.text}
          buttonText={features.flashcard.button}
          onClick={navigateToFlashcards}
        />
        <CardComponent
          title={features.exam.title}
          text={features.exam.text}
          buttonText={features.exam.button}
          onClick={navigateToExam}
        />
      </CardContainer>
    </Features>
  );
};
