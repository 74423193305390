import { Box as MuiBox, styled } from '@mui/material';

export const DemoSection = styled(MuiBox)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  rowGap: 16,
  width: '100%',
  height: 550,
  padding: '32px 24px',
  backgroundColor: theme.palette.background[800],
  borderRadius: theme.shape.borderRadius,
}));
