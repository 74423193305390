import { Box as MuiBox, styled } from '@mui/material';

export const GradientBorder = styled(MuiBox, {
  shouldForwardProp: (prop) => prop !== 'isGradient',
})<{ isGradient: boolean }>(({ theme, isGradient }) => ({
  position: 'relative',
  width: '100%',
  height: 'auto',
  backgroundColor: theme.palette.background.paper,
  border: '2px solid transparent',
  backgroundClip: 'padding-box',
  borderRadius: theme.shape.borderRadius,
  '&:after': {
    position: 'absolute',
    top: -1,
    left: -1,
    right: -1,
    bottom: -1,
    background: isGradient
      ? theme.palette.gradient
      : theme.palette.background.paper,
    content: '""',
    zIndex: -1,
    borderRadius: theme.shape.borderRadius,
  },
}));
