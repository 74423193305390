import {
  CreateQuizInput,
  Status,
  UpdateQuizInput,
  useCreateQuizMutation,
  useUpdateQuizMutation,
} from '../../generated';
import { useStudyMaterialStatusContext } from '../../hooks';
import { QuizContext } from './context';

interface IQuizProvider {
  children: React.ReactNode;
}

export const QuizProvider = ({ children }: IQuizProvider) => {
  const { updateStudyMaterialStatus } = useStudyMaterialStatusContext();

  const [_createQuiz] = useCreateQuizMutation();
  const [_updateQuiz] = useUpdateQuizMutation();

  const createQuiz = async (input: CreateQuizInput) => {
    updateStudyMaterialStatus({ [input.type]: Status.Pending });

    await _createQuiz({
      variables: {
        input,
      },
      onError: (error) => {
        console.error('Could not create quiz', { error });
      },
    });
  };

  const updateQuiz = async (id: string, update: UpdateQuizInput) => {
    console.log({ id, update });
    await _updateQuiz({
      variables: {
        id,
        update,
      },
      onError: (error) => {
        console.error('Could not update quiz', { error });
      },
    });
  };

  const config = {
    createQuiz,
    updateQuiz,
  };

  return <QuizContext.Provider value={config}>{children}</QuizContext.Provider>;
};
