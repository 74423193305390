import { GARatingCategory } from '../usage';

export type RatingPopup = {
  title: string;
  category: GARatingCategory;
  rating: number | null;
};

export interface IRatingState {
  popup: RatingPopup | null;
  isOpen: boolean;
}

export const initialRatingState: IRatingState = {
  popup: null,
  isOpen: false,
};
