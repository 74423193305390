import { Typography as MuiTypography, Slide, useTheme } from '@mui/material';

import { useAuth0 } from '@auth0/auth0-react';
import { commonStrings } from '../../../../../../assets/strings/sv';
import removeIcon from './images/remove.svg';
import {
  IconContainer,
  SnackbarContainer,
  SnackbarContent,
  Text,
  TitleContainer,
  TitleContent,
  TitleLink,
} from './style';

interface ILoginBenefitsSnackbar {
  open: boolean;
  onClose: () => void;
}

export const LoginBenefitsSnackbar = ({
  open,
  onClose,
}: ILoginBenefitsSnackbar) => {
  const theme = useTheme();
  const { loginWithRedirect } = useAuth0();

  if (!open) return null;

  const handleCreateAccount = () => {
    loginWithRedirect({ authorizationParams: { screen_hint: 'signup' } });
  };

  return (
    <SnackbarContainer>
      <Slide direction="up" in={open} mountOnEnter unmountOnExit>
        <SnackbarContent>
          <TitleContainer>
            <TitleContent>
              <TitleLink onClick={handleCreateAccount} variant="subtitle2">
                {commonStrings.loginBenefitsComponent.titlePartOne}
              </TitleLink>
              <MuiTypography>
                {commonStrings.loginBenefitsComponent.hyphen}
              </MuiTypography>
              <MuiTypography
                variant="subtitle2"
                color={theme.palette.primary[300]}
              >
                {commonStrings.loginBenefitsComponent.titlePartTwo}
              </MuiTypography>
            </TitleContent>
            <IconContainer onClick={onClose}>
              <img src={removeIcon} alt="Remove-icon"></img>
            </IconContainer>
          </TitleContainer>
          <Text variant="subtitle2">
            {commonStrings.loginBenefitsComponent.text}
          </Text>
        </SnackbarContent>
      </Slide>
    </SnackbarContainer>
  );
};
