import { SxProps, Theme } from '@mui/material';

import { Icon } from './styles';

interface IIconAtom {
  icon: JSX.Element;
  color?: string;
  backgroundColor?: string;
  borderColor?: string;
  sx?: SxProps<Theme>;
}

export const IconAtom = ({
  icon,
  color,
  backgroundColor,
  borderColor,
  sx,
}: IIconAtom) => {
  return (
    <Icon
      color={color}
      backgroundColor={backgroundColor}
      borderColor={borderColor}
      sx={sx}
    >
      {icon}
    </Icon>
  );
};
