import React from 'react';

import {
  IStudyMaterialStatusState,
  initialStudyMaterialStatusState,
} from './state';
import { ContentStatus } from '../../generated';

export interface IStudyMaterialStatusContext extends IStudyMaterialStatusState {
  updateStudyMaterialStatus: (update: Partial<ContentStatus>) => void;
}

const stub = (): never => {
  throw new Error('You forgot to wrap your component.');
};

const initialStudyMaterialStatusContext: IStudyMaterialStatusContext = {
  ...initialStudyMaterialStatusState,
  updateStudyMaterialStatus: stub,
};

export const StudyMaterialStatusContext = React.createContext(
  initialStudyMaterialStatusContext
);
