import {
  Box as MuiBox,
  Paper as MuiPaper,
  LinearProgress as MuiLinearProgress,
  styled,
} from '@mui/material';

export const ModuleContainer = styled(MuiBox)(({ theme }) => ({
  flex: 1,
  height: '100%',
  width: '100%',
  minHeight: '200px',
  position: 'relative',
  overflow: 'hidden',
  borderRadius: theme.shape.borderRadius,
  cursor: 'pointer',
}));

export const ModuleContent = styled(MuiPaper)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: 15,
  width: '100%',
  height: '100%',
  backgroundColor: theme.palette.background[500],
  '&:hover': {
    backgroundColor: theme.palette.background[300],
  },
}));

export const LinearProgress = styled(MuiLinearProgress)(({ theme }) => ({
  position: 'absolute',
  bottom: 0,
  right: 0,
  left: 0,
}));
