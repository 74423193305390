import { useState } from 'react';
import {
  Box as MuiBox,
  Grid as MuiGrid,
  Typography as MuiTypography,
} from '@mui/material';
import { EditOutlined } from '@mui/icons-material';

import { FailureReason, Status } from '../../../../generated';
import {
  useFlashcardContext,
  useStudyMaterialContext,
  useUsageContext,
} from '../../../../hooks';
import {
  ErrorStatusContainerMolecule,
  StatusContainerMolecule,
} from '../../molecules';
import {
  FlashcardsSkeletonComponent,
  SelectedFlashcardComponent,
  FlipFlashcardComponent,
  EditModalComponent,
} from './components';
import { commonStrings } from '../../../../assets/strings/sv';
import { IconButtonAtom } from '../../atoms';

export interface IFlashcardOrganism {
  isEditModeAvailable?: boolean;
}

export const FlashcardOrganism = ({
  isEditModeAvailable,
}: IFlashcardOrganism) => {
  const { createFlashcards } = useFlashcardContext();
  const { studyMaterial } = useStudyMaterialContext();
  const { flashcards, title, language } = {
    ...studyMaterial,
    flashcards: studyMaterial?.flashcards || [],
    title: studyMaterial?.title || '',
  };

  const { collectFlashcardFlippedStats, collectFlashcardFocusedStats } =
    useUsageContext();

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedCard, setSelectedCard] = useState<string | undefined>(
    undefined
  );
  const [isAnswerVisibleArray, setIsAnswerVisibleArray] = useState<boolean[]>(
    new Array(flashcards?.length).fill(false)
  );

  const { statusMessages, studyMaterialSection } = commonStrings;

  const handleSelectCard = (
    e: React.MouseEvent<HTMLButtonElement>,
    index: number
  ) => {
    e.stopPropagation();

    if (selectedCard) {
      return setSelectedCard(undefined);
    }

    const indexedCard = flashcards.find(
      (card) => card.id === flashcards[index].id
    );

    setSelectedCard(indexedCard?.id);
    collectFlashcardFocusedStats();
  };

  const handleOpenEditModal = () => setIsEditModalOpen(true);
  const handleCloseEditModal = () => setIsEditModalOpen(false);

  const handleFlipCard = (index: number) => {
    collectFlashcardFlippedStats();

    setIsAnswerVisibleArray((prevArray) => {
      return prevArray.map((answerVisible, i) =>
        i === index ? !answerVisible : answerVisible
      );
    });
  };

  if (studyMaterial?.status.flashcards === Status.Failed) {
    const { id, language, text } = studyMaterial;
    const flashcardsErrorMessage =
      statusMessages.flashcards.create.error.default[
        studyMaterial.failureReason === FailureReason.ContentViolation
          ? 'contentViolation'
          : 'default'
      ];

    return (
      <ErrorStatusContainerMolecule
        title={studyMaterialSection.unknownErrorTitle}
        buttonText={studyMaterialSection.retryButtonText}
        description={flashcardsErrorMessage}
        onClick={() =>
          createFlashcards({ studyMaterialId: id, language, text })
        }
      />
    );
  }

  if (studyMaterial?.status.flashcards === Status.Pending) {
    return (
      <MuiBox display="flex" flexDirection="column" flex={1}>
        <MuiBox>
          <StatusContainerMolecule
            statusMessage={statusMessages.flashcards.create.pending}
            descriptionText={statusMessages.flashcards.create.descriptionText}
          />
          <FlashcardsSkeletonComponent skeletonCount={8} />
        </MuiBox>
      </MuiBox>
    );
  }

  return (
    <MuiBox display="flex" flexDirection="column" flex={1}>
      <MuiBox>
        <MuiBox
          display="flex"
          justifyContent="flex-end"
          width="100%"
          marginBottom={2}
        >
          {isEditModeAvailable && (
            <IconButtonAtom
              icon={<EditOutlined />}
              onClick={handleOpenEditModal}
            />
          )}
        </MuiBox>
        <EditModalComponent
          isOpen={isEditModalOpen}
          handleClose={handleCloseEditModal}
        />
        <MuiBox>
          {!!selectedCard ? (
            <SelectedFlashcardComponent
              title={title}
              flashcards={flashcards}
              language={language}
              selectedCardId={selectedCard}
              setSelectedCard={setSelectedCard}
              handleSelectCard={handleSelectCard}
              handleFlipCard={handleFlipCard}
              isAnswerVisibleArray={isAnswerVisibleArray}
              setIsAnswerVisibleArray={setIsAnswerVisibleArray}
            />
          ) : (
            <MuiGrid container spacing={2}>
              {flashcards.map((card, index) => (
                <MuiGrid item key={card.id} xs={12} sm={6} md={4} lg={3} xl={3}>
                  <FlipFlashcardComponent
                    flashcard={card}
                    language={language}
                    isCardSelected={false}
                    index={index}
                    handleSelectCard={(e) => handleSelectCard(e, index)}
                    handleFlipCard={() => handleFlipCard(index)}
                    isAnswerVisibleArray={isAnswerVisibleArray}
                  />
                </MuiGrid>
              ))}
            </MuiGrid>
          )}
        </MuiBox>
      </MuiBox>
      <MuiBox marginTop="auto" justifySelf="flex-end">
        <MuiTypography
          marginTop={3}
          color="grey"
          variant="subtitle2"
          textAlign="center"
        >
          {commonStrings.disclaimer}
        </MuiTypography>
      </MuiBox>
    </MuiBox>
  );
};
