import { Modal as MuiModal, Box as MuiBox, styled } from '@mui/material';

export const Modal = styled(MuiModal, {
  shouldForwardProp: (prop) => prop !== 'backgroundImage',
})<{ backgroundImage: string }>(({ theme, backgroundImage }) => ({
  width: '100vw',
  height: '100dvh',
  backgroundImage: `url(${backgroundImage})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundColor: theme.palette.background[900],
}));

export const ModalContent = styled(MuiBox)(({ theme }) => ({
  height: '100dvh',
  width: '100vw',
  display: 'flex',
  flexDirection: 'column',
  padding: '25px 40px !important',

  [theme.breakpoints.down('sm')]: {
    padding: '20px !important',
  },
}));

export const HeaderContainer = styled(MuiBox)(({ theme }) => ({
  display: 'flex',
  height: 'auto',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '42px',
  [theme.breakpoints.down('sm')]: {
    marginBottom: '16px',
  },
}));

export const ButtonContainer = styled(MuiBox)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '16px',
}));

export const ChildContainer = styled(MuiBox)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flex: 1,
  overflowX: 'auto',
}));
