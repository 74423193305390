import { Language } from '@mui/icons-material';
import { useMediaQuery, useTheme } from '@mui/material';

import { GetStudyMaterialQuery } from '../../../../../../../../../generated';
import { useStudyMaterialContext } from '../../../../../../../../../hooks';
import { ToggleFieldMolecule } from '../../../../../../../../library/molecules';
import { commonStrings } from '../../../../../../../../../assets/strings/sv';

interface IPublishComponent {
  studyMaterial: GetStudyMaterialQuery['getStudyMaterial'];
}

export const PublishComponent = ({ studyMaterial }: IPublishComponent) => {
  const { publishStudyMaterial } = useStudyMaterialContext();

  const theme = useTheme();
  const isLargeDesktop = useMediaQuery(theme.breakpoints.up('lg'));

  return (
    <ToggleFieldMolecule
      size="small"
      isToggled={Boolean(studyMaterial.flags?.isPublished)}
      handleToggle={() => publishStudyMaterial(studyMaterial.id)}
      isDisabled={!Boolean(studyMaterial)}
      text={isLargeDesktop ? commonStrings.publishStudyMaterial : undefined}
      tooltipText={commonStrings.publishStudyMaterialDescription}
      icon={<Language fontSize="small" />}
    />
  );
};
