import { Box as MuiBox, styled } from '@mui/material';

export const CloseButton = styled(MuiBox)(({ theme }) => ({
  padding: 2,
  cursor: 'pointer',
  alignSelf: 'end',
  zIndex: 9999,
}));

export const ModalContent = styled(MuiBox)(({ theme }) => ({
  position: 'absolute',
  display: 'flex',
  flexDirection: 'column',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'fit-content',
  maxWidth: '90vw',
  height: 'fit-content',
  maxHeight: '85vh',
  backgroundColor: theme.palette.background[800],
  padding: theme.spacing(4),
  borderRadius: theme.shape.borderRadius,
  outline: 'none',
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(3),
  },
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2),
    width: '100%',
  },
}));

export const ModalInnerContent = styled(MuiBox)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  maxWidth: '100%',
  overflow: 'auto',
}));

export const ChildContainer = styled(MuiBox)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  overflowY: 'auto',
  maxWidth: 'inherit',
  width: 'inherit',
  maxHeight: '100%',
  marginTop: 20,
  [theme.breakpoints.down('sm')]: {
    marginTop: 16,
  },
}));
