import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { ulid } from 'ulid';

import { collectResultSatisfaction } from './collectResultSatisfaction';
import { IUsageContext, UsageContext } from './context';
import {
  collectFinishedQuizStats,
  collectRetryWrongAnswersQuizStats,
  collectShareQurieUrlStats,
  collectShareStudyMaterialUrlStats,
  collectSharedPagesVisitStats,
  collectStartOverQuizStats,
  collectStartedQuizStats,
  collectStudyMaterialGeneratedStats,
  collectTextToSpeechStats,
  collectFlashcardFocusedStats,
  collectFlashcardFlippedStats,
  collectExamEvaluationStats,
  collectRatingStats,
  collectRatingAppearanceStats,
} from './helpers';
interface IUsageProvider {
  children: React.ReactNode;
}

enum LocalStorage {
  NonUserAuthId = 'nonUserAuthId',
}

const UsageProvider = ({ children }: IUsageProvider) => {
  const { isAuthenticated, user } = useAuth0();

  // Set the userId for statistics collection
  useEffect(() => {
    if (!isAuthenticated) {
      let nonAuthUserId = localStorage.getItem(LocalStorage.NonUserAuthId);

      // Save Ulid the first time in local storage
      if (!nonAuthUserId) {
        nonAuthUserId = ulid();
        localStorage.setItem(LocalStorage.NonUserAuthId, nonAuthUserId);
      }

      ReactGA.set({ user_properties: { userId: nonAuthUserId } });
    } else {
      if (user?.sub) {
        ReactGA.set({ user_properties: { userId: user.sub } });
      }
    }
  }, [isAuthenticated, user]);

  const config: IUsageContext = {
    collectStudyMaterialGeneratedStats,
    collectShareStudyMaterialUrlStats,
    collectShareQurieUrlStats,
    collectSharedPagesVisitStats,
    collectStartedQuizStats,
    collectStartOverQuizStats,
    collectRetryWrongAnswersQuizStats,
    collectFinishedQuizStats,
    collectResultSatisfaction,
    collectTextToSpeechStats,
    collectFlashcardFocusedStats,
    collectFlashcardFlippedStats,
    collectExamEvaluationStats,
    collectRatingStats,
    collectRatingAppearanceStats,
  };

  return (
    <UsageContext.Provider value={config}>{children}</UsageContext.Provider>
  );
};

export { UsageProvider };
