import React, { useState } from 'react';
import { AdsClick, Fullscreen } from '@mui/icons-material';
import {
  Box as MuiBox,
  Typography as MuiTypography,
  useTheme,
} from '@mui/material';
import ReactCardFlip from 'react-card-flip';

import { commonStrings } from '../../../../../../assets/strings/sv';
import { HighlightedTextAtom, IconButtonAtom } from '../../../../atoms';
import {
  CardBack,
  CardFront,
  CardHeader,
  ClickToFlipText,
  Container,
  SubContainer,
  TextContainer,
} from './styles';
import { AudioPlayerMolecule } from '../../../../molecules/audioPlayer';
import { Flashcard, Language } from '../../../../../../generated';
import { ContentType } from '../../../../../../utils';

interface IFlipFlashcardComponent {
  flashcard: Flashcard;
  language?: Language;
  handleSelectCard: (event: React.MouseEvent<HTMLButtonElement>) => void;
  isCardSelected: boolean;
  index: number;
  handleFlipCard: () => void;
  isAnswerVisibleArray: boolean[];
}

export const FlipFlashcardComponent = ({
  flashcard,
  language,
  handleSelectCard,
  isCardSelected,
  index,
  handleFlipCard,
  isAnswerVisibleArray,
}: IFlipFlashcardComponent) => {
  const theme = useTheme();

  const { studyMaterialSection } = commonStrings;

  const FlashcardFront = () => {
    const [highlightIndexes, setHighlightIndexes] = useState({
      end: 0,
      start: 0,
    });

    return (
      <CardFront isCardSelected={isCardSelected} onClick={handleFlipCard}>
        <CardHeader>
          <MuiTypography variant="subtitle2">{index + 1}</MuiTypography>
          <MuiBox display="flex" columnGap={theme.spacing(1)}>
            {!isCardSelected && (
              <IconButtonAtom
                onClick={handleSelectCard}
                icon={<Fullscreen />}
                shape="round"
              />
            )}
            <AudioPlayerMolecule
              statsContentType={ContentType.FLASHCARD}
              text={flashcard.question}
              language={language?.name || commonStrings.defaultLanguage}
              setCaptionedCharacterIndexes={(startIndex, endIndex) =>
                setHighlightIndexes({ start: startIndex, end: endIndex })
              }
            />
          </MuiBox>
        </CardHeader>

        <TextContainer>
          <HighlightedTextAtom
            startHighlightIndex={highlightIndexes.start}
            endHighlightIndex={highlightIndexes.end}
            text={flashcard.question}
          />
        </TextContainer>

        <SubContainer>
          <ClickToFlipText variant={!isCardSelected ? 'caption' : 'body1'}>
            {studyMaterialSection.flashcardSection.flipText}
            <AdsClick fontSize={!isCardSelected ? 'small' : 'medium'} />
          </ClickToFlipText>
        </SubContainer>
      </CardFront>
    );
  };

  const FlashcardBack = () => {
    const [highlightIndexes, setHighlightIndexes] = useState({
      end: 0,
      start: 0,
    });

    return (
      <CardBack isCardSelected={isCardSelected} onClick={handleFlipCard}>
        <CardHeader>
          <MuiTypography variant="subtitle2">{index + 1}</MuiTypography>
          <MuiBox display="flex" columnGap={theme.spacing(1)}>
            {!isCardSelected && (
              <MuiBox sx={{ display: 'flex', gap: '10px' }}>
                <IconButtonAtom
                  onClick={handleSelectCard}
                  icon={<Fullscreen />}
                  shape="round"
                />
              </MuiBox>
            )}
            <AudioPlayerMolecule
              statsContentType={ContentType.FLASHCARD}
              text={flashcard.answer}
              language={language?.name || commonStrings.defaultLanguage}
              setCaptionedCharacterIndexes={(startIndex, endIndex) =>
                setHighlightIndexes({ start: startIndex, end: endIndex })
              }
            />
          </MuiBox>
        </CardHeader>

        <TextContainer>
          <HighlightedTextAtom
            startHighlightIndex={highlightIndexes.start}
            endHighlightIndex={highlightIndexes.end}
            text={flashcard.answer}
          />
        </TextContainer>

        <SubContainer>
          <ClickToFlipText variant={!isCardSelected ? 'caption' : 'body1'}>
            {studyMaterialSection.flashcardSection.flipText}
            <AdsClick fontSize={!isCardSelected ? 'small' : 'medium'} />
          </ClickToFlipText>
        </SubContainer>
      </CardBack>
    );
  };

  return (
    <Container isCardSelected={isCardSelected}>
      <ReactCardFlip
        isFlipped={isAnswerVisibleArray[index]}
        flipDirection="horizontal"
      >
        <FlashcardFront />
        <FlashcardBack />
      </ReactCardFlip>
    </Container>
  );
};
