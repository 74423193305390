import { Box as MuiBox, styled } from '@mui/material';

export const StepContainer = styled(MuiBox)(({ theme }) => ({
  position: 'relative',
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  borderLeft: `1px solid ${theme.palette.text.secondary}`,
}));

export const StepIndicator = styled(MuiBox)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  minWidth: '40px',
  minHeight: '40px',
  borderRadius: 100,
  border: `1px solid ${theme.palette.text.secondary}`,
  backgroundColor: theme.palette.background[900],
}));

export const Step = styled(MuiBox)(({ theme }) => ({
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  left: '-20px',
}));
