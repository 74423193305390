import { Box as MuiBox, styled } from '@mui/material';

export const Container = styled(MuiBox)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: 24,
  rowGap: 8,
  backgroundColor: theme.palette.background[800],
  borderRadius: theme.shape.borderRadius,
  border: `1px solid ${theme.palette.divider}`,
  height: '100%',
}));

export const FeedbackContainer = styled(MuiBox)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  border: `1px solid ${theme.palette.secondary[200]}`,
  backgroundColor: theme.palette.background[900],
  borderRadius: theme.shape.borderRadius,
  padding: 24,
  rowGap: 16,
}));

export const DarkContainer = styled(MuiBox)(({ theme }) => ({
  padding: '4px 10px',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background[900],
}));

export const AudioPlayerContainer = styled(MuiBox)(({ theme }) => ({
  marginLeft: 4,
}));
