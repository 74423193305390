import React from 'react';
import { Tooltip as MuiTooltip, TooltipProps } from '@mui/material';

interface ITooltipAtom {
  title: string;
  placement?: TooltipProps['placement'];
  children: React.ReactElement;
}

export const TooltipAtom = ({
  title,
  placement = 'top',
  children,
}: ITooltipAtom) => {
  return (
    <MuiTooltip title={title} placement={placement} arrow>
      {children}
    </MuiTooltip>
  );
};
