import {
  Link as MuiLink,
  Typography as MuiTypography,
  styled,
} from '@mui/material';

export const Link = styled(MuiLink)(({ theme }) => ({}));

export const Text = styled(MuiTypography)(({ theme }) => ({
  color: theme.palette.grey[400],
  textDecoration: 'underline',
  cursor: 'pointer',
  '&:hover': {
    color: theme.palette.grey[200],
  },
}));
