import { Paper as MuiPaper, styled } from '@mui/material';

export const NumberWidget = styled(MuiPaper)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  width: 'fit-content',
  padding: '4px 10px',
  '& svg': {
    marginLeft: 5,
    height: 16,
    width: 'auto',
  },
}));
