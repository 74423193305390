import { LanguageCode, Voice } from '../types';
import { getLanguageCode } from './index';

export const getVoiceName = (language: string) => {
  switch (getLanguageCode(language)) {
    case LanguageCode.SWEDISH:
      return Voice.SWEDISH;
    case LanguageCode.ENGLISH:
      return Voice.ENGLISH;
    default:
      return Voice.SWEDISH;
  }
};
