import React from 'react';
import { Box as MuiBox, SxProps, Theme } from '@mui/material';

import { paperWidth } from '../../../utils';

interface IPaperConstraintAtom {
  children: React.ReactNode;
  sx?: SxProps<Theme>;
}

export const PaperConstraintAtom = ({ children, sx }: IPaperConstraintAtom) => {
  return (
    <MuiBox
      sx={{ display: 'flex', flexDirection: 'column', flex: 1, ...sx }}
      height="100%"
      width="100%"
      maxWidth={paperWidth}
      margin="0 auto"
    >
      {children}
    </MuiBox>
  );
};
