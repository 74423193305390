import React from 'react';
import { IconButtonProps, alpha, lighten, useTheme } from '@mui/material';

import { IconButton } from './styles';
import { TooltipAtom } from '../tooltip';

type IconButtonType = Omit<IconButtonProps, 'variant' | 'shape'> & {
  icon: JSX.Element;
  tooltipTitle?: string;
  shape?: 'round' | 'square';
  variant?: 'primary' | 'dark' | 'light';
};

export const IconButtonAtom = (props: IconButtonType) => {
  const { variant, sx, shape, tooltipTitle, icon, ...restProps } = props;

  const theme = useTheme();

  let styling;

  switch (variant) {
    case 'primary':
      styling = {
        color: theme.palette.common.black,
        backgroundColor: theme.palette.primary.main,
        '&:hover': {
          backgroundColor: theme.palette.primary[600],
        },
        ...sx,
      };
      break;
    case 'dark':
      styling = {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.background[900],
        '&:hover': {
          backgroundColor: lighten(theme.palette.background?.[900] || '', 0.05),
        },
        ...sx,
      };
      break;
    case 'light':
      styling = {
        color: theme.palette.common.white,
        backgroundColor: alpha(theme.palette.common.white, 0.07),
        '&:hover': {
          backgroundColor: alpha(theme.palette.common.white, 0.1),
        },
        ...sx,
      };
      break;
    default:
      styling = {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.background[800],
        '&:hover': {
          backgroundColor: lighten(theme.palette.background?.[800] || '', 0.05),
        },
        ...sx,
      };
      break;
  }

  const iconButton = (
    <IconButton sx={styling} shape={shape || 'square'} {...restProps}>
      {icon}
    </IconButton>
  );

  if (tooltipTitle) {
    return <TooltipAtom title={tooltipTitle}>{iconButton}</TooltipAtom>;
  }
  return iconButton;
};
