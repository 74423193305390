import {
  Box as MuiBox,
  Typography as MuiTypography,
  styled,
} from '@mui/material';

export const ModalContainer = styled(MuiBox)(({ theme }) => ({
  padding: '24px',
  gap: '24px',
  display: 'flex',
  flexDirection: 'row',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
  borderRadius: '12px',
}));

export const TextContainer = styled(MuiBox)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
}));

export const StreakTagContainer = styled(MuiBox)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  padding: '4px 12px',
  borderRadius: '56px',
  background: theme.palette.grey[800],
  alignSelf: 'flex-start',
}));

export const TitleContainer = styled(MuiTypography)(({ theme }) => ({
  whiteSpace: 'nowrap',
}));

export const SubTitleContainer = styled(MuiTypography)(({ theme }) => ({
  lineHeight: '16.8px',
  letterSpacing: '0.28px',
  opacity: '0.6',
  whiteSpace: 'nowrap',
}));

export const AnimationContainer = styled(MuiBox)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
}));
