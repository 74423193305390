import { useAuth0 } from '@auth0/auth0-react';
import { Box as MuiBox, styled } from '@mui/material';
import React, { useEffect } from 'react';
import { Outlet, useParams } from 'react-router-dom';

import { SeoComponent } from '../seo';
import { LoginBenefitsComponent, RatingPopupComponent } from './components';
import {
  SubjectProvider,
  StudyMaterialStatusProvider,
  SummaryProvider,
  QuestionProvider,
  AnalysisQuestionProvider,
  QuizProvider,
  ExamProvider,
  FlashcardProvider,
  StudyMaterialProvider,
  GARatingCategory,
} from '../../../context';
import { useRatingContext } from '../../../hooks';
import { commonStrings } from '../../../assets/strings/sv';

interface IPage {
  children?: React.ReactNode;
}

const PageLayout = styled(MuiBox)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
}));

export const Page = ({ children }: IPage) => {
  const { handleOpenPopup } = useRatingContext();
  const { isAuthenticated } = useAuth0();
  const { studyMaterialId } = useParams();

  useEffect(() => {
    handleOpenPopup(commonStrings.rating.app, GARatingCategory.APP, 2 * 60);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SubjectProvider>
      <StudyMaterialStatusProvider id={studyMaterialId}>
        <SummaryProvider>
          <QuestionProvider>
            <AnalysisQuestionProvider>
              <QuizProvider>
                <ExamProvider>
                  <FlashcardProvider>
                    <StudyMaterialProvider id={studyMaterialId}>
                      <PageLayout>
                        <SeoComponent />
                        <RatingPopupComponent />
                        {!isAuthenticated && <LoginBenefitsComponent />}
                        {children || <Outlet />}
                      </PageLayout>
                    </StudyMaterialProvider>
                  </FlashcardProvider>
                </ExamProvider>
              </QuizProvider>
            </AnalysisQuestionProvider>
          </QuestionProvider>
        </SummaryProvider>
      </StudyMaterialStatusProvider>
    </SubjectProvider>
  );
};
