import { useEffect } from 'react';
import {
  Box as MuiBox,
  List as MuiList,
  ListItem as MuiListItem,
  Typography as MuiTypography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { capitalize } from 'lodash';

import { commonStrings } from '../../../../../../assets/strings/sv';
import { ButtonAtom } from '../../../../atoms';
import { UserAnswer } from '../../types';
import { CircularProgressComponent, QuizResultComponent } from './components';
import swish from './images/swish.png';
import {
  Container,
  InnerContainer,
  PointsContainer,
  PointsContent,
  ScoreContainer,
  Swish,
} from './styles';
import { useRatingContext } from '../../../../../../hooks';
import { GARatingCategory } from '../../../../../../context';

interface IQuizSummaryComponent {
  userAnswers: UserAnswer[];
  incorrectAnswers: UserAnswer[];
  correctAnswers: UserAnswer[];
  handleRetryWrongAnswers: () => void;
  handleRestartQuiz: () => void;
}

export const QuizSummaryComponent = ({
  userAnswers,
  incorrectAnswers,
  correctAnswers,
  handleRetryWrongAnswers,
  handleRestartQuiz,
}: IQuizSummaryComponent) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));
  const { quizSection } = commonStrings.studyMaterialSection;

  const { handleOpenPopup } = useRatingContext();

  useEffect(() => {
    handleOpenPopup(commonStrings.rating.quiz, GARatingCategory.QUIZ, 3);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const correctPercentage =
    Math.round((correctAnswers.length / userAnswers.length) * 1000) / 10;

  return (
    <Container>
      <InnerContainer>
        <ScoreContainer>
          <MuiBox>
            <PointsContainer>
              <MuiBox>
                <MuiTypography variant="subtitle2">
                  {capitalize(commonStrings.points)}
                </MuiTypography>
              </MuiBox>
              <PointsContent>
                <MuiTypography color={theme.palette.primary.main} variant="h5">
                  {correctAnswers.length}
                </MuiTypography>
                <MuiTypography variant="h5">{'/'}</MuiTypography>
                <MuiTypography variant="h5">{userAnswers.length}</MuiTypography>
              </PointsContent>
            </PointsContainer>
          </MuiBox>
          <MuiBox
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <CircularProgressComponent value={correctPercentage} />
          </MuiBox>
        </ScoreContainer>
        <Swish swish={swish} />
        <MuiTypography textAlign="left" variant="body1">
          {quizSection.quizIsDone.questions}
        </MuiTypography>

        <MuiList>
          {userAnswers.map((answer, index) => (
            <MuiListItem sx={{ padding: 0 }} key={index}>
              <QuizResultComponent
                questionNumber={index + 1}
                isFirstListItem={index === 0}
                isLastListItem={index === userAnswers.length - 1}
                userAnswers={answer}
              />
            </MuiListItem>
          ))}
        </MuiList>
      </InnerContainer>
      {!isDesktop && (
        <MuiBox
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          rowGap={1}
        >
          <ButtonAtom
            variant="gradient"
            sx={{ width: '100%' }}
            text={quizSection.quizIsDone.startOver}
            onClick={() => handleRestartQuiz()}
          />
          {incorrectAnswers.length ? (
            <ButtonAtom
              text={quizSection.quizIsDone.startOverIncorrectAnswers}
              onClick={handleRetryWrongAnswers}
              variant="border"
              sx={{ width: '100%' }}
            />
          ) : (
            <></>
          )}
        </MuiBox>
      )}
    </Container>
  );
};
