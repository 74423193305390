import React from 'react';

import green from './images/green_gradient.svg';
import pink from './images/pink_gradient.svg';
import { GradientBackground, Root } from './styles';

interface IGraphicLayout {
  children: React.ReactNode;
}
export const GradientLayout = ({ children }: IGraphicLayout) => {
  return (
    <Root>
      <GradientBackground backgroundImage={green} />
      <GradientBackground backgroundImage={pink} />
      {children}
    </Root>
  );
};
