import React from 'react';
import { Typography as MuiTypography, useTheme } from '@mui/material';

import { ParentContainer, TextContainer } from './styles';
import thunder_cloud from './images/thunder_cloud.svg';
import { ButtonAtom } from '../../atoms';

interface IErrorStatusContainerMolecule {
  onClick?: () => void;
  title: string;
  buttonText?: string;
  description: string;
}

export const ErrorStatusContainerMolecule = ({
  onClick,
  title,
  description,
  buttonText,
}: IErrorStatusContainerMolecule) => {
  const theme = useTheme();

  return (
    <ParentContainer>
      <img src={thunder_cloud} alt="thunder cloud" />
      <TextContainer>
        <MuiTypography variant="h2" fontSize={38} textAlign="center">
          {title}
        </MuiTypography>
        <MuiTypography
          color={theme.palette.text.secondary}
          variant="subtitle2"
          textAlign="center"
        >
          {description}
        </MuiTypography>
      </TextContainer>
      {buttonText && onClick && (
        <ButtonAtom
          variant="gradient"
          text={buttonText}
          onClick={() => onClick()}
        />
      )}
    </ParentContainer>
  );
};
