import { differenceInDays, startOfDay } from 'date-fns';

export const dateToUnix = (date: Date) => {
  return Math.floor(date.getTime() / 1000);
};

export const getTodayUnix = () => {
  const today = dateToUnix(new Date());
  return today;
};

export const getDaysSinceLastVisit = (lastVisit: number) => {
  const today = getTodayUnix();

  // StarOfDay only accepts Unix in milliseconds
  const daysDifference = differenceInDays(
    startOfDay(today * 1000),
    startOfDay(lastVisit * 1000)
  );
  return daysDifference;
};
