import { Typography as MuiTypography, Grid as MuiGrid } from '@mui/material';

import { GradientBorderAtom, TooltipAtom, ToggleAtom } from '../../atoms';

interface IToggleFieldMolecule {
  size?: 'small' | 'large';
  isToggled: boolean;
  isDisabled?: boolean;
  handleToggle: () => void;
  text?: string;
  tooltipText?: string;
  icon?: JSX.Element;
}

export const ToggleFieldMolecule = ({
  size = 'large',
  isToggled,
  isDisabled,
  text,
  handleToggle,
  tooltipText,
  icon,
}: IToggleFieldMolecule) => {
  return (
    <GradientBorderAtom isGradient={isToggled}>
      <MuiGrid
        container
        padding={size === 'small' ? '5px 8px' : '20px 12px'}
        alignItems="center"
        justifyContent="space-between"
        spacing={text ? 2 : 1.2}
      >
        <MuiGrid item container xs="auto" spacing={0.8} alignItems="center">
          {icon && (
            <MuiGrid item display="flex" alignItems="center">
              {icon}
            </MuiGrid>
          )}
          {text && (
            <MuiGrid item>
              <MuiTypography variant="body1">{text}</MuiTypography>
            </MuiGrid>
          )}
        </MuiGrid>
        <TooltipAtom title={tooltipText || ''}>
          <MuiGrid item>
            <ToggleAtom
              isToggled={isToggled}
              isDisabled={isDisabled}
              handleToggle={handleToggle}
              size={size}
            />
          </MuiGrid>
        </TooltipAtom>
      </MuiGrid>
    </GradientBorderAtom>
  );
};
